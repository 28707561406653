@import "partials/params";
@import "partials/mixins";

.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding-left: 3px;
  margin-bottom: 30px;
  & a {
    color: @font-gray-color;
  }
  & span {
    color: fade(@font-color, 40%);
  }
  & .separator {
    font-weight: 500;
    display: block;
    margin: 0 8px;
  }
}

@media (max-width: 1439px) {
  .breadcrumbs {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .breadcrumbs {
    margin-bottom: 20px;
  }
}
