@import "partials/params";
@import "partials/mixins";

body.body_main {
  background: #F7F8FA;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  color: @font-color-dark;
  & h3 {
    color: @font-color-dark;
  }
  & .main_wrap {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.first_ekran {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1315px;
  z-index: -1;
  background: linear-gradient(180deg, @blue_500 0%, @blue_400 63.67%, rgba(115, 120, 252, 0) 96.5%);
}
.main_land {
  overflow: hidden;
  padding: 10px 0;
}
.main_wrap {
  width: 100%;
  max-width: 1358px;
  padding: 10px 14px;
  margin: 0 auto;
  position: relative;
}
.main_nav {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 0 -20px;
  & li {
    padding: 0 10px;
    & a {
      display: block;
      padding: 3px 10px;
    }
  }
}
.header_currency_land.header_currency {
  margin-right: 0;
}
.sect {
  margin-top: 140px;
}
.main_h1 {
  font-size: 48px;
  font-weight: 800;
  line-height: 58px;
  text-align: center;
  max-width: 1020px;
  color: #fff;
  margin: 117px auto 0 auto;
  & span {
    display: inline-block;
    border: 2px solid;
    border-radius: 100px;
    padding: 0 28px 6px;
    margin-top: 13px;
    &.orange_h1 {
      color: @Peach_400;
    }
    &.mint_h1 {
      color: @mint_300;
    }
  }
}
.main_bt {
  text-align: center;
  margin-top: 80px;
  & .btn-white {
    color: @primary-color-main;
    &:hover, &.active, &:focus {
      background: @primary-color-bl400;
      color: #FFF;
    }
  }
}
.main_grbox {
  display: flex;
  margin: 80px -10px 0;
  & .grbox {
    padding: 0 10px;
    width: 29%;
    & > div {
      padding: 32px;
      background: @bg-main;
      border-radius: 20px;
      height: 100%;
    }
    & h3 {
      font-size: 26px;
      font-weight: 600;
      line-height: 36px;
      margin: 0 0 30px;
    }
  }
  & .grbox_img {
    width: 42%;
    padding: 0 10px;
    & img {
      display: block;
      max-width: none;
      border-radius: 20px;
    }
  }
}

ul.list_main {
  padding: 0;
  margin: 0;
  & li {
    position: relative;
    list-style: none;
    display: flex;
    align-items: center;
    & + li {
      margin-top: 14px;
    }
    &:before {
      content: "";
      width: 9px;
      min-width: 9px;
      height: 9px;
      border-radius: 100px;
      margin-right: 21px;
      background: @primary-color-main;
    }
  }
}
.main_list {
  margin-top: 168px;
}
h2.mainh2 {
  max-width: 880px;
  text-align: center;
  margin: 0 auto 80px;
  font-size: 48px;
  font-weight: 800;
  line-height: 58px;
}
.main_list_wrap {
  display: flex;
  margin: 0 -10px;
  & .listbox {
    width: calc(25% - 20px);
    padding: 36px 13px 0;
    & h3 {
      height: 108px;
      margin: 0 0 24px;
      padding: 0 6px;
      font-size: 26px;
      font-weight: 600;
      line-height: 36px;
      display: flex;
      & span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        min-width: 40px;
        height: 40px;
        margin-right: 16px;
        background: @mint_200;
        border-radius: @radius-btn;
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        color: @mint_700;
        & img {
          margin: 0 !important;
        }
      }
    }
    & img {
      display: block;
      margin: 26px auto 0;
    }
  }
}
.listbox {
  background: fade(@primary-color-main, 5%);
  border-radius: 20px;
  margin: 0 10px;
  font-size: 16px;
  line-height: 24px;
}

.tabs-content {
  & > div {
    display: none;
    background: url(./img/land/clients_fon.png) no-repeat center right;
    & img {
      display: block;
      margin: 0 auto;
    }
  }
}
.client_tabs {
  & .tabs-but {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 12px;
    border-radius: 30px;
    background: #FFFFFF;
    margin: -20px 0 60px;
    & a {
      font-size: 18px;
      line-height: 24px;
      padding: 8px 24px;
      color: @font-color;
      margin: 0 2px;
      border-radius: 20px;
      &.active {
        border-radius: 20px;
      }
    }
  }
  & h3 {
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    margin: 0 0 32px;
    max-width: 374px;
  }
  & ul.list_main li + li {
    margin-top: 23px;
    padding-top: 23px;
    border-top: 1px solid fade(@primary-color-bl600, 10%);
  }
  & .tabs-content {
    & > div {
      padding: 73px 53px 0 113px;
      border: 1px solid @bg-main;
      border-radius: 20px;
    }
    & .row > div {
      & .list_main {
        padding-bottom: 20px;
      }
      & + div {
        align-self: flex-end;
      }
    }
  }
}
.slide_tabs {
  padding: 78px 53px 0 53px;
  border: 1px solid @bg-main;
  border-radius: 20px;
  & .row {
    align-items: flex-end;
    margin-bottom: 0;
  }
  & .col6 {
    padding-bottom: 0;
  }
  & .tabs-but {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -20px 0 60px;
    & a {
      flex: 2 1 0px;
      transition: flex 0.5s ease-in-out;
      font-size: 22px;
      line-height: 28px;
      padding: 11px 0 8px 0;
      color: fade(@font-color, 40%);
      margin: 0 7px;
      border-radius: 0;
      border-top: 2px solid @onix_300;
      &.active {
        border-radius: 0;
        color: @font-color;
        background: transparent;
        border-top: 2px solid @primary-color-main;
        flex: 3 1 0px;
      }
      &:hover {
        border-radius: 0;
        color: @font-color;
        background: transparent;
        border-top: 2px solid @primary-color-main;
      }
    }
  }
  & h3 {
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
    margin: 0 0 25px;
  }
  & p {
    margin-bottom: 90px;
    font-size: 22px;
    line-height: 28px;
  }
}

.rekl_wrap {
  padding-top: 21px;
  padding-bottom: 23px;
  overflow: hidden;
  & > div {
    background: @bg-main;
    border-radius: 45px;
    padding: 20px 44px;
  }
  & .row {
    align-items: center;
  }
  & img {
    display: block;
    margin: -41px 0 -43px;
    & + img {
      display: none;
    }
  }
  & .rekl_h3 {
    font-size: 32px;
    font-weight: 800;
    line-height: 42px;
  }
  & p {
    margin: 25px 0 30px 0;
  }
  & .rekl_box {
    max-width: 348px;
    margin: 0 auto;
  }
  & .btn {
    padding: 11px 39px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
    border-radius: 20px;
  }
}
.main_otchet {
  background: #FFFFFF;
  padding-top: 60px;
  padding-bottom: 60px;
  & .main_list_wrap {
    background: url(./img/land/main_otchet.jpg) no-repeat left center;
    padding-left: calc(100% - 780px);
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    & .listbox {
      padding: 32px;
      background: #F0F0FA;
      width: 348px;
      & h3 {
        height: auto;
        margin: 0 0 17px;
        padding: 0;
      }
      &.listbox_otch2 {
        width: 317px;
      }
      &.listbox_otch3 {
        margin: 30px 94px 30px 40px;
      }
      &.listbox_otch4 {
        align-self: flex-end;
      }
      &.listbox_otch5 {
        width: 395px;
      }
    }
  }
  & .btn {
    margin: 80px auto 0;
  }
}
.main_support {
  background: #FFFFFF;
  padding: 80px 152px;
  border-radius: 20px;
  & .row {
    align-items: center;
  }
  & .col6 + .col6 {
    padding-left: 58px;
  }
  & h2 {
    text-align: left;
    margin: 0 0 32px;
    font-size: 56px;
    line-height: 56px;
    & span {
      color: @primary-color-main;
    }
  }
}
.main_reviews {
  & > img {
    display: block;
    margin: 0 auto 4px;
  }
  & .main_list_wrap .listbox {
    width: calc(33.3333% - 20px);
    padding: 32px 24px 32px;
    & h2 {
      font-size: 30px;
      font-weight: 600;
      line-height: 40px;
      text-align: center;
      margin: 0 0 6px;
    }
    & span {
      text-align: center;
      display: block;
      &:after {
        content: "";
        margin: 18px auto;
        width: 32px;
        height: 32px;
        display: block;
        background: url(./img/land/ico/quote.svg) no-repeat 0 0;
      }
    }
    & img {
      display: block;
      border-radius: 100px;
      margin: 0 auto 18px;
      width: 120px;
      height: 120px;
      object-fit: cover;
    }
    & p {
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.partners_wrap {
  & h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 65px;
    padding-left: 65px;
    & img {
      display: block;
      margin-left: 24px;
    }
  }
}
.partners {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -12px -12px;
  & img {
    display: block;
    margin: 12px;
    filter: grayscale(1);
  }
}
.main_form_wrap {
  border-radius: 45px;
  background: url(./img/land/main_form.jpg) no-repeat center center / cover;
  padding: 69px 20px 92px;
  color: #FFFFFF;
  & h2 {
    color: #FFFFFF;
    margin-bottom: 36px;
  }
  & p {
    text-align: center;
    margin: 0 0 65px;
  }
}
.main_form {
  display: flex;
  max-width: 1040px;
  margin: 0 auto;
  & .form_group {
    padding: 10px;
    width: 191px;
    &.form_group_subm {
      width: 255px;
    }
  }
  & .input_field {
    background: #FFFFFF;
    color: @font-color-dark;
  }
  & .submit_field {
    background: @blue_700;
    color: #FFFFFF;
    border: 0;
    padding: 14px 16px;
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 20px;
    width: 100%;
  }
}
.footer {
  display: flex;
  align-items: center;
  color: fade(#fff, 80%);
  margin: 80px 0 0;
  padding: 25px 0 50px;
  border-top: 1px solid fade(#fff, 80%);
  & .foot_copir {
    width: 142px;
    margin-right: 78px;
  }
  & .foot_menu {
    display: flex;
    margin: 0 -7px 0 auto;
    & a {
      color: fade(#fff, 80%);
      margin: 0 7px;
    }
  }
}
.last_ekran {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 788px;
  z-index: -1;
  background: linear-gradient(0deg, @blue_400 0%, rgba(115, 120, 252, 0) 96.5%);
}

.main_wrap .header_fx {
  .anim-fade;
  position: relative;
  z-index: 40;
}
.main_wrap .header_fx.active {
  position: fixed;
  left: 14px;
  right: 14px;
  top: 20px;
}
.menuburg {
  left: -100%;
  position: fixed;
  top: 0;
  width: 100%;
  background: @bg-main url(./img/land/menuburg.png) no-repeat bottom center;
  height: 100vh;
  z-index: 30;
  overflow: auto;
  padding-top: 148px;
  padding-bottom: 70px;
  & .main_nav {
    margin: 0;
    flex-direction: column;
    & li {
      padding: 0;
      & a {
        color: @font-color;
        padding: 12px 10px 12px 30px;
        border-left: 4px solid transparent;
      }
      &.active {
        & a {
          color: @primary-color-main;
          border-color: @primary-color-main;
          background: fade(@primary-color-main, 10%);
        }
      }
    }
  }
  & .flex_group_but {
    position: absolute;
    bottom: 70px;
    width: 100%;
    text-align: center;
    justify-content: center;
    flex-wrap: nowrap;
    & .btn {
      width: auto;
      padding: 12px 22px;
      &.btn-white {
        color: @primary-color-main;
      }
    }
  }
}
.menuburg.open {
  left: 0;
}

@media (max-width: 1199px) {
  body.body_main {
    font-size: 16px;
  }
  h2.mainh2 {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 42px;
  }
  .sect {
    margin-top: 100px;
  }
  .header_login {
    margin-top: 20px;
    & .header_menu {
      display: none;
    }
    & .flex_group_but {
      display: none;
    }
    & .header_burger {
      border-radius: 50px;
      width: 50px;
      height: 50px;
    }
  }
  .header_currency_land.header_currency {
    margin-right: 35px;
    margin-left: auto;
  }
  .main_h1 {
    font-size: 32px;
    line-height: 42px;
    margin: 98px auto 0 auto;
  }
  .main_bt {
    margin-top: 60px;
  }
  .main_grbox {
    margin: 50px -10px 0;
    flex-wrap: wrap;
    & .grbox {
      padding: 10px;
      width: 50%;
      font-size: 18px;
      & > div {
        border-radius: 30px;
      }
    }
    & .grbox_img {
      width: 100%;
      padding: 10px 10px 0;
      & img {
        max-width: 100%;
        border-radius: 30px;
      }
    }
  }
  .main_list {
    margin-top: 102px;
    & h2.mainh2 {
      max-width: 500px;
    }
  }
  .main_list_wrap {
    margin: -10px;
    flex-wrap: wrap;
    & .listbox {
      width: calc(50% - 20px);
      padding: 33px 30px 0;
      margin: 10px;
      & h3 {
        height: 72px;
        margin: 0 0 36px;
        padding: 0;
        & span {
          margin-top: 5px;
        }
      }
      & img {
        margin: 39px auto 0;
      }
    }
  }
  .client_tabs {
    & .tabs-but {
      margin: 0 0 38px;
      & a {
        padding: 8px 15px;
        margin: 0;
      }
    }
    & ul.list_main li + li {
      margin-top: 16px;
      padding-top: 16px;
    }
    & .tabs-content > div {
      padding: 50px 50px 0 50px;
      background-position: center bottom;
      border-radius: 30px;
    }
    & .col6 {
      width: 100%;
    }
    & h3 {
      max-width: none;
      margin: 0 0 36px;
    }
    & img {
      max-width: 467px;
    }
  }
  .rekl_wrap {
    padding-top: 0;
    padding-bottom: 0;
    & > div {
      border-radius: 30px;
      padding: 50px;
    }
    & p {
      margin: 30px 0 30px 0;
      font-size: 18px;
      max-width: 606px;
    }
    & .col6 {
      width: 100%;
      &:first-child {
        order: 1;
      }
    }
    & .rekl_box {
      max-width: none;
      position: relative;
      z-index: 1;
    }
    & img {
      margin: -91px -59px -53px auto;
      display: none;
      & + img {
        display: block;
      }
    }
  }
  .slide_tabs {
    padding: 70px 43px 0 43px;
    border-radius: 30px;
    overflow: hidden;
    & .col6:first-child {
      padding-right: 60px;
    }
    & h3 {
      font-size: 32px;
      line-height: 42px;
      margin: 0 0 20px;
      font-weight: 800;
    }
    & p {
      font-size: 18px;
      line-height: 24px;
    }
    & .tabs-but a {
      font-size: 18px;
      line-height: 24px;
      padding: 11px 0 0 0;
    }
    & .tabs-content > div {
      background: none;
      position: relative;
      padding: 0 6px;
      &:after {
        content: "";
        position: absolute;
        width: 852px;
        height: 799px;
        background: url(./img/land/clients_fon.png) no-repeat 0 0;
        bottom: -100px;
        z-index: -1;
      }
    }
  }
  .main_otchet {
    padding-top: 50px;
    padding-bottom: 50px;
    & .main_list_wrap {
      background: none;
      flex-direction: column;
      max-height: 800px;
      justify-content: flex-start;
      & .listbox {
        & h3 {
          font-size: 22px;
          line-height: 28px;
        }
        &.listbox_otch1 {
          width: 39%;
          order: -1;
        }
        &.listbox_otch2 {
          width: calc(61% - 40px);
        }
        &.listbox_otch3 {
          width: calc(61% - 40px);
          margin: 10px;
        }
        &.listbox_otch4 {
          width: calc(61% - 40px);
        }
        &.listbox_otch5 {
          width: 39%;
          order: -1;
        }
      }
    }
    & .btn {
      margin: 40px auto 0;
    }
  }
  .main_support {
    padding: 50px;
    border-radius: 0;
    margin-left: -20px;
    margin-right: -20px;
  }
  .main_reviews {
    & .main_list_wrap {
      flex-wrap: nowrap;
      overflow: auto;
      & .listbox {
        padding: 24px;
        min-width: 299px;
        & h2 {
          font-size: 22px;
          line-height: 28px;
        }
        & img {
          width: 80px;
          height: 80px;
        }
        & p {
          -webkit-line-clamp: 9;
        }
      }
    }
  }
  .partners_wrap h2 {
    margin-top: -20px;
    margin-bottom: 10px;
  }
  .partners {
    margin: 0 -6px -6px;
    & img {
      height: 49px;
      margin: 6px;
    }
  }
  .main_form_wrap {
    border-radius: 30px;
    padding: 50px 20px 40px;
    & h2 {
      margin-bottom: 30px;
    }
    & p {
      margin: 0 0 40px;
      font-size: 18px;
    }
  }
  .header_niz {
    margin-top: 100px !important;
    & .header_menu {
      display: none;
    }
    & .flex_group_but {
      display: flex;
    }
  }
  .footer {
    margin: 40px 0 0;
    padding: 35px 0 50px;
    letter-spacing: -0.4px;
    font-weight: 400;
    & .foot_copir {
      width: auto;
      margin-right: 20px;
      & br {
        display: none;
      }
      & + div {
        display: none;
      }
    }
  }
}
@media (max-width: 991px) {
  .main_form {
    flex-wrap: wrap;
    max-width: 570px;
    & .form_group {
      width: 100% !important;
    }
  }
}
@media (max-width: 767px) {
  h2.mainh2 {
    font-size: 26px;
    line-height: 36px;
  }
  .sect {
    margin-top: 60px;
  }
  body.body_main .main_wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
  .main_wrap {
    .header.header_login {
      padding: 10px 12px;
      border-radius: 300px;
      & .header_logo {
        margin-bottom: 0;
      }
      & img {
        width: auto;
      }
    }
  }
  .main_h1 {
    font-size: 26px;
    line-height: 36px;
    margin: 60px auto 0 auto;
    & span {
      padding: 0 10px 2px;
      margin-top: 7px;
    }
  }
  .main_bt {
    margin-top: 58px;
    & .btn-white {
      padding: 15px 32px;
    }
  }
  .main_grbox {
    & .grbox {
      width: 100%;
      & h3 {
        margin: 0 0 20px;
      }
    }
    & .grbox_img img {
      max-width: none;
      height: 402px;
    }
  }
  .main_list {
    margin-top: 60px;
  }
  .main_list_wrap .listbox {
    width: calc(100% - 20px);
    padding: 40px 50px 0;
    & h3 {
      height: auto;
      margin: 0 0 20px;
      font-size: 22px;
      line-height: 28px;
      align-items: center;
      & span {
        margin-top: 0;
      }
    }
  }
  .client_tabs {
    font-size: 18px;
    & .tabs-but {
      border-radius: 100px;
      flex-wrap: wrap;
      padding: 6px 12px;
      & a {
        margin: 8px 0;
      }
    }
    & h3 {
      font-size: 22px;
      line-height: 28px;
      margin: 0 0 45px;
    }
    & img {
      max-width: 434px;
    }
  }
  .main_form_wrap {
    padding: 44px 40px 40px;
    & h2 {
      margin-bottom: 23px;
    }
    & p {
      margin: 0 auto 30px;
      max-width: 400px;
    }
  }
  .rekl_wrap {
    overflow: visible;
    & > div {
      padding: 40px 50px 50px;
    }
    & .rekl_h3 {
      font-size: 26px;
      line-height: 36px;
    }
    & p {
      margin: 20px 0 25px 0;
    }
    & img {
      margin: 5px -59px -100px -100px;
      width: calc(100% + 200px);
      max-width: none;
    }
  }
  .slide_wrap {
    padding-top: 20px;
  }
  .slide_tabs {
    padding: 40px 43px 0;
    & .tabs-but {
      margin: 0 0 20px;
      & a {
        font-size: 0;
        line-height: 0;
        padding: 10px 0 0 0;
      }
    }
    & h3 {
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 10px;
    }
    & p {
      font-size: 16px;
      margin-bottom: 30px;
    }
    & .col6:first-child {
      padding-right: 10px;
    }
    & img {
      width: 324px;
      height: 354px;
      object-fit: cover;
      object-position: top center;
    }
  }
  .main_otchet {
    padding-top: 40px;
    padding-bottom: 40px;
    & .main_list_wrap {
      flex-direction: row;
      max-height: none;
      & .listbox {
        width: 100% !important;
        padding: 28px 24px 24px;
        & h3 {
          line-height: 26px;
          margin: 0 0 20px;
        }
        &.listbox_otch5 {
          order: inherit;
        }
      }
    }
    & h2 {
      margin-bottom: 25px;
    }
    & .flex_group_but {
      margin-top: 23px;
      & .btn {
        margin: 0 auto;
        width: auto;
      }
    }
  }
  .main_support {
    padding: 40px 50px;
    font-size: 18px;
    & .col6 + .col6 {
      padding-left: 10px;
      order: -1;
    }
    & h2 {
      margin-bottom: 20px;
    }
  }
  .main_reviews > img {
    margin: -15px auto 0;
  }
  .header_niz .flex_group_but {
    margin-top: 0;
    width: auto;
    & .btn {
      margin: 0;
      width: 100%;
      padding: 12px 14px;
      border-radius: 100px;
      & svg {
        display: none;
        & + span {
          margin-left: 0;
        }
      }
    }
  }
  .footer {
    margin: 32px 0 0;
    padding: 25px 0 50px;
  }
  & .flex_group_but {
    & .btn {
      padding: 12px 32px;
    }
  }
}
@media (max-width: 575px) {
  h2.mainh2 {
    font-size: 22px;
    line-height: 28px;
  }
  .main_wrap .header.header_login {
    display: flex;
    margin-top: 10px;
    padding: 8px 12px;
    & img {
      width: 124px;
    }
    & .header_burger {
      width: 40px;
      height: 40px;
    }
  }
  .main_h1 {
    font-size: 22px;
    line-height: 28px;
    margin: 40px auto 0 auto;
  }
  .main_bt {
    margin-top: 40px;
    & .btn {
      border-radius: 20px;
    }
  }
  .main_grbox {
    margin: 30px -10px 0;
    & .grbox {
      & h3 {
        font-size: 22px;
        line-height: 28px;
        margin: 0 0 20px;
      }
      & > div {
        border-radius: 20px;
        padding: 24px;
      }
    }
    & .grbox_img img {
      height: 279px;
    }
  }
  .main_list_wrap {
    & .listbox {
      padding: 30px 24px 0;
    }
  }
  .client_wrap {
    margin-top: 50px;
  }
  .client_tabs {
    & .tabs-but {
      flex-direction: column;
      border-radius: 20px;
    }
    & .tabs-content > div {
      padding: 25px 20px 0 20px;
    }
    & h3 {
      margin: 0 0 30px;
    }
    & img {
      max-width: 100%;
    }
  }
  .main_form_wrap {
    padding: 24px 14px 20px;
    border-radius: 20px;
    & h2 {
      margin-bottom: 15px;
    }
    & p {
      margin: 0 auto 18px;
    }
  }
  .rekl_wrap {
    margin-top: 40px;
    & > div {
      padding: 25px 25px 25px;
      border-radius: 20px;
    }
    & .rekl_h3 {
      font-size: 22px;
      line-height: 28px;
    }
    & img {
      margin: -27px -60px -56px -60px;
      width: calc(100% + 120px);
      display: block;
      & + img {
        display: none;
      }
    }
  }
  .slide_tabs {
    padding: 30px 17px 0;
    & h3 {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 15px;
    }
    & p {
      margin-bottom: 17px;
    }
    & img {
      height: 257px;
      padding: 0 10px;
    }
  }
  .main_otchet {
    padding-top: 30px;
    padding-bottom: 30px;
    & h2 {
      margin-bottom: 15px;
    }
    & .main_list_wrap .listbox {
      padding: 24px 24px 24px;
    }
  }
  .main_support {
    padding: 30px 20px;
  }
  .main_reviews {
    & > img {
      margin: 0 auto 4px;
      width: 40px;
    }
    & h2 {
      margin-bottom: 30px;
    }
  }
  .partners_wrap {
    & h2 {
      padding-left: 38px;
      & img {
        margin-left: 14px;
        width: 24px;
      }
    }
  }
  .partners {
    margin: 0 -6px -6px;
    & img {
      height: 31px;
      margin: 5px;
    }
  }
  .header_niz {
    margin-top: 60px !important;
    & .flex_group_but .btn {
      padding: 4px 12px;
    }
  }
  .footer {
    margin: 20px 0 0;
    padding: 17px 0 50px;
    & .foot_menu {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
  .main_wrap .header_fx.active {
    position: fixed;
    left: 20px;
    right: 20px;
    top: 10px;
  }
}






