@import "partials/params";
@import "partials/mixins";
@import "header";
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700;800&display=swap');

html, body {
  .clean;
  width: 100%;
  font-family: 'Manrope', sans-serif;
  font-feature-settings: 'clig' off, 'liga' off;
}
body {
  background: @bg-main;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: @font-gray-color;
}
::after, ::before {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
  scrollbar-width: thin;
}
a {
  cursor: pointer;
}

body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  background: transparent;
}
body::-webkit-scrollbar-thumb {
  background-color: rgba(117, 116, 120, 0.6);
  border-radius: 20px;
  border: 3px solid transparent;
}
body {
  scrollbar-width: thin;
  scrollbar-color: rgba(117, 116, 120, 0.6) transparent;
}
body.noscroll {
  overflow: hidden;
}


h1, h2, h3, h4, h5, h6, p {
  .clean;
}
h1 {
  font-size: 30px;
  font-weight: 800;
  line-height: normal;
  color: @font-color-dark;
}
h2 {
  font-size: 30px;
  font-weight: 800;
  line-height: 40px;
  color: @font-color-dark;
}
h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: @font-color;
}
h4 {
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
  color: @font-color-dark;
}
a {
  text-decoration: none;
  color: @primary-color-main;
}
img {
  max-width: 100%;
}
hr {
  margin: 0;
  border-bottom: 0;
  border-top: 1px solid @blue_100;
}
.txt_light40 {
  color: fade(@font-color-dark, 40%);
}
.text-right {
  text-align: right;
}
.icon12 {
  width: 12px;
  height: 12px;
  display: block;
}
.icon16 {
  width: 16px;
  height: 16px;
  display: block;
}
.icon18 {
  width: 18px;
  height: 18px;
  display: block;
}
.icon20 {
  width: 20px;
  height: 20px;
  display: block;
}
.icon22 {
  width: 22px;
  height: 22px;
  display: block;
}
.icon24 {
  width: 24px;
  height: 24px;
  display: block;
}
.icon32 {
  width: 32px;
  height: 32px;
  display: block;
}
.hidden {
  display: none;
}
.marg_0 {
  margin: 0;
}
.marg_top_20 {
  margin-top: 20px;
}
.font_bold16 {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}
.font_22 {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  color: @font-color;
  & b {
    font-weight: 800;
    & + span {
      margin-left: 12px;
    }
  }
}
.flex {
  display: flex;
  align-items: center;
}
.text-red {
  color: @red_500;
}
.text_comments {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 0 4px;
  border-radius: 8px;
  background: @onix_200;
}

.styled-scrollbars {
  /* плашка-бегунок и фон */
  scrollbar-color: #999 #333;
}
.styled-scrollbars::-webkit-scrollbar {
  width: 10px; /* в основном для вертикальных полос прокрутки */
  height: 10px; /* в основном для горизонтальных полос прокрутки */
}
.styled-scrollbars::-webkit-scrollbar-thumb { /* плашка-бегунок */
  background: #999;
}
.styled-scrollbars::-webkit-scrollbar-track { /* фон */
  background: #333;
}

.work_area {
  display: flex;
  justify-content: space-between;
}
.wrapper {
  border-radius: @radius-30 0 @radius-30 @radius-30;
  background: #FFF;
  padding: 40px 30px 40px 30px;
  width: calc(100% - @width-side);
  .anim-fade;
}

.modal_link {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: @font-gray-color;
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px dashed @primary-color-main;
  }
}
.overflow {
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
  border-radius: 0 14px 14px 14px;
}

.side_group {
  & .group_title {
    font-weight: 800;
    line-height: normal;
    margin-bottom: 10px;
    & + .range-wrap {
      margin-top: 15px;
    }
  }
  & .checkbox-container {
    padding: 5px 0;
  }
  & + .side_group {
    margin-top: 20px;
  }
}

.alert {
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  padding: 2px 10px;
  display: inline-block;
  border-radius: @radius-30;
  &-orange {
    color: #CF6D34;
    background: rgba(253, 169, 122, 0.40);
  }
  &-red {
    color: @wings_red_700;
    background: rgba(231, 61, 61, 0.10);
  }
  &-blue {
    color: #8B54FF;
    background: rgba(183, 151, 251, 0.40);
  }
  &-dark {
    color: @font-color;
    background: fade(@font-color, 10%);
  }
  &-gray {
    color: fade(@font-color, 40%);
    background: fade(@font-color, 10%);
  }
  &-green {
    color: @mint_700;
    background: fade(@font-mint-color, 40%);
  }
}

.pagination_wrap {
  .flex-center(row, space-between);
  margin: 20px -20px 0;
  & .pagination_view {
    font-size: 13px;
    font-style: normal;
  }
  & ul {
    .clean-list;
    display: flex;
    & li {
      & a, span {
        display: block;
        border-radius: var(--8-px, 8px);
        padding: 10px 4px;
        width: 44px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: @font-gray-color;
      }
      & .current {
        background: @primary-color-main;
        color: #ffffff;
      }
      & .pagination-prev, .pagination-next {
        background: url(./img/icon/chevron-up.svg) no-repeat center / 20px auto;
        height: 44px;
        transform: rotate(-90deg);
      }
      & .pagination-next {
        transform: rotate(90deg);
      }
      & span.pagination-prev, span.pagination-next {
        opacity: 0.4;
      }
    }
  }
}

.tooltip {
  background: @font-color-dark;
  max-width: 210px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #FFFFFF;
  border-radius: 14px;
  display: none;
  position: relative;
  &-long {
    max-width: 550px;
  }
  & .tooltip_close {
    position: absolute;
    color: #FFFFFF;
    top: 8px;
    right: 16px;
  }
  & p {
    margin: 0;
    padding-right: 15px;
    & + p {
      margin-top: 4px;
      padding-right: 0;
    }
  }
  &.tooltip_line {
    padding: 0;
    min-width: 142px;
    & .tooltip_block {
      padding: 8px 16px;
      & + .tooltip_block {
        border-top: 1px solid @font-color;
      }
    }
  }
  & .tooltip_dat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    & .tooltip_num {
      font-size: 10px;
      font-weight: 800;
      line-height: 12px;
    }
    & .tooltip_pass {
      display: flex;
      font-size: 10px;
      font-weight: 800;
      line-height: 12px;
      color: @onix_200;
    }
  }
  & .tooltip_inf {
    display: block;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }
}
.tooltip_img {
  max-width: 400px;
  width: 100%;
  height: 250px;
  border-radius: 20px;
  background: fade(@font-color-dark, 80%);
  padding: 10px;
  position: relative;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 14px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 20px;
    bottom: -12px;
    border: 6px solid transparent;
    border-top: 6px solid fade(@font-color-dark, 80%);
  }
}
.tooltip_price {
  max-width: 348px;
  padding: 0;
  font-weight: 600;
  border-radius: @radius-btn;
  color: @font-color;
  border: 1px solid @primary-color-gray;
  & h5 {
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
  }
  & .tooltip_price_all {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  & .tooltip_price_head {
    padding: 14px 14px 7px 14px;
  }
  & .tooltip_price_body {
    padding: 7px 14px 7px 14px;
    border-top: 1px solid @primary-color-gray;
    border-bottom: 1px solid @primary-color-gray;
  }
  & .tooltip_price_footer {
    padding: 7px 14px 10px 14px;
  }
}
.fill_bg_blue {
  background: @bg-main;
}

.dates-slider-wrapper {
  & .carousel {
    padding: 0 10px;
    & button {
      margin: 0;
    }
  }
}

.error_inf {
  display: none;
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #FFFFFF;
  background: @wings_red_400;
  padding: 2px 5px;
  width: 100%;
  border-radius: 10px;
}
.error_fields {
  margin-bottom: 25px !important;
  position: relative;
  & .input_field, .fake_select {
    border-color: @wings_red_400 !important;
  }
  & .error_inf {
    display: block;
  }
}

.load_anim {
  display: none;
  animation: 1s linear 0s normal none infinite running rot;
  -webkit-animation: 1s linear 0s normal none infinite running rot;
  width: 49px;
  position: fixed;
  bottom: 30%;
  left: 50%;
  z-index: 1000;
  color: @primary-color-main;
  & svg {
    width: 49px;
    height: 48px;
  }
}
.loads {
  & .load_anim {
    display: block;
  }
  & .loads_blur {
    filter: blur(2px);
  }
}
@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 1440px) {
  .hide_pk {
    display: none;
  }
}
@media (max-width: 1439px) {
  .modal {
    font-size: 12px;
  }
  .wrapper {
    padding: 40px 30px 40px 23px;
  }
}
@media (max-width: 1199px) {
  .wrapper {
    width: 100%;
    border-radius: 0;
    padding: 40px 14px 20px;
  }
  .hide_planshet {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .wrapper {
    padding: 40px 20px 20px;
  }
  .hide_phone {
    display: none !important;
  }
  .alert {
    font-size: 12px;
    line-height: normal;
  }
}
@media (max-width: 575px) {
  .wrapper {
    padding: 30px 20px 20px;
  }
  h1 {
    font-size: 26px;
    line-height: 36px;
  }
  .alert {
    font-size: 10px;
    line-height: 12px;
  }
}