@import "partials/params";
@import "partials/mixins";

.slide_track {
  display: flex;
}
.slide_arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 32px;
  height: 32px;
  padding: 4px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: @font-gray-color;
  outline: none;
  border-radius: 8px;
  background: @bg-main;
  z-index: 3;
  &:hover {
    background: @primary-color-main;
    color: #ffffff;
  }
}
.slide_prev {
  left: 6px;
  & svg {
    transform: rotate(90deg);
  }
}
.slide_next {
  right: 0;
  & svg {
    transform: rotate(-90deg);
  }
}
.carousel__slide {
  padding: 0 5px;
}
.carousel__next {
  right: -10px;
}
.carousel__prev {
  left: -10px;
}
.slider-tooltip {background-color: @blue_500;  border-color: @blue_500; }
.slider-connect {background-color: @blue_500;}

@media (max-width: 1439px) {

}