@import "partials/params";
@import "partials/mixins";

.main_with_side {
  display: flex;
  position: relative;
  & .main_side {
    display: block;
    width: 306px;
    min-width: 306px;
    padding: 18px 13px;
    border-radius: 20px;
    border: 2px solid @primary-color;
    background: #FFF;
    transition: 0.3s all;
    & .fake_select {
      color: @font-gray-color;
      padding: 6px 12px;
      justify-content: space-between;
      border-radius: 14px;
      border: 1px solid fade(@font-color, 30%);
    }
    & .accord_checks {
      width: 100%;
      &.accord_checks34 {
        & .checkbox-container {
          min-height: 34px;
        }
        .check_num {
          width: 17px;
          min-width: 17px;
        }
      }
    }
    & .checkbox-container {
      padding: 0;
      line-height: 20px;
      & .check_name {
        width: 100%;
      }
      & .checkmark {
        border: 2px solid rgba(64, 64, 64, 0.3);
        min-width: 18px;
        margin-top: 2px;
      }
      & + .checkbox-container {
        margin-top: 8px;
      }
      & .stars {
        transform: scale(1.5);
        transform-origin: 0;
        margin: 0 13px 0 0;
      }
    }
    & .wide_reset {
      & .modal_close {
        display: block;
      }
    }
    &.main_side_wide {
      max-width: 768px;
      width: 100%;
      padding-top: 60px;
      position: relative;
      & .hotels_switch {
        display: none;
      }
      & .wide_reset {
        position: absolute;
        top: 18px;
        left: 14px;
        right: 14px;
        font-size: 16px;
        & .reset_filtr {
          margin-right: 55px;
          font-size: 14px;
        }
        & .modal_close {
          display: block;
          right: 0;
        }
      }
      & .group_filtr {
        columns: 2;
        column-gap: 25px;
        & .side_group:first-child {
          margin-top: 0;
        }
        & > div {
          break-inside: avoid-column;
        }
      }
    }
  }
  & .main_cont {
    width: calc(100% - 324px);
    margin-left: 20px;
  }
  & .side_group_hr {
    border-top: 1px solid @primary-color;
    padding-top: 20px;
  }
  & .hotel_map {
    width: 100%;
    height: 100%;
    & img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &:not(.compact) {
    & .hotel_map {
      display: none;
    }
  }
  &.compact {
    & .main_side {
      border-radius: 20px 0 0 20px;
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
    & .hotels_switch {
      border-radius: 20px 0 0;
    }
    & .main_cont {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 304px;
      width: 295px;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 0 20px 20px 0;
      border: 2px solid @primary-color;
      margin: 0;
      padding: 8px 13px;
      background: #fff;
      & .hotels_areas {
        width: 100%;
      }
    }
  }
}
.show_check {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  align-items: center;
  padding: 6px 0;
  & svg {
    transform: rotate(180deg);
  }
}
.side_title {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  padding: 6px 0;
  margin-bottom: 10px;
  &-duo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .btn-icon {
    padding: 0;
  }
  &.accord_head {
    padding: 4px 0;
  }
  &.side_title_razdel {
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: @font-color-dark;
    padding: 0;
  }
}
.group_filtr {
  margin-top: 20px;
}
.flitr_list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px -10px;
  & span {
    display: flex;
    padding: 2px 4px;
    margin: 0 5px 10px;
    background: @primary-color;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: @primary-color-main;
    border-radius: 8px;
    & svg {
      margin-left: 2px;
    }
  }
}
.hotel_wrap {
  & .search_hotels {
    & .fields {
      & .fields_line {
        width: 67%;
      }
      & .fields_subm {
        width: calc(33% - 177px);
        position: static;
        padding-right: 0;
      }
      & .to_result {
        width: 177px;
        & span {
          width: auto;
        }
        & svg {
          display: none;
        }
      }
    }
  }
}
.hotels_switch {
  display: flex;
  justify-content: space-between;
  background: @primary-color;
  padding: 20px 15px 14px;
  margin: -20px -15px 10px;
  border-radius: 20px 20px 0 0;
  & a {
    .anim-fade;
    display: block;
    width: calc(50% - 2px);
    padding: 5px 16px;
    background: transparent;
    font-size: 16px;
    line-height: 20px;
    border-radius: 12px;
    text-align: center;
    color: @font-gray-color;
    &:hover, &.active, &:focus {
      background: @primary-color-bl400;
      color: #fff;
    }
  }
}
.favorite {
  display: flex;
  align-items: center;
  border: 1px solid fade(@font-color, 30%);
  padding: 5px 12px;
  margin-top: 20px;
  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    margin-left: -2px;
    color: @wings_red_400;
  }
  & .fav_close {
    display: none;
  }
  &.active {
    background: @primary-color;
    border-color: @primary-color;
    & .check_info {
      display: none;
    }
    & .fav_close {
      display: block;
      margin-left: 12px;
    }
  }
}
.check_num {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  width: 24px;
  min-width: 24px;
  margin-left: 1px;
  padding: 4px 0;
  text-align: right;
}
.check_info {
  width: 18px;
  min-width: 18px;
  margin-left: 12px;
  height: 18px;
  background: #E6E6F9;
  border-radius: 50px;
  color: @font-onyx-color;
}
.hotels_areas {
  & .hotels_items {
  }
}
.hotel_item {
  background: @primary-color;
  display: flex;
  border-radius: 20px;
  height: auto !important;
  & + .hotel_item {
    margin-top: 10px;
  }
  & .hotel_img {
    width: 270px;
    height: 270px;
    overflow: hidden;
    border-radius: 20px;
    position: absolute;
    left: 0;
    top: 0;
    & .hotel_img_slider {
      height: 100%;
    }
    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    & .img_favorite {
      position: absolute;
      top: 20px;
      right: 20px;
      color: transparent;
      &.active, &:hover {
        color: @wings_red_400;
        & use {
          stroke: @wings_red_400;
        }
      }
      & use {
        stroke: #fff;
      }
    }
  }
  & .hotel_info {
    padding: 20px 20px 6px 290px;
    width: 100%;
    font-size: 13px;
    position: relative;
    min-height: 270px;
    & .hotel_info_room {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid fade(@primary-color-main, 30%);
      padding: 9px 0;
      & .hotel_nomer {
        max-width: 360px;
        & .hotel_nomer_title {
          font-weight: 800;
          min-height: 36px;
        }
        & .hotel_nomer_info {
          display: flex;
          flex-wrap: wrap;
          margin: 4px -2px;
          & > span {
            padding: 0 4px;
            border-radius: 8px;
            font-size: 10px;
            font-weight: 600;
            line-height: 12px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: @font-color;
            margin: 2px;
            &.nomer_info_no {
              background: fade(@red, 10%);
            }
            &.nomer_info_yes {
              background: fade(@font-mint-color, 10%);
            }
          }
        }
      }
      & .hotel_price_block .btn {
        display: none;
      }
      & .hotel_price {
        text-align: right;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        margin-top: 6px;
        & .price_flex {
          font-size: 18px;
          line-height: 24px;
          justify-content: flex-end;
          & span {
            width: auto;
          }
          & i {
            width: auto;
          }
        }
      }
    }
    & .hotel_info_footer {
      padding-top: 14px;
      padding-bottom: 14px;
      text-align: right;
      border-top: 1px solid fade(@primary-color-main, 30%);
      & .btn {
        display: inline-block;
      }
    }
  }
}
.hotel_info_head {
  display: flex;
  flex-wrap: wrap;
  & .hotel_info_shap {
    width: 100%;
  }
  & .hotel_title_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .hotel_title {
      font-size: 18px;
      font-weight: 800;
      line-height: 24px;
      color: @primary-color-main;
    }
  }
  & .hotel_adres_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    & .hotel_icon {
      display: flex;
      justify-content: flex-end;
      & img {
        display: block;
        width: 24px;
        height: 24px;
        margin-left: 10px;
      }
    }
  }
}
.hotel_adres {
  & .hotel_adres_title {
    font-weight: 600;
    border-bottom: 1px dashed @primary-color-main;
    margin-bottom: 4px;
    display: inline-block;
  }
  & .hotel_adres_place {
    display: flex;
    & > span + span {
      margin-left: 10px;
    }
    & > span {
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      & span {
        color: @font-color;
      }
    }
  }
}
.compact {
  margin-right: -30px;
  & .hotel_item {
    background: #fff;
    flex-wrap: wrap;
    position: relative;
    border-radius: 0;
    border-bottom: 1px solid fade(@primary-color-main, 30%);
    & .hotel_img {
      width: calc(100% + 20px);
      height: 149px;
      position: relative;
      margin: 10px -10px 0;
      & .img_favorite {
        top: 10px;
        right: 10px;
      }
    }
    & .hotel_info {
      padding: 0;
      width: 100%;
      height: auto;
      & .hotel_info_head {
        padding: 0 10px 4px;
        & .hotel_title_block {
          & .hotel_title {
            font-size: 13px;
            line-height: 18px;
            width: 100%;
            padding-right: 72px;
            .ellipsis;
            position: relative;
          }
          & .hotel_info_right {
            min-width: 32px;
            & .hotel_reit {
              width: 32px;
              padding: 2px;
              font-size: 13px;
              line-height: 18px;
              margin-right: 0;
            }
          }
        }
        & .hotel_icon {
          display: none;
        }
        & .hotel_adres_block {
          padding: 0;
        }
      }
      & .hotel_info_room {
        border: 0;
        padding: 0 10px 8px;
        & .hotel_price_block {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          margin-top: 6px;
          & .btn {
            display: block;
          }
        }
        & .hotel_price {
          font-size: 10px;
          line-height: 12px;
          margin-top: 0;
          text-align: left;
          width: calc(100% - 118px);
          & > span {
            .ellipsis;
            display: block;
          }
          & .price_flex {
            justify-content: flex-start;
            font-size: 16px;
          }
        }
      }
    }
    & .extr_fix, .hotel_adres_title, .hotel_adres_metro, .hotel_nomer, .hotel_info_footer {
      display: none;
    }
    & + .hotel_item {
      margin-top: 20px;
    }
  }
  & .stars {
    background-size: auto 100%;
    height: 12px;
    position: absolute;
    right: 5px;
    top: 3px;
    &.star1 {
      width: 12px;
    }
    &.star2 {
      width: 26px;
    }
    &.star3 {
      width: 40px;
    }
    &.star4 {
      width: 54px;
    }
    &.star5 {
      width: 68px;
    }
  }
}
.pinned_wrap.pinned_wrap_hotel {
  & .pinned_line {
    width: calc(75.5% - 38px);
  }
  & .extr_buttons {
    width: 24.5%;
    & .hotel_srok {
      display: none;
    }
  }
}
.pinned_line {
  & .hotel_item {
    background: #FFFFFF;
    padding: 7px 0;
    & .hotel_img {
      width: 128px;
      height: 128px;
      border-radius: 14px;
    }
    & .hotel_info {
      padding: 0 0 0 148px;
      display: flex;
      height: 128px;
      & .hotel_info_head {
        width: 49%;
        & .hotel_adres_block {
          flex-wrap: wrap;
          padding: 4px 0;
          & .hotel_icon {
            justify-content: flex-start;
            width: 100%;
            margin-left: -10px;
            margin-top: 12px;
          }
          & .hotel_adres_place > span {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
      & .hotel_info_room {
        width: 51%;
        border: 0;
        & .hotel_nomer {
          max-width: none;
          & .hotel_nomer_title {
            min-height: 0;
          }
        }
      }
    }
  }
}

.stars {
  display: inline-block;
  margin-left: 8px;
  height: 24px;
  background: url(./img/icon/star.svg) repeat-x left center;
  vertical-align: bottom;
  &.star1 {
    width: 16px;
  }
  &.star2 {
    width: 34px;
  }
  &.star3 {
    width: 52px;
  }
  &.star4 {
    width: 70px;
  }
  &.star5 {
    width: 88px;
  }
}
.hotel_info_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .hotel_reit {
    margin-right: 20px;
  }
}
.hotel_reit {
  width: 44px;
  padding: 4px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #FFFFFF;
  text-align: center;
  margin-right: 20px;
  &.hotel_reit_high {
    background: @mint_400;
  }
  &.hotel_reit_mid {
    background: #FDA97A;
  }
  &.hotel_reit_small {
    background: @wings_red_300;
  }
}
.show_filtri {
  display: none;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 589px;
  font-size: 0px;
  font-weight: 600;
  line-height: 20px;
  z-index: 5;
  color: @primary-color-main;
  .anim-fade;
  & svg {
    color: @font-gray-color;
    transform: rotate(90deg);
    margin-left: 0px;
    .anim-fade;
  }
  &.active {
    font-size: 14px;
    left: 30px;
    & svg {
      color: @font-gray-color;
      transform: rotate(270deg);
      margin-left: 10px;
    }
  }
}
.compact .show_filtri {
  display: flex;
}

.hotel_card {
  position: absolute;
  top: 38%;
  right: 20.5%;
  width: 250px;
  border-radius: 14px;
  background: #FFFFFF;
  display: flex;
  &:after {
    content: "";
    display: block;
    width: 34px;
    height: 34px;
    position: absolute;
    bottom: -16px;
    right: 6px;
    background: url(@name-map-pin) no-repeat 0 0 / contain;
  }
  & .hotel_card_img {
    width: 85px;
    min-width: 85px ;
    height: 85px;
    border-radius: 14px;
    overflow: hidden;
  }
  & .hotel_card_info {
    padding: 9.5px 10px;
    width: 100%;
    & .hotel_card_title {
      font-size: 12px;
      font-weight: 800;
      line-height: 18px;
      margin: 3px 0 5px;
      color: @primary-color-main;
      .ellipsis;
    }
    & .hotel_card_price {
      font-size: 13px;
      font-weight: 800;
      line-height: 18px;
      color: @font-color;
    }
  }
  & .hotel_info_right {
    & .stars {
      position: relative;
      right: 0;
      top: 0;
      margin: 0;
    }
    & .hotel_reit {
      font-size: 13px;
      width: 34px;
      padding: 2px;
      margin: 0;
      line-height: 18px;
    }
  }
}

.search_hotels_modal {
  & .modal_head {
    display: none;
  }
}
.search_hotels_phone {
  display: none;
}
.hotel_pay_info1 {
  color: @wings_red_400;
  font-weight: 800;
  line-height: 20px;
}
.hotel_pay_info2 {
  color: fade(@font-color, 40%);
  font-weight: 600;
  line-height: 20px;
}
.suggestion-block.suggestion-hotels {
  max-width: 100%;
  width: 100%;
}
.hotel_pay {
  position: relative;
  z-index: 1;
  .fake_text {
    border: 1px solid fade(@font-color, 30%);
    padding: 4px 12px;
    line-height: 36px;
    border-radius: 14px;
  }
}

.hotel_detail {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
  position: relative;
  height: 400px;
  padding-top: 4px;
  & > div {
    width: calc(50% - 10px);
    margin-right: 50%;
  }
  & .hotel_detail_img {
    height: 100%;
    margin: 0;
    position: absolute;
    right: 5px;
    top: 0;
  }
  & .hotel_detail_udobs {
    & .udobs_title {
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      margin-bottom: 12px;
    }
    & .tarifs_body_list {
      columns: 3;
    }
  }
  & .hotel_detail_foot {
    background: @bg-main;
    padding: 20px 10px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: @font-onyx-color;
    display: flex;
    justify-content: space-between;
    & div {
      padding: 0 10px;
      width: 33.3333%;
    }
    & .hotel_detail_edit {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & .btn {
        color: @primary-color-main;
      }
    }
    & span {
      display: block;
      font-size: 18px;
      font-weight: 800;
      line-height: 24px;
      margin-top: 4px;
      color: @primary-color-main;
    }
  }
}
.hotel_detail_first {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  & .stars {
    margin: 0;
  }
  & .hotel_detail_date {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    color: @font-onyx-color;
    margin: 0 20px 0 12px;
  }
  & .hotel_reit {
    margin: 0 0 0 auto;
  }
}
.hotel_detail_second {
  display: flex;
  justify-content: space-between;
  & .hotel_name {
    & .hotel_detail_name {
      font-size: 22px;
      font-weight: 800;
      line-height: 28px;
      color: @font-color;
      margin-bottom: 7px;
    }
  }
  & .hotel_adres {
    display: flex;
    align-items: flex-start;
    font-size: 13px;
    & img {
      display: block;
    }
  }
  & .hotel_price_block {
    & .price_flex_bold {
      margin-top: 4px;
      font-size: 18px;
      font-weight: 800;
      line-height: 24px;
      justify-content: flex-end;
      text-transform: none;
      & span, i {
        width: auto;
      }
    }
    & .btn {
      display: inline-block;
      margin-top: 10px;
      padding: 6px 24px;
      white-space: nowrap;
    }
  }
}
.more_udobs {
  border-bottom: 1px dashed @primary-color-main;
}
.slider {
  position: relative;
  & .slider_wrap {
    overflow: hidden;
    & .slider_outer {
      display: flex;
      height: 100%;
      & .slider_item {
        display: flex;
        & img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    & .slider_nav {
      position: absolute;
      bottom: 40px;
      right: 20px;
    }
  }
}
.slider_nav {
  display: flex;
  & .slider-nav {
    display: block;
    padding: 4px;
    border-radius: 8px;
    background: @purple_100;
    box-shadow: -2px 6px 22px 0px rgba(162, 162, 186, 0.36);
    &.slider-prev {
      transform: rotate(90deg);
    }
    &.slider-next {
      transform: rotate(-90deg);
      margin-left: 20px;
    }
  }
}
.detail_slider {
  & .slider_wrap {
    & .slider_outer {
      & .slider_item {
        flex-direction: column;
        & img {
          padding: 5px;
          height: 203px;
          width: 203px;
          min-width: 203px;
          border-radius: 14px;
        }
        &.slider_item_big {
          & img {
            height: 406px;
            width: 406px;
            min-width: 406px;
          }
        }
      }
      &:after {
        content: "";
        background: linear-gradient(274deg, #FFF 36.31%, rgba(255, 255, 255, 0.00) 91.81%);
        width: 70px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -14px;
      }
    }
    & .slider_nav {
      left: 25px;
      bottom: 25px;
      right: auto;
    }
  }
}
.slider_dots {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 0 -4px;
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
  & li {
    width: 6px;
    height: 6px;
    background: rgba(255, 255, 255, 0.60);
    border-radius: 3px;
    margin: 0 4px;
    &.active {
      background: rgba(255, 255, 255, 1);
    }
  }
}
.pop_slider {
  & .slider_dots {
    left: 134px;
    bottom: 40px;
    transform: translate(0, 0);
  }
}

.slide_row {
  display: flex;
  height: 100%;
  & .slid_wrap {
    width: calc(100% - 442px);
    & .pop_slider {
      height: 100%;
    }
  }
  & .slid_col {
    width: 442px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 30px 40px 30px !important;
    & .hotel_info_head {
      position: relative;
      & .hotel_adres_block {
        padding: 20px 0;
        font-size: 13px;
        & .hotel_adres_place > span {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    & .hotel_info_shap {
      padding-top: 58px;
    }
    & .hotel_info_right .hotel_reit {
      margin-right: 0;
    }
    & .slide_variant {
      border-top: 1px solid @primary-color-main;
      padding-top: 25px;
      text-align: center;
      margin-top: 30px;
    }
    & .hotel_price_block {
      border-top: 1px solid @primary-color-main;
      padding-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      line-height: 18px;
      & .price_flex {
        justify-content: flex-end;
        font-size: 18px;
        line-height: 24px;
        & span, i {
          width: auto;
        }
      }
    }
  }
}



.hotel_award {
  border-radius: 20px;
  border: 1px solid @blue_200;
  padding: 20px 18px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  & img {
    display: block;
    width: 73px;
    max-width: 73px;
    margin-right: 10px;
  }
  & div {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    color: @font-color;
    & span {
      display: block;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: @font-gray-color;
      margin-bottom: 6px;
    }
  }
}

.hotel_description {
  border-radius: 20px;
  border: 2px solid @blue_200;
  overflow: hidden;
  margin-top: 20px;
  & .hotel_description_body {
    max-height: 178px;
    .anim-fade;
    overflow: hidden;
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 73px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, #FFF 32px, rgba(255, 255, 255, 0) 100%);
      .anim-fade;
    }
  }
  &.active {
    & .hotel_description_body {
      max-height: 1000px;
      &:after {
        height: 0;
      }
    }
  }
  & .hotel_description_head {
    background: @blue_200;
    padding: 20px;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
  }
  & .hotel_flex-fluid {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin: 0 -10px;
    & .hotel_desc {
      width: 50%;
      padding: 0 10px;
      font-size: 13px;
      & > * {
        max-width: 66%;
        margin-bottom: 10px;
        display: block;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.hotel_flex-awards {
      & .hotel_desc {
        & > * {
          max-width: 79%;
        }
      }
      & .hotel_award_wrap {
        padding: 0 10px;
        max-width: 324px;
        min-width: 324px;
        & strong {
          margin-bottom: 10px;
          display: block;
        }
      }
    }
  }
  & .hotel_description_foot {
    background: @blue_200;
    padding: 14px 20px;
    display: flex;
    justify-content: center;
  }
}
.show_inf {
  color: @primary-color-main;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  & .show_inf_span {
    .flex-center;
    & span {
      opacity: 1;
      max-height: 20px;
      .anim-fade;
      & + span {
        opacity: 0;
        max-height: 0;
      }
    }
  }
  & svg {
    margin-left: 10px;
    display: block;
    .anim-fade;
  }
  &.active {
    & .show_inf_span {
      & span {
        opacity: 0;
        max-height: 0;
        & + span {
          opacity: 1;
          max-height: 20px;
        }
      }
    }
    & svg {
      transform: rotate(180deg);
    }
  }
}

.hotel_filtr {
  background: @bg-main;
  padding: 30px 20px;
  border-radius: 20px;
  & .hotel_filtr_title {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    & span {
      display: block;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      margin-left: 20px;
    }
  }
  & .form_label {
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    margin-bottom: 6px;
    display: block;
  }
  & .fake_select {
    background: #FFFFFF;
    color: fade(@font-color-dark, 60%);
    justify-content: space-between;
    border-color: fade(@font-color, 30%);
    border-radius: 14px;
    padding: 13px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    & svg {
      color: @font-color;
    }
  }
  & + .hotel_tarifs {
    margin-top: 10px;
  }
}

.hotel_description_info {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  margin: 0 -10px;
  & > div {
    width: 50%;
    padding: 0 10px;
  }
}
.hotel_fact {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
  & > div {
    padding: 0 10px;
    min-width: 25%;
  }
  & .hotel_fact_title {
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
  }
  & .hotel_fact_name {
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  & .hotel_fact_year {
    color: @font-color;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    margin-top: 6px;
  }
  & .hotel_fact_num {
    font-size: 15px;
    font-weight: 800;
  }

  & + .hotel_fact {
    justify-content: flex-start;
    & > div {
    }
  }
}
.hotel_list_udobstv {
  padding: 20px;
  columns: 4;
  & .hotel_list_item {
    break-inside: avoid-column;
    & + .hotel_list_item {
      margin-top: 30px;
    }
    & .hotel_list_title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      & .hotel_list_img {
        .flex-center;
        border-radius: 8px;
        background: @font-onyx-color;
        padding: 6px;
        width: 36px;
        min-width: 36px;
        height: 36px;
        color: #FFFFFF;
        margin-right: 10px;
      }
    }
    & ul {
      padding-left: 11px;
      margin: 10px 0 0;
      & li {
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        & span {
          color: @font-color;
          background: fade(@font-color, 10%);
          font-size: 10px;
          font-weight: 600;
          line-height: 12px;
          letter-spacing: 1px;
          text-transform: uppercase;
          padding: 2px 10px;
          border-radius: 30px;
          display: inline-flex;
          margin-right: 50px;
        }
        & + li {
          margin-top: 4.5px;
        }
      }
    }
  }
}
.hotel_privacy {
  font-size: 13px;
  padding: 20px;
  & strong {
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    margin-bottom: 10px;
    display: block;
  }
  & ul {
    margin: 0;
    padding: 0 0 0 14px;
    & li {
      & + li {
        margin-top: 4.5px;
      }
    }
  }
  & .row + .row {
    margin-top: 10px;
  }
}

.hotel_range {
  & .hotel_range_flex {
    display: flex;
    & + .hotel_range_flex {
      margin-top: 15px;
    }
    & .hotel_range_info {
      width: 120px;
      min-width: 120px;
    }
    & .hotel_range_time {
      font-size: 18px;
      font-weight: 800;
      line-height: 24px;
      color: @font-onyx-color;
    }
    & .hotel_range_title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: @font-onyx-color;
      margin-bottom: 4px;
    }
    & .range-wrap {
      margin-left: 30px;
      width: 222px;
    }
  }
  & .range {
    & .range-handle {
      display: none;
    }
    & .range-bar {
      background: @mint_400;
      height: 8px;
      border-radius: 14px;
      top: 32px;
    }
    & .range-body {
      & .range-line {
        height: 8px;
        border-radius: 14px;
        top: 32px;
      }
      & .range-from-text, .range-to-text {
        top: 44px;
        color: @font-onyx-color;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        padding: 0;
        background: transparent;
      }
    }
  }
}
.hotel_span {
  & span {
    color: @font-color;
    background: fade(@font-color, 10%);
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 2px 10px;
    border-radius: 30px;
    display: inline-flex;
    margin-right: 250px;
    margin-bottom: 7px;
    white-space: nowrap;
  }
}

.hotel_tarifs {
  border-color: @bg-main;
  & .hotel_description_head {
    display: flex;
    align-items: center;
    padding: 0;
    background: @bg-main;
    & .hotel_tarifs_img {
      width: 86px;
      min-width: 86px;
      margin-right: 20px;
      & a {
        display: block;
        position: relative;
        & img {
          border-radius: 20px;
          display: block;
          margin-bottom: 2px;
        }
        & span {
          position: absolute;
          top: 3px;
          right: 3px;
          padding: 0 4px;
          font-size: 10px;
          font-weight: 600;
          line-height: 12px;
          letter-spacing: 1px;
          text-transform: uppercase;
          border-radius: var(--8px, 8px);
          background: rgba(255, 255, 255, 0.80);
          color: @font-color;
          display: inline-block;
        }
      }
    }
  }
  & .hotel_description_foot {
    background: @bg-main;
  }
}
.head_tarifs {
  & .head_tarifs_wrap {
    display: flex;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    margin: 0 -10px 10px;
    & > div {
      padding: 0 10px;
    }
    & .head_tarifs_title {
      width: 540px;
    }
    & .head_tarifs_predl {
      width: 191px;
    }
    & .head_tarifs_price {
      width: 216px;
    }
  }
  & .head_tarifs_dop {
    display: flex;
    margin: 0 -5px;
    & > div {
      padding: 0 4px;
      margin: 0 5px;
      background: fade(@font-mint-color, 10%);
      display: flex;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: @font-color;
      border-radius: 8px;
      & svg {
        margin-right: 2px;
      }
    }
  }
}
.tarifs_body_item {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin: 0 -10px;
  & + .tarifs_body_item {
    border-top: 2px solid @bg-main;
  }
  &:nth-child(n+3) {
    display: none;
  }
  & .tarifs_body_list {
    padding: 20px 10px;
  }
  & .tarifs_body_price {
    width: 32%;
    border-left: 2px solid @bg-main;
    display: flex;
    align-items: center;
    & .extr_buttons {
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      & .price_flex_bold {
        font-size: 18px;
        margin-top: 0;
        line-height: 24px;
        margin-bottom: 9px;
        & span, i {
          width: auto;
        }
      }
      & .hotel_srok {
        display: block;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        color: fade(@font-color-dark, 60%);
      }
      & .btn {
        width: 50%;
        text-align: center;
      }
    }
  }
}
.tarifs_body_list {
  list-style: none;
  margin: 0;
  padding: 0;
  & li {
    display: flex;
    align-items: center;
    font-size: 13px;
    & svg {
      margin-right: 10px;
    }
    & + li {
      margin-top: 4px;
    }
  }
}
@media (max-width: 1439px) {
  .main_with_side {
    & .main_side {
      width: 271px;
      min-width: 271px;
    }
    & .main_cont {
      width: calc(100% - 291px);
    }
    & .hotel_price_block, .hotel_icon {
      min-width: 240px;
    }
    &.compact .main_cont {
      left: 271px;
    }
  }
  .show_filtri {
    left: 556px;
  }
  .hotel_detail .hotel_detail_udobs .tarifs_body_list {
    columns: 2;
  }
}
@media (max-width: 1299px) {
  .head_tarifs {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 8px;
    margin-bottom: -7px;
    white-space: nowrap;
  }
}
@media (max-width: 1199px) {
  .hotel_item {
    & .hotel_info {
      padding: 20px 20px 6px 240px;
      min-height: 280px;
    }
    & .hotel_img {
      width: 220px;
      height: 280px;
    }
  }
  .main_with_side {
    & .main_side {
      width: 241px;
      min-width: 241px;
    }
    & .main_cont {
      width: calc(100% - 261px);
    }
    & .hotel_price_block {
      min-width: 200px;
    }
    & .hotel_nomer_info > span {
      width: calc(50% - 4px);
      .ellipsis;
    }
    &.compact .main_cont {
      left: 241px;
    }
  }
  .show_filtri {
    left: 526px;
  }

  .compact {
    margin-right: -14px;
  }
  .pinned_wrap.pinned_wrap_hotel {
    & .pinned_line {
      width: calc(100% - 40px);
    }
    & .extr_buttons {
      width: calc(56% - 40px);
      padding-bottom: 7px;
      & .extr_bron {
        width: 180px;
      }
    }
    & .pinned_price {
      display: block;
      margin-right: 20px;
    }
  }
  .pinned_line {
    & .hotel_item {
      padding: 7px 8px;
      & .hotel_adres_title {
        display: inline-block;
      }
      & .hotel_img {
        width: 120px;
        height: 120px;
        position: relative;
        order: -1;
        margin-right: 20px;
      }
      & .hotel_info {
        height: auto;
        flex-wrap: wrap;
        padding: 0;
        & .hotel_info_head {
          width: 100%;
          & .hotel_info_shap {
            width: calc(100% - 140px);
          }
        }
        & .hotel_info_room {
          width: 100%;
          border-top: 1px solid fade(@primary-color-main, 30%);
          margin-top: 7px;
          padding-top: 17px;
          padding-bottom: 1px;
          & .hotel_nomer {
            & .hotel_nomer_title {
              font-size: 13px;
            }
            & .hotel_nomer_info > span {
              width: auto;
            }
          }
        }
      }
    }
  }
  .pinned_wrap_hotel {
    & .price_flex {
      & span, i {
        width: auto;
      }
    }
  }
  .hotel_info_right {
    & .hotel_reit {
      margin-right: 0;
    }
  }
  .slider {
    & .slider_wrap {
      height: 100%;
      & .slider_outer .slider_item img {
        width: 100vw;
      }
    }
  }
  .slide_row {
    & .slid_wrap {
      width: 100%;
      & .slider {
        height: 100%;
      }
    }
    & .slid_col {
      display: none;
    }
  }
  .hotel_wrap {
    & .search_hotels {
      & .fields {
        & .fields_subm {
          padding-right: 64px !important;
        }
        & .to_result {
          width: 64px;
          & span {
            display: none;
          }
          & svg {
            display: block;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .main_with_side {
    & .main_side {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      &.active {
        display: block;
      }
    }
    & .main_cont {
      width: 100%;
      margin-left: 0;
    }
    &.compact {
      margin-left: -14px;
      & .main_cont {
        display: none;
      }
      & .show_filtri {
        display: none;
      }
    }
  }
  .hotel_wrap {
    & .search_hotels {
      & .search_widget_form {
        padding: 20px;
        background: @primary-color;
        border-radius: 20px;
      }
      & .fields {
        & .to_result {
          width: 64px;
          & span {
            width: 0;
          }
          & svg {
            display: block;
          }
        }
      }
    }
  }
  .search_hotels_modal {
    display: none;
    & .modal_head {
      display: flex;
    }
  }
  .search_hotels_phone {
    display: flex;
    align-items: center;
    background: @primary-color;
    padding: 12px 20px;
    margin: 0 -14px 4px;
    & .search_hotels_data {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 93px);
      background: #FFFFFF;
      border-radius: 8px;
      padding: 11px 20px;
      & .search_hotels_data_title {
        color: @primary-color-main;
      }
      & .search_hotels_data_date {
        font-weight: 500;
      }
    }
    & .search_hotels_btn {
      display: flex;
      margin-left: 11px;
      & a {
        display: block;
        margin-left: 9px;
        width: 32px;
        height: 32px;
        color: @font-gray-color;
      }
    }
  }
  .hotel_item {
    & .hotel_info {
      height: 300px;
      padding: 20px 20px 6px 285px;
      & .hotel_info_head {
        & .hotel_title_block {
          & .hotel_title {
            font-size: 16px;
            min-width: 220px;
          }
        }
      }
      & .hotel_adres_block {
        flex-wrap: wrap;
        & .hotel_adres_title {
          font-size: 12px;
        }
        & .hotel_icon {
          justify-content: flex-start !important;
          width: 100%;
          margin: 12px 0 0 -10px;
        }
      }
      & .hotel_info_room {
        padding: 11px 0 7px;
        & .hotel_nomer {
          & .hotel_nomer_title {
            min-height: 0;
            font-size: 12px;
          }
        }
        & .hotel_price_block {
          min-width: 140px;
        }
        & .hotel_price {
          font-size: 10px;
          margin-top: 0;
          & .price_flex {
            font-size: 16px;
          }
        }
        & .hotel_nomer_info > span {
          width: calc(50% - 4px);
          .ellipsis;
        }
      }
    }
    & .hotel_img {
      width: 265px;
      height: 300px;
      & .img_favorite {
        right: 15px;
      }
    }
    & + .hotel_item {
      margin-top: 4px;
    }
  }
  .wrapper_hotel {
    & .col4 {
      width: 50%;
    }
    & .colmd8 {
      width: 66.6666%;
    }
  }
  .user_group_zaezd {
    & .col8 {
      width: 100%;
    }
  }
  .user_group_pay {
    & .col8 {
      width: 50%;
    }
  }
  .hotel_detail {
    flex-direction: row;
    height: auto;
    padding-top: 0px;
    & > div {
      width: 100%;
      margin-right: 0;
    }
    & .hotel_detail_img {
      margin: 0;
      position: relative;
      right: 0;
    }
    & .hotel_detail_udobs {
      margin-bottom: 30px;
      & .udobs_title {
        margin-bottom: 15px;
      }
      & .tarifs_body_list {
        columns: 3;
      }
    }
  }
  .detail_slider {
    margin: 24px -5px;
    & .slider_wrap .slider_outer {
      & .slider_item {
        img {
          height: 137px;
          width: 137px;
          min-width: 137px;
        }
        &.slider_item_big {
          & img {
            height: 275px;
            width: 275px;
            min-width: 275px;
          }
        }
      }
      &:after {
        right: -9px;
      }
    }
  }
  .hotel_filtr {
    & .col3 {
      width: 50%;
    }
  }
  .tarifs_body_item {
    flex-wrap: wrap;
    padding: 10px 20px;
    & .tarifs_body_list {
      width: 50%;
      padding: 10px;
    }
    & .tarifs_body_price {
      width: 50%;
      border-left: 0;
      align-items: flex-start;
      & .extr_buttons {
        padding: 10px !important;
        justify-content: flex-start;
        & .pinned_price {
          width: 50%;
          margin-right: 10px;
        }
        & .btn {
          width: auto;
        }
      }
    }
    &:nth-child(n+2) {
      display: none;
    }
  }
  .hotel_description {
    & .hotel_flex-fluid {
      flex-wrap: wrap;
      & .hotel_desc {
        width: 100%;
      }
      &.hotel_flex-awards {
        & .hotel_award_wrap {
          max-width: 100%;
          width: 100%;
          min-width: 0;
          margin-top: 20px;
          margin-bottom: -10px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          & strong {
            width: 100%;
          }
        }
      }
    }
  }
  .hotel_award {
    width: calc(50% - 10px);
  }
  .hotel_list_udobstv {
    columns: 2;
  }
  .hotel_privacy {
    & .col5, .col3 {
      width: 50%;
    }
    & .col4, .col8 {
      width: 100%;
    }
  }
}
@media (max-width: 767px) {
  .hotel_item {
    & .hotel_info {
      padding: 20px;
      height: auto;
      & .hotel_title {
        display: flex;
        flex-wrap: wrap;
      }
      & .stars {
        order: -1;
        margin-top: -4px;
        margin-left: 0;
        margin-right: calc(100% - 100px);
      }
      & .hotel_info_room {
        flex-wrap: wrap;
        padding: 9px 20px 0;
        margin: 0 -20px;
        & .hotel_nomer {
          max-width: none;
          width: 100%;
          & .hotel_nomer_info > span {
            width: auto;
          }
        }
        & .hotel_price_block {
          width: 53%;
          border-right: 1px solid @bg-main;
          margin-top: 10px;
          & .hotel_price {
            text-align: left;
            display: flex;
            align-items: center;
            margin: 0;
            height: 40px;
            line-height: 1;
          }
          & .price_flex {
            justify-content: flex-start;
            margin: 0 10px 0 0;
            order: -1;
            white-space: nowrap;
          }
        }
      }
      & .hotel_info_footer {
        padding-top: 0;
        padding-bottom: 0;
        border-top: 0;
        width: 47%;
        position: absolute;
        right: 20px;
        bottom: 24px;
      }
    }
    & .hotel_img {
      width: 165px;
      height: 165px;
      position: relative;
      order: -1;
      min-width: 165px;
      margin: -20px 20px 10px -20px;
      & .img_favorite {
        right: 10px;
        top: 10px;
      }
    }
  }
  .search_hotels_phone {
    margin: 0 -20px 4px;
  }
  .main_with_side {
    &.compact {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  .pinned_wrap.pinned_wrap_hotel {
    & .extr_buttons {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 2px;
      & .extr_bron {
        width: 100%;
        order: 1;
        margin-top: 11px;
      }
      & .hotel_srok {
        display: block;
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
      }
    }
    & .pinned_line {
      width: 100%;
    }
    & .pinned_price {
      & .price_flex_bold {
        margin: 7px 0 0;
      }
    }
  }
  .pinned_line {
    & .hotel_item {
      & .hotel_img {
        width: 100%;
        height: 140px;
        margin: 0 0 12px;
      }
      & .hotel_info {
        & .hotel_info_head {
          & .hotel_info_shap {
            width: 100%;
          }
          & .hotel_title_block {
            & .hotel_title {
              width: 100%;
            }
          }
          & .hotel_adres_block {
            padding: 8px 0;
            & .hotel_adres_place > span {
              font-size: 10px;
              line-height: 12px;
            }
          }
        }
        & .hotel_info_room {
          margin: 3px 0 0;
          padding: 10px 0 0;
          & .hotel_nomer .hotel_nomer_title {
            font-size: 12px;
          }
        }
      }
    }

  }
  .wrapper_hotel {
    .hotel_item {
      & .hotel_info {
        & .hotel_info_head {
          & .hotel_info_shap {
            width: calc(100% - 165px);
          }
          & .hotel_title_block .hotel_title {
            width: 100%;
            .ellipsis;
          }
        }
      }
    }
    & .hotel_info_right .hotel_reit {
      margin-right: 20px;
    }
    & .col4 {
      width: 100%;
    }
  }
  .user_group_pay .col8 {
    width: 100%;
  }
  .user_footer_hotel {
    & .row {
      & .col4.col4_btn {
        width: 50%;
      }
    }
  }
  .hotel_detail_second {
    & .hotel_name {
      & .hotel_detail_name {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
      }
    }
    & .hotel_adres {
      font-size: 12px;
    }
    & .hotel_price_block {
      & .price_flex_bold {
        font-size: 16px;
        margin-top: 35px;
        margin-bottom: 0;
      }
      & .btn {
        margin-top: 3px;
      }
    }
  }
  .hotel_detail_first {
    margin-bottom: 10px;
  }
  .detail_slider {
    margin: 15px -20px 15px -5px;
    & .slider_outer:after {
      display: none;
    }
  }
  .hotel_detail {
    & .hotel_detail_udobs {
      margin-bottom: 10px;
      & .tarifs_body_list {
        columns: 2;
      }
    }
  }
  .tarifs_body_item {
    padding: 15px 20px;
    & .tarifs_body_list {
      padding: 5px 10px;
    }
    & .tarifs_body_price {
      width: 100%;
      margin-top: 5px;
      margin-bottom: -5px;
      & .extr_buttons {
        & .btn {
          width: 50%;
        }
      }
    }
  }
  .hotel_privacy {
    & .col5 {
      width: 100%;
    }
    & .col3, .col4 {
      width: 50%;
    }
  }
  .hotel_range {
    & .hotel_range_flex {
      & .hotel_range_info {
        width: 145px;
        min-width: 145px;
      }
      & .range-wrap {
        margin-left: 30px;
        width: 100%;
      }
    }
  }
  .slider .slider_wrap .slider_nav {
    right: 50%;
    margin-right: -42px;
  }
  .modal_full {
    & .slider_dots {
      display: none;
    }
    & .slider .slider_wrap .slider_nav {
      bottom: -50px;
    }
  }
}

@media (max-width: 575px) {
  .search_hotels_phone {
    padding: 8px 20px;
    & .search_hotels_data {
      display: block;
      padding: 4px 12px;
      & .search_hotels_data_title {
        line-height: 20px;
      }
      & .search_hotels_data_date {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
  .hotel_item {
    & .hotel_info {
      padding: 0 14px 14px;
      & .hotel_info_head {
        flex-wrap: wrap;
      }
      & .stars {
        margin-top: 0;
      }
      & .hotel_info_room {
        padding: 9px 14px 0;
        margin: 0 -14px;
        & .hotel_nomer .hotel_nomer_info > span {
          width: calc(50% - 4px);
        }
        & .hotel_price_block {
          width: 100%;
          border: 0;
          & .hotel_price {
            display: block;
            margin: 0;
            height: auto;
          }
          & .price_flex {
            margin: 7px 0 10px;
          }
        }
      }
      &  .hotel_info_footer {
        width: 100%;
        position: relative;
        right: 0;
        bottom: 0;
        & a {
          width: 100%;
          text-align: center;
          display: block;
        }
      }
    }
    & .hotel_img {
      width: calc(100% + 28px);
      margin: 0 -14px 10px;
      height: 140px;
    }
    & .hotel_info_right {
      & .hotel_reit {
        margin-right: 0;
      }
    }
    & .extr_fix {
      position: absolute;
      bottom: 60px;
      right: 14px;
    }
  }
  .main_with_side .main_side.main_side_wide .group_filtr {
    columns: auto;
  }
  .wrapper_hotel .hotel_item .hotel_info .hotel_info_head .hotel_info_shap {
    width: 100%;
  }
  .user_footer.user_footer_hotel .btn {
    margin-top: 0;
  }
  .user_footer_hotel {
    & .row {
      & .col4.col4_btn {
        width: 100%;
      }
    }
  }
  .hotel_detail_second {
    flex-wrap: wrap;
    & .hotel_name {
      & .hotel_detail_name {
        margin-bottom: 7px;
      }
    }
    & .hotel_price_block {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      & .price_flex_bold {
        margin-top: 0;
      }
      & .btn {
        margin-top: 0;
        padding: 6px 18px;
      }
    }
  }
  .hotel_detail {
    & .hotel_detail_udobs {
      margin-bottom: 15px;
      & .tarifs_body_list {
        columns: 1;
      }
    }
    & .hotel_detail_foot {
      flex-wrap: wrap;
      padding: 15px 5px;
      font-size: 13px;
      & span {
        font-size: 14px;
        line-height: 20px;
      }
      & div {
        width: 50%;
      }
      & .hotel_detail_edit {
        width: 100%;
        margin-top: 15px;
        & .btn {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .hotel_filtr {
    padding: 20px 15px;
    .hotel_filtr_title {
      font-size: 14px;
      line-height: 20px;
    }
    & .col3 {
      width: 100%;
      padding: 7px 10px;
    }
    & .form_label {
      font-size: 13px;
      line-height: 18px;
    }
  }
  .head_tarifs {
    overflow: hidden;
    padding-bottom: 8px;
    margin-bottom: 0;
    white-space: normal;
    & .head_tarifs_wrap {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      flex-wrap: wrap;
      & .head_tarifs_title {
        width: auto;
        margin-left: 97px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        height: 88px;
        font-size: 16px;
        line-height: 24px;
      }
      & .head_tarifs_predl, .head_tarifs_price {
        width: 50%;
      }
    }
    & .head_tarifs_dop {
      margin: 10px 5px -7px;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 8px;
    }
  }
  .hotel_tarifs {
    & .hotel_description_head {
      flex-wrap: wrap;
      position: relative;
      padding-bottom: 5px;
      & .hotel_tarifs_img {
        margin-right: 0;
        position: absolute;
        top: 1px;
        left: 1px;
      }
    }
  }
  .tarifs_body_item {
    padding: 11px 7px;
    margin: 0;
    & .tarifs_body_list {
      padding: 5px 5px;
      width: 100%;
    }
    & .tarifs_body_price .extr_buttons {
      padding: 10px 5px !important;
    }
  }
  .hotel_description {
    & .hotel_description_head {
      padding: 14px;
    }
    & .hotel_flex-fluid {
      & .hotel_desc {
        font-size: 12px;
        & strong {
          font-size: 14px;
          font-weight: 800;
          line-height: 20px;
        }
      }
      &.hotel_flex-awards {
        .hotel_desc > * {
          max-width: 100%;
        }
      }
    }
  }
  .hotel_award {
    width: 100%;
  }
  .hotel_list_udobstv {
    padding: 20px 15px;
    columns: 1;
    & .hotel_list_item ul li {
      font-size: 12px;
      & + li {
        margin-top: 1.5px;
      }
    }
  }
  .hotel_privacy {
    font-size: 12px;
    padding: 20px 13px;
    & .col3, .col4 {
      width: 100%;
    }
  }
  .hotel_range {
    & .hotel_range_flex {
      & .hotel_range_title {
        font-size: 12px;
        line-height: 18px;
      }
      & .hotel_range_info {
        width: 120px;
        min-width: 120px;
      }
      & .hotel_range_time {
        font-size: 16px;
      }
    }
    & .range {
      & .range-body {
        & .range-from-text, .range-to-text {
          letter-spacing: 0;
        }
      }
    }
  }
}
