@import "params";

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  .clean;
  list-style: none;
}
.flex-center(@dir: column, @just: center) {
  display: flex;
  align-items: center;
  justify-content: @just;
  flex-direction: @dir;
}
.bordered {
  border-radius: @radius-20;
  border: 2px solid @primary-color;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid @primary-color;
  &-20 {
    border-radius: 20px;
  }
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px fade(@font-gray-color, 15%);
}
.img_fit {
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
