@import "params";
body{
  --input-bg: white;//white
  --input-border: 1px solid fade(@font-color, 30%);
  --input-prefix-bg: @font-white-color;
  --input-focus-bg: fade(@primary-color-main, 30%);
  --input-focus-border: 1px solid @primary-color-main;
  --input-hover-border: 1px solid fade(@primary-color-main, 40%);

  --text-color: 0, 0, 0;
  --disabled-color: @font-color-mid;
  --content-bg: white;
  --block-header-bg: @font-white-color;
  --block-border: 1px solid @font-white-color;

  --input-focus: inherit;
  --input-focus-shadow: 0 0 10px rgba(0, 0, 0, .1);

  --input-disabled-bg: @bg-btn-dark;
  --input-selected-bg: @primary-color-main;
  --input-selected-color: white;

  --input-error-color: @red;
  --input-error-border: 1px solid @red;
  --input-error-label-color:@red;

  --button-bg: @primary-color-main;
  --button-border: 1px solid @primary-color-main;
  --button-color: white;

  --button-disabled-bg: white;
  --button-disabled-color: @font-color-mid;
  --button-disabled-border: 1px solid @bg-btn-dark;

  --button-secondary-bg: white;
  --button-secondary-color: #333;
  --button-secondary-border: 1px solid @bg-btn-dark;
}