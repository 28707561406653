.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding-left: 3px;
  margin-bottom: 30px;
}
.breadcrumbs a {
  color: #404040;
}
.breadcrumbs span {
  color: rgba(90, 90, 90, 0.4);
}
.breadcrumbs .separator {
  font-weight: 500;
  display: block;
  margin: 0 8px;
}
@media (max-width: 1439px) {
  .breadcrumbs {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .breadcrumbs {
    margin-bottom: 20px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
button.btn {
  cursor: pointer;
}
button {
  border: 0;
  background: none;
  font-family: 'Manrope', sans-serif;
}
.btn {
  line-height: 20px;
  font-weight: 600;
  border-radius: 14px;
  transition: 0.3s all;
}
.btn-small {
  font-size: 14px;
  padding: 6px 16px;
}
.btn-medium {
  font-size: 16px;
  padding: 14px 16px;
}
.btn-large {
  font-size: 18px;
  padding: 20px 32px;
}
.btn-blue_fill {
  background: #645EFC;
  color: #FFF;
}
.btn-blue_fill:hover,
.btn-blue_fill.active,
.btn-blue_fill:focus {
  background: #7378FC;
  color: #FFF;
}
.btn-gray_fill {
  background: #E3E5F6;
  color: #404040;
}
.btn-gray_fill:hover,
.btn-gray_fill.active,
.btn-gray_fill:focus {
  background: #7378FC;
  color: #FFF;
}
.btn-disable {
  background: #E3E5F6;
  color: #404040;
}
.btn-disable:hover,
.btn-disable.active,
.btn-disable:focus {
  background: #7378FC;
  color: #FFF;
}
.btn-gray_dark_fill {
  background: #C5C3DD;
  color: #404040;
}
.btn-gray_dark_fill:hover,
.btn-gray_dark_fill.active,
.btn-gray_dark_fill:focus {
  background: #7378FC;
  color: #FFF;
}
.btn-red_fill {
  background: #FFE5E5;
  color: #E73D3D;
}
.btn-red_fill:hover,
.btn-red_fill.active,
.btn-red_fill:focus {
  background: #7378FC;
  color: #FFF;
}
.btn-red2_fill {
  background: #E73D3D;
  color: #FFFFFF;
}
.btn-red2_fill:hover,
.btn-red2_fill.active,
.btn-red2_fill:focus {
  background: #7378FC;
  color: #FFF;
}
.btn-green_fill {
  background: #47B795;
  color: #FFFFFF;
}
.btn-green_fill:hover,
.btn-green_fill.active,
.btn-green_fill:focus {
  background: #37A786;
  color: #FFF;
}
.btn-orange_fill {
  background: #FDA97A;
  color: #FFFFFF;
}
.btn-orange_fill:hover,
.btn-orange_fill.active,
.btn-orange_fill:focus {
  background: #7378FC;
  color: #FFF;
}
.btn-transp_fill {
  background: #fff;
  color: #404040;
  border: 1px solid #404040;
}
.btn-transp_fill:hover,
.btn-transp_fill.active,
.btn-transp_fill:focus {
  background: #7378FC;
  color: #FFF;
  border: 1px solid #FFF;
}
.btn-dop_fill.btn {
  background: #A2A2BA;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  padding: 2px 6px;
}
.btn-dop_fill.btn:hover,
.btn-dop_fill.btn.active,
.btn-dop_fill.btn:focus {
  background: #7378FC;
  color: #FFF;
}
.btn-blue {
  color: #645EFC;
}
.btn-gray {
  color: #404040;
}
.btn-border {
  color: #404040;
  border: 1px solid #5A5A5A;
  line-height: 18px;
}
.btn-border:not(.btn-medium) {
  padding: 6px 15px;
}
.btn-border.btn-blue {
  color: #645EFC;
  border-color: #645EFC;
}
.btn-border.btn-blue:hover,
.btn-border.btn-blue.active,
.btn-border.btn-blue:focus {
  background: #7378FC;
  color: #FFF;
}
.btn-icon {
  padding: 4px;
  border-radius: 8px;
}
.btn-icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.btn-icon-text svg + span {
  margin-left: 5px;
  display: block;
}
.btn-icon-text span + svg {
  margin-left: 5px;
}
.btn-white {
  background: #ffffff;
  color: #404040;
}
.btn-alert {
  background: rgba(231, 61, 61, 0.1);
  padding: 2px 10px;
  border-radius: 30px;
  color: #BB3030;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: uppercase;
}
.btn-inl_width {
  width: 196px;
  text-align: center;
  display: inline-block;
}
.btn-wide-full {
  width: 100%;
  text-align: center;
  display: block;
}
.btn-wide-tret {
  min-width: 33.3333%;
  text-align: center;
  display: block;
  margin: 0 auto;
}
.tc_but .btn {
  border-radius: 30px;
}
/**********other*************/
.onyx_gray {
  color: rgba(64, 64, 64, 0.6);
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
}
.onyx_gray.hide_pk {
  line-height: 12px;
}
.gray_but {
  color: #5A5A5A;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 30px;
  display: inline-flex;
  padding: 0 10px;
  background: rgba(90, 90, 90, 0.1);
}
.loader {
  width: 150px;
  height: 127px;
  position: relative;
  text-align: center;
  margin: 42px auto 10px;
  color: #5A5A5A;
  padding: 11px 22.5px;
  background: url('./img/loader_avia.svg') no-repeat center center;
}
.loader span {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 50%;
  margin-left: -40px;
  width: 52px;
  height: 40px;
  background: url('./img/plane_avia.svg') no-repeat center center / contain;
}
.loader-hotel {
  background: url('./img/loader-hotel_wings.svg') no-repeat center center;
}
.loader-hotel span {
  background: url('./img/loader-search_wings.svg') no-repeat center center;
  width: 37px;
  height: 37px;
}
.loader-nohotel {
  /*background: url(./img/loader_old.svg) no-repeat center center;*/
}
.loader-train {
  overflow: hidden;
}
.loader-train span {
  background: url('./img/loader_train_wings.svg') no-repeat center center;
  width: 80px;
  height: 23px;
}
.loader a {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  margin: 0 auto;
  transition: 0.3s all;
}
.loader a svg {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -23px;
  left: 50%;
  margin-left: -23px;
  color: #645EFC;
  width: 47px;
  height: 46px;
  transition: 0.3s all;
}
.loader a:hover {
  background: radial-gradient(94.15% 94.15% at 32.1% 26.23%, #3778D2 0%, #3778D2 43.24%, #DCE8F8 100%);
}
.loader a:hover svg {
  color: #fff;
}
.loader-anim span {
  animation: plane 7s infinite linear;
  opacity: 0;
  left: 0;
  margin-left: -40px;
}
.loader-anim_hotel span {
  animation: myOrbit 7s infinite linear;
  opacity: 1;
  left: 56px;
  margin-left: 0;
}
.loader_info {
  text-align: center;
  max-width: 520px;
  font-size: 18px;
  line-height: 24px;
  margin: 0 auto;
}
.loader_info .loader_text {
  color: rgba(90, 90, 90, 0.6);
}
.loader_no_found .loader_text {
  font-size: 14px;
  line-height: 20px;
  color: #404040;
  margin: 10px 0 35px;
}
@keyframes plane {
  0% {
    margin-left: -40px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    margin-left: 170px;
    opacity: 0;
  }
}
@keyframes myOrbit {
  from {
    transform: rotate(0deg) translateX(20px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(20px) rotate(-360deg);
  }
}
@media (max-width: 575px) {
  .btn-small {
    padding: 6px 10px;
  }
  .btn-border {
    padding: 6px 9px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ui-widget.ui-widget-content {
  font-family: 'Manrope', sans-serif;
  border: none;
  width: 344px;
  margin: 20px auto 0;
}
.ui-widget.ui-widget-content.ui-datepicker-multi-2 {
  padding: 0 20px 20px;
  max-width: 754px;
  width: auto !important;
  background: transparent;
  margin-top: 15px;
  margin-bottom: 30px;
}
.ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-title {
  text-align: center;
  width: 100%;
  color: #645EFC;
  margin-bottom: 18px;
}
.ui-widget.ui-widget-content.ui-datepicker-multi-2 td span,
.ui-widget.ui-widget-content.ui-datepicker-multi-2 td a {
  font-size: 16px;
}
.ui-widget.ui-widget-content.ui-datepicker-multi-2 th {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 2px 5px;
}
.ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-group table {
  width: 100%;
  margin: 0 0 0;
}
.ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-group-first {
  padding: 0 15px 0 10px;
}
.ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-group-last {
  padding: 0 10px 0 15px;
}
.ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-prev,
.ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-next {
  position: absolute;
  top: 120px;
}
.ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-prev {
  left: -40px;
}
.ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-next {
  right: -40px;
  left: auto;
}
.ui-widget.ui-widget-content.ui-datepicker-multi-2 td {
  padding: 2px;
}
.ui-widget.ui-widget-content.ui-datepicker-multi-2 td span,
.ui-widget.ui-widget-content.ui-datepicker-multi-2 td a {
  min-width: 44px;
  height: 44px;
}
.ui-datepicker {
  padding: 0;
}
.ui-datepicker table {
  font-size: 16px;
  margin: 0;
}
.ui-datepicker .ui-datepicker-header {
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: none;
  border: none;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  display: block;
  position: relative;
  top: 0;
  width: 32px;
  height: 32px;
  left: 0;
  right: 0;
  padding: 8px;
  border-radius: 8px;
  background: #E3E5F6;
  transform: rotate(-90deg);
}
.ui-datepicker .ui-datepicker-next {
  transform: rotate(90deg);
  margin-left: 12px;
}
.ui-datepicker span.ui-icon {
  background: url(./img/icon/chevron-up.svg) no-repeat 0 0 / 100% auto;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0;
  text-align: left;
  order: -1;
  color: #5A5A5A;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: calc(100% - 76px);
}
.ui-datepicker th {
  text-align: center;
  color: #404040;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  padding: 11px 5px;
}
.ui-datepicker td {
  width: 50px;
  height: 44px;
  padding: 0;
}
.ui-datepicker td span,
.ui-datepicker td a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  padding: 0;
  text-align: center;
  text-decoration: none;
  color: #5A5A5A;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
}
.ui-datepicker td span:hover,
.ui-datepicker td a:hover {
  background: #E3E5F6;
  color: #5A5A5A;
}
.ui-state-default,
.ui-widget-content .ui-state-default {
  border: none;
  background: none;
  font-weight: 500;
  color: #5A5A5A;
}
.ui-state-highlight,
.ui-widget-content .ui-state-highlight {
  background: #E3E5F6;
}
.ui-state-active,
.ui-widget-content .ui-state-active {
  background: #645EFC;
  color: #FFFFFF;
}
.ui-state-sel,
.ui-button.ui-state-sel:hover {
  background: rgba(100, 94, 252, 0.3) !important;
  color: #5A5A5A;
}
.ui-state-mark {
  position: relative;
}
.ui-state-mark:before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 6px;
  width: 6px;
  height: 6px;
  background: #FF9090;
  border-radius: 3px;
}
@media (max-width: 767px) {
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 {
    padding: 0 10px 20px;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 td {
    width: 34px;
    height: 34px;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 td span,
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 td a {
    min-width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-group-first,
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-group-last {
    padding: 0 10px;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-prev,
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-next {
    top: 95px;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-prev {
    left: -30px;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-next {
    right: -30px;
  }
}
@media (max-width: 575px) {
  .ui-datepicker-multi-2 .ui-datepicker-group {
    width: 100%;
    margin-top: 10px;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 {
    max-width: 360px;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 td {
    width: 46px;
    height: 44px;
    padding: 0;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 td span,
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 td a {
    min-width: 46px;
    height: 44px;
    font-size: 16px;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-prev {
    top: 255px;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 .ui-datepicker-next {
    top: -33px;
  }
}
.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}
.daterangepicker:before,
.daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}
.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}
.daterangepicker.opensleft:before {
  right: 9px;
}
.daterangepicker.opensleft:after {
  right: 10px;
}
.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.daterangepicker.opensright:before {
  left: 9px;
}
.daterangepicker.opensright:after {
  left: 10px;
}
.daterangepicker.drop-up {
  margin-top: -7px;
}
.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}
.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}
.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
  float: none;
}
.daterangepicker.single .drp-selected {
  display: none;
}
.daterangepicker.show-calendar .drp-calendar {
  display: block;
}
.daterangepicker.show-calendar .drp-buttons {
  display: block;
}
.daterangepicker.auto-apply .drp-buttons {
  display: none;
}
.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}
.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}
.daterangepicker .drp-calendar.right {
  padding: 8px;
}
.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}
.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}
.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}
.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}
.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}
.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}
.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}
.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}
.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}
.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}
.daterangepicker th.month {
  width: auto;
}
.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}
.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}
.daterangepicker select.yearselect {
  width: 40%;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}
.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}
.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}
.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}
.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}
.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}
.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}
.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}
.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}
.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}
.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}
.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}
.daterangepicker .ranges li:hover {
  background-color: #eee;
}
.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}
/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }
  .daterangepicker .ranges ul {
    width: 140px;
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }
  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }
  .daterangepicker.single .ranges,
  .daterangepicker.single .drp-calendar {
    float: left;
  }
  .daterangepicker {
    direction: ltr;
    text-align: left;
  }
  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }
  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }
  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }
  .daterangepicker .ranges,
  .daterangepicker .drp-calendar {
    float: left;
  }
}
@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }
  .daterangepicker .ranges {
    float: left;
  }
  .daterangepicker.rtl .ranges {
    float: right;
  }
  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}
.dp__menu {
  font-family: 'Manrope', sans-serif !important;
  padding-bottom: 10px;
  border: none !important;
}
.dp__cell_inner {
  border: 2px solid #fff !important;
  border-radius: 8px !important;
  font-size: 14px !important;
}
.dp__range_end,
.dp__range_start,
.dp__active_date {
  background-color: #645EFC !important;
  border-color: transparent;
  color: #fff;
}
.dp__range_between {
  background: rgba(100, 94, 252, 0.3) !important;
  color: #5A5A5A;
}
.dp__calendar_header_item {
  font-size: 12px;
  font-weight: 500;
  height: 30px;
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card_tarif {
  padding: 18px 14px;
  border-radius: 20px;
  background: #E3E5F6;
  position: relative;
  border: 2px solid #E3E5F6;
}
.card_tarif .card_flex {
  display: flex;
  margin: 0 -10px -10px;
  padding-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;
}
.card_tarif .card_flex > div {
  padding: 0 10px;
  width: 100%;
}
.card_tarif .card_flex .card_body_half {
  width: 50%;
  min-width: 50%;
  padding-bottom: 52px;
}
.card_tarif .card_flex .card_body_half .tarif_foot {
  position: absolute;
  right: 0;
  width: 50%;
  bottom: 20px;
  padding: 0 10px;
}
.card_tarif .sel_tarif {
  position: absolute;
  top: 18px;
  right: 14px;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  border: 2px solid rgba(90, 90, 90, 0.3);
  z-index: 1;
}
.card_tarif.active {
  border: 2px solid #7378FC;
}
.card_tarif.active .sel_tarif {
  background: #fff;
  border: 5px solid #645EFC;
}
.card_tarif .tarif_logo {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.card_tarif .tarif_logo img {
  display: block;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}
.card_tarif .tarif_logo .tarif_company {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.card_tarif .tarif_title {
  color: #5A5A5A;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin: 20px 0 13px;
}
.card_tarif .tarif_foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  height: 32px;
}
.card_tarif .tarif_foot .tarif_price {
  color: #5A5A5A;
  font-size: 18px;
  font-weight: 800;
  line-height: normal;
  display: flex;
}
.card_tarif .tarif_foot .tarif_price span {
  display: block;
  margin-left: 4px;
}
.card_tarif .tarif_foot .tarif_more {
  display: block;
}
.card_tarif .tarif_foot .tarif_more.active svg {
  transform: rotate(180deg);
}
.card_tarif .tarif_dopinfo {
  margin-top: 20px;
  display: none;
}
.card_tarif .tarif_dopinfo .dopinfo_item {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.card_tarif .tarif_dopinfo .dopinfo_item:before {
  content: "";
  display: block;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-right: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 9px;
}
.card_tarif .tarif_dopinfo .dopinfo_item.tarif_yes:before {
  background-color: #47B795;
  background-image: url(./img/icon/list_yes.svg);
}
.card_tarif .tarif_dopinfo .dopinfo_item.tarif_orange:before {
  background-color: #FDA97A;
  background-image: url(./img/icon/list_orange.svg);
}
.card_tarif .tarif_dopinfo .dopinfo_item.tarif_none:before {
  background-color: #9090A4;
  background-image: url(./img/icon/list_none.svg);
}
.card_tarif .tarif_dopinfo .dopinfo_item + .dopinfo_item {
  margin-top: 6px;
}
.card_tarif.card_tarif_one {
  overflow: auto;
}
.card_tarif.card_tarif_one .card_flex .card_body_half {
  width: 100%;
}
.tarif_icon {
  display: flex;
  align-items: flex-start;
  color: #A2A2BA;
  margin: 0 0 0 auto;
}
.tarif_icon div + div {
  margin-left: 12px;
}
.tarif_icon .ico_val {
  position: relative;
}
.tarif_icon .ico_val i {
  font-style: normal;
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  color: #fff;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.tarif_icon .ico_val.ico_gruz {
  margin-right: 2px;
}
.tarif_icon .ico_val.ico_gruz i {
  width: 18px;
}
.tarif_icon .ico_val.ico_gruz span {
  position: absolute;
  right: -3px;
  top: 2px;
  font-size: 8px;
}
.tarif_icon .active {
  color: #47B795;
}
.card_tarif_wide .card_body {
  display: flex;
  align-items: flex-end;
  margin: 0 -10px;
  width: calc(100% + 20px);
  padding-bottom: 3px;
}
.card_tarif_wide .card_body .card_main {
  width: 50%;
  padding: 0 10px;
}
.card_tarif_wide .card_body .card_main .tarif_foot {
  margin-top: 56px;
  height: auto;
}
.card_tarif_wide .card_body .tarif_dopinfo {
  display: block !important;
  margin: 0;
  width: 50%;
  padding: 0 10px;
}
@media (max-width: 575px) {
  .card_tarif .card_flex {
    flex-wrap: wrap;
  }
  .card_tarif .card_flex .card_body {
    position: relative;
    padding-bottom: 45px;
  }
  .card_tarif .card_flex .card_body_half {
    width: 100%;
    padding-bottom: 40px;
  }
  .card_tarif .card_flex .card_body_half + .card_body_half {
    padding-bottom: 49px;
    margin-bottom: 2px;
  }
  .card_tarif .card_flex .card_body_half .tarif_foot {
    position: absolute;
    right: 0;
    width: 100%;
    bottom: 4px;
    padding: 0 10px;
    left: 0;
  }
  .card_tarif .tarif_foot {
    position: absolute;
    bottom: -4px;
    left: 10px;
    right: 10px;
    margin: 0;
  }
  .card_tarif.card_tarif_duo.active .sel_tarif {
    top: 50%;
    right: 27px;
  }
  .card_tarif.card_tarif_wide .card_body {
    flex-wrap: wrap;
    padding-bottom: 50px;
  }
  .card_tarif.card_tarif_wide .card_body .card_main {
    width: 100%;
  }
  .card_tarif.card_tarif_wide .card_body .card_main .tarif_foot {
    margin-top: 0;
    left: 20px;
    bottom: 0;
  }
  .card_tarif.card_tarif_wide .card_body .tarif_dopinfo {
    width: 100%;
    margin-top: 25px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carriers_switch a + a {
  margin-left: 10px;
}
.modal_head_carr {
  margin-left: -10px;
  width: calc(100% + 10px) !important;
}
.modal_head_carr > div {
  width: 25%;
  padding: 0 10px;
}
.carriers_wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px 0;
}
.carriers_wrap .carriers_item {
  width: 25%;
  padding: 0 10px;
}
.carriers_wrap .group_title {
  opacity: 0.4;
  line-height: 20px;
  padding: 4px 0;
}
.carriers_wrap .checkbox-container {
  padding: 5px 0;
}
@media (max-width: 1299px) {
  .modal_head_carr {
    flex-wrap: wrap;
    padding-right: 0 !important;
  }
  .modal_head_carr > div {
    width: auto;
  }
  .modal_head_carr .carriers_switch {
    margin-right: 12px;
  }
  .modal_head_carr .carriers_switch a {
    padding: 3px 16px;
  }
  .modal_head_carr .search_form {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 3px;
  }
  .carriers_wrap .carriers_item {
    width: 50%;
  }
  .carriers_wrap .carriers_item:nth-child(n+3) {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .modal_side.modal_side_carr {
    padding: 11px 0;
  }
  .modal_head_carr .search_form {
    margin-top: 22px;
    margin-bottom: -7px;
  }
}
@media (max-width: 575px) {
  .modal_side .modal_head_carr {
    margin-top: 0;
  }
  .modal_side .modal_head_carr .carriers_check {
    width: 100%;
    margin-top: 22px;
  }
  .modal_side .modal_head_carr .carriers_switch {
    margin-right: 0;
    margin-left: -4px;
  }
  .carriers_wrap {
    max-height: calc(100vh - 163px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .carriers_wrap .carriers_item {
    width: 100%;
  }
  .carriers_wrap .carriers_item:nth-child(n+2) {
    margin-top: 20px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.search_widget.search_contract {
  background: #E3E5F6;
  padding: 20px 20px;
}
.contract_line_search {
  display: flex;
  padding: 10px 0;
  font-size: 18px;
}
.contract_line_search .form_group {
  width: 100%;
}
.contract_line_search .form_group .input_field {
  background: #FFFFFF;
  border-radius: 14px 0 0 14px;
}
.contract_line_search .fields_result {
  flex: 0 0 auto;
  width: 119px;
}
.contract_line_search .fields_result .to_result {
  border-radius: 0 14px 14px 0;
  width: 100%;
  height: 100%;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: #645EFC;
}
.contract_line_search .fields_result .to_result svg {
  display: none;
}
.contract_line_search .fields_result .to_result:hover {
  background: #7378FC;
}
.search_contract_form .user_group {
  margin: 0;
  border: 0;
  padding: 10px 0;
}
.search_contract_form .user_group .fake_select {
  background: #FFFFFF;
}
.search_contract_form .user_group .input_field {
  background: #FFFFFF;
}
.search_contract_form .view_filtr_wrap {
  display: none;
}
.inp_date {
  position: relative;
}
.inp_date .inp_open_date {
  position: absolute;
  top: 12px;
  right: 12px;
  color: #404040;
}
.user_group.user_group_dogovor {
  padding: 18px 16px;
}
.user_group.user_group_dogovor .user_group_head {
  margin: 0;
}
.user_group.user_group_dogovor .user_group_head .fake_select {
  padding: 5px 12px;
}
.work_contr span {
  display: flex;
}
.work_contr > svg {
  display: none;
}
.dogovora_wrap {
  max-height: 1045px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 7px;
  margin-right: -7px;
}
.dogovora {
  margin-top: 20px;
  color: #5A5A5A;
  border-radius: 20px;
  overflow: hidden;
  border-bottom: 2px solid #E3E5F6;
}
.dogovora .dogovora_head {
  padding: 8px 12px;
  background: #E3E5F6;
  font-size: 13px;
}
.dogovora .dogovora_item {
  display: flex;
  padding: 6px 12px 10px 12px;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 800;
  border-top: 2px solid #E3E5F6;
  border-right: 2px solid #E3E5F6;
  border-left: 2px solid #E3E5F6;
  min-height: 64px;
}
.dogovora .dogovora_item > div {
  padding: 0 4px;
}
.dogovora .dogovora_item > div.dogovor_but {
  padding: 0;
}
.dogovora .dogovora_item > div > div {
  margin-top: 4px;
}
.dogovora .dogovora_item > div.dogovor_data_start,
.dogovora .dogovora_item > div.dogovor_data_end {
  display: none;
}
.dogovora .dogovora_item span:not(.status) {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #757478;
}
.dogovora .dogovora_item.active {
  background: #E3E5F6;
}
.dogovor_status {
  width: 104px;
}
.dogovor_but a {
  display: block;
}
.contract_line {
  display: flex;
  align-items: center;
  padding: 10px 0 10px;
  color: #404040;
}
.contract_line .contract_line_info {
  display: flex;
  border-right: 1px solid rgba(100, 94, 252, 0.3);
}
.contract_line .contract_line_info > div {
  margin-right: 10px;
  padding: 5px 16px;
  line-height: 20px;
  border-radius: 12px;
  white-space: nowrap;
}
.contract_line .contract_line_info .contract_line_dolz {
  background: rgba(231, 61, 61, 0.1);
  color: rgba(231, 61, 61, 0.6);
}
.contract_line .contract_line_info .contract_line_dolz span {
  color: rgba(231, 61, 61, 0.3);
}
.contract_line .contract_line_info .contract_line_closed {
  background: #645EFC;
  color: #fff;
}
.contract_line .fake_wrap {
  margin-left: 20px;
}
.contract_line .fake_wrap .fake_select {
  color: #645EFC;
}
.contract_line .add_contract {
  margin-left: 20px;
  color: #645EFC;
  border-radius: 14px;
}
.contract_line .add_contract svg {
  /*color: @blue_400;*/
  padding: 4.5px;
}
.contract_line .add_contract span {
  margin-left: 8px;
}
.contract_line .clear_contract {
  margin: 0 0 0 auto;
  color: #645EFC;
}
.flex_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.flex_group_but {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.flex_group_but > .btn {
  margin-left: 20px;
}
.flex_group_wis_but {
  margin-bottom: 20px;
}
.form_group_down .fake_select {
  padding: 15px 16px;
  color: #645EFC;
  background: #E3E5F6;
}
.form_group_down .fake_select:hover {
  background: #7378FC;
  color: #FFFFFF;
}
.filt_clear + .form_group_down {
  margin: 0 0 0 auto;
  min-width: 200px;
}
.search_transaction {
  display: flex;
  align-items: center;
}
.search_transaction > .btn {
  margin-left: 20px;
  width: 168px;
  text-align: center;
}
.search_transaction .filt_clear {
  background: #E3E5F6;
}
.search_transaction .filt_clear:hover {
  background: #7378FC;
  color: #FFFFFF;
}
.search_transaction .transact_down {
  margin: 0 0 0 auto;
  color: #404040;
  padding: 12px;
}
.search_transaction .transact_down:hover {
  background: #E3E5F6;
}
.search_transaction .transact_down span {
  display: none;
}
.transaction_sel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -10px;
  width: 100%;
  max-width: 1000px;
}
.transaction_sel .transaction_inp {
  width: 25%;
  padding: 0 10px;
}
.transaction_sel .transaction_inp .fake_select {
  color: #5A5A5A;
  padding: 14px 12px;
  justify-content: space-between;
  border-radius: 14px;
  border: 1px solid rgba(90, 90, 90, 0.3);
  background: #FFFFFF;
  margin: 0;
}
.transaction_sel .transaction_inp .fake_select > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.transaction_sel .btn {
  margin-left: 10px;
}
.transaction_sel.transaction_sel_period {
  max-width: 500px;
}
.transaction_sel.transaction_sel_period .transaction_inp {
  width: 50%;
}
.transaction_wrap {
  overflow-x: auto;
  margin-top: 20px;
}
.transaction {
  font-size: 13px;
  border-bottom: 1px solid #E3E5F6;
}
.transaction .transaction_item {
  display: flex;
  min-width: 1276px;
}
.transaction .transaction_item > div {
  padding: 15px 11px;
  border: 1px solid #E3E5F6;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.transaction .transaction_item > div > div {
  padding: 7px 11px;
  border: 1px solid #E3E5F6;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.transaction .transaction_item > div.transact_duo {
  padding: 0;
  border: 0 !important;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr;
}
.transaction .transaction_item:not(.transaction_head) > div > div {
  min-height: 73px;
  /*height: 50%;*/
}
.transaction .transaction_item:not(.transaction_head) .transact_num {
  border-left-width: 2px;
}
.transaction .transaction_item:not(.transaction_head) .transact_action {
  border-right-width: 2px;
}
.transaction .transaction_item.transaction_head {
  border-radius: 20px 20px 0 0;
  background: #E3E5F6;
  border-bottom: 2px solid #E3E5F6;
}
.transaction .transaction_item.transaction_head > div {
  border: 1px solid #fff;
  border-bottom-color: transparent;
}
.transaction .transaction_item.transaction_head > div > div {
  border: 1px solid #fff;
}
.transaction .transaction_item.transaction_head > div > div + div {
  border-bottom-color: transparent;
}
.transaction .transaction_item.transaction_head .transact_num {
  border-left-color: transparent;
}
.transaction .transaction_item.transaction_head .transact_action {
  border-right-color: transparent;
}
.transaction .transaction_item.active {
  background: #E3E5F6;
}
.transact_num {
  width: 7.8%;
  min-width: 99px;
}
.transact_prod {
  width: 9.1%;
  min-width: 116px;
}
.transact_tip {
  width: 9.1%;
  min-width: 116px;
}
.transact_data {
  width: 10.4%;
  min-width: 133px;
}
.transact_pnr {
  width: 10.6%;
  min-width: 136px;
}
.transact_fio {
  width: 10.6%;
  min-width: 136px;
}
.transact_sum {
  width: 10%;
  min-width: 128px;
  align-items: center;
}
.transact_creat {
  width: 15.8%;
  min-width: 201px;
}
.transact_izmen {
  width: 7.8%;
  min-width: 99px;
}
.transact_action {
  width: 8.9%;
  min-width: 113px;
  align-items: center;
}
.transact_action a {
  position: relative;
}
.transact_action a.active {
  background: #645EFC;
}
.transact_action a.active:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background: #EE5C5C;
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid #E3E5F6;
}
.transact_action a.active:hover {
  background: #7378FC;
}
.user_group .dogovora_head {
  margin: -30px -20px 28px;
  padding: 8px 12px;
  font-size: 13px;
  border-radius: 18px 18px 0 0;
  background: #E3E5F6;
}
.transaction_but {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.transaction_but .btn {
  padding: 14px 16px;
  width: 196px;
  text-align: center;
  margin-left: 20px;
}
.select_comments_wrap.shadow {
  padding: 20px 22px;
  max-width: 450px;
  border-radius: 20px;
}
.select_comments_wrap.shadow .select_comments_head {
  color: #5A5A5A;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
}
.select_comments {
  margin: 20px 0;
  position: relative;
}
.select_comments .fake_select {
  color: rgba(35, 35, 43, 0.6);
  padding: 13px 14px;
  justify-content: space-between;
  border-radius: 14px;
  border: 1px solid rgba(90, 90, 90, 0.3);
}
.select_comments .form_group + .form_group {
  margin-top: 20px;
}
.select_comments .form_group.form_group_down {
  margin-top: 12px;
}
.select_comments .form_label {
  line-height: 20px;
  font-weight: 800;
}
.select_comments + .select_comments {
  padding-top: 20px;
  border-top: 1px solid #E3E5F6;
}
.form_group textarea {
  border-radius: 14px;
  border: 1px solid rgba(90, 90, 90, 0.3);
  height: 120px;
  padding: 12px;
  width: 100%;
  resize: none;
  color: #23232B;
  outline: none !important;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.form_group textarea:focus,
.form_group textarea:active,
.form_group textarea.focused {
  border-color: rgba(100, 94, 252, 0.4);
}
.select_comments_body {
  padding: 20px 0;
  margin: 20px 0;
  border-bottom: 1px solid #E3E5F6;
  border-top: 1px solid #E3E5F6;
}
.select_comments_body .select_comments_info {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.select_comments_body .select_comments_sbor {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}
.sels.fake_select {
  color: #404040;
  border: 1px solid #645EFC;
}
.flex_group_but .status {
  margin: 0 16px;
}
.select_comments_but {
  display: flex;
}
.select_comments_but .btn {
  width: calc(50% - 5px);
  text-align: center;
}
.dogovor_favorite {
  color: transparent;
}
.dogovor_favorite.active,
.dogovor_favorite:hover {
  color: #EE5C5C;
}
.dogovor_favorite.active use,
.dogovor_favorite:hover use {
  stroke: #EE5C5C;
}
.dogovor_favorite use {
  stroke: #404040;
}
.dogovor_panel {
  display: flex;
  align-items: center;
  padding-top: 6px;
}
.dogovor_panel .btn:not(.dogovor_block) {
  margin-right: 20px;
  color: #645EFC;
  border-color: rgba(100, 94, 252, 0.4);
}
.dogovor_panel .btn:not(.dogovor_block):hover {
  background: #7378FC;
  color: #FFFFFF;
  border-color: #7378FC;
}
.dogovor_panel .dogovor_block {
  margin: 0 0 0 auto;
}
.dogovor_panel .form_group_down .fake_select {
  color: rgba(35, 35, 43, 0.6);
  background: transparent;
  border-color: rgba(90, 90, 90, 0.3);
  border-radius: 14px;
}
.dogovor_panel .form_group_down .fake_select:hover {
  border-color: #C9B0FE;
}
.dogovor_panel .btn-red_fill:hover,
.dogovor_panel .btn-red_fill.active,
.dogovor_panel .btn-red_fill:focus {
  color: #FFF;
}
.dogovor_balanser {
  display: flex;
  margin: 20px -10px 0;
}
.dogovor_balanser .col6 {
  padding: 10px;
}
.dogovor_balans_qvart {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}
.dogovor_balans_qvart .col6 {
  padding: 10px;
}
.dogovor_balans_qvart .dogovor_qvart {
  background: #E3E5F6;
  padding: 20px 24px;
  border-radius: 20px;
  height: 100%;
}
.dogovor_balans_qvart .dogovor_qvart .dogovor_qvart_mame {
  color: rgba(90, 90, 90, 0.6);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}
.dogovor_balans_qvart .dogovor_qvart .dogovor_qvart_mame .btn {
  text-transform: none;
  border-radius: 12px;
}
.dogovor_balans_qvart .dogovor_qvart .dogovor_qvart_price {
  display: flex;
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  margin-top: 24px;
  color: rgba(90, 90, 90, 0.4);
}
.dogovor_balans_qvart .dogovor_qvart .dogovor_qvart_price span {
  display: block;
  margin-right: 6px;
  color: #404040;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dogovor_balans_block {
  height: 100%;
  background: #E3E5F6;
  padding: 20px;
  border-radius: 20px;
}
.dogovor_balans_block .dogovor_balans_line {
  display: flex;
  justify-content: space-between;
}
.dogovor_balans_block .dogovor_balans_line .dogovor_balans_name {
  color: #757478;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
}
.dogovor_balans_block .dogovor_balans_line .dogovor_balans_value {
  color: #404040;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding-left: 15px;
}
.dogovor_balans_block .dogovor_balans_line + .dogovor_balans_line {
  margin-top: 6px;
}
div.midfont {
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
  color: #5A5A5A;
}
.flex_group_balans {
  margin: 30px 0 15px;
}
.user_group_balans.user_group {
  padding: 26px 30px;
}
.user_group_balans.user_group .flex_group {
  margin-bottom: 20px;
}
.user_group_balans.user_group .dogovor_balans_qvart .dogovor_qvart .dogovor_qvart_price {
  font-size: 22px;
}
.balans_dat {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #757478;
}
.balans_dat span {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  color: #5A5A5A;
  display: block;
  margin-top: 5px;
}
.balans_dat + .balans_dat {
  margin-left: 25px;
}
.detail_tbs .btn {
  color: #404040;
  border-radius: 12px;
  margin-left: 10px;
}
.detail_tbs .btn.active {
  color: #FFFFFF;
  background: #645EFC;
}
.detail_tbs .btn.detail_tbs_pay {
  display: flex;
  align-items: center;
}
.detail_tbs .btn.detail_tbs_pay:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #E73D3D;
  margin-left: 10px;
}
.user_group_nobord.user_group {
  padding: 0;
  border: none;
}
.user_group_nobord.user_group .form_group {
  margin-bottom: 20px;
}
.user_group_nobord.user_group .form_group .line_pay {
  color: #5A5A5A;
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
}
.user_group_noline.user_group {
  padding: 0;
  border: none;
}
.modal_side_wrap .form_group_but {
  display: flex;
  justify-content: space-between;
}
.modal_side_wrap .form_group_but .btn {
  text-align: center;
  width: calc(50% - 10px);
}
.balans_accord {
  background: #E3E5F6;
  border-radius: 14px;
  overflow: hidden;
  border: 1px solid #E3E5F6;
}
.balans_accord .accord_head.side_title {
  background: #E3E5F6;
  height: 60px;
  padding: 8px 24px;
  margin: 0;
}
.balans_accord .accord_head.side_title .balans_accord_head {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}
.balans_accord .accord_head.side_title .balans_accord_head .balans_period {
  margin-left: 30px;
}
.balans_accord .accord_head.side_title .balans_accord_head .status {
  margin: 0 60px 0 30px;
}
.balans_accord .accord_body .user_group_balans.user_group {
  width: 100%;
  margin: 2px 0 0;
}
.balans_accord .accord_body .user_group_balans.user_group .flex_group {
  margin-bottom: 30px;
}
.balans_accord .accord_body .user_group_balans.user_group .dogovor_balans_qvart .dogovor_qvart {
  background: #FFFFFF;
}
.balans_accord.accord_item {
  margin-top: 20px;
}
.pagination_wrap.pagination_nomarg {
  margin-left: 0;
  margin-right: 0;
}
.user_group_provaid.user_group {
  margin-top: 50px;
}
.user_group_provaid.user_group .fake_wrap {
  max-width: 304px;
}
.user_group_provaid.user_group .fake_wrap li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user_group_provaid.user_group .fake_wrap li span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  color: rgba(64, 64, 64, 0.4);
}
.user_group.user_group2 {
  margin-top: 44px;
}
.user_group.user_group2 .user_group_head {
  margin-bottom: 20px;
}
.user_group.user_group2 .form_group {
  margin-bottom: 0;
}
.kredit_wrap {
  margin: 15px 0 45px;
}
.kredit_item {
  padding: 28px 24px;
  border-radius: 20px;
  border: 1px solid #E3E5F6;
  background: #FFF;
  height: 100%;
}
.kredit_item .kredit_item_mame {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  color: #757478;
}
.kredit_item .kredit_item_mame span {
  /*.ellipsis;*/
}
.kredit_item .kredit_item_value {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-top: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.relat {
  position: relative;
}
.abs_group_but {
  display: flex;
  position: absolute;
  top: -3px;
  right: 0;
}
.abs_group_but .btn + .btn {
  margin-left: 12px;
}
@media (max-width: 1439px) {
  .contract_line {
    flex-wrap: wrap;
  }
  .contract_line .contract_line_info {
    width: 100%;
    border: 0;
    margin-bottom: 20px;
  }
  .contract_line .fake_wrap {
    margin-left: 0;
  }
  .search_transaction .btn {
    margin-left: 12px;
  }
  .search_transaction .filt_clear {
    background: #E3E5F6;
  }
  .search_transaction .transact_down {
    margin: 0;
  }
  .transaction_sel {
    margin: 0 -6px;
  }
  .transaction_sel .transaction_inp {
    padding: 0 6px;
  }
}
@media (max-width: 1199px) {
  .search_contract_form .col3 {
    width: 50%;
  }
  .abs_group_but {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 40px;
  }
}
@media (max-width: 991px) {
  h1.midfont {
    font-size: 26px;
  }
  .search_widget.search_contract {
    padding: 20px 20px 10px 20px;
    margin-top: 30px;
  }
  .contract_line .contract_line_info {
    justify-content: space-between;
  }
  .contract_line .contract_line_info > div:last-child {
    margin-right: 0;
  }
  .contract_line .add_contract {
    padding: 4px;
    border-radius: 8px;
    margin-left: 16px;
  }
  .contract_line .add_contract span {
    display: none;
  }
  .contract_line_search .fields_result {
    width: 48px;
  }
  .contract_line_search .fields_result .to_result span {
    display: none;
  }
  .contract_line_search .fields_result .to_result svg {
    display: block;
  }
  .work_contr {
    padding: 4px;
    border-radius: 8px;
    background: #FFFFFF;
  }
  .work_contr > svg {
    display: block;
  }
  .work_contr span {
    display: none;
  }
  .user_group.user_group_dogovor {
    padding: 22px 16px;
    margin-top: 10px;
  }
  .dogovora {
    margin-top: 10px;
  }
  .dogovora .dogovora_item {
    padding: 0 12px;
    flex-wrap: wrap;
  }
  .dogovora .dogovora_item > div {
    min-height: 64px;
    padding: 8px 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 130px;
  }
  .dogovora .dogovora_item > div.dogovor_but {
    min-width: 32px;
  }
  .flex_group_but .form_group {
    position: absolute;
    right: 0;
    top: 141px;
  }
  .search_transaction {
    flex-wrap: wrap;
  }
  .search_transaction > .btn {
    margin: 20px 0 0 0;
    width: 217px;
  }
  .search_transaction > .btn.filt_clear {
    margin-left: 12px;
  }
  .search_transaction .filt_clear {
    background: #E3E5F6;
  }
  .search_transaction .transact_down {
    margin: 0 0 0 12px;
    padding: 11px;
    width: 139px;
    order: -1;
    display: flex;
    justify-content: center;
    color: #645EFC;
    border: 1px solid #645EFC;
    border-radius: 14px;
  }
  .search_transaction .transact_down span {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-right: 12px;
  }
  .transaction_sel {
    width: calc(100% + 20px);
    max-width: none;
    order: -2;
  }
  .transaction_sel.transaction_sel_period {
    max-width: none;
  }
  .transaction_sel.transaction_sel_period ~ .btn {
    width: calc(33.3333% - 8px);
  }
  .flex_group {
    margin-bottom: 25px;
  }
  .transaction_wrap {
    margin-top: 35px;
    margin-right: -10px;
  }
  .filt_clear + .form_group_down {
    margin: 20px 12px 0 0;
    width: calc(33.3333% - 8px);
    order: -1;
  }
  .filt_clear + .form_group_down .btn {
    margin-left: 0;
  }
  .dogovor_balans_wrap {
    overflow: hidden;
    margin: 0 -14px;
  }
  .dogovor_balanser {
    flex-wrap: wrap;
    margin: 10px 4px 0;
  }
  .dogovor_balanser > .col6 {
    width: 100%;
  }
  .dogovor_panel {
    padding-top: 4px;
  }
  .dogovor_panel .dogovor_block {
    margin: 0;
  }
  .dogovor_panel .btn:not(.dogovor_block) {
    margin-right: 16px;
  }
  .dogovor_panel .form_group_down {
    order: 1;
  }
  .dogovor_panel .form_group_down .btn {
    margin: 0 0 0 16px;
    padding: 15px 10px;
  }
  .dogovor_panel .form_group_down .btn svg {
    display: none;
  }
  .flex_group_dogovorhead {
    margin: -10px 0 15px;
    padding: 0 5px;
  }
  .flex_group_balans {
    margin: 10px 0 15px;
  }
  .user_group_balans.user_group {
    padding: 26px 12px;
    margin: 20px 0 0;
  }
  .user_group_balans.user_group .dogovor_balans_qvart .col6:last-child {
    width: 100%;
  }
  .accord_body .user_group_balans.user_group .dogovor_balans_qvart .col6:last-child {
    width: 50%;
  }
  .abs_group_but {
    position: relative;
    top: 0;
  }
  .abs_group_but .btn {
    width: calc(50% - 6px);
    text-align: center;
  }
  .user_group_provaid.user_group {
    margin-top: 10px;
  }
  .user_group_provaid.user_group .col4 {
    width: 30%;
  }
  .user_group_provaid.user_group .col4:nth-child(2) {
    width: 40%;
  }
  .user_group_provaid.user_group .checkbox-container {
    line-height: 20px;
  }
  .user_group_nobord.user_group .form_group {
    margin-bottom: 15px;
  }
  .user_group_nobord.user_group .col4 .form_group,
  .user_group_nobord.user_group .col6 .form_group {
    margin-bottom: 2px;
  }
  .kredit_wrap {
    margin: 10px 0 0;
  }
  .kredit_wrap .col4 {
    width: 50%;
  }
  .kredit_wrap .col4:nth-child(1) {
    width: 100%;
  }
  .kredit_wrap + .user_group {
    margin-top: 15px;
  }
  .kredit_item {
    padding: 27px 24px;
  }
}
@media (max-width: 767px) {
  .contract_line .contract_line_info {
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 7px;
    margin-bottom: 13px;
  }
  .contract_line {
    padding: 0 0 10px;
  }
  .dogovora .dogovora_item {
    padding: 4px 6px 10px;
  }
  .dogovora .dogovora_item > div {
    min-height: 52px;
    min-width: 127px;
  }
  .dogovora .dogovora_item > div.dogovor_status {
    display: none;
  }
  .dogovora .dogovora_item > div.dogovor_data_start,
  .dogovora .dogovora_item > div.dogovor_data_end {
    display: flex;
  }
  h1.midfont {
    font-size: 22px;
  }
  .transaction_wrap {
    margin-right: -15px;
  }
  .search_transaction {
    position: relative;
  }
  .search_transaction > .btn {
    margin: 8px 0 0 0;
    width: calc(50% - 6px);
  }
  .search_transaction .transact_down {
    margin: 0;
    width: calc(33.3333% - 6px);
    position: absolute;
    right: 0;
    top: 60px;
  }
  .flex_group {
    flex-wrap: wrap;
    justify-content: center;
  }
  .flex_group_but {
    width: 100%;
    margin-top: 46px;
    flex-wrap: wrap;
  }
  .flex_group_but .form_group {
    position: relative;
    right: auto;
    top: 0;
    width: calc(50% - 6px);
    order: 1;
  }
  .flex_group_but:not(.detail_tbs) > .btn {
    margin: 0 12px 0 0;
    width: calc(50% - 6px);
    text-align: center;
  }
  .transaction_sel {
    width: calc(100% + 12px);
    max-width: calc(100% + 12px);
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .transaction_sel .transaction_inp {
    width: 50%;
    margin-bottom: 12px;
  }
  .transaction_sel.transaction_sel_period {
    padding-top: 70px;
  }
  .transaction_sel.transaction_sel_period ~ .btn {
    width: calc(50% - 6px);
  }
  .filt_clear + .form_group_down {
    margin: 0;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .flex_group_dogovorhead {
    margin: 0;
    padding: 0;
    justify-content: flex-start;
  }
  .flex_group_dogovorhead h1.midfont {
    font-size: 26px;
  }
  .flex_group_dogovorhead .flex_group_but {
    margin: 0 0 25px;
    justify-content: flex-start;
  }
  .flex_group_dogovorhead > .btn {
    width: calc(50% - 7px);
  }
  .dogovor_panel {
    padding-top: 18px;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .dogovor_panel .btn:not(.dogovor_block) {
    margin-right: 0;
    width: calc(50% - 5px);
    text-align: center;
  }
  .dogovor_panel .dogovor_block {
    margin: 0;
    position: absolute;
    top: -50px;
    right: 0;
    width: calc(50% - 5px);
    text-align: center;
  }
  .dogovor_panel .form_group_down {
    width: 100%;
    margin-top: 20px;
  }
  .dogovor_panel .form_group_down .btn {
    margin: 0;
    width: 100%;
  }
  .dogovor_balans_qvart .col6 {
    width: 50%;
  }
  .dogovor_balans_qvart .dogovor_qvart .dogovor_qvart_mame .btn {
    font-size: 12px;
    padding: 6px 8px;
  }
  .flex_group_balans {
    margin: 10px 0 15px;
    justify-content: flex-start;
  }
  .flex_group_but.detail_tbs {
    justify-content: flex-start;
    margin-top: 20px;
  }
  .flex_group_but.detail_tbs .btn {
    margin: 0 10px 0 0;
  }
  .user_group_balans.user_group {
    padding: 26px 28px;
    margin: 20px 0 0;
  }
  .user_group_balans.user_group .flex_group {
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  .user_group_balans.user_group .flex_group_but {
    margin-top: 0;
    display: block;
    width: auto;
  }
  .balans_dat + .balans_dat {
    margin: 22px 0 0;
  }
  .balans_accord .accord_head.side_title {
    height: 132px;
    flex-wrap: wrap;
    padding: 0 20px;
    align-items: stretch;
  }
  .balans_accord .accord_head.side_title .balans_accord_head {
    width: 100%;
    height: 60px;
  }
  .balans_accord .accord_head.side_title .balans_accord_head .extr_toggle {
    position: absolute;
    right: 20px;
    top: 50px;
  }
  .flex_group_editdogovor {
    justify-content: flex-start;
    margin-top: -7px;
    margin-bottom: 18px;
  }
  .flex_group_editdogovor h1.midfont {
    font-size: 26px;
  }
  .user_group_provaid.user_group .fake_wrap {
    max-width: 100%;
  }
  .user_group_provaid.user_group .col4 {
    width: 43%;
  }
  .user_group_provaid.user_group .col4:nth-child(1) {
    width: 100%;
    margin-bottom: 4px;
  }
  .user_group_provaid.user_group .col4:nth-child(2) {
    width: 57%;
  }
  .user_group.user_group2 {
    margin-top: 42px;
  }
  .user_group.user_group2 .col4 {
    width: 33.3333%;
  }
  .user_group.user_group2 .col6 {
    width: 50%;
  }
  .user_group.user_group2 .form_group .form_label {
    font-size: 14px;
    margin-bottom: 7px;
  }
  .kredit_wrap .col4 {
    width: 100%;
  }
  .kredit_wrap + .user_group {
    margin-top: 15px;
  }
}
@media (max-width: 575px) {
  .search_widget.search_contract {
    padding: 23px 20px 10px 20px;
    margin-top: 20px;
  }
  .search_contract_form .col3 {
    width: 100%;
  }
  .search_contract_form .view_filtr_wrap {
    display: flex;
    position: relative;
    margin: 10px -20px 0;
    width: calc(100% + 40px);
  }
  .search_contract_form .user_group {
    height: 0;
    opacity: 0;
    padding: 0;
    transition: 0.3s;
  }
  .search_contract_form.active .user_group {
    height: 272px;
    padding: 10px 0;
    opacity: 1;
  }
  .search_contract_form.active .view_filtr .filtr_text span {
    opacity: 0;
    max-height: 0;
  }
  .search_contract_form.active .view_filtr .filtr_text span + span {
    opacity: 1;
    max-height: 20px;
  }
  .search_contract_form.active .view_filtr svg {
    transform: rotate(180deg);
  }
  .user_group_dogovor {
    padding: 16px 14px;
  }
  .user_group_dogovor .user_group_head h5 {
    font-size: 14px;
  }
  .dogovora .dogovora_item {
    padding: 4px 6px 10px;
  }
  .dogovora .dogovora_item > div {
    min-height: 52px;
    min-width: 0;
    width: 50%;
  }
  .dogovora .dogovora_item > div.dogovor_but {
    align-items: flex-end;
  }
  .flex_group_but {
    margin-top: 24px;
  }
  .flex_group_but .form_group {
    width: 100%;
  }
  .flex_group_but > .btn {
    margin: 0 0 12px;
    width: 100%;
  }
  .flex_group {
    margin-bottom: 12px;
    flex-wrap: wrap;
  }
  .flex_group > .btn {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-left: 0;
  }
  .search_transaction {
    position: relative;
  }
  .search_transaction > .btn {
    margin: 0;
    width: 100%;
  }
  .search_transaction > .btn.filt_clear {
    margin: 12px 0 0;
  }
  .search_transaction .transact_down {
    margin: 0 0 24px;
    order: -3;
    width: 100%;
    position: relative;
    top: 0;
  }
  .transaction_sel .transaction_inp {
    width: 100%;
  }
  .transaction_sel .transaction_inp:nth-child(1),
  .transaction_sel .transaction_inp:nth-child(2) {
    width: 100%;
  }
  .transaction_sel.transaction_sel_period {
    max-width: calc(100% + 12px);
  }
  .transaction_sel.transaction_sel_period ~ .btn {
    width: 100%;
  }
  .transaction_but {
    margin-top: 18px;
    flex-wrap: wrap;
  }
  .transaction_but .btn {
    width: 100%;
    margin: 12px 0 0;
  }
  .flex_group_dogovorhead {
    position: relative;
  }
  .flex_group_dogovorhead .flex_group_but {
    margin: 30px 0 23px;
    justify-content: space-between;
  }
  .flex_group_dogovorhead .flex_group_but .status {
    margin: 0;
    position: absolute;
    top: -35px;
    left: 0;
    font-size: 10px;
    letter-spacing: 1px;
  }
  .flex_group_dogovorhead > .btn {
    margin-top: 0;
  }
  .dogovor_panel {
    padding-top: 65px;
  }
  .dogovor_panel .btn:not(.dogovor_block) {
    width: 100%;
    padding: 14px 9px;
    margin-bottom: 20px;
  }
  .dogovor_panel .dogovor_block {
    top: -3px;
    width: 100%;
  }
  .dogovor_panel .form_group_down {
    margin-top: 0;
  }
  .dogovor_panel .form_group_down .fake_select {
    padding: 12px 9px;
    margin: 0;
  }
  .dogovor_balans_qvart .col6 {
    width: 100%;
  }
  .flex_group_but.detail_tbs {
    justify-content: center;
    margin: 20px -5px 0;
    flex-wrap: nowrap;
  }
  .flex_group_but.detail_tbs .btn {
    margin: 0 5px;
    text-align: center;
  }
  .flex_group_but.detail_tbs .btn.detail_tbs_pay:after {
    margin-left: 8px;
  }
  .user_group_balans.user_group {
    padding: 28px 28px;
    margin: 20px 0 0;
  }
  .user_group_balans.user_group .flex_group {
    flex-wrap: wrap;
  }
  .balans_accord .accord_head.side_title {
    height: 152px;
    padding: 43px 20px 20px 20px;
  }
  .balans_accord .accord_head.side_title .balans_accord_head {
    flex-wrap: wrap;
    height: auto;
  }
  .balans_accord .accord_head.side_title .balans_accord_head .extr_toggle {
    top: 60px;
  }
  .balans_accord .accord_head.side_title .balans_accord_head .balans_period {
    margin-left: 0;
    width: 100%;
  }
  .balans_accord .accord_head.side_title .balans_accord_head .status {
    margin: 0;
    position: absolute;
    top: 20px;
  }
  .balans_accord .accord_head.side_title .balans_accord_head span {
    margin-top: 8px;
  }
  .balans_accord.accord_item.accord_active {
    margin: 10px -10px 0;
  }
  .balans_accord .accord_body .user_group_balans.user_group {
    width: 100%;
    margin: 0;
    padding: 25px 28px;
  }
  .accord_body .user_group_balans.user_group .dogovor_balans_qvart .col6:last-child {
    width: 100%;
  }
  .flex_group_editdogovor {
    margin-top: 1px;
    margin-bottom: 20px;
  }
  .user_group_provaid.user_group .fake_wrap {
    max-width: 100%;
  }
  .user_group_provaid.user_group .col4 {
    width: 100%;
  }
  .user_group_provaid.user_group .col4:nth-child(2) {
    width: 100%;
    margin-bottom: 10px;
  }
  .user_group.user_group2 .col4 {
    width: 100%;
  }
  .user_group.user_group2 .col6 {
    width: 100%;
  }
  .kredit_wrap {
    margin: 33px 0 0;
  }
  .kredit_item .kredit_item_value {
    font-size: 18px;
    line-height: 24px;
    margin-top: 47px;
  }
  .user_group2 .user_group_head h5 {
    font-size: 18px;
  }
  .user_group_nobord.user_group .col4 .form_group,
  .user_group_nobord.user_group .col6 .form_group {
    margin-bottom: 0;
  }
  .abs_group_but {
    margin-top: 8px;
    flex-wrap: wrap;
  }
  .abs_group_but .btn {
    width: 100%;
    margin-top: 12px;
  }
  .abs_group_but .btn + .btn {
    margin-left: 0;
  }
  .table_new .balans_accord {
    margin: 10px -10px 0;
  }
  .table_new .balans_accord .accord_head.side_title {
    height: 60px;
  }
  .table_new .balans_accord .accord_head.side_title .balans_accord_head_start {
    height: auto;
    padding: 0 40px 0 55px;
    position: relative;
  }
  .table_new .balans_accord .accord_head.side_title .accord_ico {
    position: absolute;
    top: 0;
    left: 0;
  }
  .table_new .balans_accord .accord_head.side_title span {
    margin-top: 0;
  }
  .table_new .balans_accord .accord_head.side_title .extr_toggle {
    top: 15px;
    right: 15px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.extradition_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.extradition_head .extradition_date {
  width: 570px;
  position: relative;
  padding: 0 10px;
  overflow: hidden;
  margin-left: -5px;
}
.extradition_head .extradition_date .extr_date_item {
  padding: 0 5px;
  width: 111px;
  min-width: 111px;
}
.extradition_head .extradition_date .slide_arrow {
  padding: 7px;
}
.extradition_head .extradition_date .icon24 {
  width: 18px;
  height: 18px;
}
.extradition_head .extradition_date:before,
.extradition_head .extradition_date:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 90px;
  height: 100%;
}
.extradition_head .extradition_date:before {
  left: 0;
  background: linear-gradient(90deg, #FFF 0.07%, #FFF 0.08%, rgba(255, 255, 255, 0) 60.9%);
}
.extradition_head .extradition_date:after {
  right: 0;
  background: linear-gradient(270deg, #FFF 0.07%, #FFF 0.08%, rgba(255, 255, 255, 0) 60.9%);
}
.card_date {
  border-radius: 20px;
  border: 1px solid #E3E5F6;
  display: inline-flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 82px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s all;
}
.card_date:hover {
  border-color: rgba(115, 120, 252, 0.4);
}
.card_date:hover .card_price {
  color: #7378FC;
}
.card_date.active {
  background: #E3E5F6;
  border-color: #E3E5F6;
}
.card_date.active .card_price {
  color: #404040;
}
.card_date .card_info {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card_date .card_price {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.extradition_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.extradition_right > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.extradition_right .fake_wrap {
  margin: 0 30px;
}
.switch {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 20px;
}
.switch .btn-icon {
  border-radius: 8px 0 0 8px;
}
.switch .btn-icon + .btn-icon {
  border-radius: 0 8px 8px 0;
}
.extradition_tabs {
  display: inline-flex;
  margin-top: 20px;
  width: 100%;
}
.extradition_tabs .tabs {
  max-width: calc(100% - 148px);
  overflow: auto;
}
.new_search {
  border-radius: 14px 14px 0 0;
  background: #645EFC;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 7px 16px;
  white-space: nowrap;
}
.new_search span {
  margin-right: 3px;
}
.new_search:hover {
  background: #7378FC;
}
.new_search svg {
  margin-left: 10px;
}
.extr_shap {
  margin-top: 4px;
  border: 2px solid #645EFC;
  border-radius: 14px;
  overflow: hidden;
  height: 60px;
  border-bottom: 20px;
  transition: 0.3s all;
}
.extr_shap .extr_tr_main > div {
  padding: 6px 4px;
}
.extr_shap .extr_tr_content {
  background: #ffffff;
}
.extr_shap .extr_tr_content + .extr_tr_content {
  border-top: 1px solid #E3E5F6;
}
.extr_shap .extr_tr_content > div > div + div {
  margin-top: 2px;
}
.extr_shap .extr_tr_main .extr_toggle svg,
.extr_shap .extr_tr_main .extr_path,
.extr_shap .extr_tr_main .extr_komis,
.extr_shap .extr_tr_main .extr_price {
  opacity: 1;
  transition: 0.3s all;
}
.extr_shap.active {
  height: auto;
}
.extr_shap.active .extr_toggle svg {
  transform: rotate(180deg);
}
.extr_shap.active .extr_tr_main .extr_path,
.extr_shap.active .extr_tr_main .extr_komis,
.extr_shap.active .extr_tr_main .extr_price {
  opacity: 0;
}
.extr_shap .extr_price {
  text-align: center;
}
.extr_shap .extr_komis {
  text-align: right;
  color: #47B795;
}
.extr_shap .extr_komis i {
  font-style: normal;
  color: #5A5A5A;
}
.extr_tr {
  background: #E3E5F6;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #404040;
}
.extr_tr.extr_head {
  border-radius: 0 14px 14px 14px;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  background: #A2A2BA;
  min-width: 1277px;
  padding: 4px 0;
}
.extr_tr.extr_head + .extr_buttons {
  position: absolute;
  right: 0;
  top: 8px;
  width: 89px;
  padding-left: 0;
  background: #A2A2BA;
}
.extr_tr.extr_head.active {
  border-radius: 0 14px 0 0;
}
.extr_tr > div:first-child {
  padding-left: 12px;
}
.extr_tr > div {
  padding: 8px 4px;
}
.reis_hide {
  display: none;
}
.reis_active .extr_head:not(.select_reis) {
  border-radius: 0 14px 0 0;
}
.reis_active .select_reis {
  border-radius: 0 0 14px 14px;
  border-top: 1px solid rgba(90, 90, 90, 0.1);
  background: rgba(183, 151, 251, 0.1);
  align-items: flex-start;
  padding: 0;
}
.reis_active .select_reis + .extr_buttons {
  top: 57px;
  width: 162px !important;
  padding-right: 2px !important;
  background: transparent;
}
.reis_active .select_reis > div {
  padding: 4px;
}
.reis_val span {
  color: #5A5A5A;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  background: rgba(90, 90, 90, 0.1);
  padding: 0 4px;
}
.reis_val i {
  cursor: pointer;
  background: url(./img/icon/ico_close.svg) no-repeat 0 0;
  width: 12px;
  height: 12px;
  display: block;
  margin-left: 2px;
}
.reis_val + .reis_val {
  margin-top: 2px;
}
.extr_shap .extr_tr_content_osn {
  /*background: @primary-color;*/
  background: #E3E5F6;
}
.extr_reis {
  width: 7.1%;
}
.extr_from_date,
.extr_to_date {
  font-size: 15px;
  font-weight: 800;
  color: #5A5A5A;
  line-height: normal;
  position: relative;
  display: inline-block;
}
.extr_from_date sup,
.extr_to_date sup {
  color: #FF3A3A;
  font-size: 10px;
  position: absolute;
  top: -9px;
  right: -9px;
}
.klas_gruz {
  display: flex;
}
.klas_gruz svg {
  margin-left: 8px;
  color: rgba(64, 64, 64, 0.4);
}
.klas_gruz span {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(./img/icon/gruz.svg) no-repeat 0 0;
  position: relative;
  color: #FFF;
  text-align: center;
  font-size: 9px;
  font-weight: 800;
  line-height: 9px;
  text-transform: uppercase;
  margin-left: 8px;
}
.klas_gruz span i {
  font-style: normal;
  display: flex;
  position: absolute;
  top: 4px;
  right: -11px;
  width: 14px;
  height: 11px;
  align-items: center;
  justify-content: center;
  color: #47B795;
}
.extr_from,
.extr_to {
  width: 11.4%;
}
.extr_path {
  width: 7%;
}
.extr_transfer {
  width: 7.2%;
}
.extr_klas {
  width: 8.6%;
}
.extr_klas .gray_but {
  margin-bottom: 8px;
}
.extr_provaid {
  width: 6.3%;
}
.extr_komis {
  width: 7.8%;
}
.extr_price {
  width: 14.5%;
}
.extr_buttons {
  width: 18.7%;
}
.extr_logo {
  width: 29.9%;
  display: flex;
  align-items: center;
}
.extr_logo img {
  display: block;
  margin-right: 10px;
}
.extr_buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 12px !important;
}
.extr_buttons a + a {
  margin-left: 10px;
}
.extr_buttons.extr_buttons_empty {
  display: none !important;
}
.price_flex {
  display: flex;
  color: rgba(90, 90, 90, 0.6);
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
}
.price_flex.price_flex_bold {
  color: #5A5A5A;
  font-size: 15px;
  font-weight: 800;
  line-height: 18px;
  margin-top: 4px;
}
.price_flex span {
  text-align: right;
  display: block;
  width: calc(100% - 55px);
  padding-right: 5px;
}
.price_flex i {
  font-style: normal;
  display: block;
  width: 55px;
  text-align: left;
}
.compact_price {
  display: none;
}
.compact_block {
  display: none !important;
}
.extradition_table.tbcomp .extr_fil {
  display: none !important;
}
.extradition_table.tbcomp .reis_hide {
  display: none !important;
}
.reis_extend {
  display: flex;
  align-items: flex-start;
}
.reis_extend .reis_side {
  width: 0px;
  overflow: hidden;
  transition: 0.3s all;
}
.reis_extend .reis_items {
  width: 100%;
  transition: 0.3s all;
}
.reis_extend.compact .extr_shap {
  border-radius: 20px;
  height: auto;
}
.reis_extend.compact .extr_shap + .extr_shap {
  margin-top: 10px;
}
.reis_extend.compact .reis_side {
  display: block;
  width: 304px;
  padding: 18px 15px;
  border-radius: 20px;
  border: 2px solid #E3E5F6;
  background: #FFF;
  margin-top: 4px;
  transition: 0.3s all;
}
.reis_extend.compact .reis_items {
  width: calc(100% - 324px);
  margin-left: 20px;
}
.reis_extend.compact .reis_items .extr_tr {
  flex-wrap: wrap;
}
.reis_extend.compact .reis_items .extr_tr_content_osn {
  align-items: normal;
}
.reis_extend.compact .extr_tr_main {
  display: none;
}
.reis_extend.compact .extr_tr_content:not(.extr_tr_content_osn) {
  display: none;
}
.reis_extend.compact .compact_block {
  display: flex !important;
}
.reis_extend.compact .extend_block {
  display: none;
}
.reis_extend.compact .compact_info {
  width: 68.2%;
  padding: 18px 20px;
  flex-wrap: wrap;
  height: auto;
}
.reis_extend.compact .compact_logo {
  width: 100%;
  display: flex;
  align-items: center;
}
.reis_extend.compact .compact_tablo {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0 20px;
  width: 100%;
  margin: 0;
}
.reis_extend.compact .compact_tablo .tablo_dop_info {
  width: 100%;
  height: 18px;
  text-align: center;
  position: absolute;
  top: -20px;
  left: 0;
  color: rgba(64, 64, 64, 0.8);
}
.reis_extend.compact .compact_tablo .tablo_center {
  display: flex;
  width: 100%;
  position: relative;
  margin-top: 18px;
}
.reis_extend.compact .compact_tablo .date_comp {
  display: flex;
  width: 120px;
  position: relative;
}
.reis_extend.compact .compact_tablo .date_comp .extr_from_date {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #404040;
}
.reis_extend.compact .compact_tablo .date_comp .extr_from_date span {
  order: -1;
  width: 80%;
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
  color: #5A5A5A;
}
.reis_extend.compact .compact_tablo .date_comp .extr_from_port {
  position: absolute;
  top: 3px;
  right: 2px;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}
.reis_extend.compact .compact_tablo .date_comp.date_last {
  order: 1;
  justify-content: flex-end;
}
.reis_extend.compact .compact_tablo .date_comp.date_last .extr_from_date {
  text-align: right;
  justify-content: flex-end;
}
.reis_extend.compact .compact_tablo .date_comp.date_last .extr_from_port {
  left: 5px;
  right: auto;
}
.reis_extend.compact .compact_tablo .date_graf {
  width: calc(100% - 240px);
}
.reis_extend.compact .compact_tablo .date_graf .graf_line {
  height: 2px;
  background: #A2A2BA;
  margin: 13px 6px;
  position: relative;
}
.reis_extend.compact .compact_tablo .date_graf .graf_line .graf_circle {
  position: absolute;
  top: -3px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #ffffff;
  border: 2px solid #A2A2BA;
}
.reis_extend.compact .compact_tablo .date_graf .graf_line .graf_circle_first {
  left: 0;
}
.reis_extend.compact .compact_tablo .date_graf .graf_line .graf_circle_last {
  right: 0;
}
.reis_extend.compact .compact_tablo .date_graf .graf_line .graf_circle_dop {
  left: 50%;
  margin-left: -4px;
}
.reis_extend.compact .compact_tablo .date_graf .graf_line .graf_circle_dop span {
  position: absolute;
  bottom: 5px;
  left: -13px;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}
.reis_extend.compact .compact_tablo .date_graf .tablo_path {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(64, 64, 64, 0.8);
}
.reis_extend.compact .compact_tablo .date_graf .tablo_path > div {
  width: 50%;
  display: flex;
  justify-content: center;
}
.reis_extend.compact .compact_tablo .date_graf .tablo_path > div .info_which_reis + div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.reis_extend.compact .compact_tablo .date_graf .tablo_path .path_val {
  margin-left: 4px;
  color: #23232B;
}
.reis_extend.compact .compact_tablo.peresadka {
  padding-top: 15px;
}
.reis_extend.compact .compact_tablo.peresadka .tablo_dop_info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.reis_extend.compact .compact_tablo.peresadka .tablo_dop_info .extr_peresad {
  margin: 0 7px;
}
.reis_extend.compact .tablo_but {
  margin-top: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.reis_extend.compact .tablo_but .btn {
  color: #645EFC;
}
.reis_extend.compact .extr_buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 18px 12px 18px 20px !important;
  width: 31.2%;
  position: relative;
  border-left: 1px solid rgba(100, 94, 252, 0.3);
}
.reis_extend.compact .extr_buttons a + a {
  margin-left: 10px;
}
.reis_extend.compact .extr_buttons .compact_price {
  display: block;
  padding: 45px 0 38px;
  width: 100%;
  text-align: right;
  position: absolute;
  right: 12px;
  bottom: 50px;
}
.reis_extend.compact .extr_buttons .compact_price .price_flex_bold {
  margin-top: 2px;
}
.reis_extend.compact .extr_buttons .compact_price .gray_but {
  font-size: 13px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.reis_extend.compact .extr_buttons .compact_price .price_flex {
  justify-content: flex-end;
}
.reis_extend.compact .extr_buttons .compact_price .price_flex span {
  width: auto;
  font-size: 26px;
  font-weight: 800;
  line-height: 28px;
}
.reis_extend.compact .extr_buttons .compact_price .price_flex i {
  width: auto;
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
}
.reis_extend.compact .extr_buttons .extr_fix {
  position: absolute;
  top: 18px;
  right: 12px;
}
.reis_extend.compact .extr_buttons .extr_bron,
.reis_extend.compact .extr_buttons .extr_tarif {
  width: calc(50% - 10px);
  text-align: center;
}
.reis_extend.compact .extr_buttons .extr_tarif {
  background: #ffffff;
  color: #7378FC;
}
.reis_extend.compact .extr_buttons .extr_tarif:hover {
  background: #7378FC;
  color: #fff;
}
.reis_extend.compact .logo_images {
  display: flex;
  align-items: center;
}
.reis_extend.compact .logo_images img {
  width: 30px;
  display: block;
}
.reis_extend.compact .logo_images img + img {
  margin-left: -5px;
}
.reis_extend.compact .logo_company {
  margin: 0 0 0 8px;
  color: rgba(64, 64, 64, 0.8);
}
.reis_extend.compact .reis_icon {
  display: flex;
  align-items: flex-start;
  color: #A2A2BA;
  margin: 0 0 0 auto;
}
.reis_extend.compact .reis_icon div + div {
  margin-left: 12px;
}
.reis_extend.compact .reis_icon .active {
  color: #47B795;
}
.reis_extend.compact .reis_icon .ico_val {
  position: relative;
}
.reis_extend.compact .reis_icon .ico_val i {
  font-style: normal;
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  color: #fff;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.reis_extend.compact .reis_icon .ico_val.ico_gruz {
  margin-right: 2px;
}
.reis_extend.compact .reis_icon .ico_val.ico_gruz svg {
  padding-right: 4px;
}
.reis_extend.compact .reis_icon .ico_val.ico_gruz i {
  width: 18px;
}
.reis_extend.compact .reis_icon .ico_val.ico_gruz span {
  position: absolute;
  right: -3px;
  top: 2px;
  font-size: 8px;
}
.logo_detail {
  display: none;
}
.tablo_mob {
  display: none;
}
.date_dop {
  display: none !important;
}
.info_which_reis {
  display: none;
}
.modal_side.modal_side_pinned .modal_head {
  margin: 11px 0 23px 0;
}
.modal_side.modal_side_pinned .modal_head .checkbox-container {
  margin-left: 30px;
}
.modal_side.modal_side_pinned .modal_head .applay_but {
  margin: 0 60px 0 auto;
}
.modal_side.modal_side_pinned .modal_head .modal_close {
  top: 5px;
}
.pinned_flex {
  margin-left: -40px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 352px;
  padding-right: 10px;
}
.pinned_flex .pinned + .pinned {
  margin-top: 4px;
}
.pinned input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.pinned input:checked ~ .pinned_wrap {
  border: 1px solid #645EFC;
  outline: 1px solid #E3E5F6;
}
.pinned input:checked ~ .pinned_wrap .pinned_check {
  background: #645EFC;
}
.pinned input:checked ~ .pinned_wrap .pinned_check .checkmark {
  background: url(./img/icon/ico_check3.svg) no-repeat center center;
  border: 0;
}
.pinned_wrap {
  border-radius: 20px;
  border: 1px solid #E3E5F6;
  outline: 1px solid #E3E5F6;
  display: flex;
  overflow: hidden;
}
.pinned_wrap .pinned_check {
  width: 38px;
  border-right: 1px solid #E3E5F6;
  padding-right: 5px;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pinned_wrap .pinned_check .checkmark {
  margin-right: 0;
}
.pinned_wrap .extr_tr {
  background: #ffffff;
  padding: 0 0 0 8px;
}
.pinned_wrap:hover .pinned_check {
  background: #E3E5F6;
}
.pinned_wrap .pinned_line {
  width: calc(67.6% - 38px);
}
.pinned_wrap .extr_buttons {
  width: 32.4%;
  align-items: center;
  border-left: 1px solid #E3E5F6;
  padding-right: 8px !important;
}
.pinned_wrap .extr_tr > div {
  padding: 10px 4px;
}
.pinned_wrap .extr_tr + .extr_tr {
  border-top: 1px solid #E3E5F6;
}
.pinned_wrap .extr_reis {
  width: 9.8%;
}
.pinned_wrap .extr_from,
.pinned_wrap .extr_to {
  width: 17.2%;
}
.pinned_wrap .extr_from span,
.pinned_wrap .extr_to span {
  margin-left: 3px;
}
.pinned_wrap .extr_path {
  width: 10.7%;
}
.pinned_wrap .extr_transfer {
  width: 10.9%;
}
.pinned_wrap .extr_klas {
  width: 13%;
}
.pinned_wrap .extr_provaid {
  width: 9.3%;
}
.pinned_wrap .extr_komis {
  width: 11.9%;
  text-align: right;
}
.pinned_wrap .extr_komis i {
  font-style: normal;
}
.pinned_wrap .pinned_price {
  margin-right: 22px;
}
.pinned_wrap .extr_fix.activ .icon24 {
  padding: 3px;
}
@media (min-width: 1300px) {
  .reis_extend.compact .extr_buttons {
    min-height: 200px;
  }
}
@media (max-width: 1439px) {
  .overflow {
    border-radius: 0 0 14px 14px;
  }
  .extradition_head {
    flex-wrap: wrap;
  }
  .extradition_head .extradition_date {
    width: 100%;
  }
  .extradition_head .extradition_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }
  .extradition_head .extradition_right .fake_wrap {
    order: -1;
    margin-left: 0;
  }
  .extradition_tabs {
    margin-top: 30px;
  }
  .extradition_tabs .tabs {
    max-width: calc(100% - 103px);
  }
  .extradition_tabs .new_search {
    position: relative;
  }
  .extradition_tabs .new_search:after {
    content: "";
    display: block;
    position: absolute;
    left: -29px;
    top: 0;
    width: 29px;
    height: 32px;
    background: linear-gradient(270deg, #FFF 0.07%, #FFF 0.08%, rgba(255, 255, 255, 0) 100%);
  }
  .extr_shap {
    height: 64px;
  }
  .extr_shap .extr_tr_main {
    padding: 2px 0;
  }
  .extr_shap .extr_tr_content {
    padding: 8px 0;
  }
  .extr_shap .extr_tr_content > div {
    padding-top: 8px;
    padding-bottom: 8px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .extr_tr_content {
    flex-wrap: wrap;
  }
  .extr_tr_content .extr_reis,
  .extr_tr_content .extr_klas {
    width: 17.1%;
  }
  .extr_tr_content .extr_klas {
    padding-left: 10px;
  }
  .extr_tr_content .extr_from,
  .extr_tr_content .extr_to,
  .extr_tr_content .extr_provaid {
    width: 24.1%;
  }
  .extr_tr_content .extr_transfer {
    width: 17.3%;
  }
  .extr_tr_content .extr_path {
    width: 16.9%;
  }
  .extr_tr_content .extr_transfer {
    padding-right: 10px;
  }
  .extr_tr_content .extr_komis {
    width: 33.9%;
    text-align: right;
  }
  .extr_tr_content .extr_price {
    width: 24.9%;
    padding: 7px 10px 7px 4px !important;
  }
  .extr_tr_content .extr_price .price_flex {
    justify-content: flex-end;
  }
  .extr_tr_content .extr_price .price_flex i,
  .extr_tr_content .extr_price .price_flex span {
    width: auto;
  }
  .extr_tr_content .extr_buttons {
    width: 100%;
    justify-content: space-between !important;
    align-items: center;
    padding: 8px 6px !important;
    flex-direction: row !important;
    height: auto !important;
  }
  .extr_tr_content .extr_buttons a:not(.extr_fix) {
    width: calc(50% - 32px);
    max-width: 322px;
    text-align: center;
  }
  .extr_tr_content .extr_buttons a.extr_fix {
    margin: 0 0 0 auto;
  }
  .extr_tr_content .extr_buttons a + a {
    margin-left: 24px;
  }
  .extr_tr {
    font-size: 12px;
  }
  .extr_tr > div:first-child {
    padding-left: 10px;
  }
  .extr_tr_main .extr_logo {
    width: 16.6%;
    font-size: 10px;
    letter-spacing: 1px;
  }
  .extr_tr_main .extr_logo img {
    width: 30px;
    padding: 8px 0;
    display: block;
    margin-right: 4px;
  }
  .extr_tr_main .extr_path {
    width: 22.7%;
  }
  .extr_tr_main .extr_transfer,
  .extr_tr_main .extr_klas,
  .extr_tr_main .extr_provaid {
    padding: 0 !important;
    width: 0;
  }
  .extr_tr_main .extr_komis {
    width: 24.3%;
  }
  .extr_tr_main .extr_price {
    width: 22.7%;
  }
  .extr_tr_main .extr_buttons {
    width: 13.7%;
    padding-right: 10px !important;
  }
  .price_flex.price_flex_bold {
    font-size: 14px;
    margin-bottom: 2px;
    margin-top: 2px;
  }
  .new_search {
    width: 103px;
    text-transform: capitalize;
  }
  .new_search span {
    display: none;
  }
  .pinned_wrap .pinned_line {
    width: calc(68.6% - 38px);
    padding: 1px 0;
  }
  .pinned_wrap .extr_buttons {
    width: 31.4%;
  }
  .pinned_wrap .extr_tr {
    font-size: 13px;
    padding: 0 0 0 4px;
  }
  .pinned_wrap .extr_reis {
    width: 9.9%;
  }
  .pinned_wrap .extr_from,
  .pinned_wrap .extr_to {
    width: 16.7%;
  }
  .pinned_wrap .extr_path {
    width: 11.7%;
  }
  .pinned_wrap .extr_transfer {
    width: 11.5%;
  }
  .pinned_wrap .extr_klas {
    width: 14.1%;
  }
  .pinned_wrap .extr_provaid {
    width: 12.1%;
  }
  .pinned_wrap .extr_komis {
    width: 7.3%;
    text-align: left;
  }
  .pinned_wrap .hide_pk {
    display: none;
  }
  .pinned_wrap .pinned_price {
    margin-right: 8px;
  }
  .pinned_wrap .pinned_price .price_flex_bold {
    font-size: 15px;
  }
}
@media (max-width: 1299px) {
  .logo_detail {
    display: block;
  }
  .reis_extend.compact .reis_side {
    display: none;
  }
  .reis_extend.compact .reis_items {
    width: 100%;
    margin-left: 0px;
  }
  .reis_extend.compact .extr_shap + .extr_shap {
    margin-top: 4px;
  }
  .reis_extend.compact .compact_info {
    width: 100%;
    padding: 10px 18px;
    flex-direction: row;
    position: relative;
  }
  .reis_extend.compact .compact_tablo {
    padding: 12px 0 11px;
  }
  .reis_extend.compact .compact_logo {
    flex-wrap: wrap;
  }
  .reis_extend.compact .logo_company {
    margin: 0 0 0 10px;
    font-size: 13px;
  }
  .reis_extend.compact .tablo_but .btn {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    top: 12px;
    background: transparent;
  }
  .reis_extend.compact .tablo_but .btn-alert {
    display: none;
  }
  .reis_extend.compact .extr_buttons {
    width: 100%;
    border-top: 1px solid rgba(100, 94, 252, 0.3);
    border-left: 0;
    padding-left: 0 !important;
    padding-bottom: 8px !important;
    padding-right: 18px !important;
    align-items: flex-end;
  }
  .reis_extend.compact .extr_buttons .compact_price {
    padding: 0 10px 0 0;
    width: 25%;
    position: relative;
    right: auto;
    bottom: auto;
  }
  .reis_extend.compact .extr_buttons .extr_bron,
  .reis_extend.compact .extr_buttons .extr_tarif {
    width: calc(37.5% - 46px);
    text-align: center;
  }
  .reis_extend.compact .extr_buttons .extr_fix {
    position: relative;
    top: 0;
    right: 0;
  }
  .modal_side.modal_side_pinned {
    border-radius: 0;
  }
  .modal_side.modal_side_pinned .modal_head {
    margin: -1px 0 15px 0;
    justify-content: space-between;
  }
  .modal_side.modal_side_pinned .modal_head .modal_close {
    position: relative;
    top: 0;
    right: 0px;
  }
  .modal_side.modal_side_pinned .modal_side_wrap {
    padding: 0 4px 0 14px;
  }
  .modal_side_pinned .modal_head .checkbox-container {
    margin-left: 0 !important;
    order: -1;
  }
  .modal_side_pinned .modal_head .modal_head_rzn .checkbox-container {
    margin-left: 20px !important;
  }
  .pinned_flex {
    max-height: calc(100vh - 83px);
    margin-left: 0;
  }
  .pinned_flex .pinned + .pinned {
    margin-top: 10px;
  }
  .pinned_wrap {
    flex-wrap: wrap;
    position: relative;
  }
  .pinned_wrap .pinned_check {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .pinned_wrap .pinned_line {
    width: calc(100% - 40px);
    margin-left: 40px;
  }
  .pinned_wrap .extr_tr {
    font-size: 12px;
    padding: 5px 0 6px;
  }
  .pinned_wrap .extr_tr + .extr_tr {
    padding-top: 9px;
  }
  .pinned_wrap .extr_tr > div > div + div {
    margin-top: 2px;
  }
  .pinned_wrap .extr_reis {
    width: 18.2%;
  }
  .pinned_wrap .extr_from,
  .pinned_wrap .extr_to {
    width: 25.3%;
  }
  .pinned_wrap .extr_path {
    width: 15.1%;
  }
  .pinned_wrap .extr_transfer {
    width: 16.1%;
  }
  .pinned_wrap .extr_klas {
    width: 18.2%;
    padding-left: 11px !important;
  }
  .pinned_wrap .extr_klas .gray_but {
    margin-bottom: 5px;
  }
  .pinned_wrap .extr_provaid {
    width: 48.7%;
  }
  .pinned_wrap .extr_komis {
    width: 12.4%;
    text-align: right;
  }
  .pinned_wrap .extr_price {
    width: 19.8%;
  }
  .pinned_wrap .hide_pk {
    display: block;
  }
  .pinned_wrap .modal_link {
    font-size: 12px;
  }
  .pinned_wrap .extr_buttons {
    width: calc(100% - 40px);
    align-items: center;
    border-left: 0;
    padding-left: 8px;
    padding-bottom: 13px;
    margin-left: 40px;
  }
  .pinned_wrap .pinned_price {
    display: none;
  }
  .pinned_wrap a + a {
    margin-left: 24px;
  }
  .pinned_wrap .extr_bron,
  .pinned_wrap .extr_tarif {
    width: calc(50% - 40px);
    text-align: center;
  }
}
@media (max-width: 767px) {
  .new_search {
    width: 50px;
    font-size: 0;
  }
  .new_search svg {
    margin-left: 0;
  }
  .extradition_tabs .tabs {
    max-width: calc(100% - 50px);
  }
  .extradition_head .extradition_right .fake_wrap {
    margin-right: 15px;
  }
  .extr_shap .extr_tr_content .extr_reis {
    width: 18.5%;
  }
  .extr_shap .extr_tr_content .extr_from,
  .extr_shap .extr_tr_content .extr_to {
    width: 25.2%;
  }
  .extr_shap .extr_tr_content .extr_path {
    width: 14.8%;
  }
  .extr_shap .extr_tr_content .extr_transfer {
    width: 16.3%;
  }
  .extr_shap .extr_tr_content .extr_klas {
    width: 18.3%;
  }
  .extr_shap .extr_tr_content .extr_klas .gray_but {
    margin-bottom: 2px;
  }
  .extr_shap .extr_tr_content .extr_provaid {
    width: 15%;
  }
  .extr_shap .extr_tr_content .extr_komis {
    width: 31.7%;
  }
  .extr_shap .extr_tr_content .extr_price {
    width: 35%;
    padding-right: 14px !important;
  }
  .extr_shap .extr_tr_content .extr_price .price_flex.price_flex_bold {
    margin-bottom: -2px;
    margin-top: 6px;
  }
  .extr_tr_main .extr_logo {
    flex-wrap: wrap;
    width: 19.2%;
  }
  .extr_tr_main .extr_logo img {
    padding: 0;
    margin-right: 0;
  }
  .extr_tr_main .extr_path {
    width: 16.5%;
  }
  .extr_tr_main .extr_komis {
    width: 20.8%;
  }
  .extr_tr_main .extr_price {
    width: 25.7%;
    text-align: right;
  }
  .extr_tr_main .extr_price .price_flex {
    justify-content: flex-end;
  }
  .extr_tr_main .extr_price .price_flex span,
  .extr_tr_main .extr_price .price_flex i {
    width: auto;
  }
  .extr_tr_main .extr_price .price_flex.price_flex_bold {
    margin-bottom: 4px;
    margin-top: 1px;
  }
  .extr_tr_main .extr_buttons {
    width: 20.8%;
    padding-right: 8px !important;
  }
  .reis_extend.compact .compact_info {
    width: 100%;
    padding: 10px 12px;
  }
  .reis_extend.compact .compact_tablo {
    padding: 20px 0 5px;
  }
  .reis_extend.compact .compact_tablo .date_comp .extr_from_date span {
    font-size: 18px;
    line-height: 24px;
  }
  .reis_extend.compact .compact_tablo .date_comp .extr_from_port {
    top: 1px;
  }
  .reis_extend.compact .compact_tablo .tablo_center {
    margin-top: 19px;
  }
  .reis_extend.compact .compact_tablo .date_graf .graf_line {
    margin: 11px 6px;
  }
  .reis_extend.compact .compact_tablo.peresadka {
    padding-top: 25px;
  }
  .reis_extend.compact .extr_buttons {
    padding-right: 11px !important;
    justify-content: center !important;
    padding-top: 12px !important;
    padding-bottom: 11px !important;
  }
  .reis_extend.compact .extr_buttons .compact_price {
    margin: 0 auto 0 0;
    padding: 0 0 0 13px;
    width: auto;
    text-align: left;
  }
  .reis_extend.compact .extr_buttons .compact_price .price_flex {
    justify-content: flex-start;
    margin: 0;
  }
  .reis_extend.compact .extr_buttons .extr_bron,
  .reis_extend.compact .extr_buttons .extr_tarif {
    width: 124px;
  }
  .reis_extend.compact .extr_buttons .extr_fix {
    margin: 0 0 0 auto;
  }
  .extr_from_date sup,
  .extr_to_date sup {
    font-size: 9px;
    position: relative;
    top: 0;
    right: auto;
  }
  .modal_side.modal_side_pinned .modal_side_wrap {
    padding: 0 10px 0 20px;
  }
  .modal_side.modal_side_pinned .modal_head {
    margin: -1px 0 20px 0;
  }
  .modal_side.modal_side_pinned .modal_head .checkbox-container {
    margin-left: 0 !important;
    order: 1;
    margin-top: 15px;
  }
  .modal_side.modal_side_pinned .modal_head .applay_but {
    margin: 15px 0 0 auto;
    width: auto;
    order: 2;
  }
  .pinned_flex {
    max-height: calc(100vh - 125px);
  }
  .pinned_flex .pinned + .pinned {
    margin-top: 20px;
  }
  .pinned_wrap {
    outline: none !important;
  }
  .pinned_wrap .pinned_check {
    bottom: auto;
    padding-right: 0;
    width: 100%;
    height: 40px;
    position: relative;
    border-bottom: 1px solid #E6E6F9;
    border-right: 0;
  }
  .pinned_wrap .pinned_line {
    width: 100%;
    margin-left: 0;
  }
  .pinned_wrap .extr_from_date,
  .pinned_wrap .extr_to_date {
    margin-top: 0 !important;
    font-size: 14px;
  }
  .pinned_wrap .extr_tr {
    padding: 6px 8px 6px;
  }
  .pinned_wrap .extr_tr > div {
    padding: 8px 4px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pinned_wrap .extr_reis {
    width: 26.4%;
    padding-left: 4px !important;
  }
  .pinned_wrap .extr_from,
  .pinned_wrap .extr_to {
    width: 36.5%;
  }
  .pinned_wrap .extr_to {
    padding-right: 0 !important;
  }
  .pinned_wrap .extr_path {
    width: 26.1%;
  }
  .pinned_wrap .extr_transfer {
    width: 37%;
  }
  .pinned_wrap .extr_klas {
    width: 36.9%;
    padding-left: 4px !important;
  }
  .pinned_wrap .extr_klas .gray_but {
    margin-bottom: 1px;
  }
  .pinned_wrap .extr_provaid {
    width: 30%;
  }
  .pinned_wrap .extr_komis {
    width: 26.5%;
  }
  .pinned_wrap .extr_price {
    width: 43.5%;
    padding-right: 8px !important;
  }
  .pinned_wrap .extr_buttons {
    width: 100%;
    padding-bottom: 16px;
    margin-left: 0;
  }
  .pinned_wrap .extr_buttons a + a {
    margin-left: 8px;
  }
  .pinned_wrap .extr_buttons .extr_bron,
  .pinned_wrap .extr_buttons .extr_tarif {
    width: calc(50% - 24px);
  }
}
@media (max-width: 575px) {
  .extradition_head .extradition_right {
    flex-wrap: wrap;
  }
  .extradition_head .extradition_right .fake_wrap {
    margin-right: 6px;
  }
  .extradition_head .extradition_right > div + div {
    width: 100%;
    margin-top: 20px;
  }
  .extradition_tabs {
    margin-top: 20px;
  }
  .extradition_table .overflow {
    overflow: hidden;
  }
  .extradition_table .overflow .extr_head > div {
    width: 120px;
  }
  .extr_shap {
    height: 112px;
  }
  .extr_shap .extr_tr_main {
    padding: 4px 0;
    flex-wrap: wrap;
  }
  .extr_shap .extr_tr_main > div {
    padding: 4px 0;
    height: 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .extr_shap .extr_tr_main .extr_logo {
    width: 41.9%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .extr_shap .extr_tr_main .extr_logo img {
    margin-right: 4px;
  }
  .extr_shap .extr_tr_main .extr_path {
    width: 27.5%;
  }
  .extr_shap .extr_tr_main .extr_komis {
    width: 30.6%;
    padding-right: 11px;
  }
  .extr_shap .extr_tr_main .extr_price {
    width: 71.9%;
    text-align: left;
    padding-left: 11px;
  }
  .extr_shap .extr_tr_main .extr_price .price_flex {
    justify-content: flex-start;
  }
  .extr_shap .extr_tr_main .extr_buttons {
    width: 28.1%;
    justify-content: flex-end !important;
    align-items: center;
    flex-direction: row !important;
    height: auto !important;
  }
  .extr_shap .extr_tr_content .extr_reis,
  .extr_shap .extr_tr_content .extr_path,
  .extr_shap .extr_tr_content .extr_provaid {
    width: 26.8%;
    padding-left: 11px;
  }
  .extr_shap .extr_tr_content .extr_from,
  .extr_shap .extr_tr_content .extr_transfer {
    width: 35.9%;
  }
  .extr_shap .extr_tr_content .extr_to,
  .extr_shap .extr_tr_content .extr_klas {
    width: 36.9%;
    padding-left: 4px;
  }
  .extr_shap .extr_tr_content .extr_komis {
    width: 27.8%;
  }
  .extr_shap .extr_tr_content .extr_price {
    width: 45.4%;
  }
  .extr_shap .extr_tr_content .extr_buttons a:not(.extr_fix) {
    width: calc(50% - 24px);
  }
  .extr_shap .extr_tr_content .extr_buttons a + a {
    margin-left: 8px;
  }
  .extr_shap.active .extr_tr_main .extr_path,
  .extr_shap.active .extr_tr_main .extr_komis,
  .extr_shap.active .extr_tr_main .extr_price {
    opacity: 1;
  }
  .extr_tr > div:first-child {
    padding-left: 11px;
  }
  .extr_tr.extr_head {
    padding: 4px 0 6px;
  }
  .reis_extend.compact .reis_icon {
    margin: 18px 0 0;
    width: 100%;
  }
  .reis_extend.compact .tablo_but .btn {
    left: auto;
    transform: none;
    top: 12px;
    right: 3px;
    font-size: 13px;
  }
  .reis_extend.compact .compact_tablo {
    padding: 0 0 5px;
  }
  .reis_extend.compact .compact_tablo .tablo_center {
    margin-top: 16px;
    flex-wrap: wrap;
  }
  .reis_extend.compact .compact_tablo .date_comp {
    width: 22%;
    flex-wrap: wrap;
  }
  .reis_extend.compact .compact_tablo .date_comp .extr_from_date {
    font-size: 0;
    width: 100%;
  }
  .reis_extend.compact .compact_tablo .date_comp .extr_from_date span {
    font-size: 16px;
    width: 100%;
  }
  .reis_extend.compact .compact_tablo .date_comp.date_last {
    order: 0;
    justify-content: flex-start;
    margin-right: 40%;
  }
  .reis_extend.compact .compact_tablo .date_comp.date_last .extr_from_date {
    text-align: left;
    justify-content: flex-start;
  }
  .reis_extend.compact .compact_tablo .date_comp.date_last .extr_from_port {
    left: auto;
  }
  .reis_extend.compact .compact_tablo .date_comp.date_last:before {
    content: "–";
    display: block;
    position: absolute;
    top: 0px;
    left: -14px;
    color: #5A5A5A;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
  }
  .reis_extend.compact .compact_tablo .date_comp .extr_from_port {
    top: 0;
    position: relative;
    left: 0;
    right: auto;
    font-size: 13px;
    line-height: 18px;
  }
  .reis_extend.compact .compact_tablo .date_comp.date_dop {
    display: flex !important;
    margin-top: 10px;
  }
  .reis_extend.compact .compact_tablo .date_graf {
    width: 56%;
    padding-left: 9.5%;
    position: absolute;
    right: 0;
    top: 0;
  }
  .reis_extend.compact .compact_tablo .date_graf .graf_line {
    display: none;
  }
  .reis_extend.compact .compact_tablo .date_graf .tablo_dop_info {
    width: 100%;
    height: auto;
    text-align: left;
    position: relative;
    top: 0;
    left: 0;
  }
  .reis_extend.compact .compact_tablo .date_graf .tablo_path {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .reis_extend.compact .compact_tablo .date_graf .tablo_path .info_which_reis {
    display: flex;
    align-items: center;
  }
  .reis_extend.compact .compact_tablo .date_graf .tablo_path .info_which_reis .icon24 {
    width: 18px;
    margin-right: 4px;
  }
  .reis_extend.compact .compact_tablo .date_graf .tablo_path .info_which_reis + div {
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
  }
  .reis_extend.compact .compact_tablo .date_graf .tablo_path > div {
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 40px;
  }
  .reis_extend.compact .compact_tablo .date_graf .tablo_path > div + div {
    margin-top: 10px;
  }
  .reis_extend.compact .compact_tablo .date_graf .tablo_dop_info {
    display: none;
  }
  .reis_extend.compact .compact_tablo.peresadka {
    padding-top: 0;
  }
  .reis_extend.compact .extr_buttons {
    justify-content: space-between !important;
    padding-left: 13px !important;
  }
  .reis_extend.compact .extr_buttons .compact_price {
    margin: 0 0 9px;
    padding-left: 0;
    width: 100%;
  }
  .reis_extend.compact .extr_buttons a:not(.extr_fix) {
    width: calc(50% - 5px);
  }
  .reis_extend.compact .extr_buttons .extr_fix {
    margin: 0;
    position: absolute;
    top: 18px;
    right: 11px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
input {
  font-family: 'Manrope', sans-serif;
}
.fake_wrap {
  position: relative;
  /*z-index: 20;*/
}
.form_input {
  border: 0;
  padding: 3px 14px;
  background: #fff;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  color: #5A5A5A;
}
.form_input:focus {
  outline: none;
}
.form_area {
  border-radius: 14px;
  border: 1px solid rgba(90, 90, 90, 0.3);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 10px;
  background: transparent;
  font-family: 'Manrope', sans-serif;
  width: 100%;
  color: #5A5A5A;
  height: 96px;
  resize: none;
}
.form_area:focus {
  outline: none;
}
.simple-typeahead-input {
  font-family: 'Manrope', sans-serif;
  color: #5A5A5A;
}
.simple-typeahead-input:focus {
  outline: none;
}
.fake_text {
  display: flex;
  padding: 0 14px;
  font-weight: 500;
  line-height: 20px;
}
.fake_text .fake_text_title {
  opacity: 0.6;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fake_text .fake_text_kod {
  opacity: 0.4;
  font-weight: 600;
  width: 20%;
  text-align: right;
}
.fake_text .search-input {
  width: 100%;
  height: 100%;
}
.fake_input_placeholder {
  display: none;
}
.checkbox-container {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-container input:checked ~ .checkmark {
  background: #645EFC url(./img/icon/ico_check2.svg) no-repeat center center;
  border: 0;
}
.checkbox-container.checkbox-disable input:checked ~ .checkmark {
  background-color: rgba(35, 35, 43, 0.3);
}
.checkbox-container.checkbox-disable .checkmark {
  background-color: rgba(35, 35, 43, 0.3);
}
.checkbox-container .checkmark {
  position: relative;
  display: block;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-right: 10px;
  border: 1px solid rgba(35, 35, 43, 0.2);
  border-radius: 4px;
}
.checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.radio-container {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.radio-container input:checked ~ .radiomark {
  background: #fff;
  border: 5px solid #645EFC;
}
.radio-container .radiomark {
  position: relative;
  display: block;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-right: 10px;
  border: 1px solid rgba(75, 70, 92, 0.2);
  border-radius: 50px;
}
.search_form {
  position: relative;
}
.search_form .search_sub {
  position: absolute;
  right: 22px;
  top: 12px;
}
.input_field {
  border-radius: 14px;
  border: 1px solid rgba(90, 90, 90, 0.3);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 13px 12px;
  background: transparent;
  font-family: 'Manrope', sans-serif;
  width: 100%;
  color: #5A5A5A;
  transition: 0.3s all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.input_field:focus,
.input_field:active,
.input_field.focused {
  outline: none;
  color: #404040;
  background: rgba(100, 94, 252, 0.3);
  border: 1px solid #645EFC;
}
.input_field:hover {
  border: 1px solid rgba(100, 94, 252, 0.4);
}
input[type="submit"] {
  border: 0;
  width: 100%;
  cursor: pointer;
}
button[type="submit"] {
  border: 0;
  cursor: pointer;
  font-family: "Manrope", sans-serif;
  line-height: 30px;
}
.input_error {
  display: none;
}
.sel_error .input_field {
  border: 2px solid #EE5C5C;
  padding: 12px 12px;
}
.sel_error .input_error {
  display: block;
  border-radius: 20px;
  background: #EE5C5C;
  padding: 0 8px;
  color: #FFFFFF;
  margin-top: 6px;
}
.just_input {
  align-items: center;
}
.just_input .form_group {
  flex: 1 1 auto;
}
.just_input .form_group > div {
  background: #FFFFFF;
  border-radius: 14px;
}
.just_input .btn-icon {
  background: #FFFFFF;
}
.just_input .checkbox-container {
  display: inline-flex;
}
.modal_side_otchet .relat {
  position: static;
}
.modal_side_otchet .just_input {
  margin: 0 0 0 auto;
}
.modal_side_otchet .just_input .btn-icon {
  background: #E3E5F6;
}
.modal_side_otchet .just_input .otchet_option.active {
  background: #645EFC;
}
.modal_side_otchet .just_input .otchet_option + .fake_dropdown {
  right: 0;
  margin-top: 100px;
  top: 0;
  height: calc(100% - 102px);
  min-height: 0;
  max-height: none;
}
.modal_side_otchet .just_input .modal_close {
  background: rgba(100, 94, 252, 0.5);
  position: relative;
  top: 0;
  right: 0;
  display: block;
}
.modal_side_otchet .just_input .modal_close:hover {
  background: #645efc;
}
.fields .chosen-container.chosen-container-single .search-input {
  border: 0 !important;
}
.fields .chosen-container.chosen-container-single .chosen-single {
  border: 0 !important;
}
.chosen-container.chosen-container-single .search-input {
  width: 100%;
  border: 0 !important;
}
.chosen-container.chosen-container-single .chosen-single {
  border-radius: 14px !important;
  border: 1px solid rgba(90, 90, 90, 0.3) !important;
}
.chosen-container.chosen-container-single .chosen-single > div {
  background: url(./img/icon/chevron-select.svg) no-repeat center center;
  width: 18px;
  height: 18px;
  right: 9px;
  top: 14px;
}
.chosen-container.chosen-container-single .chosen-single > div b {
  display: none;
}
.chosen-container.chosen-container-single .chosen-drop {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px !important;
  border: 1px solid #F1EAFF !important;
  padding: 10px;
  margin-top: 10px;
}
.chosen-container.chosen-container-single .chosen-results li.highlighted {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), #645EFC;
  border-radius: 14px;
  color: #404040;
}
.chosen-container.chosen-container-single.chosen-container-active .chosen-single {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), #645EFC !important;
  color: #404040 !important;
}
.chosen-container.chosen-container-single.chosen-container-active .search-input {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), #645EFC !important;
  color: #404040 !important;
  border: 1px solid #645EFC !important;
}
.chosen-container.chosen-container-single .search-input:focus {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), #645EFC !important;
  color: #404040 !important;
  border: 1px solid #645EFC !important;
}
.form_group .select2-container {
  display: block;
  width: 100% !important;
}
.form_group .select2-container > div {
  display: block;
}
.form_group .select2-container--default .select2-selection--single {
  color: #404040;
  padding: 14px 12px;
  justify-content: space-between;
  border-radius: 14px !important;
  border: 1px solid rgba(90, 90, 90, 0.3);
  display: flex;
  align-items: center;
  height: auto;
}
.form_group .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 18px;
  color: #404040;
  padding: 0;
}
.form_group .select2-container--default .select2-selection--single .select2-selection__arrow {
  background: url(./img/icon/chevron-select.svg) no-repeat center center;
  width: 18px;
  height: 18px;
  right: 9px;
  top: 14px;
}
.form_group .select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}
.form_group .select2-container--default .select2-selection--single:focus,
.form_group .select2-container--default .select2-selection--single:active,
.form_group .select2-container--default .select2-selection--single.select2-container--open {
  outline: none;
  color: #404040;
  background: rgba(100, 94, 252, 0.3);
  border: 1px solid #645EFC;
}
.form_group .select2-container--default .select2-selection--single:hover {
  border: 1px solid rgba(100, 94, 252, 0.4);
}
.select2-dropdown {
  margin-top: 10px;
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px !important;
  border: 1px solid #F1EAFF !important;
  padding: 10px;
}
.select2-dropdown ul li.select2-results__option {
  padding: 12px 20px;
}
.select2-dropdown ul li.select2-results__option:hover,
.select2-dropdown ul li.select2-results__option.select2-results__option--highlighted.select2-results__option--selectable {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), #645EFC;
  border-radius: 14px;
  color: #404040;
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 14px 0;
  height: 68px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #23232B;
}
.header a {
  color: #23232B;
}
.header a.header_burger {
  color: #645EFC;
}
.header .header_left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.header .header_left .header_logo {
  width: 224px;
  padding: 0 0 0 30px;
  position: relative;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.header .header_left .header_logo .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: 194px;
  height: 50px;
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: 0.3s all;
}
.header .header_left .header_logo .logo span {
  transition: 0.3s all;
  width: auto;
  display: block;
  overflow: hidden;
  margin-left: 5px;
}
.header .header_left .header_logo .logo img {
  display: block;
  max-width: 100%;
  position: absolute;
  left: 0;
}
.header .header_left .header_logo .logo.logo_withtxt {
  height: 40px;
  text-align: center;
  font-weight: 800;
  line-height: normal;
  font-size: 18px;
  color: #EEEEF8;
  border-radius: 10px;
  background: #645EFC;
  padding: 0 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.header .header_left .header_logo .logo.logo_withtxt img {
  position: relative;
}
.header .header_left .header_logo.active {
  /*padding-left: 16px;
        padding-right: 16px;*/
  width: 103px;
}
.header .header_left .header_logo.active .logo {
  max-width: 49px;
  border-radius: 100px;
}
.header .header_left .header_logo.active .logo img {
  max-width: 194px;
  margin-left: 9px;
}
.header .header_left .header_logo.active .logo span {
  width: 0;
  margin: 0;
}
.header .header_left .header_logo.active .side_toggle a {
  margin: 4px -15px 0 0;
}
.header .header_left .header_logo .side_toggle {
  position: absolute;
  right: -40px;
}
.header .header_left .header_logo .side_toggle a {
  display: inline-block;
  color: #70717C;
  margin: 4px 6px 0 0;
  transition: 0.3s all;
  /*&.active {
            transform: scale(-1, 1);
          }*/
}
.header .header_left .header_cent {
  margin: 0 40px 0 90px;
}
.header .header_left .header_cent span {
  display: none;
}
.header .header_left .header_tech span {
  opacity: 0.6;
  display: block;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 2px;
}
.header .header_left .header_tech a + a {
  margin-left: 37px;
}
.header .header_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.header .header_right .header_ico {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-right: 20px;
}
.header .header_right .header_ico img {
  width: 24px;
  height: 24px;
  display: block;
}
.header .header_right .header_user_mess a {
  color: #5A5A5A;
  position: relative;
  display: block;
}
.header .header_right .header_user_mess a.active:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #EE5C5C;
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid #E3E5F6;
}
.header .header_right .header_user a {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 30px;
  width: 176px;
}
.header .header_right .header_user a span {
  display: block;
  margin-right: 14px;
}
.header.header_login {
  padding: 9px 12px;
  border-radius: 30px;
  height: auto;
  background: #FFFFFF;
  margin-bottom: 12px;
}
.header.header_login .header_logo img {
  display: block;
  height: 34px;
  width: auto;
}
.header.header_login a.btn {
  color: #FFFFFF;
}
.currency_wrap {
  position: relative;
}
.currency_wrap .fake_select:focus,
.currency_wrap .fake_select:active,
.currency_wrap .fake_select.focused {
  outline: none;
  color: #5A5A5A;
  background: transparent;
  border: 1px solid transparent;
}
.header_currency {
  margin-right: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.header_currency a {
  display: flex;
  align-items: center;
  color: #23232B;
}
.header_currency a + a {
  margin-left: 20px;
}
.header_currency a svg,
.header_currency a img {
  display: block;
  margin-right: 6px;
}
.header_currency a .icon_fill {
  background: #C4CAFD;
  border-radius: 4px;
}
.header_currency a .icon_fill:hover {
  color: #FFFFFF;
  background: #7378FC;
}
.header_currency a .icon_bord {
  border: 1px solid #C4CAFD;
  border-radius: 5.5px;
  box-sizing: content-box;
}
.header_currency a .icon_bord:hover {
  border: 1px solid #7378FC;
}
.header_currency a .icon_rus {
  width: 22px;
  height: 22px;
}
.header_burger {
  display: none;
}
@media (max-width: 1199px) {
  .header .header_left .header_logo {
    width: 89px !important;
    padding: 0 20px !important;
  }
  .header .header_left .header_logo .logo {
    width: 49px !important;
    border-radius: 100px;
  }
  .header .header_left .header_logo .logo img {
    max-width: 194px;
  }
  .header .header_left .header_logo .logo span {
    width: 0;
  }
  .header .header_left .header_cent {
    margin: 0 20px 0 0;
    font-size: 0;
  }
  .header .header_left .header_cent a {
    display: flex;
  }
  .header .header_left .header_cent span {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .header .header_left .header_cent span svg {
    margin-right: 6px;
  }
  .header .header_right .header_user a {
    width: 170px;
    margin-right: 15px;
  }
  .header .header_right .header_currency {
    margin-right: 20px;
  }
  .header.open_sidebar {
    z-index: 30;
    background: #EEEEF8;
  }
  .header_burger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 0 14px 14px 0;
    background: rgba(100, 94, 252, 0.3);
    width: 71px;
    height: 68px;
  }
  .header_burger .burg-close {
    display: none;
  }
  .header_burger.active {
    color: #ffffff;
  }
  .header_burger.active .burg-open {
    display: none;
  }
  .header_burger.active .burg-close {
    display: block;
  }
  .side_toggle,
  .header_tech {
    display: none;
  }
}
@media (max-width: 767px) {
  .header .header_right .header_user a {
    width: 40px;
  }
  .header .header_right .header_user a span {
    display: none;
  }
  .header.header_login {
    padding: 12px 20px;
  }
  .header.header_login img {
    width: 132px;
  }
  .header.header_login .header_logo {
    margin-bottom: 28px;
  }
  .header.header_login .header_cent {
    position: absolute;
    left: 20px;
    top: 42px;
  }
  .header.header_login.header_login_reg .header_logo {
    margin-bottom: 0;
  }
  .header.header_login.header_login_reg .header_cent {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 575px) {
  .header .header_left {
    flex: 1 1 auto;
  }
  .header .header_left .header_logo {
    display: none;
  }
  .header .header_left .header_cent {
    margin: 0 0 0 auto;
  }
  .header .header_left .header_cent span {
    font-size: 0;
  }
  .header .header_currency {
    margin-right: 14px;
  }
  .header .header_currency a {
    font-size: 0;
  }
  .header .header_currency a + a {
    margin-left: 8px;
  }
  .header .header_right .header_ico {
    margin-right: 34px;
  }
  .header .header_right .header_user a {
    margin-right: 20px;
  }
  .header.header_login {
    display: block;
  }
  .header.header_login .header_logo {
    margin-bottom: 0;
  }
  .header.header_login .header_cent {
    position: relative;
    left: 0;
    top: 0;
    margin: 7px 0 14px;
    font-size: 12px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main_with_side {
  display: flex;
  position: relative;
}
.main_with_side .main_side {
  display: block;
  width: 306px;
  min-width: 306px;
  padding: 18px 13px;
  border-radius: 20px;
  border: 2px solid #E3E5F6;
  background: #FFF;
  transition: 0.3s all;
}
.main_with_side .main_side .fake_select {
  color: #404040;
  padding: 6px 12px;
  justify-content: space-between;
  border-radius: 14px;
  border: 1px solid rgba(90, 90, 90, 0.3);
}
.main_with_side .main_side .accord_checks {
  width: 100%;
}
.main_with_side .main_side .accord_checks.accord_checks34 .checkbox-container {
  min-height: 34px;
}
.main_with_side .main_side .accord_checks.accord_checks34 .check_num {
  width: 17px;
  min-width: 17px;
}
.main_with_side .main_side .checkbox-container {
  padding: 0;
  line-height: 20px;
}
.main_with_side .main_side .checkbox-container .check_name {
  width: 100%;
}
.main_with_side .main_side .checkbox-container .checkmark {
  border: 2px solid rgba(64, 64, 64, 0.3);
  min-width: 18px;
  margin-top: 2px;
}
.main_with_side .main_side .checkbox-container + .checkbox-container {
  margin-top: 8px;
}
.main_with_side .main_side .checkbox-container .stars {
  transform: scale(1.5);
  transform-origin: 0;
  margin: 0 13px 0 0;
}
.main_with_side .main_side .wide_reset .modal_close {
  display: block;
}
.main_with_side .main_side.main_side_wide {
  max-width: 768px;
  width: 100%;
  padding-top: 60px;
  position: relative;
}
.main_with_side .main_side.main_side_wide .hotels_switch {
  display: none;
}
.main_with_side .main_side.main_side_wide .wide_reset {
  position: absolute;
  top: 18px;
  left: 14px;
  right: 14px;
  font-size: 16px;
}
.main_with_side .main_side.main_side_wide .wide_reset .reset_filtr {
  margin-right: 55px;
  font-size: 14px;
}
.main_with_side .main_side.main_side_wide .wide_reset .modal_close {
  display: block;
  right: 0;
}
.main_with_side .main_side.main_side_wide .group_filtr {
  columns: 2;
  column-gap: 25px;
}
.main_with_side .main_side.main_side_wide .group_filtr .side_group:first-child {
  margin-top: 0;
}
.main_with_side .main_side.main_side_wide .group_filtr > div {
  break-inside: avoid-column;
}
.main_with_side .main_cont {
  width: calc(100% - 324px);
  margin-left: 20px;
}
.main_with_side .side_group_hr {
  border-top: 1px solid #E3E5F6;
  padding-top: 20px;
}
.main_with_side .hotel_map {
  width: 100%;
  height: 100%;
}
.main_with_side .hotel_map img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.main_with_side:not(.compact) .hotel_map {
  display: none;
}
.main_with_side.compact .main_side {
  border-radius: 20px 0 0 20px;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.main_with_side.compact .hotels_switch {
  border-radius: 20px 0 0;
}
.main_with_side.compact .main_cont {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 304px;
  width: 295px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0 20px 20px 0;
  border: 2px solid #E3E5F6;
  margin: 0;
  padding: 8px 13px;
  background: #fff;
}
.main_with_side.compact .main_cont .hotels_areas {
  width: 100%;
}
.show_check {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  align-items: center;
  padding: 6px 0;
}
.show_check svg {
  transform: rotate(180deg);
}
.side_title {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  padding: 6px 0;
  margin-bottom: 10px;
}
.side_title-duo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.side_title .btn-icon {
  padding: 0;
}
.side_title.accord_head {
  padding: 4px 0;
}
.side_title.side_title_razdel {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #23232B;
  padding: 0;
}
.group_filtr {
  margin-top: 20px;
}
.flitr_list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px -10px;
}
.flitr_list span {
  display: flex;
  padding: 2px 4px;
  margin: 0 5px 10px;
  background: #E3E5F6;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #645EFC;
  border-radius: 8px;
}
.flitr_list span svg {
  margin-left: 2px;
}
.hotel_wrap .search_hotels .fields .fields_line {
  width: 67%;
}
.hotel_wrap .search_hotels .fields .fields_subm {
  width: calc(33% - 177px);
  position: static;
  padding-right: 0;
}
.hotel_wrap .search_hotels .fields .to_result {
  width: 177px;
}
.hotel_wrap .search_hotels .fields .to_result span {
  width: auto;
}
.hotel_wrap .search_hotels .fields .to_result svg {
  display: none;
}
.hotels_switch {
  display: flex;
  justify-content: space-between;
  background: #E3E5F6;
  padding: 20px 15px 14px;
  margin: -20px -15px 10px;
  border-radius: 20px 20px 0 0;
}
.hotels_switch a {
  transition: 0.3s all;
  display: block;
  width: calc(50% - 2px);
  padding: 5px 16px;
  background: transparent;
  font-size: 16px;
  line-height: 20px;
  border-radius: 12px;
  text-align: center;
  color: #404040;
}
.hotels_switch a:hover,
.hotels_switch a.active,
.hotels_switch a:focus {
  background: #7378FC;
  color: #fff;
}
.favorite {
  display: flex;
  align-items: center;
  border: 1px solid rgba(90, 90, 90, 0.3);
  padding: 5px 12px;
  margin-top: 20px;
}
.favorite > svg {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  margin-left: -2px;
  color: #EE5C5C;
}
.favorite .fav_close {
  display: none;
}
.favorite.active {
  background: #E3E5F6;
  border-color: #E3E5F6;
}
.favorite.active .check_info {
  display: none;
}
.favorite.active .fav_close {
  display: block;
  margin-left: 12px;
}
.check_num {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  width: 24px;
  min-width: 24px;
  margin-left: 1px;
  padding: 4px 0;
  text-align: right;
}
.check_info {
  width: 18px;
  min-width: 18px;
  margin-left: 12px;
  height: 18px;
  background: #E6E6F9;
  border-radius: 50px;
  color: #757478;
}
.hotel_item {
  background: #E3E5F6;
  display: flex;
  border-radius: 20px;
  height: auto !important;
}
.hotel_item + .hotel_item {
  margin-top: 10px;
}
.hotel_item .hotel_img {
  width: 270px;
  height: 270px;
  overflow: hidden;
  border-radius: 20px;
  position: absolute;
  left: 0;
  top: 0;
}
.hotel_item .hotel_img .hotel_img_slider {
  height: 100%;
}
.hotel_item .hotel_img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hotel_item .hotel_img .img_favorite {
  position: absolute;
  top: 20px;
  right: 20px;
  color: transparent;
}
.hotel_item .hotel_img .img_favorite.active,
.hotel_item .hotel_img .img_favorite:hover {
  color: #EE5C5C;
}
.hotel_item .hotel_img .img_favorite.active use,
.hotel_item .hotel_img .img_favorite:hover use {
  stroke: #EE5C5C;
}
.hotel_item .hotel_img .img_favorite use {
  stroke: #fff;
}
.hotel_item .hotel_info {
  padding: 20px 20px 6px 290px;
  width: 100%;
  font-size: 13px;
  position: relative;
  min-height: 270px;
}
.hotel_item .hotel_info .hotel_info_room {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(100, 94, 252, 0.3);
  padding: 9px 0;
}
.hotel_item .hotel_info .hotel_info_room .hotel_nomer {
  max-width: 360px;
}
.hotel_item .hotel_info .hotel_info_room .hotel_nomer .hotel_nomer_title {
  font-weight: 800;
  min-height: 36px;
}
.hotel_item .hotel_info .hotel_info_room .hotel_nomer .hotel_nomer_info {
  display: flex;
  flex-wrap: wrap;
  margin: 4px -2px;
}
.hotel_item .hotel_info .hotel_info_room .hotel_nomer .hotel_nomer_info > span {
  padding: 0 4px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5A5A5A;
  margin: 2px;
}
.hotel_item .hotel_info .hotel_info_room .hotel_nomer .hotel_nomer_info > span.nomer_info_no {
  background: rgba(219, 57, 49, 0.1);
}
.hotel_item .hotel_info .hotel_info_room .hotel_nomer .hotel_nomer_info > span.nomer_info_yes {
  background: rgba(71, 183, 149, 0.1);
}
.hotel_item .hotel_info .hotel_info_room .hotel_price_block .btn {
  display: none;
}
.hotel_item .hotel_info .hotel_info_room .hotel_price {
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 6px;
}
.hotel_item .hotel_info .hotel_info_room .hotel_price .price_flex {
  font-size: 18px;
  line-height: 24px;
  justify-content: flex-end;
}
.hotel_item .hotel_info .hotel_info_room .hotel_price .price_flex span {
  width: auto;
}
.hotel_item .hotel_info .hotel_info_room .hotel_price .price_flex i {
  width: auto;
}
.hotel_item .hotel_info .hotel_info_footer {
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: right;
  border-top: 1px solid rgba(100, 94, 252, 0.3);
}
.hotel_item .hotel_info .hotel_info_footer .btn {
  display: inline-block;
}
.hotel_info_head {
  display: flex;
  flex-wrap: wrap;
}
.hotel_info_head .hotel_info_shap {
  width: 100%;
}
.hotel_info_head .hotel_title_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hotel_info_head .hotel_title_block .hotel_title {
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  color: #645EFC;
}
.hotel_info_head .hotel_adres_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.hotel_info_head .hotel_adres_block .hotel_icon {
  display: flex;
  justify-content: flex-end;
}
.hotel_info_head .hotel_adres_block .hotel_icon img {
  display: block;
  width: 24px;
  height: 24px;
  margin-left: 10px;
}
.hotel_adres .hotel_adres_title {
  font-weight: 600;
  border-bottom: 1px dashed #645EFC;
  margin-bottom: 4px;
  display: inline-block;
}
.hotel_adres .hotel_adres_place {
  display: flex;
}
.hotel_adres .hotel_adres_place > span + span {
  margin-left: 10px;
}
.hotel_adres .hotel_adres_place > span {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
}
.hotel_adres .hotel_adres_place > span span {
  color: #5A5A5A;
}
.compact {
  margin-right: -30px;
}
.compact .hotel_item {
  background: #fff;
  flex-wrap: wrap;
  position: relative;
  border-radius: 0;
  border-bottom: 1px solid rgba(100, 94, 252, 0.3);
}
.compact .hotel_item .hotel_img {
  width: calc(100% + 20px);
  height: 149px;
  position: relative;
  margin: 10px -10px 0;
}
.compact .hotel_item .hotel_img .img_favorite {
  top: 10px;
  right: 10px;
}
.compact .hotel_item .hotel_info {
  padding: 0;
  width: 100%;
  height: auto;
}
.compact .hotel_item .hotel_info .hotel_info_head {
  padding: 0 10px 4px;
}
.compact .hotel_item .hotel_info .hotel_info_head .hotel_title_block .hotel_title {
  font-size: 13px;
  line-height: 18px;
  width: 100%;
  padding-right: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}
.compact .hotel_item .hotel_info .hotel_info_head .hotel_title_block .hotel_info_right {
  min-width: 32px;
}
.compact .hotel_item .hotel_info .hotel_info_head .hotel_title_block .hotel_info_right .hotel_reit {
  width: 32px;
  padding: 2px;
  font-size: 13px;
  line-height: 18px;
  margin-right: 0;
}
.compact .hotel_item .hotel_info .hotel_info_head .hotel_icon {
  display: none;
}
.compact .hotel_item .hotel_info .hotel_info_head .hotel_adres_block {
  padding: 0;
}
.compact .hotel_item .hotel_info .hotel_info_room {
  border: 0;
  padding: 0 10px 8px;
}
.compact .hotel_item .hotel_info .hotel_info_room .hotel_price_block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 6px;
}
.compact .hotel_item .hotel_info .hotel_info_room .hotel_price_block .btn {
  display: block;
}
.compact .hotel_item .hotel_info .hotel_info_room .hotel_price {
  font-size: 10px;
  line-height: 12px;
  margin-top: 0;
  text-align: left;
  width: calc(100% - 118px);
}
.compact .hotel_item .hotel_info .hotel_info_room .hotel_price > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.compact .hotel_item .hotel_info .hotel_info_room .hotel_price .price_flex {
  justify-content: flex-start;
  font-size: 16px;
}
.compact .hotel_item .extr_fix,
.compact .hotel_item .hotel_adres_title,
.compact .hotel_item .hotel_adres_metro,
.compact .hotel_item .hotel_nomer,
.compact .hotel_item .hotel_info_footer {
  display: none;
}
.compact .hotel_item + .hotel_item {
  margin-top: 20px;
}
.compact .stars {
  background-size: auto 100%;
  height: 12px;
  position: absolute;
  right: 5px;
  top: 3px;
}
.compact .stars.star1 {
  width: 12px;
}
.compact .stars.star2 {
  width: 26px;
}
.compact .stars.star3 {
  width: 40px;
}
.compact .stars.star4 {
  width: 54px;
}
.compact .stars.star5 {
  width: 68px;
}
.pinned_wrap.pinned_wrap_hotel .pinned_line {
  width: calc(75.5% - 38px);
}
.pinned_wrap.pinned_wrap_hotel .extr_buttons {
  width: 24.5%;
}
.pinned_wrap.pinned_wrap_hotel .extr_buttons .hotel_srok {
  display: none;
}
.pinned_line .hotel_item {
  background: #FFFFFF;
  padding: 7px 0;
}
.pinned_line .hotel_item .hotel_img {
  width: 128px;
  height: 128px;
  border-radius: 14px;
}
.pinned_line .hotel_item .hotel_info {
  padding: 0 0 0 148px;
  display: flex;
  height: 128px;
}
.pinned_line .hotel_item .hotel_info .hotel_info_head {
  width: 49%;
}
.pinned_line .hotel_item .hotel_info .hotel_info_head .hotel_adres_block {
  flex-wrap: wrap;
  padding: 4px 0;
}
.pinned_line .hotel_item .hotel_info .hotel_info_head .hotel_adres_block .hotel_icon {
  justify-content: flex-start;
  width: 100%;
  margin-left: -10px;
  margin-top: 12px;
}
.pinned_line .hotel_item .hotel_info .hotel_info_head .hotel_adres_block .hotel_adres_place > span {
  font-size: 12px;
  line-height: 18px;
}
.pinned_line .hotel_item .hotel_info .hotel_info_room {
  width: 51%;
  border: 0;
}
.pinned_line .hotel_item .hotel_info .hotel_info_room .hotel_nomer {
  max-width: none;
}
.pinned_line .hotel_item .hotel_info .hotel_info_room .hotel_nomer .hotel_nomer_title {
  min-height: 0;
}
.stars {
  display: inline-block;
  margin-left: 8px;
  height: 24px;
  background: url(./img/icon/star.svg) repeat-x left center;
  vertical-align: bottom;
}
.stars.star1 {
  width: 16px;
}
.stars.star2 {
  width: 34px;
}
.stars.star3 {
  width: 52px;
}
.stars.star4 {
  width: 70px;
}
.stars.star5 {
  width: 88px;
}
.hotel_info_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hotel_info_right .hotel_reit {
  margin-right: 20px;
}
.hotel_reit {
  width: 44px;
  padding: 4px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #FFFFFF;
  text-align: center;
  margin-right: 20px;
}
.hotel_reit.hotel_reit_high {
  background: #6ACBAE;
}
.hotel_reit.hotel_reit_mid {
  background: #FDA97A;
}
.hotel_reit.hotel_reit_small {
  background: #FF9090;
}
.show_filtri {
  display: none;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 589px;
  font-size: 0px;
  font-weight: 600;
  line-height: 20px;
  z-index: 5;
  color: #645EFC;
  transition: 0.3s all;
}
.show_filtri svg {
  color: #404040;
  transform: rotate(90deg);
  margin-left: 0px;
  transition: 0.3s all;
}
.show_filtri.active {
  font-size: 14px;
  left: 30px;
}
.show_filtri.active svg {
  color: #404040;
  transform: rotate(270deg);
  margin-left: 10px;
}
.compact .show_filtri {
  display: flex;
}
.hotel_card {
  position: absolute;
  top: 38%;
  right: 20.5%;
  width: 250px;
  border-radius: 14px;
  background: #FFFFFF;
  display: flex;
}
.hotel_card:after {
  content: "";
  display: block;
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: -16px;
  right: 6px;
  background: url('./img/icon/ico_pin_avia.svg') no-repeat 0 0 / contain;
}
.hotel_card .hotel_card_img {
  width: 85px;
  min-width: 85px ;
  height: 85px;
  border-radius: 14px;
  overflow: hidden;
}
.hotel_card .hotel_card_info {
  padding: 9.5px 10px;
  width: 100%;
}
.hotel_card .hotel_card_info .hotel_card_title {
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  margin: 3px 0 5px;
  color: #645EFC;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hotel_card .hotel_card_info .hotel_card_price {
  font-size: 13px;
  font-weight: 800;
  line-height: 18px;
  color: #5A5A5A;
}
.hotel_card .hotel_info_right .stars {
  position: relative;
  right: 0;
  top: 0;
  margin: 0;
}
.hotel_card .hotel_info_right .hotel_reit {
  font-size: 13px;
  width: 34px;
  padding: 2px;
  margin: 0;
  line-height: 18px;
}
.search_hotels_modal .modal_head {
  display: none;
}
.search_hotels_phone {
  display: none;
}
.hotel_pay_info1 {
  color: #EE5C5C;
  font-weight: 800;
  line-height: 20px;
}
.hotel_pay_info2 {
  color: rgba(90, 90, 90, 0.4);
  font-weight: 600;
  line-height: 20px;
}
.suggestion-block.suggestion-hotels {
  max-width: 100%;
  width: 100%;
}
.hotel_pay {
  position: relative;
  z-index: 1;
}
.hotel_pay .fake_text {
  border: 1px solid rgba(90, 90, 90, 0.3);
  padding: 4px 12px;
  line-height: 36px;
  border-radius: 14px;
}
.hotel_detail {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
  position: relative;
  height: 400px;
  padding-top: 4px;
}
.hotel_detail > div {
  width: calc(50% - 10px);
  margin-right: 50%;
}
.hotel_detail .hotel_detail_img {
  height: 100%;
  margin: 0;
  position: absolute;
  right: 5px;
  top: 0;
}
.hotel_detail .hotel_detail_udobs .udobs_title {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 12px;
}
.hotel_detail .hotel_detail_udobs .tarifs_body_list {
  columns: 3;
}
.hotel_detail .hotel_detail_foot {
  background: #E3E5F6;
  padding: 20px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #757478;
  display: flex;
  justify-content: space-between;
}
.hotel_detail .hotel_detail_foot div {
  padding: 0 10px;
  width: 33.3333%;
}
.hotel_detail .hotel_detail_foot .hotel_detail_edit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.hotel_detail .hotel_detail_foot .hotel_detail_edit .btn {
  color: #645EFC;
}
.hotel_detail .hotel_detail_foot span {
  display: block;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  margin-top: 4px;
  color: #645EFC;
}
.hotel_detail_first {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.hotel_detail_first .stars {
  margin: 0;
}
.hotel_detail_first .hotel_detail_date {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: #757478;
  margin: 0 20px 0 12px;
}
.hotel_detail_first .hotel_reit {
  margin: 0 0 0 auto;
}
.hotel_detail_second {
  display: flex;
  justify-content: space-between;
}
.hotel_detail_second .hotel_name .hotel_detail_name {
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
  color: #5A5A5A;
  margin-bottom: 7px;
}
.hotel_detail_second .hotel_adres {
  display: flex;
  align-items: flex-start;
  font-size: 13px;
}
.hotel_detail_second .hotel_adres img {
  display: block;
}
.hotel_detail_second .hotel_price_block .price_flex_bold {
  margin-top: 4px;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  justify-content: flex-end;
  text-transform: none;
}
.hotel_detail_second .hotel_price_block .price_flex_bold span,
.hotel_detail_second .hotel_price_block .price_flex_bold i {
  width: auto;
}
.hotel_detail_second .hotel_price_block .btn {
  display: inline-block;
  margin-top: 10px;
  padding: 6px 24px;
  white-space: nowrap;
}
.more_udobs {
  border-bottom: 1px dashed #645EFC;
}
.slider {
  position: relative;
}
.slider .slider_wrap {
  overflow: hidden;
}
.slider .slider_wrap .slider_outer {
  display: flex;
  height: 100%;
}
.slider .slider_wrap .slider_outer .slider_item {
  display: flex;
}
.slider .slider_wrap .slider_outer .slider_item img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.slider .slider_wrap .slider_nav {
  position: absolute;
  bottom: 40px;
  right: 20px;
}
.slider_nav {
  display: flex;
}
.slider_nav .slider-nav {
  display: block;
  padding: 4px;
  border-radius: 8px;
  background: #F1EAFF;
  box-shadow: -2px 6px 22px 0px rgba(162, 162, 186, 0.36);
}
.slider_nav .slider-nav.slider-prev {
  transform: rotate(90deg);
}
.slider_nav .slider-nav.slider-next {
  transform: rotate(-90deg);
  margin-left: 20px;
}
.detail_slider .slider_wrap .slider_outer .slider_item {
  flex-direction: column;
}
.detail_slider .slider_wrap .slider_outer .slider_item img {
  padding: 5px;
  height: 203px;
  width: 203px;
  min-width: 203px;
  border-radius: 14px;
}
.detail_slider .slider_wrap .slider_outer .slider_item.slider_item_big img {
  height: 406px;
  width: 406px;
  min-width: 406px;
}
.detail_slider .slider_wrap .slider_outer:after {
  content: "";
  background: linear-gradient(274deg, #FFF 36.31%, rgba(255, 255, 255, 0) 91.81%);
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -14px;
}
.detail_slider .slider_wrap .slider_nav {
  left: 25px;
  bottom: 25px;
  right: auto;
}
.slider_dots {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 0 -4px;
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
}
.slider_dots li {
  width: 6px;
  height: 6px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  margin: 0 4px;
}
.slider_dots li.active {
  background: #ffffff;
}
.pop_slider .slider_dots {
  left: 134px;
  bottom: 40px;
  transform: translate(0, 0);
}
.slide_row {
  display: flex;
  height: 100%;
}
.slide_row .slid_wrap {
  width: calc(100% - 442px);
}
.slide_row .slid_wrap .pop_slider {
  height: 100%;
}
.slide_row .slid_col {
  width: 442px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 30px 40px 30px !important;
}
.slide_row .slid_col .hotel_info_head {
  position: relative;
}
.slide_row .slid_col .hotel_info_head .hotel_adres_block {
  padding: 20px 0;
  font-size: 13px;
}
.slide_row .slid_col .hotel_info_head .hotel_adres_block .hotel_adres_place > span {
  font-size: 12px;
  line-height: 16px;
}
.slide_row .slid_col .hotel_info_shap {
  padding-top: 58px;
}
.slide_row .slid_col .hotel_info_right .hotel_reit {
  margin-right: 0;
}
.slide_row .slid_col .slide_variant {
  border-top: 1px solid #645EFC;
  padding-top: 25px;
  text-align: center;
  margin-top: 30px;
}
.slide_row .slid_col .hotel_price_block {
  border-top: 1px solid #645EFC;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
}
.slide_row .slid_col .hotel_price_block .price_flex {
  justify-content: flex-end;
  font-size: 18px;
  line-height: 24px;
}
.slide_row .slid_col .hotel_price_block .price_flex span,
.slide_row .slid_col .hotel_price_block .price_flex i {
  width: auto;
}
.hotel_award {
  border-radius: 20px;
  border: 1px solid #C4CAFD;
  padding: 20px 18px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.hotel_award img {
  display: block;
  width: 73px;
  max-width: 73px;
  margin-right: 10px;
}
.hotel_award div {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #5A5A5A;
}
.hotel_award div span {
  display: block;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #404040;
  margin-bottom: 6px;
}
.hotel_description {
  border-radius: 20px;
  border: 2px solid #C4CAFD;
  overflow: hidden;
  margin-top: 20px;
}
.hotel_description .hotel_description_body {
  max-height: 178px;
  transition: 0.3s all;
  overflow: hidden;
  position: relative;
}
.hotel_description .hotel_description_body:after {
  content: "";
  display: block;
  width: 100%;
  height: 73px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, #FFF 32px, rgba(255, 255, 255, 0) 100%);
  transition: 0.3s all;
}
.hotel_description.active .hotel_description_body {
  max-height: 1000px;
}
.hotel_description.active .hotel_description_body:after {
  height: 0;
}
.hotel_description .hotel_description_head {
  background: #C4CAFD;
  padding: 20px;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}
.hotel_description .hotel_flex-fluid {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 0 -10px;
}
.hotel_description .hotel_flex-fluid .hotel_desc {
  width: 50%;
  padding: 0 10px;
  font-size: 13px;
}
.hotel_description .hotel_flex-fluid .hotel_desc > * {
  max-width: 66%;
  margin-bottom: 10px;
  display: block;
}
.hotel_description .hotel_flex-fluid .hotel_desc > *:last-child {
  margin-bottom: 0;
}
.hotel_description .hotel_flex-fluid.hotel_flex-awards .hotel_desc > * {
  max-width: 79%;
}
.hotel_description .hotel_flex-fluid.hotel_flex-awards .hotel_award_wrap {
  padding: 0 10px;
  max-width: 324px;
  min-width: 324px;
}
.hotel_description .hotel_flex-fluid.hotel_flex-awards .hotel_award_wrap strong {
  margin-bottom: 10px;
  display: block;
}
.hotel_description .hotel_description_foot {
  background: #C4CAFD;
  padding: 14px 20px;
  display: flex;
  justify-content: center;
}
.show_inf {
  color: #645EFC;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.show_inf .show_inf_span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.show_inf .show_inf_span span {
  opacity: 1;
  max-height: 20px;
  transition: 0.3s all;
}
.show_inf .show_inf_span span + span {
  opacity: 0;
  max-height: 0;
}
.show_inf svg {
  margin-left: 10px;
  display: block;
  transition: 0.3s all;
}
.show_inf.active .show_inf_span span {
  opacity: 0;
  max-height: 0;
}
.show_inf.active .show_inf_span span + span {
  opacity: 1;
  max-height: 20px;
}
.show_inf.active svg {
  transform: rotate(180deg);
}
.hotel_filtr {
  background: #E3E5F6;
  padding: 30px 20px;
  border-radius: 20px;
}
.hotel_filtr .hotel_filtr_title {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}
.hotel_filtr .hotel_filtr_title span {
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-left: 20px;
}
.hotel_filtr .form_label {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  margin-bottom: 6px;
  display: block;
}
.hotel_filtr .fake_select {
  background: #FFFFFF;
  color: rgba(35, 35, 43, 0.6);
  justify-content: space-between;
  border-color: rgba(90, 90, 90, 0.3);
  border-radius: 14px;
  padding: 13px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.hotel_filtr .fake_select svg {
  color: #5A5A5A;
}
.hotel_filtr + .hotel_tarifs {
  margin-top: 10px;
}
.hotel_description_info {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  margin: 0 -10px;
}
.hotel_description_info > div {
  width: 50%;
  padding: 0 10px;
}
.hotel_fact {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
}
.hotel_fact > div {
  padding: 0 10px;
  min-width: 25%;
}
.hotel_fact .hotel_fact_title {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
}
.hotel_fact .hotel_fact_name {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.hotel_fact .hotel_fact_year {
  color: #5A5A5A;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  margin-top: 6px;
}
.hotel_fact .hotel_fact_num {
  font-size: 15px;
  font-weight: 800;
}
.hotel_fact + .hotel_fact {
  justify-content: flex-start;
}
.hotel_list_udobstv {
  padding: 20px;
  columns: 4;
}
.hotel_list_udobstv .hotel_list_item {
  break-inside: avoid-column;
}
.hotel_list_udobstv .hotel_list_item + .hotel_list_item {
  margin-top: 30px;
}
.hotel_list_udobstv .hotel_list_item .hotel_list_title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
}
.hotel_list_udobstv .hotel_list_item .hotel_list_title .hotel_list_img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  background: #757478;
  padding: 6px;
  width: 36px;
  min-width: 36px;
  height: 36px;
  color: #FFFFFF;
  margin-right: 10px;
}
.hotel_list_udobstv .hotel_list_item ul {
  padding-left: 11px;
  margin: 10px 0 0;
}
.hotel_list_udobstv .hotel_list_item ul li {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}
.hotel_list_udobstv .hotel_list_item ul li span {
  color: #5A5A5A;
  background: rgba(90, 90, 90, 0.1);
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 2px 10px;
  border-radius: 30px;
  display: inline-flex;
  margin-right: 50px;
}
.hotel_list_udobstv .hotel_list_item ul li + li {
  margin-top: 4.5px;
}
.hotel_privacy {
  font-size: 13px;
  padding: 20px;
}
.hotel_privacy strong {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  margin-bottom: 10px;
  display: block;
}
.hotel_privacy ul {
  margin: 0;
  padding: 0 0 0 14px;
}
.hotel_privacy ul li + li {
  margin-top: 4.5px;
}
.hotel_privacy .row + .row {
  margin-top: 10px;
}
.hotel_range .hotel_range_flex {
  display: flex;
}
.hotel_range .hotel_range_flex + .hotel_range_flex {
  margin-top: 15px;
}
.hotel_range .hotel_range_flex .hotel_range_info {
  width: 120px;
  min-width: 120px;
}
.hotel_range .hotel_range_flex .hotel_range_time {
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  color: #757478;
}
.hotel_range .hotel_range_flex .hotel_range_title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #757478;
  margin-bottom: 4px;
}
.hotel_range .hotel_range_flex .range-wrap {
  margin-left: 30px;
  width: 222px;
}
.hotel_range .range .range-handle {
  display: none;
}
.hotel_range .range .range-bar {
  background: #6ACBAE;
  height: 8px;
  border-radius: 14px;
  top: 32px;
}
.hotel_range .range .range-body .range-line {
  height: 8px;
  border-radius: 14px;
  top: 32px;
}
.hotel_range .range .range-body .range-from-text,
.hotel_range .range .range-body .range-to-text {
  top: 44px;
  color: #757478;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  padding: 0;
  background: transparent;
}
.hotel_span span {
  color: #5A5A5A;
  background: rgba(90, 90, 90, 0.1);
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 2px 10px;
  border-radius: 30px;
  display: inline-flex;
  margin-right: 250px;
  margin-bottom: 7px;
  white-space: nowrap;
}
.hotel_tarifs {
  border-color: #E3E5F6;
}
.hotel_tarifs .hotel_description_head {
  display: flex;
  align-items: center;
  padding: 0;
  background: #E3E5F6;
}
.hotel_tarifs .hotel_description_head .hotel_tarifs_img {
  width: 86px;
  min-width: 86px;
  margin-right: 20px;
}
.hotel_tarifs .hotel_description_head .hotel_tarifs_img a {
  display: block;
  position: relative;
}
.hotel_tarifs .hotel_description_head .hotel_tarifs_img a img {
  border-radius: 20px;
  display: block;
  margin-bottom: 2px;
}
.hotel_tarifs .hotel_description_head .hotel_tarifs_img a span {
  position: absolute;
  top: 3px;
  right: 3px;
  padding: 0 4px;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: var(--8px, 8px);
  background: rgba(255, 255, 255, 0.8);
  color: #5A5A5A;
  display: inline-block;
}
.hotel_tarifs .hotel_description_foot {
  background: #E3E5F6;
}
.head_tarifs .head_tarifs_wrap {
  display: flex;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  margin: 0 -10px 10px;
}
.head_tarifs .head_tarifs_wrap > div {
  padding: 0 10px;
}
.head_tarifs .head_tarifs_wrap .head_tarifs_title {
  width: 540px;
}
.head_tarifs .head_tarifs_wrap .head_tarifs_predl {
  width: 191px;
}
.head_tarifs .head_tarifs_wrap .head_tarifs_price {
  width: 216px;
}
.head_tarifs .head_tarifs_dop {
  display: flex;
  margin: 0 -5px;
}
.head_tarifs .head_tarifs_dop > div {
  padding: 0 4px;
  margin: 0 5px;
  background: rgba(71, 183, 149, 0.1);
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5A5A5A;
  border-radius: 8px;
}
.head_tarifs .head_tarifs_dop > div svg {
  margin-right: 2px;
}
.tarifs_body_item {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin: 0 -10px;
}
.tarifs_body_item + .tarifs_body_item {
  border-top: 2px solid #E3E5F6;
}
.tarifs_body_item:nth-child(n+3) {
  display: none;
}
.tarifs_body_item .tarifs_body_list {
  padding: 20px 10px;
}
.tarifs_body_item .tarifs_body_price {
  width: 32%;
  border-left: 2px solid #E3E5F6;
  display: flex;
  align-items: center;
}
.tarifs_body_item .tarifs_body_price .extr_buttons {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
}
.tarifs_body_item .tarifs_body_price .extr_buttons .price_flex_bold {
  font-size: 18px;
  margin-top: 0;
  line-height: 24px;
  margin-bottom: 9px;
}
.tarifs_body_item .tarifs_body_price .extr_buttons .price_flex_bold span,
.tarifs_body_item .tarifs_body_price .extr_buttons .price_flex_bold i {
  width: auto;
}
.tarifs_body_item .tarifs_body_price .extr_buttons .hotel_srok {
  display: block;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: rgba(35, 35, 43, 0.6);
}
.tarifs_body_item .tarifs_body_price .extr_buttons .btn {
  width: 50%;
  text-align: center;
}
.tarifs_body_list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tarifs_body_list li {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.tarifs_body_list li svg {
  margin-right: 10px;
}
.tarifs_body_list li + li {
  margin-top: 4px;
}
@media (max-width: 1439px) {
  .main_with_side .main_side {
    width: 271px;
    min-width: 271px;
  }
  .main_with_side .main_cont {
    width: calc(100% - 291px);
  }
  .main_with_side .hotel_price_block,
  .main_with_side .hotel_icon {
    min-width: 240px;
  }
  .main_with_side.compact .main_cont {
    left: 271px;
  }
  .show_filtri {
    left: 556px;
  }
  .hotel_detail .hotel_detail_udobs .tarifs_body_list {
    columns: 2;
  }
}
@media (max-width: 1299px) {
  .head_tarifs {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 8px;
    margin-bottom: -7px;
    white-space: nowrap;
  }
}
@media (max-width: 1199px) {
  .hotel_item .hotel_info {
    padding: 20px 20px 6px 240px;
    min-height: 280px;
  }
  .hotel_item .hotel_img {
    width: 220px;
    height: 280px;
  }
  .main_with_side .main_side {
    width: 241px;
    min-width: 241px;
  }
  .main_with_side .main_cont {
    width: calc(100% - 261px);
  }
  .main_with_side .hotel_price_block {
    min-width: 200px;
  }
  .main_with_side .hotel_nomer_info > span {
    width: calc(50% - 4px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .main_with_side.compact .main_cont {
    left: 241px;
  }
  .show_filtri {
    left: 526px;
  }
  .compact {
    margin-right: -14px;
  }
  .pinned_wrap.pinned_wrap_hotel .pinned_line {
    width: calc(100% - 40px);
  }
  .pinned_wrap.pinned_wrap_hotel .extr_buttons {
    width: calc(56% - 40px);
    padding-bottom: 7px;
  }
  .pinned_wrap.pinned_wrap_hotel .extr_buttons .extr_bron {
    width: 180px;
  }
  .pinned_wrap.pinned_wrap_hotel .pinned_price {
    display: block;
    margin-right: 20px;
  }
  .pinned_line .hotel_item {
    padding: 7px 8px;
  }
  .pinned_line .hotel_item .hotel_adres_title {
    display: inline-block;
  }
  .pinned_line .hotel_item .hotel_img {
    width: 120px;
    height: 120px;
    position: relative;
    order: -1;
    margin-right: 20px;
  }
  .pinned_line .hotel_item .hotel_info {
    height: auto;
    flex-wrap: wrap;
    padding: 0;
  }
  .pinned_line .hotel_item .hotel_info .hotel_info_head {
    width: 100%;
  }
  .pinned_line .hotel_item .hotel_info .hotel_info_head .hotel_info_shap {
    width: calc(100% - 140px);
  }
  .pinned_line .hotel_item .hotel_info .hotel_info_room {
    width: 100%;
    border-top: 1px solid rgba(100, 94, 252, 0.3);
    margin-top: 7px;
    padding-top: 17px;
    padding-bottom: 1px;
  }
  .pinned_line .hotel_item .hotel_info .hotel_info_room .hotel_nomer .hotel_nomer_title {
    font-size: 13px;
  }
  .pinned_line .hotel_item .hotel_info .hotel_info_room .hotel_nomer .hotel_nomer_info > span {
    width: auto;
  }
  .pinned_wrap_hotel .price_flex span,
  .pinned_wrap_hotel .price_flex i {
    width: auto;
  }
  .hotel_info_right .hotel_reit {
    margin-right: 0;
  }
  .slider .slider_wrap {
    height: 100%;
  }
  .slider .slider_wrap .slider_outer .slider_item img {
    width: 100vw;
  }
  .slide_row .slid_wrap {
    width: 100%;
  }
  .slide_row .slid_wrap .slider {
    height: 100%;
  }
  .slide_row .slid_col {
    display: none;
  }
  .hotel_wrap .search_hotels .fields .fields_subm {
    padding-right: 64px !important;
  }
  .hotel_wrap .search_hotels .fields .to_result {
    width: 64px;
  }
  .hotel_wrap .search_hotels .fields .to_result span {
    display: none;
  }
  .hotel_wrap .search_hotels .fields .to_result svg {
    display: block;
  }
}
@media (max-width: 991px) {
  .main_with_side .main_side {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .main_with_side .main_side.active {
    display: block;
  }
  .main_with_side .main_cont {
    width: 100%;
    margin-left: 0;
  }
  .main_with_side.compact {
    margin-left: -14px;
  }
  .main_with_side.compact .main_cont {
    display: none;
  }
  .main_with_side.compact .show_filtri {
    display: none;
  }
  .hotel_wrap .search_hotels .search_widget_form {
    padding: 20px;
    background: #E3E5F6;
    border-radius: 20px;
  }
  .hotel_wrap .search_hotels .fields .to_result {
    width: 64px;
  }
  .hotel_wrap .search_hotels .fields .to_result span {
    width: 0;
  }
  .hotel_wrap .search_hotels .fields .to_result svg {
    display: block;
  }
  .search_hotels_modal {
    display: none;
  }
  .search_hotels_modal .modal_head {
    display: flex;
  }
  .search_hotels_phone {
    display: flex;
    align-items: center;
    background: #E3E5F6;
    padding: 12px 20px;
    margin: 0 -14px 4px;
  }
  .search_hotels_phone .search_hotels_data {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 93px);
    background: #FFFFFF;
    border-radius: 8px;
    padding: 11px 20px;
  }
  .search_hotels_phone .search_hotels_data .search_hotels_data_title {
    color: #645EFC;
  }
  .search_hotels_phone .search_hotels_data .search_hotels_data_date {
    font-weight: 500;
  }
  .search_hotels_phone .search_hotels_btn {
    display: flex;
    margin-left: 11px;
  }
  .search_hotels_phone .search_hotels_btn a {
    display: block;
    margin-left: 9px;
    width: 32px;
    height: 32px;
    color: #404040;
  }
  .hotel_item .hotel_info {
    height: 300px;
    padding: 20px 20px 6px 285px;
  }
  .hotel_item .hotel_info .hotel_info_head .hotel_title_block .hotel_title {
    font-size: 16px;
    min-width: 220px;
  }
  .hotel_item .hotel_info .hotel_adres_block {
    flex-wrap: wrap;
  }
  .hotel_item .hotel_info .hotel_adres_block .hotel_adres_title {
    font-size: 12px;
  }
  .hotel_item .hotel_info .hotel_adres_block .hotel_icon {
    justify-content: flex-start !important;
    width: 100%;
    margin: 12px 0 0 -10px;
  }
  .hotel_item .hotel_info .hotel_info_room {
    padding: 11px 0 7px;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_nomer .hotel_nomer_title {
    min-height: 0;
    font-size: 12px;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_price_block {
    min-width: 140px;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_price {
    font-size: 10px;
    margin-top: 0;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_price .price_flex {
    font-size: 16px;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_nomer_info > span {
    width: calc(50% - 4px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .hotel_item .hotel_img {
    width: 265px;
    height: 300px;
  }
  .hotel_item .hotel_img .img_favorite {
    right: 15px;
  }
  .hotel_item + .hotel_item {
    margin-top: 4px;
  }
  .wrapper_hotel .col4 {
    width: 50%;
  }
  .wrapper_hotel .colmd8 {
    width: 66.6666%;
  }
  .user_group_zaezd .col8 {
    width: 100%;
  }
  .user_group_pay .col8 {
    width: 50%;
  }
  .hotel_detail {
    flex-direction: row;
    height: auto;
    padding-top: 0px;
  }
  .hotel_detail > div {
    width: 100%;
    margin-right: 0;
  }
  .hotel_detail .hotel_detail_img {
    margin: 0;
    position: relative;
    right: 0;
  }
  .hotel_detail .hotel_detail_udobs {
    margin-bottom: 30px;
  }
  .hotel_detail .hotel_detail_udobs .udobs_title {
    margin-bottom: 15px;
  }
  .hotel_detail .hotel_detail_udobs .tarifs_body_list {
    columns: 3;
  }
  .detail_slider {
    margin: 24px -5px;
  }
  .detail_slider .slider_wrap .slider_outer .slider_item img {
    height: 137px;
    width: 137px;
    min-width: 137px;
  }
  .detail_slider .slider_wrap .slider_outer .slider_item.slider_item_big img {
    height: 275px;
    width: 275px;
    min-width: 275px;
  }
  .detail_slider .slider_wrap .slider_outer:after {
    right: -9px;
  }
  .hotel_filtr .col3 {
    width: 50%;
  }
  .tarifs_body_item {
    flex-wrap: wrap;
    padding: 10px 20px;
  }
  .tarifs_body_item .tarifs_body_list {
    width: 50%;
    padding: 10px;
  }
  .tarifs_body_item .tarifs_body_price {
    width: 50%;
    border-left: 0;
    align-items: flex-start;
  }
  .tarifs_body_item .tarifs_body_price .extr_buttons {
    padding: 10px !important;
    justify-content: flex-start;
  }
  .tarifs_body_item .tarifs_body_price .extr_buttons .pinned_price {
    width: 50%;
    margin-right: 10px;
  }
  .tarifs_body_item .tarifs_body_price .extr_buttons .btn {
    width: auto;
  }
  .tarifs_body_item:nth-child(n+2) {
    display: none;
  }
  .hotel_description .hotel_flex-fluid {
    flex-wrap: wrap;
  }
  .hotel_description .hotel_flex-fluid .hotel_desc {
    width: 100%;
  }
  .hotel_description .hotel_flex-fluid.hotel_flex-awards .hotel_award_wrap {
    max-width: 100%;
    width: 100%;
    min-width: 0;
    margin-top: 20px;
    margin-bottom: -10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .hotel_description .hotel_flex-fluid.hotel_flex-awards .hotel_award_wrap strong {
    width: 100%;
  }
  .hotel_award {
    width: calc(50% - 10px);
  }
  .hotel_list_udobstv {
    columns: 2;
  }
  .hotel_privacy .col5,
  .hotel_privacy .col3 {
    width: 50%;
  }
  .hotel_privacy .col4,
  .hotel_privacy .col8 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .hotel_item .hotel_info {
    padding: 20px;
    height: auto;
  }
  .hotel_item .hotel_info .hotel_title {
    display: flex;
    flex-wrap: wrap;
  }
  .hotel_item .hotel_info .stars {
    order: -1;
    margin-top: -4px;
    margin-left: 0;
    margin-right: calc(100% - 100px);
  }
  .hotel_item .hotel_info .hotel_info_room {
    flex-wrap: wrap;
    padding: 9px 20px 0;
    margin: 0 -20px;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_nomer {
    max-width: none;
    width: 100%;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_nomer .hotel_nomer_info > span {
    width: auto;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_price_block {
    width: 53%;
    border-right: 1px solid #E3E5F6;
    margin-top: 10px;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_price_block .hotel_price {
    text-align: left;
    display: flex;
    align-items: center;
    margin: 0;
    height: 40px;
    line-height: 1;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_price_block .price_flex {
    justify-content: flex-start;
    margin: 0 10px 0 0;
    order: -1;
    white-space: nowrap;
  }
  .hotel_item .hotel_info .hotel_info_footer {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0;
    width: 47%;
    position: absolute;
    right: 20px;
    bottom: 24px;
  }
  .hotel_item .hotel_img {
    width: 165px;
    height: 165px;
    position: relative;
    order: -1;
    min-width: 165px;
    margin: -20px 20px 10px -20px;
  }
  .hotel_item .hotel_img .img_favorite {
    right: 10px;
    top: 10px;
  }
  .search_hotels_phone {
    margin: 0 -20px 4px;
  }
  .main_with_side.compact {
    margin-left: -20px;
    margin-right: -20px;
  }
  .pinned_wrap.pinned_wrap_hotel .extr_buttons {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 2px;
  }
  .pinned_wrap.pinned_wrap_hotel .extr_buttons .extr_bron {
    width: 100%;
    order: 1;
    margin-top: 11px;
  }
  .pinned_wrap.pinned_wrap_hotel .extr_buttons .hotel_srok {
    display: block;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
  }
  .pinned_wrap.pinned_wrap_hotel .pinned_line {
    width: 100%;
  }
  .pinned_wrap.pinned_wrap_hotel .pinned_price .price_flex_bold {
    margin: 7px 0 0;
  }
  .pinned_line .hotel_item .hotel_img {
    width: 100%;
    height: 140px;
    margin: 0 0 12px;
  }
  .pinned_line .hotel_item .hotel_info .hotel_info_head .hotel_info_shap {
    width: 100%;
  }
  .pinned_line .hotel_item .hotel_info .hotel_info_head .hotel_title_block .hotel_title {
    width: 100%;
  }
  .pinned_line .hotel_item .hotel_info .hotel_info_head .hotel_adres_block {
    padding: 8px 0;
  }
  .pinned_line .hotel_item .hotel_info .hotel_info_head .hotel_adres_block .hotel_adres_place > span {
    font-size: 10px;
    line-height: 12px;
  }
  .pinned_line .hotel_item .hotel_info .hotel_info_room {
    margin: 3px 0 0;
    padding: 10px 0 0;
  }
  .pinned_line .hotel_item .hotel_info .hotel_info_room .hotel_nomer .hotel_nomer_title {
    font-size: 12px;
  }
  .wrapper_hotel .hotel_item .hotel_info .hotel_info_head .hotel_info_shap {
    width: calc(100% - 165px);
  }
  .wrapper_hotel .hotel_item .hotel_info .hotel_info_head .hotel_title_block .hotel_title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .wrapper_hotel .hotel_info_right .hotel_reit {
    margin-right: 20px;
  }
  .wrapper_hotel .col4 {
    width: 100%;
  }
  .user_group_pay .col8 {
    width: 100%;
  }
  .user_footer_hotel .row .col4.col4_btn {
    width: 50%;
  }
  .hotel_detail_second .hotel_name .hotel_detail_name {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .hotel_detail_second .hotel_adres {
    font-size: 12px;
  }
  .hotel_detail_second .hotel_price_block .price_flex_bold {
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 0;
  }
  .hotel_detail_second .hotel_price_block .btn {
    margin-top: 3px;
  }
  .hotel_detail_first {
    margin-bottom: 10px;
  }
  .detail_slider {
    margin: 15px -20px 15px -5px;
  }
  .detail_slider .slider_outer:after {
    display: none;
  }
  .hotel_detail .hotel_detail_udobs {
    margin-bottom: 10px;
  }
  .hotel_detail .hotel_detail_udobs .tarifs_body_list {
    columns: 2;
  }
  .tarifs_body_item {
    padding: 15px 20px;
  }
  .tarifs_body_item .tarifs_body_list {
    padding: 5px 10px;
  }
  .tarifs_body_item .tarifs_body_price {
    width: 100%;
    margin-top: 5px;
    margin-bottom: -5px;
  }
  .tarifs_body_item .tarifs_body_price .extr_buttons .btn {
    width: 50%;
  }
  .hotel_privacy .col5 {
    width: 100%;
  }
  .hotel_privacy .col3,
  .hotel_privacy .col4 {
    width: 50%;
  }
  .hotel_range .hotel_range_flex .hotel_range_info {
    width: 145px;
    min-width: 145px;
  }
  .hotel_range .hotel_range_flex .range-wrap {
    margin-left: 30px;
    width: 100%;
  }
  .slider .slider_wrap .slider_nav {
    right: 50%;
    margin-right: -42px;
  }
  .modal_full .slider_dots {
    display: none;
  }
  .modal_full .slider .slider_wrap .slider_nav {
    bottom: -50px;
  }
}
@media (max-width: 575px) {
  .search_hotels_phone {
    padding: 8px 20px;
  }
  .search_hotels_phone .search_hotels_data {
    display: block;
    padding: 4px 12px;
  }
  .search_hotels_phone .search_hotels_data .search_hotels_data_title {
    line-height: 20px;
  }
  .search_hotels_phone .search_hotels_data .search_hotels_data_date {
    font-size: 10px;
    line-height: 12px;
  }
  .hotel_item .hotel_info {
    padding: 0 14px 14px;
  }
  .hotel_item .hotel_info .hotel_info_head {
    flex-wrap: wrap;
  }
  .hotel_item .hotel_info .stars {
    margin-top: 0;
  }
  .hotel_item .hotel_info .hotel_info_room {
    padding: 9px 14px 0;
    margin: 0 -14px;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_nomer .hotel_nomer_info > span {
    width: calc(50% - 4px);
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_price_block {
    width: 100%;
    border: 0;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_price_block .hotel_price {
    display: block;
    margin: 0;
    height: auto;
  }
  .hotel_item .hotel_info .hotel_info_room .hotel_price_block .price_flex {
    margin: 7px 0 10px;
  }
  .hotel_item .hotel_info .hotel_info_footer {
    width: 100%;
    position: relative;
    right: 0;
    bottom: 0;
  }
  .hotel_item .hotel_info .hotel_info_footer a {
    width: 100%;
    text-align: center;
    display: block;
  }
  .hotel_item .hotel_img {
    width: calc(100% + 28px);
    margin: 0 -14px 10px;
    height: 140px;
  }
  .hotel_item .hotel_info_right .hotel_reit {
    margin-right: 0;
  }
  .hotel_item .extr_fix {
    position: absolute;
    bottom: 60px;
    right: 14px;
  }
  .main_with_side .main_side.main_side_wide .group_filtr {
    columns: auto;
  }
  .wrapper_hotel .hotel_item .hotel_info .hotel_info_head .hotel_info_shap {
    width: 100%;
  }
  .user_footer.user_footer_hotel .btn {
    margin-top: 0;
  }
  .user_footer_hotel .row .col4.col4_btn {
    width: 100%;
  }
  .hotel_detail_second {
    flex-wrap: wrap;
  }
  .hotel_detail_second .hotel_name .hotel_detail_name {
    margin-bottom: 7px;
  }
  .hotel_detail_second .hotel_price_block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  .hotel_detail_second .hotel_price_block .price_flex_bold {
    margin-top: 0;
  }
  .hotel_detail_second .hotel_price_block .btn {
    margin-top: 0;
    padding: 6px 18px;
  }
  .hotel_detail .hotel_detail_udobs {
    margin-bottom: 15px;
  }
  .hotel_detail .hotel_detail_udobs .tarifs_body_list {
    columns: 1;
  }
  .hotel_detail .hotel_detail_foot {
    flex-wrap: wrap;
    padding: 15px 5px;
    font-size: 13px;
  }
  .hotel_detail .hotel_detail_foot span {
    font-size: 14px;
    line-height: 20px;
  }
  .hotel_detail .hotel_detail_foot div {
    width: 50%;
  }
  .hotel_detail .hotel_detail_foot .hotel_detail_edit {
    width: 100%;
    margin-top: 15px;
  }
  .hotel_detail .hotel_detail_foot .hotel_detail_edit .btn {
    width: 100%;
    text-align: center;
  }
  .hotel_filtr {
    padding: 20px 15px;
  }
  .hotel_filtr .hotel_filtr_title {
    font-size: 14px;
    line-height: 20px;
  }
  .hotel_filtr .col3 {
    width: 100%;
    padding: 7px 10px;
  }
  .hotel_filtr .form_label {
    font-size: 13px;
    line-height: 18px;
  }
  .head_tarifs {
    overflow: hidden;
    padding-bottom: 8px;
    margin-bottom: 0;
    white-space: normal;
  }
  .head_tarifs .head_tarifs_wrap {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    flex-wrap: wrap;
  }
  .head_tarifs .head_tarifs_wrap .head_tarifs_title {
    width: auto;
    margin-left: 97px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    height: 88px;
    font-size: 16px;
    line-height: 24px;
  }
  .head_tarifs .head_tarifs_wrap .head_tarifs_predl,
  .head_tarifs .head_tarifs_wrap .head_tarifs_price {
    width: 50%;
  }
  .head_tarifs .head_tarifs_dop {
    margin: 10px 5px -7px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 8px;
  }
  .hotel_tarifs .hotel_description_head {
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 5px;
  }
  .hotel_tarifs .hotel_description_head .hotel_tarifs_img {
    margin-right: 0;
    position: absolute;
    top: 1px;
    left: 1px;
  }
  .tarifs_body_item {
    padding: 11px 7px;
    margin: 0;
  }
  .tarifs_body_item .tarifs_body_list {
    padding: 5px 5px;
    width: 100%;
  }
  .tarifs_body_item .tarifs_body_price .extr_buttons {
    padding: 10px 5px !important;
  }
  .hotel_description .hotel_description_head {
    padding: 14px;
  }
  .hotel_description .hotel_flex-fluid .hotel_desc {
    font-size: 12px;
  }
  .hotel_description .hotel_flex-fluid .hotel_desc strong {
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
  }
  .hotel_description .hotel_flex-fluid.hotel_flex-awards .hotel_desc > * {
    max-width: 100%;
  }
  .hotel_award {
    width: 100%;
  }
  .hotel_list_udobstv {
    padding: 20px 15px;
    columns: 1;
  }
  .hotel_list_udobstv .hotel_list_item ul li {
    font-size: 12px;
  }
  .hotel_list_udobstv .hotel_list_item ul li + li {
    margin-top: 1.5px;
  }
  .hotel_privacy {
    font-size: 12px;
    padding: 20px 13px;
  }
  .hotel_privacy .col3,
  .hotel_privacy .col4 {
    width: 100%;
  }
  .hotel_range .hotel_range_flex .hotel_range_title {
    font-size: 12px;
    line-height: 18px;
  }
  .hotel_range .hotel_range_flex .hotel_range_info {
    width: 120px;
    min-width: 120px;
  }
  .hotel_range .hotel_range_flex .hotel_range_time {
    font-size: 16px;
  }
  .hotel_range .range .range-body .range-from-text,
  .hotel_range .range .range-body .range-to-text {
    letter-spacing: 0;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
body.body_main {
  background: #F7F8FA;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  color: #23232B;
}
body.body_main h3 {
  color: #23232B;
}
body.body_main .main_wrap {
  padding-top: 0;
  padding-bottom: 0;
}
.first_ekran {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1315px;
  z-index: -1;
  background: linear-gradient(180deg, #645EFC 0%, #7378FC 63.67%, rgba(115, 120, 252, 0) 96.5%);
}
.main_land {
  overflow: hidden;
  padding: 10px 0;
}
.main_wrap {
  width: 100%;
  max-width: 1358px;
  padding: 10px 14px;
  margin: 0 auto;
  position: relative;
}
.main_nav {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 0 -20px;
}
.main_nav li {
  padding: 0 10px;
}
.main_nav li a {
  display: block;
  padding: 3px 10px;
}
.header_currency_land.header_currency {
  margin-right: 0;
}
.sect {
  margin-top: 140px;
}
.main_h1 {
  font-size: 48px;
  font-weight: 800;
  line-height: 58px;
  text-align: center;
  max-width: 1020px;
  color: #fff;
  margin: 117px auto 0 auto;
}
.main_h1 span {
  display: inline-block;
  border: 2px solid;
  border-radius: 100px;
  padding: 0 28px 6px;
  margin-top: 13px;
}
.main_h1 span.orange_h1 {
  color: #FDBF9B;
}
.main_h1 span.mint_h1 {
  color: #96DFC9;
}
.main_bt {
  text-align: center;
  margin-top: 80px;
}
.main_bt .btn-white {
  color: #645EFC;
}
.main_bt .btn-white:hover,
.main_bt .btn-white.active,
.main_bt .btn-white:focus {
  background: #7378FC;
  color: #FFF;
}
.main_grbox {
  display: flex;
  margin: 80px -10px 0;
}
.main_grbox .grbox {
  padding: 0 10px;
  width: 29%;
}
.main_grbox .grbox > div {
  padding: 32px;
  background: #E3E5F6;
  border-radius: 20px;
  height: 100%;
}
.main_grbox .grbox h3 {
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  margin: 0 0 30px;
}
.main_grbox .grbox_img {
  width: 42%;
  padding: 0 10px;
}
.main_grbox .grbox_img img {
  display: block;
  max-width: none;
  border-radius: 20px;
}
ul.list_main {
  padding: 0;
  margin: 0;
}
ul.list_main li {
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;
}
ul.list_main li + li {
  margin-top: 14px;
}
ul.list_main li:before {
  content: "";
  width: 9px;
  min-width: 9px;
  height: 9px;
  border-radius: 100px;
  margin-right: 21px;
  background: #645EFC;
}
.main_list {
  margin-top: 168px;
}
h2.mainh2 {
  max-width: 880px;
  text-align: center;
  margin: 0 auto 80px;
  font-size: 48px;
  font-weight: 800;
  line-height: 58px;
}
.main_list_wrap {
  display: flex;
  margin: 0 -10px;
}
.main_list_wrap .listbox {
  width: calc(25% - 20px);
  padding: 36px 13px 0;
}
.main_list_wrap .listbox h3 {
  height: 108px;
  margin: 0 0 24px;
  padding: 0 6px;
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  display: flex;
}
.main_list_wrap .listbox h3 span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 16px;
  background: #B4EDDC;
  border-radius: 14px;
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  color: #3D9076;
}
.main_list_wrap .listbox h3 span img {
  margin: 0 !important;
}
.main_list_wrap .listbox img {
  display: block;
  margin: 26px auto 0;
}
.listbox {
  background: rgba(100, 94, 252, 0.05);
  border-radius: 20px;
  margin: 0 10px;
  font-size: 16px;
  line-height: 24px;
}
.tabs-content > div {
  display: none;
  background: url(./img/land/clients_fon.png) no-repeat center right;
}
.tabs-content > div img {
  display: block;
  margin: 0 auto;
}
.client_tabs .tabs-but {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 12px;
  border-radius: 30px;
  background: #FFFFFF;
  margin: -20px 0 60px;
}
.client_tabs .tabs-but a {
  font-size: 18px;
  line-height: 24px;
  padding: 8px 24px;
  color: #5A5A5A;
  margin: 0 2px;
  border-radius: 20px;
}
.client_tabs .tabs-but a.active {
  border-radius: 20px;
}
.client_tabs h3 {
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  margin: 0 0 32px;
  max-width: 374px;
}
.client_tabs ul.list_main li + li {
  margin-top: 23px;
  padding-top: 23px;
  border-top: 1px solid rgba(183, 151, 251, 0.1);
}
.client_tabs .tabs-content > div {
  padding: 73px 53px 0 113px;
  border: 1px solid #E3E5F6;
  border-radius: 20px;
}
.client_tabs .tabs-content .row > div .list_main {
  padding-bottom: 20px;
}
.client_tabs .tabs-content .row > div + div {
  align-self: flex-end;
}
.slide_tabs {
  padding: 78px 53px 0 53px;
  border: 1px solid #E3E5F6;
  border-radius: 20px;
}
.slide_tabs .row {
  align-items: flex-end;
  margin-bottom: 0;
}
.slide_tabs .col6 {
  padding-bottom: 0;
}
.slide_tabs .tabs-but {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -20px 0 60px;
}
.slide_tabs .tabs-but a {
  flex: 2 1 0px;
  transition: flex 0.5s ease-in-out;
  font-size: 22px;
  line-height: 28px;
  padding: 11px 0 8px 0;
  color: rgba(90, 90, 90, 0.4);
  margin: 0 7px;
  border-radius: 0;
  border-top: 2px solid #A2A2BA;
}
.slide_tabs .tabs-but a.active {
  border-radius: 0;
  color: #5A5A5A;
  background: transparent;
  border-top: 2px solid #645EFC;
  flex: 3 1 0px;
}
.slide_tabs .tabs-but a:hover {
  border-radius: 0;
  color: #5A5A5A;
  background: transparent;
  border-top: 2px solid #645EFC;
}
.slide_tabs h3 {
  font-size: 48px;
  font-weight: 600;
  line-height: 58px;
  margin: 0 0 25px;
}
.slide_tabs p {
  margin-bottom: 90px;
  font-size: 22px;
  line-height: 28px;
}
.rekl_wrap {
  padding-top: 21px;
  padding-bottom: 23px;
  overflow: hidden;
}
.rekl_wrap > div {
  background: #E3E5F6;
  border-radius: 45px;
  padding: 20px 44px;
}
.rekl_wrap .row {
  align-items: center;
}
.rekl_wrap img {
  display: block;
  margin: -41px 0 -43px;
}
.rekl_wrap img + img {
  display: none;
}
.rekl_wrap .rekl_h3 {
  font-size: 32px;
  font-weight: 800;
  line-height: 42px;
}
.rekl_wrap p {
  margin: 25px 0 30px 0;
}
.rekl_wrap .rekl_box {
  max-width: 348px;
  margin: 0 auto;
}
.rekl_wrap .btn {
  padding: 11px 39px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
  border-radius: 20px;
}
.main_otchet {
  background: #FFFFFF;
  padding-top: 60px;
  padding-bottom: 60px;
}
.main_otchet .main_list_wrap {
  background: url(./img/land/main_otchet.jpg) no-repeat left center;
  padding-left: calc(100% - 780px);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
}
.main_otchet .main_list_wrap .listbox {
  padding: 32px;
  background: #F0F0FA;
  width: 348px;
}
.main_otchet .main_list_wrap .listbox h3 {
  height: auto;
  margin: 0 0 17px;
  padding: 0;
}
.main_otchet .main_list_wrap .listbox.listbox_otch2 {
  width: 317px;
}
.main_otchet .main_list_wrap .listbox.listbox_otch3 {
  margin: 30px 94px 30px 40px;
}
.main_otchet .main_list_wrap .listbox.listbox_otch4 {
  align-self: flex-end;
}
.main_otchet .main_list_wrap .listbox.listbox_otch5 {
  width: 395px;
}
.main_otchet .btn {
  margin: 80px auto 0;
}
.main_support {
  background: #FFFFFF;
  padding: 80px 152px;
  border-radius: 20px;
}
.main_support .row {
  align-items: center;
}
.main_support .col6 + .col6 {
  padding-left: 58px;
}
.main_support h2 {
  text-align: left;
  margin: 0 0 32px;
  font-size: 56px;
  line-height: 56px;
}
.main_support h2 span {
  color: #645EFC;
}
.main_reviews > img {
  display: block;
  margin: 0 auto 4px;
}
.main_reviews .main_list_wrap .listbox {
  width: calc(33.3333% - 20px);
  padding: 32px 24px 32px;
}
.main_reviews .main_list_wrap .listbox h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  margin: 0 0 6px;
}
.main_reviews .main_list_wrap .listbox span {
  text-align: center;
  display: block;
}
.main_reviews .main_list_wrap .listbox span:after {
  content: "";
  margin: 18px auto;
  width: 32px;
  height: 32px;
  display: block;
  background: url(./img/land/ico/quote.svg) no-repeat 0 0;
}
.main_reviews .main_list_wrap .listbox img {
  display: block;
  border-radius: 100px;
  margin: 0 auto 18px;
  width: 120px;
  height: 120px;
  object-fit: cover;
}
.main_reviews .main_list_wrap .listbox p {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.partners_wrap h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 65px;
  padding-left: 65px;
}
.partners_wrap h2 img {
  display: block;
  margin-left: 24px;
}
.partners {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -12px -12px;
}
.partners img {
  display: block;
  margin: 12px;
  filter: grayscale(1);
}
.main_form_wrap {
  border-radius: 45px;
  background: url(./img/land/main_form.jpg) no-repeat center center / cover;
  padding: 69px 20px 92px;
  color: #FFFFFF;
}
.main_form_wrap h2 {
  color: #FFFFFF;
  margin-bottom: 36px;
}
.main_form_wrap p {
  text-align: center;
  margin: 0 0 65px;
}
.main_form {
  display: flex;
  max-width: 1040px;
  margin: 0 auto;
}
.main_form .form_group {
  padding: 10px;
  width: 191px;
}
.main_form .form_group.form_group_subm {
  width: 255px;
}
.main_form .input_field {
  background: #FFFFFF;
  color: #23232B;
}
.main_form .submit_field {
  background: #503E85;
  color: #FFFFFF;
  border: 0;
  padding: 14px 16px;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 20px;
  width: 100%;
}
.footer {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  margin: 80px 0 0;
  padding: 25px 0 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.8);
}
.footer .foot_copir {
  width: 142px;
  margin-right: 78px;
}
.footer .foot_menu {
  display: flex;
  margin: 0 -7px 0 auto;
}
.footer .foot_menu a {
  color: rgba(255, 255, 255, 0.8);
  margin: 0 7px;
}
.last_ekran {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 788px;
  z-index: -1;
  background: linear-gradient(0deg, #7378FC 0%, rgba(115, 120, 252, 0) 96.5%);
}
.main_wrap .header_fx {
  transition: 0.3s all;
  position: relative;
  z-index: 40;
}
.main_wrap .header_fx.active {
  position: fixed;
  left: 14px;
  right: 14px;
  top: 20px;
}
.menuburg {
  left: -100%;
  position: fixed;
  top: 0;
  width: 100%;
  background: #E3E5F6 url(./img/land/menuburg.png) no-repeat bottom center;
  height: 100vh;
  z-index: 30;
  overflow: auto;
  padding-top: 148px;
  padding-bottom: 70px;
}
.menuburg .main_nav {
  margin: 0;
  flex-direction: column;
}
.menuburg .main_nav li {
  padding: 0;
}
.menuburg .main_nav li a {
  color: #5A5A5A;
  padding: 12px 10px 12px 30px;
  border-left: 4px solid transparent;
}
.menuburg .main_nav li.active a {
  color: #645EFC;
  border-color: #645EFC;
  background: rgba(100, 94, 252, 0.1);
}
.menuburg .flex_group_but {
  position: absolute;
  bottom: 70px;
  width: 100%;
  text-align: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.menuburg .flex_group_but .btn {
  width: auto;
  padding: 12px 22px;
}
.menuburg .flex_group_but .btn.btn-white {
  color: #645EFC;
}
.menuburg.open {
  left: 0;
}
@media (max-width: 1199px) {
  body.body_main {
    font-size: 16px;
  }
  h2.mainh2 {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 42px;
  }
  .sect {
    margin-top: 100px;
  }
  .header_login {
    margin-top: 20px;
  }
  .header_login .header_menu {
    display: none;
  }
  .header_login .flex_group_but {
    display: none;
  }
  .header_login .header_burger {
    border-radius: 50px;
    width: 50px;
    height: 50px;
  }
  .header_currency_land.header_currency {
    margin-right: 35px;
    margin-left: auto;
  }
  .main_h1 {
    font-size: 32px;
    line-height: 42px;
    margin: 98px auto 0 auto;
  }
  .main_bt {
    margin-top: 60px;
  }
  .main_grbox {
    margin: 50px -10px 0;
    flex-wrap: wrap;
  }
  .main_grbox .grbox {
    padding: 10px;
    width: 50%;
    font-size: 18px;
  }
  .main_grbox .grbox > div {
    border-radius: 30px;
  }
  .main_grbox .grbox_img {
    width: 100%;
    padding: 10px 10px 0;
  }
  .main_grbox .grbox_img img {
    max-width: 100%;
    border-radius: 30px;
  }
  .main_list {
    margin-top: 102px;
  }
  .main_list h2.mainh2 {
    max-width: 500px;
  }
  .main_list_wrap {
    margin: -10px;
    flex-wrap: wrap;
  }
  .main_list_wrap .listbox {
    width: calc(50% - 20px);
    padding: 33px 30px 0;
    margin: 10px;
  }
  .main_list_wrap .listbox h3 {
    height: 72px;
    margin: 0 0 36px;
    padding: 0;
  }
  .main_list_wrap .listbox h3 span {
    margin-top: 5px;
  }
  .main_list_wrap .listbox img {
    margin: 39px auto 0;
  }
  .client_tabs .tabs-but {
    margin: 0 0 38px;
  }
  .client_tabs .tabs-but a {
    padding: 8px 15px;
    margin: 0;
  }
  .client_tabs ul.list_main li + li {
    margin-top: 16px;
    padding-top: 16px;
  }
  .client_tabs .tabs-content > div {
    padding: 50px 50px 0 50px;
    background-position: center bottom;
    border-radius: 30px;
  }
  .client_tabs .col6 {
    width: 100%;
  }
  .client_tabs h3 {
    max-width: none;
    margin: 0 0 36px;
  }
  .client_tabs img {
    max-width: 467px;
  }
  .rekl_wrap {
    padding-top: 0;
    padding-bottom: 0;
  }
  .rekl_wrap > div {
    border-radius: 30px;
    padding: 50px;
  }
  .rekl_wrap p {
    margin: 30px 0 30px 0;
    font-size: 18px;
    max-width: 606px;
  }
  .rekl_wrap .col6 {
    width: 100%;
  }
  .rekl_wrap .col6:first-child {
    order: 1;
  }
  .rekl_wrap .rekl_box {
    max-width: none;
    position: relative;
    z-index: 1;
  }
  .rekl_wrap img {
    margin: -91px -59px -53px auto;
    display: none;
  }
  .rekl_wrap img + img {
    display: block;
  }
  .slide_tabs {
    padding: 70px 43px 0 43px;
    border-radius: 30px;
    overflow: hidden;
  }
  .slide_tabs .col6:first-child {
    padding-right: 60px;
  }
  .slide_tabs h3 {
    font-size: 32px;
    line-height: 42px;
    margin: 0 0 20px;
    font-weight: 800;
  }
  .slide_tabs p {
    font-size: 18px;
    line-height: 24px;
  }
  .slide_tabs .tabs-but a {
    font-size: 18px;
    line-height: 24px;
    padding: 11px 0 0 0;
  }
  .slide_tabs .tabs-content > div {
    background: none;
    position: relative;
    padding: 0 6px;
  }
  .slide_tabs .tabs-content > div:after {
    content: "";
    position: absolute;
    width: 852px;
    height: 799px;
    background: url(./img/land/clients_fon.png) no-repeat 0 0;
    bottom: -100px;
    z-index: -1;
  }
  .main_otchet {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .main_otchet .main_list_wrap {
    background: none;
    flex-direction: column;
    max-height: 800px;
    justify-content: flex-start;
  }
  .main_otchet .main_list_wrap .listbox h3 {
    font-size: 22px;
    line-height: 28px;
  }
  .main_otchet .main_list_wrap .listbox.listbox_otch1 {
    width: 39%;
    order: -1;
  }
  .main_otchet .main_list_wrap .listbox.listbox_otch2 {
    width: calc(61% - 40px);
  }
  .main_otchet .main_list_wrap .listbox.listbox_otch3 {
    width: calc(61% - 40px);
    margin: 10px;
  }
  .main_otchet .main_list_wrap .listbox.listbox_otch4 {
    width: calc(61% - 40px);
  }
  .main_otchet .main_list_wrap .listbox.listbox_otch5 {
    width: 39%;
    order: -1;
  }
  .main_otchet .btn {
    margin: 40px auto 0;
  }
  .main_support {
    padding: 50px;
    border-radius: 0;
    margin-left: -20px;
    margin-right: -20px;
  }
  .main_reviews .main_list_wrap {
    flex-wrap: nowrap;
    overflow: auto;
  }
  .main_reviews .main_list_wrap .listbox {
    padding: 24px;
    min-width: 299px;
  }
  .main_reviews .main_list_wrap .listbox h2 {
    font-size: 22px;
    line-height: 28px;
  }
  .main_reviews .main_list_wrap .listbox img {
    width: 80px;
    height: 80px;
  }
  .main_reviews .main_list_wrap .listbox p {
    -webkit-line-clamp: 9;
  }
  .partners_wrap h2 {
    margin-top: -20px;
    margin-bottom: 10px;
  }
  .partners {
    margin: 0 -6px -6px;
  }
  .partners img {
    height: 49px;
    margin: 6px;
  }
  .main_form_wrap {
    border-radius: 30px;
    padding: 50px 20px 40px;
  }
  .main_form_wrap h2 {
    margin-bottom: 30px;
  }
  .main_form_wrap p {
    margin: 0 0 40px;
    font-size: 18px;
  }
  .header_niz {
    margin-top: 100px !important;
  }
  .header_niz .header_menu {
    display: none;
  }
  .header_niz .flex_group_but {
    display: flex;
  }
  .footer {
    margin: 40px 0 0;
    padding: 35px 0 50px;
    letter-spacing: -0.4px;
    font-weight: 400;
  }
  .footer .foot_copir {
    width: auto;
    margin-right: 20px;
  }
  .footer .foot_copir br {
    display: none;
  }
  .footer .foot_copir + div {
    display: none;
  }
}
@media (max-width: 991px) {
  .main_form {
    flex-wrap: wrap;
    max-width: 570px;
  }
  .main_form .form_group {
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  h2.mainh2 {
    font-size: 26px;
    line-height: 36px;
  }
  .sect {
    margin-top: 60px;
  }
  body.body_main .main_wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
  .main_wrap .header.header_login {
    padding: 10px 12px;
    border-radius: 300px;
  }
  .main_wrap .header.header_login .header_logo {
    margin-bottom: 0;
  }
  .main_wrap .header.header_login img {
    width: auto;
  }
  .main_h1 {
    font-size: 26px;
    line-height: 36px;
    margin: 60px auto 0 auto;
  }
  .main_h1 span {
    padding: 0 10px 2px;
    margin-top: 7px;
  }
  .main_bt {
    margin-top: 58px;
  }
  .main_bt .btn-white {
    padding: 15px 32px;
  }
  .main_grbox .grbox {
    width: 100%;
  }
  .main_grbox .grbox h3 {
    margin: 0 0 20px;
  }
  .main_grbox .grbox_img img {
    max-width: none;
    height: 402px;
  }
  .main_list {
    margin-top: 60px;
  }
  .main_list_wrap .listbox {
    width: calc(100% - 20px);
    padding: 40px 50px 0;
  }
  .main_list_wrap .listbox h3 {
    height: auto;
    margin: 0 0 20px;
    font-size: 22px;
    line-height: 28px;
    align-items: center;
  }
  .main_list_wrap .listbox h3 span {
    margin-top: 0;
  }
  .client_tabs {
    font-size: 18px;
  }
  .client_tabs .tabs-but {
    border-radius: 100px;
    flex-wrap: wrap;
    padding: 6px 12px;
  }
  .client_tabs .tabs-but a {
    margin: 8px 0;
  }
  .client_tabs h3 {
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 45px;
  }
  .client_tabs img {
    max-width: 434px;
  }
  .main_form_wrap {
    padding: 44px 40px 40px;
  }
  .main_form_wrap h2 {
    margin-bottom: 23px;
  }
  .main_form_wrap p {
    margin: 0 auto 30px;
    max-width: 400px;
  }
  .rekl_wrap {
    overflow: visible;
  }
  .rekl_wrap > div {
    padding: 40px 50px 50px;
  }
  .rekl_wrap .rekl_h3 {
    font-size: 26px;
    line-height: 36px;
  }
  .rekl_wrap p {
    margin: 20px 0 25px 0;
  }
  .rekl_wrap img {
    margin: 5px -59px -100px -100px;
    width: calc(100% + 200px);
    max-width: none;
  }
  .slide_wrap {
    padding-top: 20px;
  }
  .slide_tabs {
    padding: 40px 43px 0;
  }
  .slide_tabs .tabs-but {
    margin: 0 0 20px;
  }
  .slide_tabs .tabs-but a {
    font-size: 0;
    line-height: 0;
    padding: 10px 0 0 0;
  }
  .slide_tabs h3 {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 10px;
  }
  .slide_tabs p {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .slide_tabs .col6:first-child {
    padding-right: 10px;
  }
  .slide_tabs img {
    width: 324px;
    height: 354px;
    object-fit: cover;
    object-position: top center;
  }
  .main_otchet {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .main_otchet .main_list_wrap {
    flex-direction: row;
    max-height: none;
  }
  .main_otchet .main_list_wrap .listbox {
    width: 100% !important;
    padding: 28px 24px 24px;
  }
  .main_otchet .main_list_wrap .listbox h3 {
    line-height: 26px;
    margin: 0 0 20px;
  }
  .main_otchet .main_list_wrap .listbox.listbox_otch5 {
    order: inherit;
  }
  .main_otchet h2 {
    margin-bottom: 25px;
  }
  .main_otchet .flex_group_but {
    margin-top: 23px;
  }
  .main_otchet .flex_group_but .btn {
    margin: 0 auto;
    width: auto;
  }
  .main_support {
    padding: 40px 50px;
    font-size: 18px;
  }
  .main_support .col6 + .col6 {
    padding-left: 10px;
    order: -1;
  }
  .main_support h2 {
    margin-bottom: 20px;
  }
  .main_reviews > img {
    margin: -15px auto 0;
  }
  .header_niz .flex_group_but {
    margin-top: 0;
    width: auto;
  }
  .header_niz .flex_group_but .btn {
    margin: 0;
    width: 100%;
    padding: 12px 14px;
    border-radius: 100px;
  }
  .header_niz .flex_group_but .btn svg {
    display: none;
  }
  .header_niz .flex_group_but .btn svg + span {
    margin-left: 0;
  }
  .footer {
    margin: 32px 0 0;
    padding: 25px 0 50px;
  }
  .flex_group_but .btn {
    padding: 12px 32px;
  }
}
@media (max-width: 575px) {
  h2.mainh2 {
    font-size: 22px;
    line-height: 28px;
  }
  .main_wrap .header.header_login {
    display: flex;
    margin-top: 10px;
    padding: 8px 12px;
  }
  .main_wrap .header.header_login img {
    width: 124px;
  }
  .main_wrap .header.header_login .header_burger {
    width: 40px;
    height: 40px;
  }
  .main_h1 {
    font-size: 22px;
    line-height: 28px;
    margin: 40px auto 0 auto;
  }
  .main_bt {
    margin-top: 40px;
  }
  .main_bt .btn {
    border-radius: 20px;
  }
  .main_grbox {
    margin: 30px -10px 0;
  }
  .main_grbox .grbox h3 {
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 20px;
  }
  .main_grbox .grbox > div {
    border-radius: 20px;
    padding: 24px;
  }
  .main_grbox .grbox_img img {
    height: 279px;
  }
  .main_list_wrap .listbox {
    padding: 30px 24px 0;
  }
  .client_wrap {
    margin-top: 50px;
  }
  .client_tabs .tabs-but {
    flex-direction: column;
    border-radius: 20px;
  }
  .client_tabs .tabs-content > div {
    padding: 25px 20px 0 20px;
  }
  .client_tabs h3 {
    margin: 0 0 30px;
  }
  .client_tabs img {
    max-width: 100%;
  }
  .main_form_wrap {
    padding: 24px 14px 20px;
    border-radius: 20px;
  }
  .main_form_wrap h2 {
    margin-bottom: 15px;
  }
  .main_form_wrap p {
    margin: 0 auto 18px;
  }
  .rekl_wrap {
    margin-top: 40px;
  }
  .rekl_wrap > div {
    padding: 25px 25px 25px;
    border-radius: 20px;
  }
  .rekl_wrap .rekl_h3 {
    font-size: 22px;
    line-height: 28px;
  }
  .rekl_wrap img {
    margin: -27px -60px -56px -60px;
    width: calc(100% + 120px);
    display: block;
  }
  .rekl_wrap img + img {
    display: none;
  }
  .slide_tabs {
    padding: 30px 17px 0;
  }
  .slide_tabs h3 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  .slide_tabs p {
    margin-bottom: 17px;
  }
  .slide_tabs img {
    height: 257px;
    padding: 0 10px;
  }
  .main_otchet {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .main_otchet h2 {
    margin-bottom: 15px;
  }
  .main_otchet .main_list_wrap .listbox {
    padding: 24px 24px 24px;
  }
  .main_support {
    padding: 30px 20px;
  }
  .main_reviews > img {
    margin: 0 auto 4px;
    width: 40px;
  }
  .main_reviews h2 {
    margin-bottom: 30px;
  }
  .partners_wrap h2 {
    padding-left: 38px;
  }
  .partners_wrap h2 img {
    margin-left: 14px;
    width: 24px;
  }
  .partners {
    margin: 0 -6px -6px;
  }
  .partners img {
    height: 31px;
    margin: 5px;
  }
  .header_niz {
    margin-top: 60px !important;
  }
  .header_niz .flex_group_but .btn {
    padding: 4px 12px;
  }
  .footer {
    margin: 20px 0 0;
    padding: 17px 0 50px;
  }
  .footer .foot_menu {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .main_wrap .header_fx.active {
    position: fixed;
    left: 20px;
    right: 20px;
    top: 10px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 14px 0;
  height: 68px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #23232B;
}
.header a {
  color: #23232B;
}
.header a.header_burger {
  color: #645EFC;
}
.header .header_left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.header .header_left .header_logo {
  width: 224px;
  padding: 0 0 0 30px;
  position: relative;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.header .header_left .header_logo .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: 194px;
  height: 50px;
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: 0.3s all;
}
.header .header_left .header_logo .logo span {
  transition: 0.3s all;
  width: auto;
  display: block;
  overflow: hidden;
  margin-left: 5px;
}
.header .header_left .header_logo .logo img {
  display: block;
  max-width: 100%;
  position: absolute;
  left: 0;
}
.header .header_left .header_logo .logo.logo_withtxt {
  height: 40px;
  text-align: center;
  font-weight: 800;
  line-height: normal;
  font-size: 18px;
  color: #EEEEF8;
  border-radius: 10px;
  background: #645EFC;
  padding: 0 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.header .header_left .header_logo .logo.logo_withtxt img {
  position: relative;
}
.header .header_left .header_logo.active {
  /*padding-left: 16px;
        padding-right: 16px;*/
  width: 103px;
}
.header .header_left .header_logo.active .logo {
  max-width: 49px;
  border-radius: 100px;
}
.header .header_left .header_logo.active .logo img {
  max-width: 194px;
  margin-left: 9px;
}
.header .header_left .header_logo.active .logo span {
  width: 0;
  margin: 0;
}
.header .header_left .header_logo.active .side_toggle a {
  margin: 4px -15px 0 0;
}
.header .header_left .header_logo .side_toggle {
  position: absolute;
  right: -40px;
}
.header .header_left .header_logo .side_toggle a {
  display: inline-block;
  color: #70717C;
  margin: 4px 6px 0 0;
  transition: 0.3s all;
  /*&.active {
            transform: scale(-1, 1);
          }*/
}
.header .header_left .header_cent {
  margin: 0 40px 0 90px;
}
.header .header_left .header_cent span {
  display: none;
}
.header .header_left .header_tech span {
  opacity: 0.6;
  display: block;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 2px;
}
.header .header_left .header_tech a + a {
  margin-left: 37px;
}
.header .header_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.header .header_right .header_ico {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-right: 20px;
}
.header .header_right .header_ico img {
  width: 24px;
  height: 24px;
  display: block;
}
.header .header_right .header_user_mess a {
  color: #5A5A5A;
  position: relative;
  display: block;
}
.header .header_right .header_user_mess a.active:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #EE5C5C;
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid #E3E5F6;
}
.header .header_right .header_user a {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 30px;
  width: 176px;
}
.header .header_right .header_user a span {
  display: block;
  margin-right: 14px;
}
.header.header_login {
  padding: 9px 12px;
  border-radius: 30px;
  height: auto;
  background: #FFFFFF;
  margin-bottom: 12px;
}
.header.header_login .header_logo img {
  display: block;
  height: 34px;
  width: auto;
}
.header.header_login a.btn {
  color: #FFFFFF;
}
.currency_wrap {
  position: relative;
}
.currency_wrap .fake_select:focus,
.currency_wrap .fake_select:active,
.currency_wrap .fake_select.focused {
  outline: none;
  color: #5A5A5A;
  background: transparent;
  border: 1px solid transparent;
}
.header_currency {
  margin-right: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.header_currency a {
  display: flex;
  align-items: center;
  color: #23232B;
}
.header_currency a + a {
  margin-left: 20px;
}
.header_currency a svg,
.header_currency a img {
  display: block;
  margin-right: 6px;
}
.header_currency a .icon_fill {
  background: #C4CAFD;
  border-radius: 4px;
}
.header_currency a .icon_fill:hover {
  color: #FFFFFF;
  background: #7378FC;
}
.header_currency a .icon_bord {
  border: 1px solid #C4CAFD;
  border-radius: 5.5px;
  box-sizing: content-box;
}
.header_currency a .icon_bord:hover {
  border: 1px solid #7378FC;
}
.header_currency a .icon_rus {
  width: 22px;
  height: 22px;
}
.header_burger {
  display: none;
}
@media (max-width: 1199px) {
  .header .header_left .header_logo {
    width: 89px !important;
    padding: 0 20px !important;
  }
  .header .header_left .header_logo .logo {
    width: 49px !important;
    border-radius: 100px;
  }
  .header .header_left .header_logo .logo img {
    max-width: 194px;
  }
  .header .header_left .header_logo .logo span {
    width: 0;
  }
  .header .header_left .header_cent {
    margin: 0 20px 0 0;
    font-size: 0;
  }
  .header .header_left .header_cent a {
    display: flex;
  }
  .header .header_left .header_cent span {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .header .header_left .header_cent span svg {
    margin-right: 6px;
  }
  .header .header_right .header_user a {
    width: 170px;
    margin-right: 15px;
  }
  .header .header_right .header_currency {
    margin-right: 20px;
  }
  .header.open_sidebar {
    z-index: 30;
    background: #EEEEF8;
  }
  .header_burger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 0 14px 14px 0;
    background: rgba(100, 94, 252, 0.3);
    width: 71px;
    height: 68px;
  }
  .header_burger .burg-close {
    display: none;
  }
  .header_burger.active {
    color: #ffffff;
  }
  .header_burger.active .burg-open {
    display: none;
  }
  .header_burger.active .burg-close {
    display: block;
  }
  .side_toggle,
  .header_tech {
    display: none;
  }
}
@media (max-width: 767px) {
  .header .header_right .header_user a {
    width: 40px;
  }
  .header .header_right .header_user a span {
    display: none;
  }
  .header.header_login {
    padding: 12px 20px;
  }
  .header.header_login img {
    width: 132px;
  }
  .header.header_login .header_logo {
    margin-bottom: 28px;
  }
  .header.header_login .header_cent {
    position: absolute;
    left: 20px;
    top: 42px;
  }
  .header.header_login.header_login_reg .header_logo {
    margin-bottom: 0;
  }
  .header.header_login.header_login_reg .header_cent {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 575px) {
  .header .header_left {
    flex: 1 1 auto;
  }
  .header .header_left .header_logo {
    display: none;
  }
  .header .header_left .header_cent {
    margin: 0 0 0 auto;
  }
  .header .header_left .header_cent span {
    font-size: 0;
  }
  .header .header_currency {
    margin-right: 14px;
  }
  .header .header_currency a {
    font-size: 0;
  }
  .header .header_currency a + a {
    margin-left: 8px;
  }
  .header .header_right .header_ico {
    margin-right: 34px;
  }
  .header .header_right .header_user a {
    margin-right: 20px;
  }
  .header.header_login {
    display: block;
  }
  .header.header_login .header_logo {
    margin-bottom: 0;
  }
  .header.header_login .header_cent {
    position: relative;
    left: 0;
    top: 0;
    margin: 7px 0 14px;
    font-size: 12px;
  }
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE-_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F.ttf) format('truetype');
}
html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: 'Manrope', sans-serif;
  font-feature-settings: 'clig' off, 'liga' off;
}
body {
  background: #E3E5F6;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #404040;
}
::after,
::before {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
  scrollbar-width: thin;
}
a {
  cursor: pointer;
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  background: transparent;
}
body::-webkit-scrollbar-thumb {
  background-color: rgba(117, 116, 120, 0.6);
  border-radius: 20px;
  border: 3px solid transparent;
}
body {
  scrollbar-width: thin;
  scrollbar-color: rgba(117, 116, 120, 0.6) transparent;
}
body.noscroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}
h1 {
  font-size: 30px;
  font-weight: 800;
  line-height: normal;
  color: #23232B;
}
h2 {
  font-size: 30px;
  font-weight: 800;
  line-height: 40px;
  color: #23232B;
}
h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: #5A5A5A;
}
h4 {
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
  color: #23232B;
}
a {
  text-decoration: none;
  color: #645EFC;
}
img {
  max-width: 100%;
}
hr {
  margin: 0;
  border-bottom: 0;
  border-top: 1px solid #E3E5F6;
}
.txt_light40 {
  color: rgba(35, 35, 43, 0.4);
}
.text-right {
  text-align: right;
}
.icon12 {
  width: 12px;
  height: 12px;
  display: block;
}
.icon16 {
  width: 16px;
  height: 16px;
  display: block;
}
.icon18 {
  width: 18px;
  height: 18px;
  display: block;
}
.icon20 {
  width: 20px;
  height: 20px;
  display: block;
}
.icon22 {
  width: 22px;
  height: 22px;
  display: block;
}
.icon24 {
  width: 24px;
  height: 24px;
  display: block;
}
.icon32 {
  width: 32px;
  height: 32px;
  display: block;
}
.hidden {
  display: none;
}
.marg_0 {
  margin: 0;
}
.marg_top_20 {
  margin-top: 20px;
}
.font_bold16 {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}
.font_22 {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  color: #5A5A5A;
}
.font_22 b {
  font-weight: 800;
}
.font_22 b + span {
  margin-left: 12px;
}
.flex {
  display: flex;
  align-items: center;
}
.text-red {
  color: #E73D3D;
}
.text_comments {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 0 4px;
  border-radius: 8px;
  background: #C5C3DD;
}
.styled-scrollbars {
  /* плашка-бегунок и фон */
  scrollbar-color: #999 #333;
}
.styled-scrollbars::-webkit-scrollbar {
  width: 10px;
  /* в основном для вертикальных полос прокрутки */
  height: 10px;
  /* в основном для горизонтальных полос прокрутки */
}
.styled-scrollbars::-webkit-scrollbar-thumb {
  /* плашка-бегунок */
  background: #999;
}
.styled-scrollbars::-webkit-scrollbar-track {
  /* фон */
  background: #333;
}
.work_area {
  display: flex;
  justify-content: space-between;
}
.wrapper {
  border-radius: 30px 0 30px 30px;
  background: #FFF;
  padding: 40px 30px 40px 30px;
  width: calc(100% - 224px);
  transition: 0.3s all;
}
.modal_link {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #404040;
  position: relative;
}
.modal_link:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 1px dashed #645EFC;
}
.overflow {
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
  border-radius: 0 14px 14px 14px;
}
.side_group .group_title {
  font-weight: 800;
  line-height: normal;
  margin-bottom: 10px;
}
.side_group .group_title + .range-wrap {
  margin-top: 15px;
}
.side_group .checkbox-container {
  padding: 5px 0;
}
.side_group + .side_group {
  margin-top: 20px;
}
.alert {
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  padding: 2px 10px;
  display: inline-block;
  border-radius: 30px;
}
.alert-orange {
  color: #CF6D34;
  background: rgba(253, 169, 122, 0.4);
}
.alert-red {
  color: #BB3030;
  background: rgba(231, 61, 61, 0.1);
}
.alert-blue {
  color: #8B54FF;
  background: rgba(183, 151, 251, 0.4);
}
.alert-dark {
  color: #5A5A5A;
  background: rgba(90, 90, 90, 0.1);
}
.alert-gray {
  color: rgba(90, 90, 90, 0.4);
  background: rgba(90, 90, 90, 0.1);
}
.alert-green {
  color: #3D9076;
  background: rgba(71, 183, 149, 0.4);
}
.pagination_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 20px -20px 0;
}
.pagination_wrap .pagination_view {
  font-size: 13px;
  font-style: normal;
}
.pagination_wrap ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}
.pagination_wrap ul li a,
.pagination_wrap ul li span {
  display: block;
  border-radius: var(--8-px, 8px);
  padding: 10px 4px;
  width: 44px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #404040;
}
.pagination_wrap ul li .current {
  background: #645EFC;
  color: #ffffff;
}
.pagination_wrap ul li .pagination-prev,
.pagination_wrap ul li .pagination-next {
  background: url(./img/icon/chevron-up.svg) no-repeat center / 20px auto;
  height: 44px;
  transform: rotate(-90deg);
}
.pagination_wrap ul li .pagination-next {
  transform: rotate(90deg);
}
.pagination_wrap ul li span.pagination-prev,
.pagination_wrap ul li span.pagination-next {
  opacity: 0.4;
}
.tooltip {
  background: #23232B;
  max-width: 210px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #FFFFFF;
  border-radius: 14px;
  display: none;
  position: relative;
}
.tooltip-long {
  max-width: 550px;
}
.tooltip .tooltip_close {
  position: absolute;
  color: #FFFFFF;
  top: 8px;
  right: 16px;
}
.tooltip p {
  margin: 0;
  padding-right: 15px;
}
.tooltip p + p {
  margin-top: 4px;
  padding-right: 0;
}
.tooltip.tooltip_line {
  padding: 0;
  min-width: 142px;
}
.tooltip.tooltip_line .tooltip_block {
  padding: 8px 16px;
}
.tooltip.tooltip_line .tooltip_block + .tooltip_block {
  border-top: 1px solid #5A5A5A;
}
.tooltip .tooltip_dat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}
.tooltip .tooltip_dat .tooltip_num {
  font-size: 10px;
  font-weight: 800;
  line-height: 12px;
}
.tooltip .tooltip_dat .tooltip_pass {
  display: flex;
  font-size: 10px;
  font-weight: 800;
  line-height: 12px;
  color: #C5C3DD;
}
.tooltip .tooltip_inf {
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.tooltip_img {
  max-width: 400px;
  width: 100%;
  height: 250px;
  border-radius: 20px;
  background: rgba(35, 35, 43, 0.8);
  padding: 10px;
  position: relative;
}
.tooltip_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
}
.tooltip_img:after {
  content: '';
  position: absolute;
  left: 20px;
  bottom: -12px;
  border: 6px solid transparent;
  border-top: 6px solid rgba(35, 35, 43, 0.8);
}
.tooltip_price {
  max-width: 348px;
  padding: 0;
  font-weight: 600;
  border-radius: 14px;
  color: #5A5A5A;
  border: 1px solid #A2A2BA;
}
.tooltip_price h5 {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
}
.tooltip_price .tooltip_price_all {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.tooltip_price .tooltip_price_head {
  padding: 14px 14px 7px 14px;
}
.tooltip_price .tooltip_price_body {
  padding: 7px 14px 7px 14px;
  border-top: 1px solid #A2A2BA;
  border-bottom: 1px solid #A2A2BA;
}
.tooltip_price .tooltip_price_footer {
  padding: 7px 14px 10px 14px;
}
.fill_bg_blue {
  background: #E3E5F6;
}
.dates-slider-wrapper .carousel {
  padding: 0 10px;
}
.dates-slider-wrapper .carousel button {
  margin: 0;
}
.error_inf {
  display: none;
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #FFFFFF;
  background: #EE5C5C;
  padding: 2px 5px;
  width: 100%;
  border-radius: 10px;
}
.error_fields {
  margin-bottom: 25px !important;
  position: relative;
}
.error_fields .input_field,
.error_fields .fake_select {
  border-color: #EE5C5C !important;
}
.error_fields .error_inf {
  display: block;
}
.load_anim {
  display: none;
  animation: 1s linear 0s normal none infinite running rot;
  -webkit-animation: 1s linear 0s normal none infinite running rot;
  width: 49px;
  position: fixed;
  bottom: 30%;
  left: 50%;
  z-index: 1000;
  color: #645EFC;
}
.load_anim svg {
  width: 49px;
  height: 48px;
}
.loads .load_anim {
  display: block;
}
.loads .loads_blur {
  filter: blur(2px);
}
@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (min-width: 1440px) {
  .hide_pk {
    display: none;
  }
}
@media (max-width: 1439px) {
  .modal {
    font-size: 12px;
  }
  .wrapper {
    padding: 40px 30px 40px 23px;
  }
}
@media (max-width: 1199px) {
  .wrapper {
    width: 100%;
    border-radius: 0;
    padding: 40px 14px 20px;
  }
  .hide_planshet {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .wrapper {
    padding: 40px 20px 20px;
  }
  .hide_phone {
    display: none !important;
  }
  .alert {
    font-size: 12px;
    line-height: normal;
  }
}
@media (max-width: 575px) {
  .wrapper {
    padding: 30px 20px 20px;
  }
  h1 {
    font-size: 26px;
    line-height: 36px;
  }
  .alert {
    font-size: 10px;
    line-height: 12px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modal_wrap {
  padding: 15px 22px;
  max-width: 384px;
}
.modal_wrap.modal_wrap_wide {
  max-width: 742px;
  position: relative;
}
.modal_wrap.modal_wrap_wide .slide_prev {
  left: -12px;
}
.modal_wrap.modal_wrap_wide .slide_next {
  right: -12px;
}
.modal_wrap.modal_accord {
  max-width: 768px;
  padding: 20px 14px;
}
.modal_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 6px 0;
  width: 100%;
  position: relative;
}
.modal_head h5 {
  color: #404040;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
}
.modal_head_long_text h5 {
  white-space: normal;
  width: 80%;
}
.modal_h400 {
  height: 400px;
}
.overflow_h {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 284px;
}
.modal_body {
  display: flex;
  margin: 10px -10px 0;
}
.modal_body .modal_col_body {
  width: 100%;
}
.modal_body .modal_col_body-duo {
  padding: 10px;
}
.modal_body .modal_col {
  width: 50%;
  padding: 0 10px;
}
.modal_body .modal_col_head {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 4px 0;
  margin-bottom: 4px;
}
.modal_body .modal_col_head svg {
  margin: 0 10px;
}
.modal_body .modal_col_body .checkbox-container {
  padding: 5px 0;
}
.modal_footer {
  padding: 10px 0 0 ;
}
.modal_wrap_wide .overflow_h {
  max-height: 255px;
  margin-bottom: 20px;
  margin-right: 10px;
}
.modal_slide {
  display: flex;
  margin: 0 -10px;
}
.modal_slide .modal_item {
  width: 360px;
  min-width: 360px;
  padding: 0 10px;
}
.accord_item {
  margin-top: 10px;
}
.accord_item .accord_body {
  display: none;
}
.accord_item .accord_body .accord_body_wrap {
  width: 100%;
  padding: 0 10px;
}
.accord_item .accord_head {
  position: relative;
}
.accord_item .accord_head .reset_filtr {
  position: absolute;
  right: 0;
  top: 12px;
  display: none;
}
.accord_item .accord_head.has_sel {
  padding-right: 95px;
}
.accord_item .accord_head.has_sel .reset_filtr {
  display: block;
}
.accord_item.accord_active .extr_toggle svg {
  transform: rotate(180deg);
}
.accord_item.accord_active .accord_body {
  display: flex;
}
.lay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: rgba(90, 90, 90, 0.6);
}
.modal_side {
  display: none;
  position: fixed;
  padding: 16px 0;
  z-index: 25;
  bottom: 0;
  border-radius: 30px 30px 0 0;
  background: #FFF;
  width: 100%;
  left: 0;
}
.modal_side.modal_side_md {
  max-width: 936px;
  left: 50%;
  transform: translate(-50%, 0);
}
.modal_side.modal_side_md .modal_side_wrap {
  padding: 0 30px 0 40px;
}
.modal_side.modal_side_md .modal_side_air {
  padding: 0 40px 0 40px;
}
.modal_side.modal_side_md .modal_side_air .zabron {
  margin: 0 54px 0 auto;
}
.modal_side.modal_side_md .modal_side_air .modal_close {
  right: 0;
}
.modal_side.modal_notify {
  width: 556px !important;
}
.modal_side.modal_notify .modal_side_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 22px 30px 22px 30px !important;
}
.modal_side.modal_notify .notify_head {
  flex-wrap: wrap;
  padding: 0 0 10px 0;
}
.modal_side.modal_notify .notify_head h5 {
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
}
.modal_side.modal_notify .notify_body {
  margin: 0 -7px auto 0;
  overflow: auto;
  padding-right: 7px;
}
.modal_side.modal_notify .newstbl {
  margin-top: 30px;
}
.modal_side.modal_notify .newstbl:first-child {
  margin-top: 0;
}
.modal_side.modal_notify .newstbl_head {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid #E3E5F6;
}
.modal_side.modal_notify .newstbl_head span {
  color: rgba(35, 35, 43, 0.4);
  margin-right: 20px;
  white-space: nowrap;
}
.modal_side.modal_notify .newstbl_head a {
  text-align: right;
}
.modal_side.modal_notify .newstbl_head .link_read_yes {
  color: rgba(35, 35, 43, 0.4);
}
.modal_side.modal_notify .newstbl_item {
  margin: 4px 0 0;
  padding: 10px 7px;
  border-radius: 12px;
  align-items: normal;
  border: 0;
  background: #EEEEF8;
}
.modal_side.modal_notify .newstbl_item.no_read {
  background: rgba(183, 151, 251, 0.4);
}
.modal_side.modal_notify .newstbl_item:before {
  content: none;
}
.modal_side.modal_notify .newstbl_item > div {
  padding: 0 5px;
}
.modal_side.modal_notify .newstbl_item .newstbl_but {
  width: 42px;
  min-width: 42px;
  align-items: normal;
}
.modal_side.modal_notify .newstbl_item .newstbl_text {
  width: 100%;
  color: #5A5A5A;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.modal_side.modal_notify .newstbl_item .newstbl_text .status {
  display: inline;
}
.modal_side.modal_notify .newstbl_item .newstbl_date {
  margin: 6px 0;
  font-size: 14px;
  line-height: 20px;
  width: auto;
  white-space: nowrap;
  color: rgba(35, 35, 43, 0.4);
}
.modal_side.modal_notify .notify_footer {
  padding-top: 30px;
}
.modal_side.modal_notify .notify_footer .btn {
  width: 100%;
  border-radius: 20px;
}
.modal_side.modal_notify .notify_none {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.modal_side.modal_notify .notify_inf {
  width: 144px;
  height: 40px;
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background: #EEEEF8;
  color: #5A5A5A;
}
.modal_side.modal_notify .tarif_switch.tabs-but {
  margin-top: 23px;
}
.modal_side.modal_notify .tarif_switch.tabs-but a {
  font-size: 14px;
}
.modal_side.modal_notify.notify_empty .notify_footer {
  visibility: hidden;
  height: 1px;
}
.modal_side.modal_side_600 {
  max-width: 600px;
  width: 100%;
  left: 50%;
  bottom: auto;
  top: 50%;
  border-radius: 30px;
  transform: translate(-50%, -50%);
  height: auto;
}
.modal_side.modal_side_600 .modal_side_wrap {
  padding: 0 20px;
}
.modal_side.modal_side_600 .modal_close {
  right: 0;
}
.modal_side.modal_height_600 {
  max-height: 600px;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.modal_side.modal_right {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
  width: 50%;
  top: 0;
  right: 0;
  left: auto;
  border-radius: 20px 0 0 0;
}
.modal_side.modal_right .modal_side_wrap {
  padding: 22px 20px 22px 30px;
}
.modal_side .modal_side_wrap {
  padding: 0 20px 0 134px;
}
.modal_side .modal_head {
  justify-content: flex-start;
  padding-right: 10px;
}
.modal_side .modal_head_tarif {
  flex-wrap: wrap;
}
.modal_side .zabron {
  margin: 0 108px 0 auto;
}
.modal_side .switch {
  margin-left: -32px;
  position: absolute;
  left: 50%;
}
.modal_side.modal_side_otchet .modal_head {
  position: static;
  flex-wrap: nowrap;
}
.modal_full {
  border-radius: 0;
  max-width: 1440px;
  max-height: 862px;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50% !important;
  transform: translate(-50%, -50%);
  padding: 0;
}
.modal_full .modal_close {
  top: 40px;
  right: 30px;
}
.head_with_price {
  margin-top: 7px;
  margin-bottom: 14px;
}
.head_with_price .switch {
  margin-left: 11%;
  position: relative;
  left: 0;
}
.head_with_price .tarif_head_price {
  margin: 0 0 0 auto;
  color: #404040;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  display: flex;
}
.head_with_price .tarif_head_price span {
  display: block;
  margin: 0 4px 0 10px;
}
.head_with_price .zabron {
  margin: 0 8.4% 0 6.2%;
}
.modal_close {
  border-radius: 30px;
  background: rgba(100, 94, 252, 0.5);
  position: absolute;
  top: 5px;
  right: 10px;
  transition: 0.3s all;
}
.modal_close:hover {
  background: #645efc;
}
.modal_side_search {
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
}
.download_area {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.download_area .download_area_inp {
  border-radius: 8px;
  background: #EEEEF8;
  padding: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #23232B;
  flex: 1 1 auto;
  align-items: center;
  display: none;
}
.download_area .download_area_inp .btn {
  margin-left: 10px;
  padding: 2px 12px;
  font-size: 12px;
  line-height: 18px;
  color: #645EFC;
}
.download_area .download_area_inp + .btn_download {
  margin-left: 8px;
}
.download_area .btn_download {
  padding: 2px 12px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 14px;
  background: #EEEEF8;
  white-space: nowrap;
  transition: 0.3s all;
}
.download_area .btn_download:hover {
  background: #7378FC;
  color: #FFFFFF;
}
.download_area .btn_download_add {
  display: inline-block;
}
.download_area .btn_download_replace {
  display: none;
}
.download_area.active .download_area_inp {
  display: flex;
}
.download_area.active .btn_download_add {
  display: none;
}
.download_area.active .btn_download_replace {
  display: inline-block;
}
.add_other_file {
  margin: 4px 0 24px;
  display: inline-block;
}
.download_del {
  position: absolute;
  top: 18px;
  right: 0;
}
.form_group_del {
  height: 25px;
}
@media (max-width: 1439px) {
  .modal_side .modal_side_wrap {
    padding: 0 20px 0 114px;
  }
}
@media (max-width: 1299px) {
  .modal_side:not(.modal_side_otchet) {
    height: 100%;
    border-radius: 0;
    top: 0;
  }
}
@media (max-width: 1199px) {
  .modal_side .modal_side_wrap {
    padding: 0 4px 0 14px;
  }
  .modal_side.modal_side_md .modal_side_wrap {
    padding: 0 10px 0 20px;
  }
  .modal_side.modal_side_md .modal_side_air {
    padding: 2px 14px 0 14px;
  }
  .modal_side.modal_side_detail {
    width: 70%;
  }
  .modal_side.modal_side_detail .modal_side_wrap {
    padding: 4px 4px 4px 14px;
  }
  .modal_full {
    height: 400px;
  }
  .modal_full .modal_close {
    top: 20px;
    right: 20px;
    position: fixed;
    z-index: 40;
  }
}
@media (max-width: 767px) {
  .modal_side .modal_side_wrap {
    padding: 0 10px 0 20px;
  }
  .modal_side .modal_head {
    margin-top: 3px;
    margin-bottom: 21px;
    flex-wrap: wrap;
  }
  .modal_side .modal_head h5 {
    width: 90%;
  }
  .modal_side .modal_head .modal_head_check {
    order: 1;
  }
  .modal_side .tarif_head_price {
    margin: 37px 0 0 0;
    width: 50%;
    font-size: 16px;
  }
  .modal_side .tarif_head_price span {
    margin: 0 4px 0 20px;
  }
  .modal_side .tarif_head_price + .zabron {
    width: 50%;
  }
  .modal_side .zabron {
    margin: 37px 0 0 0;
    width: 100%;
    text-align: right;
  }
  .modal_side .switch {
    top: 2px;
    margin-left: -37px;
  }
  .modal_side.modal_side_md .modal_side_air {
    padding: 0 20px;
  }
  .modal_side.modal_side_md .modal_side_air .modal_head {
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal_side.modal_side_md .modal_side_air .zabron {
    margin: 17px auto 0;
    text-align: center;
  }
  .modal_side.modal_side_otchet .modal_head h5 {
    white-space: normal;
  }
  .head_with_price {
    margin-top: 2px;
    margin-bottom: 18px;
  }
  .head_with_price .switch {
    margin-left: -37px;
    position: absolute;
    left: 50%;
  }
  .modal_close {
    top: 5px;
    right: 11px;
  }
  .modal_side.modal_side_600 .modal_close {
    right: 0;
  }
  .modal_side.modal_notify {
    width: 371px !important;
  }
  .modal_side.modal_notify .modal_side_wrap {
    padding: 22px 20px 22px 20px !important;
  }
  .modal_side.modal_notify .newstbl_item .newstbl_date {
    width: 70px;
    min-width: 70px;
    white-space: normal;
  }
  .modal_full {
    height: 250px;
    overflow: visible;
  }
  .modal_full .modal_close {
    top: calc(202px - 50vh);
    right: 20px;
  }
  .download_area {
    flex-wrap: wrap;
  }
  .download_area .download_area_inp + .btn_download {
    margin: 8px 0 0;
  }
}
@media (max-width: 575px) {
  .modal_side.modal_side_600 {
    width: calc(100% - 20px);
  }
  .modal_side_wrap .form_group_but {
    flex-wrap: wrap;
  }
  .modal_side_wrap .form_group_but .btn {
    width: 100%;
    margin-bottom: 10px;
  }
  .modal_side.modal_notify {
    width: 100% !important;
  }
  .modal_side.modal_notify .notify_head {
    margin-bottom: 0;
  }
  .modal_side.modal_notify .tarif_switch.tabs-but {
    margin-top: 20px;
  }
  .modal_side.modal_notify .tarif_switch.tabs-but a {
    margin: 0;
    padding: 5px 16px;
  }
  .modal_side.modal_notify .newstbl_item {
    flex-wrap: nowrap;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news_cal {
  display: flex;
  margin: 20px -10px 0;
}
.news_cal .calendar {
  width: 33.333%;
}
.news_cal .news {
  width: 66.666%;
}
.news_cal > div {
  padding: 0 10px;
}
.news_cal > div > div {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
  padding: 28px 18px 22px 18px;
  height: 100%;
  background: #E3E5F6;
}
.news_cal .fake_select {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 9px;
}
.calendar .fake_select {
  font-size: 14px;
  padding: 4px 0;
}
.calendar .fake_select .icon18 {
  margin-left: 10px;
}
.blocks_button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.sort_wrap {
  position: relative;
}
.sort_wrap + .main_but {
  margin-left: 20px;
}
.newstbl {
  margin-top: 20px;
}
.newstbl_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-top: 1px solid rgba(227, 229, 246, 0.5);
  padding: 0 8px;
  margin-left: -19px;
  margin-right: -19px;
  position: relative;
}
.newstbl_item:before {
  content: "";
  position: absolute;
  top: 16px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  opacity: 0;
  background: #C4CAFD;
  transition: 0.3s all;
}
.newstbl_item.no_read {
  background: rgba(183, 151, 251, 0.4);
}
.newstbl_item.no_read:before {
  opacity: 1;
}
.newstbl_item.no_read .newstbl_text {
  font-weight: 800;
  color: #23232B;
}
.newstbl_item.no_read .newstbl_but .newstbl_but_mail img {
  opacity: 0;
}
.newstbl_item.no_read .newstbl_but .newstbl_but_mail img + img {
  opacity: 1;
}
.newstbl_item.no_read .newstbl_but_logo .newstbl_but_mail img {
  opacity: 1;
}
.newstbl_item > div {
  padding: 11px 10px 12px 10px;
}
.newstbl_item .newstbl_text {
  color: #404040;
  line-height: 20px;
  width: calc(100% - 186px);
}
.newstbl_item .newstbl_text span {
  color: #23232B;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  opacity: 0.6;
}
.newstbl_item .newstbl_date {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  width: 100px;
}
.newstbl_but {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 94px;
}
.newstbl_but a + a {
  margin-left: 10px;
  height: 32px;
  width: 32px;
  padding: 5px;
  background: #fff;
  border-radius: 8px;
}
.newstbl_but a,
.newstbl_but img {
  display: block;
  color: #23232B;
}
.newstbl_but .newstbl_but_mail {
  height: 32px;
  width: 32px;
  padding: 5px;
  background: #fff;
  border-radius: 8px;
  position: relative;
}
.newstbl_but .newstbl_but_mail img,
.newstbl_but .newstbl_but_mail svg {
  position: absolute;
  top: 5px;
  left: 5px;
  opacity: 1;
  transition: 0.3s all;
}
.newstbl_but .newstbl_but_mail img + img,
.newstbl_but .newstbl_but_mail svg + svg {
  opacity: 0;
}
.newstbl_but.newstbl_but_logo .newstbl_but_mail {
  padding: 0;
  background: none;
}
.newstbl_but.newstbl_but_logo img {
  top: 0;
  left: 0;
}
.no_read .newstbl_but .newstbl_but_mail img {
  opacity: 0;
}
.no_read .newstbl_but .newstbl_but_mail img + img {
  opacity: 1;
}
.no_read .newstbl_but .newstbl_but_mail svg {
  opacity: 0;
}
.no_read .newstbl_but .newstbl_but_mail svg + svg {
  opacity: 1;
}
#news_calendar {
  margin: 0 -8px;
}
#news_calendar .ui-widget.ui-widget-content {
  margin-top: 27px;
  background: transparent;
  padding: 0 4px;
}
#news_calendar .ui-datepicker .ui-datepicker-title {
  order: 0;
  color: #645EFC;
  width: 100%;
  text-align: center;
}
#news_calendar .ui-datepicker .ui-datepicker-prev,
#news_calendar .ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: -4px;
  left: 5px;
}
#news_calendar .ui-datepicker .ui-datepicker-next {
  left: auto;
  right: 5px;
}
#news_calendar .ui-datepicker .ui-datepicker-header {
  margin-bottom: 20px;
  padding: 0 6px;
}
#news_calendar .ui-datepicker th {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  padding: 3px 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
#news_calendar .ui-datepicker td {
  width: 48px;
  height: 48px;
  padding: 2px;
}
#news_calendar .ui-datepicker td span,
#news_calendar .ui-datepicker td a {
  font-size: 16px;
}
#news_calendar .ui-datepicker .ui-state-default:not(.ui-state-active) {
  color: #5A5A5A;
}
.bron_one_calendar {
  width: 340px;
}
.bron_one_calendar #news_calendar .ui-datepicker td {
  width: 44px;
  height: 44px;
}
.bron_one_calendar #news_calendar .ui-widget.ui-widget-content {
  width: 316px;
  margin-bottom: 16px;
  margin-top: 20px;
}
.news_calendar_two {
  max-width: 754px;
  margin: 0 auto;
}
.news_calendar_head {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 15px 0 15px;
}
.news_calendar_head span {
  display: block;
  color: #5A5A5A;
  font-size: 18px;
  font-weight: 800;
  line-height: 27px;
}
.news_calendar_head .btn {
  text-align: center;
  display: block;
}
.news_calendar_period {
  display: flex;
  align-items: center;
  padding: 0 15px 22px;
}
.news_calendar_period span {
  display: flex;
  align-items: center;
  padding: 5px 16px;
  border-radius: 12px;
  color: #404040;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-right: 10px;
  transition: 0.3s all;
}
.news_calendar_period span svg {
  display: none;
}
.news_calendar_period span:hover {
  background: #645EFC;
  color: #FFFFFF;
}
.news_calendar_period span.active {
  background: #645EFC;
  color: #FFFFFF;
}
.news_calendar_period span.active svg {
  display: block;
  color: #FFFFFF;
  margin-left: 5px;
  margin-right: -4px;
}
.cal6 {
  width: 50%;
  padding: 0 15px;
}
@media (max-width: 1439px) {
  #news_calendar .ui-widget.ui-widget-content {
    width: 100%;
  }
  #news_calendar .ui-datepicker td {
    width: 36px;
    height: 36px;
  }
  #news_calendar .ui-datepicker td span,
  #news_calendar .ui-datepicker td a {
    font-size: 16px;
  }
  .ui-state-mark:before {
    top: 1px;
  }
}
@media (max-width: 991px) {
  .news_cal {
    flex-wrap: wrap;
  }
  .news_cal .calendar,
  .news_cal .news {
    width: 100%;
  }
  .news_cal .news {
    margin-top: 20px;
  }
  .newstbl {
    max-width: none;
  }
  #news_calendar .ui-datepicker td {
    width: 48px;
    height: 48px;
    padding: 2px;
  }
  #news_calendar .ui-datepicker td a {
    width: 44px;
    margin: 0 auto;
  }
  .ui-state-mark:before {
    top: 6px;
  }
}
@media (max-width: 767px) {
  .newstbl_item {
    align-items: flex-start;
  }
  #news_calendar .ui-datepicker td a {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .news_calendar_head {
    padding: 0 5px;
  }
  .cal6 {
    width: 100%;
    margin-top: 10px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.flex_row_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form_group_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form_group_center > .btn + .btn {
  margin-left: 20px;
}
.to-top_up {
  box-shadow: 0 6px 18px 0 rgba(101, 83, 216, 0.13);
  padding: 10px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  transform: rotate(-180deg);
}
.orders {
  padding: 0 20px;
  margin-top: 33px;
  color: #5A5A5A;
}
.orders .blocks_head {
  align-items: flex-start;
}
.orders .btn {
  width: 119px;
  text-align: center;
}
.pagination_nomarg + .orders {
  margin-top: 20px;
}
.blocks_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.orders_table {
  margin: 15px -20px 0;
  border: 2px solid #EEEEF8;
  border-radius: 20px;
  overflow: hidden;
}
.orders_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 12px 14px;
  background: #E3E5F6;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.orders_head > div {
  padding: 8px 6px;
  display: flex;
  align-items: center;
}
.sort {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 4px;
  cursor: pointer;
}
.sort a {
  display: block;
  width: 12px;
  height: 12px;
  cursor: pointer;
  background: url(./img/icon/chevron-up.svg) no-repeat 0 0;
}
.sort a.down {
  transform: rotate(180deg);
  margin-top: -3px;
}
.sort a:hover {
  opacity: 0.5;
}
.usesort {
  display: flex;
  align-items: center;
  justify-content: center;
}
.usesort .sort {
  margin-left: 6px;
  cursor: pointer;
  padding: 0;
  width: 16px;
}
.usesort use {
  fill: #23232B;
  color: #23232B;
}
.usesort.active {
  color: #645EFC;
}
.usesort.active_down svg {
  margin-left: -7px;
}
.usesort.active_down use {
  fill: #645EFC;
  color: transparent;
}
.usesort.active_up svg {
  margin-right: -7px;
}
.usesort.active_up use {
  fill: transparent;
  color: #645EFC;
}
.orders_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 14px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid #E3E5F6;
}
.orders_item:last-child {
  border-bottom: 1px solid transparent;
}
.orders_item > div {
  padding: 8px 6px;
}
.orders_item > div.ord_date_travel {
  padding-top: 0;
  padding-bottom: 0;
}
.orders_item .ord_order {
  display: flex;
}
.orders_item .ord_order img {
  display: block;
  margin-right: 8px;
  width: 20px;
}
.orders_item .ord_attention {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 59px;
}
.ord_order {
  width: 110px;
}
.ord_date_order {
  width: 92px;
}
.ord_status {
  width: calc(33% - 238px);
}
.ord_status .status {
  white-space: normal;
}
.ord_attention {
  width: 80px;
  justify-content: center;
}
.ord_date_travel {
  width: 104px;
}
.ord_route {
  width: calc(33% - 228px);
}
.ord_pass {
  width: 110px;
  word-break: break-all;
}
.ord_clients {
  width: 100px;
}
.ord_amount {
  width: 124px;
}
.ord_company {
  width: calc(33% - 242px);
}
.status {
  display: inline-block;
  border-radius: 30px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  white-space: nowrap;
}
.status.cancelled {
  background: rgba(231, 61, 61, 0.1);
  color: #BB3030;
}
.status.at_work {
  background: rgba(183, 151, 251, 0.4);
  color: #8B54FF;
}
.status.completed {
  background: rgba(89, 217, 178, 0.4);
  color: #3D9076;
}
.status.agreement {
  background: #FFD8B4;
  color: #CF6D34;
}
.status.void {
  background: rgba(90, 90, 90, 0.3);
  color: rgba(90, 90, 90, 0.6);
}
.status.st_job {
  background: #47B795;
  color: #FFFFFF;
}
.status.status_big {
  padding: 6px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
}
.essent-red {
  color: #E73D3D;
}
.essent-gray {
  color: #757478;
}
.att_span {
  display: inline-block;
  border-radius: 30px;
  padding: 2px 10px;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}
.att_span.att-red {
  background: rgba(231, 61, 61, 0.1);
  color: #BB3030;
}
.att_span.att-green {
  background: rgba(89, 217, 178, 0.4);
  color: #3D9076;
}
.widspan > span,
.widspan > a {
  width: 100%;
  display: flex;
  color: #5A5A5A;
}
.block_widget {
  background: #E3E5F6;
  padding: 20px 20px;
  border-radius: 20px;
  color: #23232B;
  margin-top: 38px;
}
.block_widget .block_widget_head {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.block_widget .widget_head_info {
  display: flex;
  align-items: center;
}
.block_widget .widget_head_info .block_widget_switch {
  display: flex;
  border-radius: 12px;
  border: 1px solid rgba(100, 94, 252, 0.4);
}
.block_widget .widget_head_info .block_widget_switch label {
  padding: 6px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  z-index: 1;
}
.block_widget .widget_head_info .block_widget_switch label .checkmark {
  border-radius: 12px;
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  border: 0;
  background: transparent;
  color: #404040;
  transition: 0.3s all;
  z-index: -1;
}
.block_widget .widget_head_info .block_widget_switch label input:checked ~ .checkmark {
  background: #645EFC;
}
.block_widget .widget_head_info .block_widget_switch label input:checked ~ .checkmark + span {
  color: #FFFFFF;
}
.block_widget .widget_head_info .block_widget_switch label:hover span {
  color: #fff;
}
.block_widget .widget_head_info .block_widget_switch label:hover .checkmark {
  background: #645EFC;
}
.block_widget .widget_head_info .block_widget_switch a {
  padding: 6px 16px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #404040;
  transition: 0.3s all;
}
.block_widget .widget_head_info .block_widget_switch a.active,
.block_widget .widget_head_info .block_widget_switch a:hover {
  background-color: #645EFC;
  color: #fff;
}
.block_widget .widget_head_info .block_widget_switch + .block_widget_checkbox {
  margin-left: 20px;
}
.block_widget .widget_head_info .block_widget_checkbox {
  display: flex;
}
.block_widget .widget_head_info .block_widget_checkbox .checkbox-container {
  padding: 14px 14px;
}
.block_widget .widget_head_but {
  display: flex;
  align-items: center;
}
.block_widget .widget_head_but > * {
  margin-left: 20px;
}
.block_widget .widget_head_but .extr_toggle {
  color: #645EFC;
  border-color: rgba(100, 94, 252, 0.4);
}
.block_widget .widget_head_but .extr_toggle svg {
  transform: rotate(180deg);
  margin-left: 10px;
}
.block_widget .widget_head_but .extr_toggle .filtr_text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.block_widget .widget_head_but .extr_toggle .filtr_text span {
  opacity: 1;
  max-height: 20px;
  transition: 0.3s all;
}
.block_widget .widget_head_but .extr_toggle .filtr_text span b {
  display: none;
  font-weight: 400;
}
.block_widget .widget_head_but .extr_toggle .filtr_text span + span {
  opacity: 0;
  max-height: 0;
}
.block_widget .widget_inputs {
  opacity: 1;
  /*max-height: 400px;*/
  margin: 10px 0;
  transition: 0.3s all;
}
.block_widget .widget_inputs .block_widget_head {
  padding: 0 0 20px;
}
.block_widget .widget_inputs.widget_inputs_icon {
  margin-bottom: -10px;
}
.block_widget .user_group {
  padding: 0;
  margin: 0;
  border: 0;
}
.block_widget.active .extr_toggle {
  color: #fff;
  background-color: #645EFC;
}
.block_widget.active .extr_toggle svg {
  transform: rotate(0deg);
}
.block_widget.active .extr_toggle .filtr_text span {
  opacity: 0;
  max-height: 0;
}
.block_widget.active .extr_toggle .filtr_text span + span {
  opacity: 1;
  max-height: 20px;
}
.block_widget.active .widget_inputs {
  opacity: 0;
  max-height: 0;
  margin: 0;
}
.block_widget_menu {
  background: #E3E5F6;
  padding: 8px 10px;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 40px;
}
.block_widget_menu.block_widget_menu_doc {
  margin-bottom: 13px;
}
.block_widget_menu.block_widget_menu_doc + h1 + .block_widget {
  margin-top: 20px;
}
.block_widget_menu .block_widget_menu_scroll {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: -8px;
  padding-bottom: 8px;
  white-space: nowrap;
  /*max-width: 750px;*/
}
.block_widget_menu .block_widget_menu_scroll a {
  display: block;
  color: #757478;
  text-decoration: none;
  margin: 0 10px;
  line-height: 24px;
  position: relative;
  transition: 0.3s all;
}
.block_widget_menu .block_widget_menu_scroll a:after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 0;
  height: 1px;
  transition: 0.3s all;
  background-color: #E3E5F6;
}
.block_widget_menu .block_widget_menu_scroll a:hover,
.block_widget_menu .block_widget_menu_scroll a.active {
  color: #645EFC;
}
.block_widget_menu .block_widget_menu_scroll a:hover:after,
.block_widget_menu .block_widget_menu_scroll a.active:after {
  background-color: #645EFC;
}
.block_widget_menu_gray {
  text-align: center;
}
.block_widget_menu_gray .block_widget_menu {
  display: inline-block;
  width: auto;
  border-radius: 12px;
  background: #A2A2BA;
  margin: -10px 0 27px;
  padding: 6px 10px;
}
.block_widget_menu_gray .block_widget_menu .block_widget_menu_scroll a {
  color: #FFFFFF;
  margin: 0 2px;
  border-radius: 12px;
  padding: 6px 16px;
  line-height: 20px;
}
.block_widget_menu_gray .block_widget_menu .block_widget_menu_scroll a:after {
  content: none;
}
.block_widget_menu_gray .block_widget_menu .block_widget_menu_scroll a.active,
.block_widget_menu_gray .block_widget_menu .block_widget_menu_scroll a:hover {
  background-color: #645EFC;
}
.block_widget_menu_fix {
  position: fixed;
  top: 98px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 762px;
  z-index: 1;
  margin-left: 112px;
  transition: 0.3s all;
}
.block_widget_menu_fix .block_widget_menu {
  margin: 0;
}
.block_widget_menu_fix + .head_ico_date {
  margin-top: 61px;
}
.sidebar.active + .wrapper .block_widget_menu_fix {
  margin-left: 51px;
}
.menu_order_comm span {
  color: rgba(255, 255, 255, 0.4);
  font-weight: 800;
}
.menu_order_bron span {
  color: #fff;
  font-weight: 800;
}
.menu_order_bron span.active {
  color: rgba(90, 90, 90, 0.3);
}
.menu_order_bron:before {
  position: absolute;
  top: 12px;
  right: 16px;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  vertical-align: middle;
  margin-left: 10px;
}
.menu_order_bron-attention {
  padding-right: 34px !important;
}
.menu_order_bron-attention:before {
  content: "";
  background: #DB3931;
}
.menu_order_bron-ready {
  padding-right: 34px !important;
}
.menu_order_bron-ready:before {
  content: "";
  background: #FFFFFF;
}
[class*="menu_order-"] {
  padding-right: 34px !important;
}
[class*="menu_order-"]:before {
  content: "";
  position: absolute;
  top: 13px;
  right: 16px;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  vertical-align: middle;
  margin-left: 10px;
}
.menu_order-attention:before {
  background: #DB3931;
}
.menu_order-ready:before {
  background: #FFFFFF;
}
.block_widget_type {
  display: flex;
  margin: -6px -3px;
}
.block_widget_type div {
  display: flex;
  align-items: center;
  margin: 0 3px;
  padding: 6px 11px 6px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 12px;
  background-color: rgba(100, 94, 252, 0.5);
  color: #fff;
}
.block_widget_type div a {
  display: block;
  margin-left: 6px;
  color: #fff;
}
.block_widget_znach {
  display: flex;
  margin: 16px -4px -4px;
  flex-wrap: wrap;
}
.block_widget_znach div {
  padding: 4px;
}
.block_widget_znach div a {
  display: block;
  padding: 6px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 12px;
  color: #404040;
}
.block_widget_znach div a.active {
  background-color: #645EFC;
  color: #fff;
}
.input_type {
  background: #FFF;
  position: relative;
  display: flex;
  align-items: center;
  overflow: visible;
}
.input_type .plais {
  display: block;
  color: rgba(90, 90, 90, 0.6);
}
.input_type .inp_open_type {
  position: absolute;
  top: 0;
  right: 10px;
  padding: 14px;
  background: #fff;
  color: #404040;
}
.input_type .inp_open_type + .fake_dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
  margin-top: 10px;
  min-width: 280px;
}
.input_type.selected .plais {
  display: none;
}
.inp_search {
  display: flex;
  align-items: center;
}
.inp_search .inp_search_ico {
  width: calc(100% - 100px);
  position: relative;
}
.inp_search .inp_search_ico svg {
  position: absolute;
  top: 12px;
  left: 12px;
}
.inp_search .inp_search_ico .input_field {
  padding-left: 48px;
  background: #FFFFFF;
}
.inp_search .inp_search_btn {
  width: 80px;
  margin-left: 20px;
}
.pagination_wrap + .table_scrol {
  margin-top: 20px;
}
.table_scrol {
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 8px;
  margin-bottom: -8px;
  margin-right: -30px;
}
.table_tip {
  width: 100%;
  border: 2px solid #E3E5F6;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 20px;
  overflow: hidden;
  white-space: nowrap;
}
.table_tip tr:hover a {
  color: #645EFC;
}
.table_tip th {
  background: #E3E5F6;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding: 16px 24px;
  border-left: 1px solid #FFFFFF;
  border-right: 1px solid #FFFFFF;
  height: 70px;
}
.table_tip th:first-child {
  border-left-color: #EEEEF8;
}
.table_tip th:last-child {
  border-right-color: #EEEEF8;
}
.table_tip td {
  padding: 24px 22px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  border: 1px solid #E3E5F6;
}
.table_tip td a {
  color: #404040;
  display: flex;
  align-items: center;
}
.table_tip td a svg {
  margin-right: 6px;
}
.table_tip td > span {
  display: flex;
  align-items: center;
}
.table_tip td span.btn {
  padding: 10px;
  border-radius: 8px;
  margin-right: 10px;
}
.table_tip_doc th:nth-child(1) {
  width: 9.9%;
}
.table_tip_doc th:nth-child(2) {
  width: 15%;
}
.table_tip_doc th:nth-child(3) {
  width: 21.1%;
}
.table_tip_doc th:nth-child(4) {
  width: 16.4%;
}
.table_tip_doc th:nth-child(5) {
  width: 18.8%;
}
.table_tip_doc th:nth-child(6) {
  width: 18.8%;
}
.table_tip_bron th {
  width: 26.3%;
}
.table_tip_bron th:nth-child(1) {
  width: 21.1%;
}
.table_tip_lonn {
  text-align: center;
}
.table_tip_lonn tr.active {
  background: #E3E5F6;
}
.table_tip_lonn th {
  font-size: 14px;
  line-height: 20px;
  padding: 13px 12px;
  border-left: 0;
  border-right: 0;
  height: auto;
  min-width: 75px;
}
.table_tip_lonn th:first-child {
  padding: 13px 23px;
  vertical-align: middle;
  min-width: 64px;
}
.table_tip_lonn th:first-child .checkbox-container .checkmark {
  margin: 0;
}
.table_tip_lonn td {
  padding: 13px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border: 0;
  max-width: 300px;
  white-space: normal;
}
.table_tip_lonn td.wide_td {
  min-width: 300px;
}
.table_tip_lonn td:first-child {
  padding: 13px 23px;
  vertical-align: middle;
}
.table_tip_lonn td:first-child .checkbox-container .checkmark {
  margin: 0;
}
.modal_side_otchet .table_tip_lonn tr:nth-child(2n+2) {
  background: #FAFAFA;
}
.modal_side_otchet .table_tip_lonn td {
  padding: 23px 12px;
}
.modal_side_otchet .table_scrol {
  max-height: calc(100vh - 200px);
  overflow-y: visible;
}
.list_check_col {
  columns: 4;
}
.list_check_col .checkbox-container {
  padding: 5px 0;
}
.head_ico_date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head_ico_date .head_ico_date_head {
  display: flex;
  align-items: center;
}
.head_ico_date .head_ico_date_head span.btn {
  padding: 16px;
  color: #fff;
  margin-right: 21px;
  background: #757478;
}
.head_ico_date .head_ico_date_head .head_ico_date_title h1 {
  margin: 0 0 8px;
  display: flex;
  align-items: center;
}
.head_ico_date .head_ico_date_head .head_ico_date_title h1 .btn {
  margin-left: 32px;
}
.head_ico_date .head_ico_date_head .head_ico_date_title .head_ico_date_date {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  color: rgba(90, 90, 90, 0.6);
}
.head_ico_date .head_ico_date_right {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.print_version {
  border-bottom: 1px dashed #645EFC;
  color: #404040;
  font-size: 12px;
}
.print_version.print_svg {
  position: relative;
  margin-bottom: 10px;
}
.print_version.print_svg svg {
  position: absolute;
  top: 1px;
  left: -20px;
  color: #645EFC;
}
.polya_line {
  border-radius: 20px;
  border: 2px solid #EEEEF8;
  padding: 18px 16px;
  display: flex;
}
.polya_line > div {
  padding: 0 4px;
}
.polya_line > div:nth-child(1) {
  width: 15%;
}
.polya_line > div:nth-child(2),
.polya_line > div:nth-child(3),
.polya_line > div:nth-child(4),
.polya_line > div:nth-child(6) {
  width: 18.1%;
}
.polya_line > div:nth-child(5) {
  width: 12.5%;
}
.polya_line .status {
  line-height: 18px;
  white-space: normal;
}
.polya_line .btn-red_fill {
  margin-left: 10px;
  background: #E73D3D;
  color: #FFFFFF;
  border-radius: 12px;
}
.polya_line_item_name {
  color: rgba(90, 90, 90, 0.6);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.polya_line_item_value {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.polya_line_item_value a {
  color: #645EFC;
}
.polya_line_item_value.polya_line_item_value_grey {
  color: #5A5A5A;
}
.polya_line_item_value.polya_line_item_value_b {
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  color: #23232B;
}
.polya_line_item_value b {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #23232B;
}
.polya_line_item_value.polya_line_item_price {
  font-weight: 800;
}
.polya_line_item_value.polya_line_item_dark {
  color: #23232B;
}
.polya_line_item_value.polya_line_item_flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.polya_line_item_value.polya_line_item_flex svg {
  padding: 3px;
}
.chat_body {
  border-radius: 14px;
  border: 2px solid #EEEEF8;
  padding: 18px;
  background: #EEEEF8;
}
.chat_gotov {
  border-radius: 14px;
  border: 2px solid #EEEEF8;
  padding: 18px;
}
.chat_gotov .gotov_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  margin: 20px -8px -5px -5px;
  padding-right: 3px;
  height: 160px;
  overflow-x: hidden;
  overflow-y: auto;
}
.chat_gotov .gotov_wrap .gotov_item {
  margin: 5px;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 14px 14px 14px 0;
  background: #EEEEF8;
  color: #645EFC;
}
.chat_gotov + .chat_send {
  margin-top: 20px;
}
.chat_send {
  border-radius: 14px;
  border: 2px solid #EEEEF8;
  padding: 18px;
}
.chat_send textarea {
  resize: none;
  border: 0;
  height: 246px;
  padding: 0;
  width: 100%;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  outline: none;
  margin: 0;
}
.chat_send .checkbox-container {
  margin: 20px 0 20px;
}
.chat_send .btn {
  padding: 6px 10px;
  font-size: 14px;
}
.comment_item .comment_item_head {
  position: relative;
  font-weight: 800;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 8px 0 6px;
}
.comment_item .comment_item_head:before {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background: #A9C5EF;
  border-radius: 30px;
}
.comment_item .comment_manager {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #37A786;
  margin: 0 12px;
}
.comment_item .comment_item_body {
  width: 66%;
  background: #C5C3DD;
  padding: 6px 16px;
  line-height: 20px;
  font-weight: 500;
  color: #23232B;
}
.comment_item .comment_item_date {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  margin-top: 6px;
  color: rgba(90, 90, 90, 0.4);
}
.comment_item .comment_item_date .comment_date_time {
  margin: 0 6px;
}
.comment_item .comment_item_date svg {
  color: #645EFC;
  padding: 2px;
}
.comment_item.comment_item_read .comment_item_date svg {
  padding: 0;
}
.comment_item.comment_item_left .comment_item_head:before {
  margin-right: 10px;
}
.comment_item.comment_item_left .status {
  margin-left: 10px;
}
.comment_item.comment_item_left .comment_item_body {
  border-radius: 0 20px 20px 20px;
}
.comment_item.comment_item_right .comment_item_head {
  justify-content: flex-end;
}
.comment_item.comment_item_right .comment_item_head:before {
  order: 1;
}
.comment_item.comment_item_right .comment_item_body {
  border-radius: 20px 0 20px 20px;
  margin: 0 0 0 auto;
}
.comment_item .status {
  cursor: pointer;
}
.pass_grid {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}
.pass_grid .pass_grid_side {
  width: 24.8%;
  padding: 0 10px;
  order: 1;
}
.pass_grid .pass_grid_side .btn-white {
  color: #645EFC;
}
.pass_grid .pass_grid_wrap {
  width: 75.2%;
  padding: 0 10px;
}
.pass_grid .font_bold16 {
  color: #23232B;
}
.grid_grey_title {
  color: rgba(90, 90, 90, 0.6);
}
.pass_fill {
  background: #EEEEF8;
  padding: 18px;
}
.pass_ramm {
  border-radius: 20px;
  border: 2px solid #EEEEF8;
  overflow: hidden;
}
.pass_ramm.pass_ramm_cancel {
  border: 2px solid #FFC0C0;
}
.pass_ramm.pass_ramm_cancel .pass_fill {
  background: rgba(219, 57, 49, 0.1);
  padding-bottom: 16px;
}
.pass_ramm.pass_ramm_cancel .pass_route {
  border-top: 1px solid #fff;
}
.pass_ramm.pass_ramm_void {
  border: 2px solid rgba(90, 90, 90, 0.3);
}
.pass_ramm.pass_ramm_void .pass_fill {
  background: rgba(90, 90, 90, 0.1);
  padding-bottom: 16px;
}
.pass_ramm.pass_ramm_void .pass_route {
  border-top: 1px solid #fff;
}
.pass_flex {
  display: flex;
  margin: 0 -4px;
}
.pass_flex > div {
  padding: 0 4px;
}
.pass_ord_inf_line > div.pass_ord_inf_w32 {
  width: 31.9%;
}
.pass_ord_inf_line > div.pass_ord_inf_w20 {
  width: 20.1%;
}
.pass_ord_inf_line > div.pass_ord_inf_w12 {
  width: 11.8%;
}
.pass_ord_inf_line > div.pass_ord_inf_w13 {
  width: 16.3%;
}
.pass_ord_inf_line > div.pass_ord_inf_w18 {
  width: 17.9%;
}
.pass_stat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px -5px;
}
.pass_stat .btn {
  margin: 5px;
  display: flex;
  align-items: center;
}
.pass_stat .btn svg {
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.pass_stat.pass_stat_select .btn {
  opacity: 0.5;
  cursor: default;
}
.pass_stat.pass_stat_select .btn.active {
  opacity: 1;
  cursor: pointer;
}
.pass_stat_dop {
  margin: 0 -18px 0;
  padding: 16px 18px 2px;
  border-top: 1px solid #fff;
  display: flex;
}
.pass_flex_col .pass_flex_item {
  margin-top: 16px;
}
.pass_flex_duo {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
}
.pass_flex_duo .pass_flex_item {
  width: 50%;
  padding: 0 4px;
}
.pass_infflex {
  margin: 16px -2px 1px;
}
.pass_flex_infpass {
  display: flex;
  justify-content: space-between;
  margin: 16px -2px 1px;
}
.pass_flex_infpass > div {
  padding: 0 2px;
}
.pass_flex_infpass > div:nth-child(1) {
  width: 19.7%;
  max-width: 184px;
}
.pass_flex_infpass > div:nth-child(2) {
  width: 28.2%;
}
.pass_flex_infpass > div:nth-child(3),
.pass_flex_infpass > div:nth-child(4) {
  width: 15.9%;
}
.pass_flex_infpass > div:nth-child(5) {
  width: 20.3%;
}
.pass_route_wrap {
  position: relative;
}
.pass_route_wrap > .row {
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 6px;
  position: relative;
}
.pass_route_from {
  position: relative;
}
.pass_route_from:before {
  content: "";
  display: block;
  position: absolute;
  left: 24px;
  top: 55px;
  width: 2px;
  height: calc(100% - 46px);
  background: #A2A2BA;
}
.pass_route_from:after,
.pass_route_to:after {
  content: "";
  display: block;
  position: absolute;
  left: 23px;
  width: 2px;
  height: 2px;
  top: 52px;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #A2A2BA;
}
.pass_route_to:after {
  top: 11px;
}
.pass_route_from.pass_route_to .col4 {
  position: relative;
}
.pass_route_from.pass_route_to .col4:after {
  content: "";
  display: block;
  position: absolute;
  left: 22px;
  width: 2px;
  height: 2px;
  top: 48px;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #A2A2BA;
}
.pass_route_bilet .pass_route_from {
  margin-bottom: -26px;
}
.pass_route_bilet .pass_route_from:before {
  left: calc(33.3333% - 11px);
  height: calc(100% - 18px);
  top: 24px;
}
.pass_route_bilet .pass_route_from:after {
  left: calc(33.3333% - 12px);
  top: 22px;
}
.pass_route_bilet .pass_route_to:after {
  left: calc(33.3333% - 12px);
  top: 22px;
}
.pass_route_bilet .pass_route_from.pass_route_to .col4:after {
  content: none;
}
.pass_reg_cancel {
  margin-left: 6px;
}
.pass_route {
  padding: 15px 18px 16px;
  border-top: 1px solid #EEEEF8;
}
.pass_route .pass_route_city {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.pass_route .pass_route_city .pass_id,
.pass_route .pass_route_city .pass_time {
  opacity: 0.8;
  display: inline-block;
  margin-left: 2px;
}
.pass_route .pass_route_city .pass_id {
  font-weight: 800;
  color: #23232B;
}
.pass_route .pass_route_city .pass_route_city_tick {
  color: rgba(35, 35, 43, 0.8);
}
.pass_route .pass_route_city .pass_route_city_station {
  color: rgba(64, 64, 64, 0.8);
}
.pass_route .pass_ord_inf_line {
  margin-top: 16px;
}
.pass_route .pass_ord_inf_line.pass_route_oldprice .polya_line_item_price {
  text-decoration: line-through;
  color: rgba(35, 35, 43, 0.6);
}
.pass_route .pass_ord_inf_line.pass_route_newprice .polya_line_item_price {
  color: #23232B;
}
.pass_route:last-child {
  padding-bottom: 19px;
}
.pass_airport {
  display: flex;
  align-items: center;
  line-height: 20px;
}
.pass_airport img {
  display: block;
  width: 30px;
  min-width: 30px;
  margin-right: 8px;
}
.pass_airport .btn {
  background: #A2A2BA;
  border-radius: 30px;
  margin-right: 8px;
  color: #FFFFFF;
  padding: 6px;
}
.pass_airport b {
  display: block;
}
.text_ico32 {
  display: flex;
  align-items: center;
  color: #23232B;
}
.text_ico32 svg {
  background: rgba(90, 90, 90, 0.1);
  border-radius: 32px;
  margin-right: 10px;
  color: #404040;
}
.pass_great_noinf {
  color: rgba(90, 90, 90, 0.4);
  text-align: center;
  margin-top: 10px;
}
.pass_great_inf {
  line-height: 20px;
  margin-top: 10px;
}
.pass_great {
  position: relative;
  padding: 18px;
  border-radius: 20px;
}
.pass_great ul {
  padding: 0 0 0 21px;
  margin: 3px 0 0;
}
.pass_great ul li {
  padding: 2px 0;
  overflow: auto;
}
.pass_great.pass_great_empty {
  border: 2px dashed rgba(90, 90, 90, 0.1);
}
.pass_great.pass_great_orange {
  background: rgba(253, 169, 122, 0.4);
}
.pass_great.pass_great_orange .text_ico32 svg {
  background: #FFFFFF;
}
.pass_great.pass_great_green {
  background: rgba(71, 183, 149, 0.3);
}
.pass_great.pass_great_green .text_ico32 svg {
  background: #FFFFFF;
}
.pass_great.pass_great_green .pass_great_inf .btn {
  margin-top: 10px;
}
.pass_load_wrap {
  padding: 24px 18px 32px;
  background: #FFFFFF;
  text-align: center;
  line-height: 20px;
}
.pass_load_wrap .pass_load_img {
  padding: 10px 0 14px;
}
.pass_load_wrap .font_bold16 {
  padding: 10px 0;
  color: #23232B;
}
.pass_load_wrap .pass_load_red {
  color: #EE5C5C;
}
.pass_load_wrap.pass_load_success .pass_load_img,
.pass_load_wrap.pass_load_error .pass_load_img {
  margin-top: 25px;
}
.pass_load_wrap.pass_load_success .pass_load_txt,
.pass_load_wrap.pass_load_error .pass_load_txt {
  margin-bottom: 15px;
}
.span_marg_r10 b {
  font-weight: 800;
}
.span_marg_r10 span {
  margin-left: 11px;
}
.pass_ticket_chast {
  display: inline-block;
  color: rgba(90, 90, 90, 0.4);
}
.pass_ticket_slide {
  position: relative;
  margin-top: 13px;
}
.pass_ticket_slide .pass_ticket_wrap {
  overflow-y: hidden;
  overflow-x: hidden;
}
.pass_ticket_slide .pass_ticket_outer {
  display: flex;
}
.pass_ticket_slide .pass_ticket_item {
  width: 226px;
  min-width: 226px;
  height: 70px;
  padding: 4px 10px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  color: #757478;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 5px;
}
.pass_ticket_slide .pass_ticket_item span {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  opacity: 0.7;
}
.pass_ticket_slide .pass_ticket_item.active {
  background: #645EFC;
  color: #FFFFFF;
}
.pass_ticket_slide .pass_ticket_item.pass_ticket_item_all {
  width: 111px;
  min-width: 111px;
}
.pass_ticket_slide .slider_nav {
  position: absolute;
  top: -41px;
  right: 0;
}
.pass_ticket_slide .slider_nav .slider-nav {
  background: #fff;
  box-shadow: none;
  color: #404040;
}
.flex_bron_form {
  display: flex;
  margin: -4px;
}
.flex_bron_form > div {
  width: 33.3333%;
  padding: 4px;
}
.pass_bron_hotel {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.pass_bron_hotel .pass_bron_hotel_img {
  width: 160px;
  min-width: 160px;
  height: 160px;
  margin-right: 16px;
  border-radius: 20px;
  overflow: hidden;
}
.pass_bron_hotel .pass_bron_hotel_info {
  flex: 1 1 auto;
}
.pass_bron_hotel .hotel_title {
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  color: #23232B;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.pass_bron_hotel .pass_hotel_info {
  display: flex;
  justify-content: space-between;
  margin: 0 -2px;
}
.pass_bron_hotel .pass_hotel_info > div {
  min-width: 15.5%;
  padding: 0 2px;
}
.select_bron {
  font-size: 12px;
  font-weight: 500;
}
.select_bron .user_group .form_group .form_label {
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  color: rgba(90, 90, 90, 0.6);
}
.modal_side.modal_side_bron {
  height: auto;
  border-radius: 30px 30px 0 0;
  top: auto;
}
.modal_side.modal_side_bron .modal_head .checkbox-container {
  margin-left: 30px !important;
  order: 0;
}
.modal_side.modal_side_bron .zabron {
  margin: 0 60px 0 auto;
}
.modal_side.modal_side_bron .pinned_flex {
  max-height: 352px;
}
.pinned_wrap.pinned_wrap_usluga .pinned_line {
  min-width: 986px;
  width: auto;
  flex: 1 1 auto;
}
.pinned_wrap.pinned_wrap_usluga .extr_buttons {
  width: auto;
  min-width: 290px;
  border-left: 1px solid #EEEEF8;
  margin-left: 0;
  padding: 0;
}
.pinned_wrap.pinned_wrap_usluga .pinned_price {
  margin-right: 4px;
  display: block;
}
.pinned_wrap.pinned_wrap_usluga .price_flex i,
.pinned_wrap.pinned_wrap_usluga .price_flex span {
  width: auto;
}
.bold_15_dark {
  font-size: 15px;
  font-weight: 800;
  line-height: normal;
  color: #5A5A5A;
}
.marg_top_12 {
  margin-top: 12px;
}
.bron_dop_item {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}
.bron_dop_item > div {
  padding: 16px 4px;
}
.bron_dop_item > div:last-child {
  flex: 1 1 auto;
}
.bron_dop_item .bron_dop_item_title {
  width: 554px;
  display: flex;
}
.bron_dop_item .bron_dop_item_title span {
  display: block;
  width: 120px;
}
.bron_dop_item .bron_dop_item_90 {
  width: 90px;
}
.bron_dop_item .bron_dop_item_253 {
  width: 253px;
}
.bron_dop_item .bron_dop_item_name {
  width: 252px;
}
.air_mesta_wrap {
  background: rgba(90, 90, 90, 0.1);
  margin: 0 -40px -16px;
}
.air_mesta_wrap .air_mesta_body {
  width: 516px;
  height: 454px;
  padding: 6px 64px 20px;
  margin: 0 auto;
  background: #FFFFFF;
  position: relative;
}
.air_mesta_wrap .air_mesta_body .air_place_head {
  padding: 14px 27px 16px;
  background: #FFFFFF;
  border-radius: 120px 120px 0 0;
  border: 4px solid #EEEEF8;
  border-bottom: 0;
}
.air_mesta_wrap .air_mesta_body .air_place_line {
  display: flex;
  align-items: center;
}
.air_mesta_wrap .air_mesta_body .air_place_line:not(.air_place_head) {
  position: relative;
}
.air_mesta_wrap .air_mesta_body .air_place_line:not(.air_place_head):before,
.air_mesta_wrap .air_mesta_body .air_place_line:not(.air_place_head):after {
  content: "";
  width: 7px;
  height: 24px;
  background: #EEEEF8;
  border-radius: 3.5px;
  position: absolute;
  top: 10px;
}
.air_mesta_wrap .air_mesta_body .air_place_line:not(.air_place_head):before {
  left: -28px;
}
.air_mesta_wrap .air_mesta_body .air_place_line:not(.air_place_head):after {
  right: -28px;
}
.air_mesta_wrap .air_mesta_body .air_place_num {
  width: 40px;
  margin: 0 4px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 1px;
  color: rgba(90, 90, 90, 0.6);
}
.air_mesta_wrap .air_mesta_body .air_place_razd {
  width: 36px;
  margin: 0 -4px 0 1px;
}
.air_mesta_wrap .air_mesta_body .air_mesta_scroll {
  max-height: 392px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 14px;
  margin-right: -14px;
  margin-top: -8px;
}
.air_mesta_wrap .air_mesta_body .air_mesta_scroll > div {
  border-left: 4px solid #EEEEF8;
  border-right: 4px solid #EEEEF8;
  padding: 0 27px;
  width: 388px;
  margin-top: -4px;
}
.air_mesta_wrap .air_mesta_body .air_tic {
  width: 40px;
  height: 40px;
  border-radius: 14px;
  position: relative;
  font-size: 10px;
  font-weight: 800;
  line-height: 12px;
  border: 2px solid;
  padding: 16px 0 8px;
  text-align: center;
  color: #404040;
  margin: 4px;
  cursor: pointer;
}
.air_mesta_wrap .air_mesta_body .air_tic-green {
  background-color: #96DFC9;
  border-color: #96DFC9;
}
.air_mesta_wrap .air_mesta_body .air_tic-blue {
  background-color: #C4CAFD;
  border-color: #C4CAFD;
}
.air_mesta_wrap .air_mesta_body .air_tic-purple {
  background-color: #E6DAFF;
  border-color: #E6DAFF;
}
.air_mesta_wrap .air_mesta_body .air_tic-gray {
  background-color: rgba(90, 90, 90, 0.1);
  border-color: transparent;
  padding: 6px;
}
.air_mesta_wrap .air_mesta_body .air_tic-gray svg {
  color: rgba(90, 90, 90, 0.3);
}
.air_mesta_wrap .air_mesta_body .air_tic-baby {
  background-image: url(./img/icon/baby.svg);
  background-repeat: no-repeat;
  background-position: top center;
}
.air_mesta_wrap .air_mesta_body .air_tic-pets {
  background-image: url(./img/icon/pets.svg);
  background-repeat: no-repeat;
  background-position: center 4px;
}
.air_mesta_wrap .air_mesta_body .air_tic-check {
  border: 2px solid #645EFC;
}
.air_mesta_wrap .air_mesta_body .air_tic-check:after {
  content: "";
  position: absolute;
  left: 11px;
  bottom: -6px;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  background: #645EFC url(./img/icon/ico_check2.svg) no-repeat center center / 10px;
}
.modal_dop_sub {
  padding: 24px 0 20px;
  position: relative;
}
.modal_dop_sub:after {
  content: "";
  display: block;
  margin: 0 -40px;
  height: 35px;
  box-shadow: 0 6px 10px 0 rgba(101, 83, 216, 0.13);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.vibor_place_inf {
  display: flex;
  justify-content: space-between;
}
.vibor_place_inf .vibor_place_item:nth-child(1) {
  width: 254px;
}
.vibor_place_inf .vibor_place_item:nth-child(2) {
  width: 386px;
}
.vibor_place_inf .vibor_place_item:nth-child(3) {
  width: 186px;
}
.vibor_place_inf .vibor_place_item .polya_line_item_name {
  margin-bottom: 0;
}
.columns2 {
  columns: 2;
}
.form_group .check_oform {
  align-items: center;
  min-height: 48px;
}
.otchet_option.active {
  color: #FFFFFF;
  background: #645EFC;
}
.otchet_option + .fake_dropdown {
  position: absolute;
  right: -40px;
  top: 100%;
  z-index: 1;
  margin-top: 30px;
  min-width: 334px;
  border-radius: 0;
  border: 2px solid #D6D7F7;
  max-height: calc(100vh - 464px);
  overflow: auto;
  min-height: 600px;
}
.otchet_option + .fake_dropdown .flex {
  justify-content: space-between;
  padding: 5px 10px;
}
.otchet_option + .fake_dropdown .flex h5 {
  color: #5A5A5A;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.otchet_option + .fake_dropdown .flex h5 img {
  margin-right: 8px;
}
.otchet_option + .fake_dropdown .flex .btn {
  background: #E3E5F6;
  color: #404040;
}
.otchet_option + .fake_dropdown .checkbox-container {
  line-height: 18px;
}
.otchet_option + .fake_dropdown .checkbox-container:after {
  content: "";
  position: absolute;
  top: -3px;
  left: 22px;
  width: 25px;
  height: 24px;
  background: url(./img/icon/ravno.svg) no-repeat center center;
}
.otchet_option + .fake_dropdown .checkbox-container .checkmark {
  margin-right: 33px;
}
.otchet_option + .fake_dropdown .suggestion-block + .flex {
  margin-top: 14px;
}
.but_sum li {
  background: none !important;
}
.but_sum .btn {
  padding: 6px 16px;
  width: 100%;
  justify-content: space-between;
}
.but_sum .btn span {
  display: block;
  text-align: left;
  width: 193px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.but_sum .btn span:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  min-width: 25px;
  height: 24px;
  margin-right: 10px;
  background: url(./img/icon/ravno.svg) no-repeat center center;
}
@media (max-width: 1439px) {
  .ord_order {
    width: 94px;
  }
  .ord_date_order,
  .ord_date_travel {
    width: 87px;
  }
  .ord_clients {
    width: 90px;
  }
  .ord_clients span {
    justify-content: center;
  }
  .ord_amount {
    width: 100px;
  }
  .ord_company {
    width: 90px;
  }
  .ord_route {
    width: 90px;
  }
  .ord_attention {
    width: 75px;
  }
  .ord_status {
    width: 125px;
  }
  .pinned_wrap.pinned_wrap_usluga .extr_buttons {
    min-width: 170px;
  }
}
@media (max-width: 1299px) {
  .air_mesta_wrap .air_mesta_body {
    height: calc(100vh - 132px);
  }
  .air_mesta_wrap .air_mesta_body .air_mesta_scroll {
    max-height: calc(100% - 24px);
  }
  .pinned_wrap.pinned_wrap_usluga .pinned_line {
    width: calc(100% - 210px);
    min-width: 0;
  }
  .block_widget_menu_fix {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 1199px) {
  .head_ico_date {
    position: relative;
  }
  .head_ico_date .head_ico_date_head {
    width: 100%;
  }
  .head_ico_date .head_ico_date_head .head_ico_date_title {
    flex: 1 1 auto;
  }
  .head_ico_date .head_ico_date_head .head_ico_date_title h1 .btn {
    margin-left: auto;
  }
  .head_ico_date .head_ico_date_right {
    flex-direction: row;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .print_version.print_svg {
    margin: 0 10px 0 0;
  }
  .polya_line {
    flex-wrap: wrap;
    padding: 8px;
  }
  .polya_line > div:nth-child(n) {
    width: 33.3333%;
    padding: 10px;
  }
  .pass_grid {
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
  }
  .pass_grid .pass_grid_side {
    width: calc(100% + 20px);
    padding: 0;
    order: 0;
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
  }
  .pass_grid .pass_grid_side > div {
    width: calc(50% - 20px);
    margin: 0 10px;
  }
  .pass_grid .pass_grid_wrap {
    width: 100%;
    padding: 0;
  }
  .pass_infflex {
    margin: 4px -10px 4px;
  }
  .pass_flex_infpass {
    flex-wrap: wrap;
    margin: 4px -10px -4px;
  }
  .pass_flex_infpass > div {
    width: 33.3333% !important;
    padding: 10px;
  }
  .pass_flex {
    flex-wrap: wrap;
    margin: 4px -10px -10px;
  }
  .pass_flex > div {
    width: 33.3333% !important;
    padding: 10px;
  }
  .pass_flex > div.pass_ord_wide {
    width: 66.6666% !important;
  }
  .pass_route .pass_ord_inf_line {
    margin-top: 7px;
  }
  .pass_bron_hotel .pass_hotel_info {
    flex-wrap: wrap;
    margin: -10px;
  }
  .pass_bron_hotel .pass_hotel_info > div {
    padding: 10px;
    width: 35%;
  }
  .pass_bron_hotel .pass_hotel_info > div:nth-child(2n+2) {
    width: 65%;
  }
  .modal_side.modal_side_bron {
    height: 100%;
    border-radius: 0;
    top: 0;
  }
  .modal_side.modal_side_bron .modal_head {
    padding-left: 0;
  }
  .modal_side.modal_side_bron .modal_head .checkbox-container {
    margin-left: 0 !important;
    order: -1;
  }
  .modal_side.modal_side_bron .zabron {
    margin: 0 10px;
  }
  .modal_side.modal_side_bron .pinned_flex {
    max-height: calc(100vh - 83px);
  }
  .bron_dop_item {
    flex-wrap: wrap;
  }
  .bron_dop_item .bron_dop_item_title {
    width: 100%;
    border-bottom: 1px solid #EEEEF8;
  }
  .bron_dop_item .bron_dop_item_title span {
    width: 21%;
  }
  .bron_dop_item .bron_dop_item_90,
  .bron_dop_item .bron_dop_item_name {
    width: 25%;
  }
  .bron_dop_item .bron_dop_item_253 {
    width: 37%;
  }
  .pinned_wrap.pinned_wrap_usluga .extr_buttons {
    width: calc(25% - 9.5px);
    flex: initial;
    border: none;
    position: absolute;
    bottom: 1px;
    right: 0;
    height: 50px;
  }
  .pinned_wrap.pinned_wrap_usluga .pinned_price {
    display: block;
  }
  .modal_dop_sub {
    padding: 19px 0 20px;
  }
  .modal_dop_sub .vibor_place_item:nth-child(1) {
    width: 266px;
  }
  .modal_dop_sub .vibor_place_item:nth-child(2) {
    width: 264px;
  }
  .modal_dop_sub .vibor_place_item:nth-child(3) {
    width: 170px;
  }
  .air_mesta_wrap {
    margin: 0 -14px -16px;
  }
  .air_mesta_wrap .air_mesta_body {
    padding: 24px calc(50% - 208px) 20px;
    width: calc(100% - 28px);
    margin: 0 14px;
  }
  .table_scrol {
    margin-right: -14px;
  }
  .otchet_option + .fake_dropdown {
    right: -24px;
  }
}
@media (max-width: 991px) {
  .orders_item {
    flex-wrap: wrap;
    padding: 4px 14px 5px;
  }
  .orders_item > div {
    display: flex;
    align-items: flex-start;
    height: 58px;
    flex-direction: column;
    justify-content: center;
  }
  .orders_item > div:before {
    display: block;
    width: 100%;
    color: rgba(90, 90, 90, 0.6);
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 4px;
  }
  .orders {
    padding: 0;
  }
  .orders_table {
    margin: 15px 0 0;
  }
  .pagination_wrap {
    margin: 20px 0 0;
  }
  .orders_head {
    display: none;
  }
  .ord_order,
  .ord_route {
    width: 19.7%;
  }
  .ord_date_order,
  .ord_pass {
    width: 19.7%;
  }
  .ord_status,
  .ord_clients {
    width: 23.2%;
  }
  .ord_attention,
  .ord_amount {
    width: 16.6%;
    align-items: flex-start !important;
  }
  .ord_date_travel,
  .ord_company {
    width: 20.8%;
  }
  .ord_route,
  .ord_date_travel {
    line-height: 1;
  }
  /*.ord_order:before {
    content: "заказ";
  }
  .ord_date_order:before {
    content: "дата заказа";
  }
  .ord_status:before {
    content: "Статус";
  }
  .ord_attention:before {
    content: "важно";
  }
  .ord_date_travel:before {
    content: "даты поездки";
  }
  .ord_route:before {
    content: "Маршрут";
  }
  .ord_pass:before {
    content: "пассажиры";
  }
  .ord_clients:before {
    content: "число клиентов";
  }
  .ord_amount:before {
    content: "сумма";
  }
  .ord_company:before {
    content: "Компания";
  }*/
  .orders_table_attrmob .orders_item > div:before {
    content: attr(data-namecol);
  }
  .block_widget .widget_head_info {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
  .block_widget .widget_head_info .block_widget_switch + .block_widget_checkbox {
    margin: 10px  0 0;
  }
  .block_widget .widget_head_but {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
  }
  .block_widget .widget_head_but .extr_toggle {
    order: -1;
    margin-bottom: 17px;
  }
  .block_widget .col3 {
    width: 50%;
  }
  form .flex_group {
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .flex_title_but.flex_title_but_left {
    margin: 0 -6px;
  }
  .flex_title_but.flex_title_but_left .btn {
    border-radius: 12px;
    padding: 6px 12px;
  }
  .flex_title_but.flex_title_but_left > * {
    margin: 0 6px;
  }
  .block_widget_type {
    margin: 0 -3px;
  }
  .block_widget_type div {
    padding: 5px 5px 5px 8px;
    font-size: 12px;
    line-height: 18px;
    border-radius: 8px;
  }
  .block_widget_type div a {
    margin-left: 1px;
  }
  .block_widget_type div a svg {
    width: 16px;
    height: 16px;
  }
  .just_input.row {
    flex-wrap: nowrap;
    margin: -10px -7px;
  }
  .just_input.row > div {
    padding: 10px 7px;
  }
  .just_input .input_type {
    padding: 9px 35px 9px 11px;
  }
  .just_input .input_type .inp_open_type {
    top: 12px;
    right: 12px;
    padding: 3px 3px;
  }
  .modal_side_otchet .just_input.row {
    margin: 0 0 0 auto;
  }
  .modal_side_otchet .table_tip_lonn td {
    padding: 6px 6px;
  }
  .inp_date .input_field {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 36px;
  }
  .table_scrol {
    margin-right: -14px;
  }
  .modal_side_scroll {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 100px);
  }
  .list_check_col {
    columns: 3;
  }
  .block_widget_menu_gray .block_widget_menu {
    margin-top: -5px;
    width: 100%;
  }
  .head_ico_date .head_ico_date_head .head_ico_date_title h1 {
    font-size: 26px;
  }
  .head_ico_date .head_ico_date_head span.btn {
    padding: 14px 16px;
  }
  .polya_line {
    flex-wrap: wrap;
    padding: 8px 16px;
  }
  .btn-inl_width {
    width: 100%;
  }
  .table_tip_bron {
    table-layout: fixed;
  }
  .table_tip_bron th {
    width: 21%;
  }
  .table_tip_bron th:nth-child(1) {
    width: 37%;
  }
  .table_tip_bron td:nth-child(2) {
    white-space: normal;
    word-break: break-all;
  }
  .table_tip_lonn th {
    font-size: 12px;
    line-height: 18px;
    padding: 9px 6px;
    height: auto;
    vertical-align: top;
  }
  .table_tip_lonn th:first-child {
    padding: 9px 11px;
    min-width: 40px;
  }
  .table_tip_lonn td {
    padding: 9.5px 6px;
    font-size: 12px;
    line-height: 18px;
    max-width: 210px;
  }
  .table_tip_lonn td.wide_td {
    min-width: 210px;
  }
  .table_tip_lonn td:first-child {
    padding: 9.5px 11px;
    min-width: 40px;
  }
  .usesort {
    justify-content: flex-start;
    flex-direction: column;
  }
  .usesort .sort {
    order: -1;
  }
  .comment_item .comment_item_body {
    width: 74%;
  }
  .comment_item .status {
    display: none;
  }
  .chat_wrap .col4,
  .chat_wrap .col8 {
    width: 100%;
  }
  .chat_gotov .gotov_wrap {
    height: 78px;
  }
}
@media (max-width: 767px) {
  .orders_item {
    padding: 4px 10px 5px;
    justify-content: flex-start;
  }
  .ord_order,
  .ord_date_order,
  .ord_date_travel,
  .ord_route,
  .ord_amount,
  .ord_company {
    width: 25.3%;
  }
  .ord_status,
  .ord_pass {
    width: 27.7%;
  }
  .ord_attention,
  .ord_clients {
    width: 21.7%;
    padding-right: 0 !important;
  }
  .pagination_wrap {
    flex-wrap: wrap;
    justify-content: center;
  }
  .pagination_wrap .pagination_view {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .block_widget {
    margin-top: 20px;
  }
  .block_widget .widget_inputs {
    max-height: none;
  }
  form .flex_group {
    margin-bottom: 20px;
    justify-content: flex-start;
  }
  .flex_title_but.flex_title_but_left {
    margin: 20px -6px 0;
    width: calc(100% + 20px);
  }
  .flex_title_but.flex_title_but_left .btn-fix .btn {
    width: auto;
  }
  .flex_title_but.flex_title_but_left > * {
    flex: inherit;
  }
  .just_input.row {
    flex-wrap: wrap;
    margin: -7px;
  }
  .just_input.row > div {
    padding: 7px;
  }
  .just_input.row .form_group {
    width: 50%;
  }
  .just_input.row > div:not(.form_group) {
    order: 1;
    margin-top: 8px;
    margin-bottom: 6px;
  }
  .widget_inputs_icon .just_input.row {
    flex-wrap: nowrap;
  }
  .widget_inputs_icon .just_input.row > div:not(.form_group) {
    margin-top: 0;
    margin-bottom: 0;
  }
  .table_scrol {
    margin-right: -20px;
  }
  .modal_side_scroll {
    max-height: calc(100vh - 120px);
  }
  .list_check_col {
    columns: 2;
  }
  .block_widget_menu_gray .block_widget_menu {
    border-radius: 30px;
    margin: -10px 0 30px;
  }
  .head_ico_date {
    flex-wrap: wrap;
  }
  .head_ico_date .head_ico_date_head {
    align-items: flex-start;
  }
  .head_ico_date .head_ico_date_head span.btn {
    padding: 16px;
    /*& .icon32 {
          width: 24px;
          height: 24px;
        }*/
  }
  .head_ico_date .print_version {
    order: -1;
    margin: 0 0 18px auto;
    display: block;
  }
  .head_ico_date .head_ico_date_right .print_version {
    margin: 0;
  }
  .polya_line {
    padding: 8px 16px;
  }
  .polya_line > div:nth-child(n) {
    width: 50%;
    padding: 10px 4px;
  }
  .btn-inl_width {
    padding: 10px 16px;
  }
  .table_tip_bron {
    white-space: normal;
  }
  .table_tip_bron th {
    width: 23%;
    padding: 14px;
    height: 68px;
  }
  .table_tip_bron th:nth-child(1) {
    width: 31%;
  }
  .table_tip_bron td {
    padding: 14px;
    height: 86px;
  }
  .table_tip_bron td span.btn {
    padding: 5px;
  }
  .comment_item .comment_item_head {
    margin: 20px 0 6px;
  }
  .comment_item .comment_item_body {
    width: 100%;
  }
  .chat_send textarea {
    height: 152px;
  }
  .pass_stat_dop {
    padding: 16px 18px 0;
  }
  .pass_flex_infpass > div {
    width: 50% !important;
  }
  .pass_flex > div {
    width: 50% !important;
  }
  .pass_route_wrap .col4 {
    width: 50%;
  }
  .pass_route_wrap .col8 {
    width: 50%;
  }
  .pass_route_wrap .col8 .col6 + .col6 {
    padding-top: 0;
  }
  .pass_route_double {
    display: flex;
    flex-wrap: wrap;
  }
  .pass_route_double .font_bold16 {
    width: 100%;
  }
  .pass_route_double .pass_flex {
    width: 50%;
  }
  .pass_route_double .pass_flex > div {
    width: 100% !important;
    height: 62px;
  }
  .pass_route_bilet .pass_route_from {
    margin-bottom: -10px;
  }
  .pass_route_bilet .pass_route_from:before {
    left: calc(50% - 5px);
    height: calc(100% - 5px);
  }
  .pass_route_bilet .pass_route_from:after {
    left: calc(50% - 7px);
  }
  .pass_route_bilet .pass_route_to:after {
    left: calc(50% - 7px);
  }
  .pass_bron_hotel {
    align-items: flex-start;
  }
  .pass_bron_hotel .pass_bron_hotel_img {
    width: 100px;
    min-width: 100px;
    height: 100px;
  }
  .pass_bron_hotel .pass_hotel_info > div:nth-child(n) {
    width: 100%;
  }
  .pass_flex > div.pass_ord_wide {
    width: 100% !important;
  }
  .modal_side.modal_side_bron .modal_head .checkbox-container {
    order: 0;
    margin-top: 19px;
  }
  .modal_side.modal_side_bron .modal_head .modal_close {
    position: absolute;
    top: 5px;
    right: 11px;
  }
  .modal_side.modal_side_bron .zabron {
    margin: 17px 0 0;
    width: auto;
  }
  .modal_side.modal_side_bron .zabron .btn {
    padding: 6px 20px;
  }
  .air_mesta_wrap {
    margin: 0 -20px -16px;
  }
  .air_mesta_wrap .air_mesta_body {
    padding: 10px calc(50% - 180px) 20px;
    width: 100%;
    margin: 0;
    height: calc(100vh - 206px);
  }
  .air_mesta_wrap .air_mesta_body .air_place_head {
    padding: 35px 13px 16px;
    border-radius: 56px 56px 0 0;
  }
  .air_mesta_wrap .air_mesta_body .air_mesta_scroll {
    padding-right: 0;
    margin-right: 0;
    max-height: calc(100% - 46px);
  }
  .air_mesta_wrap .air_mesta_body .air_mesta_scroll > div {
    padding: 0 13px;
    width: 360px;
  }
  .air_mesta_wrap .air_mesta_body .air_place_line:not(.air_place_head):before {
    left: -14px;
  }
  .air_mesta_wrap .air_mesta_body .air_place_line:not(.air_place_head):after {
    right: -14px;
  }
  .modal_dop_sub {
    padding: 11px 0 23px;
  }
  .vibor_place_inf {
    flex-wrap: wrap;
  }
  .vibor_place_inf .vibor_place_item:nth-child(1) {
    width: 100%;
    margin-bottom: 11px;
  }
  .vibor_place_inf .vibor_place_item:nth-child(2) {
    width: 55%;
  }
  .vibor_place_inf .vibor_place_item:nth-child(3) {
    width: 45%;
    padding-left: 10px;
  }
  .columns2 {
    columns: 1;
  }
  .form_group_center > .btn + .btn {
    margin-left: 8px;
  }
  .form_group_center > .btn + .btn span {
    display: none;
  }
  .otchet_option + .fake_dropdown {
    right: -30px;
  }
}
@media (max-width: 575px) {
  .head_ico_date .head_ico_date_head span.btn {
    padding: 6px;
  }
  .head_ico_date .head_ico_date_head span.btn .icon32 {
    width: 24px;
    height: 24px;
  }
  .head_ico_date .head_ico_date_right {
    position: relative;
    margin-left: 76px;
  }
  .news .blocks_head {
    flex-wrap: wrap;
  }
  .news .blocks_button {
    margin-top: 10px;
    justify-content: space-between;
    width: 100%;
  }
  .news .blocks_button .fake_select {
    padding: 5px 0;
  }
  .news .blocks_button .sort_wrap + .main_but {
    margin-left: 0;
    padding: 6px 17px;
  }
  .newstbl_item {
    flex-wrap: wrap;
  }
  .newstbl_item .newstbl_text {
    width: 100%;
    padding-bottom: 0px;
  }
  .newstbl_item .newstbl_date {
    width: 130px;
  }
  .orders_item > div {
    width: 50% !important;
  }
  .block_widget {
    margin-top: 20px;
  }
  .block_widget .block_widget_head {
    justify-content: flex-start;
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
  }
  .block_widget .widget_head_info {
    width: 100%;
    text-align: center;
  }
  .block_widget .widget_head_info .block_widget_switch {
    flex-direction: column;
    width: 100%;
  }
  .block_widget .widget_head_info .block_widget_switch + .block_widget_checkbox {
    width: 100%;
  }
  .block_widget .widget_head_info .block_widget_switch + .block_widget_checkbox .checkbox-container {
    width: 50%;
  }
  .block_widget .widget_head_but {
    align-items: center;
    order: -1;
    width: 100%;
    margin-bottom: 10px;
  }
  .block_widget .widget_head_but > * {
    margin: 0;
    width: 100%;
    text-align: center;
  }
  .block_widget .widget_head_but .extr_toggle {
    margin-bottom: 10px;
  }
  .block_widget .widget_head_but .extr_toggle span b {
    display: inline;
  }
  .block_widget .col3 {
    width: 100%;
  }
  .menu_flex {
    flex-wrap: wrap;
    margin: -6px 0;
  }
  .menu_flex a {
    margin: 6px 0;
  }
  .just_input.row .form_group {
    width: 100%;
  }
  .fake_wrap_subduo .fake_dropdown .fake_wrap a {
    flex-wrap: nowrap;
  }
  .inp_search {
    flex-wrap: wrap;
  }
  .inp_search .inp_search_ico {
    width: 100%;
  }
  .inp_search .inp_search_btn {
    width: 100%;
    margin: 20px 0 0;
  }
  .table_tip {
    white-space: normal;
  }
  .table_tip tr th {
    padding: 8px 11px;
    font-size: 10px;
  }
  .table_tip tr td {
    padding: 8px 7px;
    font-size: 10px;
    line-height: 14px;
    word-break: break-word;
  }
  .table_tip tr td a {
    flex-wrap: wrap;
  }
  .table_tip tr td > span {
    flex-wrap: wrap;
  }
  .table_tip tr td span.btn {
    padding: 6px;
    margin-right: 0;
    margin-bottom: 8px;
  }
  .table_tip_doc {
    display: block;
  }
  .table_tip_doc thead,
  .table_tip_doc tbody {
    display: block;
  }
  .table_tip_doc tr {
    display: grid;
    grid-template-columns: 17.5% 20% 28.5% 34%;
    grid-auto-rows: 35px;
  }
  .table_tip_doc tr th {
    width: auto !important;
    white-space: nowrap;
  }
  .table_tip_doc tr td {
    width: auto !important;
  }
  .table_tip_doc tr td .table_tip_num {
    white-space: nowrap;
    justify-content: center;
  }
  .table_tip_doc tbody tr {
    grid-auto-rows: 73px;
  }
  .table_tip_doc th,
  .table_tip_doc td {
    display: flex;
    align-items: center;
    height: auto;
  }
  .table_tip_doc th:nth-child(1),
  .table_tip_doc td:nth-child(1) {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .table_tip_doc th:nth-child(2),
  .table_tip_doc td:nth-child(2) {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .table_tip_doc th:nth-child(3),
  .table_tip_doc td:nth-child(3) {
    order: 3;
  }
  .table_tip_doc th:nth-child(4),
  .table_tip_doc td:nth-child(4) {
    order: 1;
  }
  .table_tip_doc th:nth-child(5),
  .table_tip_doc td:nth-child(5) {
    order: 2;
  }
  .table_tip_doc th:nth-child(6),
  .table_tip_doc td:nth-child(6) {
    order: 4;
  }
  .table_tip_doc th {
    border: 1px solid #FFFFFF;
    border-top-color: #E8EEF6;
    border-bottom-color: #E8EEF6;
  }
  .table_tip_doc th:nth-child(2) {
    justify-content: center;
  }
  .table_tip_doc th:nth-child(3) {
    order: 3;
    border-top-color: #FFFFFF;
  }
  .table_tip_doc th:nth-child(4) {
    order: 1;
    border-bottom-color: #FFFFFF;
  }
  .table_tip_doc th:nth-child(5) {
    order: 2;
    border-bottom-color: #FFFFFF;
    border-right-color: #E8EEF6;
  }
  .table_tip_doc th:nth-child(6) {
    order: 4;
    border-top-color: #FFFFFF;
  }
  .table_tip_lonn tr th {
    font-size: 12px;
    line-height: 18px;
    padding: 9px 6px;
  }
  .table_tip_lonn tr td {
    padding: 9.5px 6px;
    font-size: 12px;
    line-height: 18px;
    word-break: normal;
  }
  .list_check_col {
    columns: 1;
  }
  .table_scrol_head_mob tbody {
    max-height: 399px;
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    width: 100%;
  }
  .table_scrol_head_mob tbody tr {
    display: flex;
  }
  .table_scrol_head_mob tbody tr td {
    display: flex;
    align-items: center;
  }
  .table_scrol_head_mob .table_tip_bron {
    display: block;
  }
  .table_scrol_head_mob .table_tip_bron tr td {
    width: 24.3%;
  }
  .table_scrol_head_mob .table_tip_bron tr td:nth-child(1) {
    width: 27.1%;
  }
  .block_widget_menu_gray .block_widget_menu {
    margin: 0 0 30px;
  }
  .head_ico_date .head_ico_date_head .head_ico_date_title h1 {
    font-size: 22px;
    line-height: 28px;
  }
  .polya_line {
    padding: 10px 16px;
  }
  .polya_line > div:nth-child(n) {
    width: 100%;
    padding: 7px 4px;
  }
  .table_tip_bron th {
    height: 60px;
    line-height: 14px;
    width: 24.3%;
  }
  .table_tip_bron th:nth-child(1) {
    width: 27.1%;
  }
  .table_tip_bron tr td {
    height: auto;
    padding: 5px 6px;
  }
  .comment_item .comment_item_head {
    line-height: 18px;
    font-size: 12px;
  }
  .comment_item .comment_item_head:before {
    width: 24px;
    height: 24px;
  }
  .comment_item .comment_item_head_manag {
    color: #37A786;
  }
  .comment_item .comment_manager {
    display: none;
  }
  .comment_item .comment_item_body {
    line-height: 18px;
    font-size: 12px;
  }
  .comment_item.comment_item_right .comment_item_head:before {
    margin-left: 10px;
  }
  .pass_grid .pass_grid_side {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    flex-wrap: wrap;
  }
  .pass_grid .pass_grid_side > div {
    width: 100%;
    margin: 0;
  }
  .pass_grid .pass_grid_side > div + div {
    margin-top: 20px;
  }
  .pass_stat .btn {
    padding: 6px 16px;
  }
  .span_marg_r10 b {
    display: block;
    margin-bottom: 6px;
  }
  .span_marg_r10 .inf_ticket_num {
    margin-left: 0;
  }
  .span_marg_r10 .status {
    margin: 6px 50% 0 0;
  }
  .pass_ramm_inform .pass_fill {
    background: #fff;
  }
  .pass_infflex {
    margin: 8px 0 -11px;
  }
  .pass_flex_infpass {
    margin: 8px 0 -11px;
  }
  .pass_flex_infpass > div {
    width: 100% !important;
    padding: 8px 0;
  }
  .pass_route {
    padding: 9px 18px 10px;
  }
  .pass_route .pass_ord_inf_line {
    margin-top: 10px;
  }
  .pass_route_wrap > .row {
    padding-left: 20px;
    margin-top: 3px;
  }
  .pass_route_wrap .col4 {
    width: 100%;
  }
  .pass_route_wrap .col8 {
    width: 100%;
    padding-top: 0;
  }
  .pass_route_from:after {
    left: 11px;
    top: 33px;
  }
  .pass_route_to:after {
    left: 11px;
    top: 30px;
  }
  .pass_route_from:before {
    left: 13px;
    top: 36px;
    height: calc(100% - 8px);
  }
  .pass_flex {
    margin-left: 0;
    margin-right: 0;
  }
  .pass_flex > div {
    width: 100% !important;
    padding: 7px 0;
  }
  .pass_route_bilet .pass_route_from {
    margin-bottom: -10px;
  }
  .pass_route_bilet .pass_route_from:before {
    left: 13px;
    height: calc(100% - 5px);
    top: 36px;
  }
  .pass_route_bilet .pass_route_from:after {
    left: 11px;
    top: 33px;
  }
  .pass_route_bilet .pass_route_to:after {
    left: 11px;
    top: 33px;
  }
  .pass_flex_duo {
    margin: 0;
  }
  .pass_flex_duo .pass_flex_item {
    width: 100%;
    padding: 0;
  }
  .pass_bron_hotel {
    flex-wrap: wrap;
  }
  .pass_bron_hotel .pass_bron_hotel_img {
    margin: 0 0 16px;
  }
  .pass_bron_hotel .hotel_title {
    margin-bottom: 10px;
  }
  .pass_bron_hotel .pass_hotel_info {
    margin: -7px 0;
  }
  .pass_bron_hotel .pass_hotel_info > div {
    padding: 7px 0;
  }
  .bron_dop_item > div {
    padding: 10px 3px;
  }
  .bron_dop_item .bron_dop_item_title {
    font-size: 13px;
    border-bottom: 0;
  }
  .bron_dop_item .bron_dop_item_title span {
    width: 35%;
  }
  .bron_dop_item .bron_dop_item_90 {
    width: 30%;
  }
  .bron_dop_item .bron_dop_item_90 + .bron_dop_item_90 {
    width: 23%;
  }
  .bron_dop_item .bron_dop_item_name {
    width: 47%;
  }
  .bron_dop_item .bron_dop_item_253 {
    width: 50%;
  }
  .pinned_wrap.pinned_wrap_usluga .extr_buttons {
    width: 100%;
    border-top: 1px solid #EEEEF8;
    position: relative;
    bottom: 0;
    height: 46px;
    padding-right: 0 !important;
    margin-bottom: 5px;
  }
  .modal_side_bron .pinned + .pinned {
    margin-top: 0;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.regdetail_wrap {
  display: flex;
  margin: 0 -10px;
}
.regdetail_wrap > div {
  padding: 0 10px;
}
.regdetail_wrap > div > div {
  border-radius: 20px;
  background: #E3E5F6;
  padding: 30px 20px;
  height: 100%;
}
.regdetail_wrap .regdetail .reg_title {
  color: #404040;
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
}
.regdetail_wrap .regdetail .detail_wrap {
  display: flex;
  padding: 0;
  max-height: none;
  align-items: flex-start;
  margin-top: 20px;
}
.regdetail_wrap .regdetail .detail_wrap .detail_item .detail_airport {
  flex-wrap: wrap;
}
.regdetail_wrap .regdetail .detail_wrap .detail_item .detail_airport .detail_airport_val {
  width: 100%;
  margin-bottom: 5px;
}
.regdetail_wrap .regdetail .detail_item {
  width: 39.3%;
  max-width: 500px;
}
.regdetail_wrap .regdetail .detail_item .detail_line .detail_path {
  margin-top: 7px;
}
.regdetail_wrap .regdetail .detail_item:first-child {
  margin: 0 auto 0 0;
}
.regdetail_wrap .regdetail .detail_peresad {
  width: 21.4%;
  max-width: 172px;
  padding: 0 20px;
  margin: 0;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.regdetail_wrap .regdetail .detail_peresad div {
  width: 100%;
  margin-top: 6px;
  text-align: center;
}
.regdetail_wrap .regdetail .detail_peresad span {
  margin: 0;
}
.regdetail_wrap .regdetail .detail_peresad svg {
  margin-right: 4px;
}
.regdetail_wrap .regbonus {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.regdetail_wrap .regbonus > b {
  display: block;
  margin-bottom: 6px;
}
.user_group {
  margin-top: 20px;
  padding: 28px 20px;
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.user_group .form_group .form_label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 800;
  line-height: 20px;
  margin-bottom: 6px;
  display: block;
}
.user_group .sub_group {
  display: flex;
  margin-top: 10px;
}
.user_group .sub_group > * {
  width: 50%;
}
.user_group .sub_group .take_num {
  width: 100%;
}
.user_group .fake_select {
  color: #404040;
  padding: 14px 12px;
  justify-content: space-between;
  border-radius: 14px;
  border: 1px solid rgba(90, 90, 90, 0.3);
}
.user_group.user_group_vidl {
  border-color: #C9B0FE;
}
.user_group.user_group_nopad {
  padding: 0;
  border-radius: 0;
  border: none;
}
.user_group .form_group .input_value {
  display: none;
}
.user_group .form_group.sel_value .input_value {
  display: block;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
}
.user_group .form_group.sel_value input {
  display: none;
}
.user_group.user_group_alert {
  border: 2px solid #FDBF9B;
  background: #FFF2EB;
}
.user_group.user_group_alert .user_group_head {
  margin-bottom: 0;
}
.user_group.user_group_alert .user_group_head > .btn {
  margin: 0 0 0 20px;
  width: 304px;
  text-align: center;
  max-width: 100%;
}
.user_group.user_group_alert h5 {
  width: 66.6666%;
}
.user_group.user_group_alert h5 div {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 14px;
}
.user_group_scroll {
  max-height: 226px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 8px !important;
  margin-right: -8px;
}
.user_group_scroll .form_group + .form_group {
  margin-top: 12px;
}
.user_group_head {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 14px;
}
.user_group_head h5 {
  color: #404040;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  padding: 4px 0;
  width: 33.3333%;
}
.user_group_head.user_group_head_single h5 {
  padding: 0;
  width: 100%;
}
.user_group_head .alert {
  width: 33.3333%;
}
.user_group_head .form_group_center {
  width: 33.3333%;
  justify-content: flex-end;
}
.user_group_head .form_group_center .btn {
  border-radius: 14px;
}
.user_group_head > .btn {
  margin: 0 0 0 auto;
  border-radius: 20px;
}
.user_group_head .sort_wrap {
  margin: 0 0 0 auto;
}
.user_group_head .sort_wrap .fake_select {
  border: 0;
  padding: 5px 0;
}
.user_group_withhead .user_group_head {
  margin: -28px -20px 28px;
  border-radius: 18px 18px 0 0;
  padding: 18px 20px;
}
.user_group_withhead .user_group_head h5 {
  color: #5A5A5A;
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  width: auto;
}
.user_group_withhead.user_group_noline .user_group_head {
  margin: 0;
  padding: 18px 16px;
}
.user_group_withhead.user_group_noline .grid_table_col4 {
  margin: 0;
}
.form_group_center .btn {
  border-radius: 20px;
}
.group_input {
  display: flex;
  margin: 0 -3px;
}
.group_input-duo > div {
  width: 116px;
  padding: 0 3px;
}
.group_input-duo > div + div {
  width: calc(100% - 116px);
}
.group_input-trio > div {
  width: 33.3333%;
  padding: 0 3px;
}
.dupl_group {
  display: flex;
  align-items: center;
}
.dupl_group span {
  display: block;
  color: #5A5A5A;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
}
.dupl_group .btn {
  padding: 14px 16px;
  min-width: 136px;
  text-align: center;
  margin-left: 6px;
  border-radius: 20px;
}
.mili_group {
  display: flex;
  align-items: center;
}
.mili_group span {
  display: block;
  color: rgba(90, 90, 90, 0.6);
  font-weight: 500;
  line-height: 20px;
}
.mili_group .btn {
  padding: 14px 16px;
  min-width: 216px;
  text-align: center;
  margin-right: 20px;
}
.card_mili {
  display: flex;
  align-items: center;
}
.card_mili .btn:not(.fake_select) {
  margin-left: 6px;
  padding: 6px 7px;
  min-width: 99px;
}
.card_mili .fake_wrap {
  width: 100%;
}
.pay_group {
  display: flex;
  margin: 0 -10px;
  padding: 10px 0;
}
.pay_group label {
  width: 50%;
  padding: 5px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user_group_zaezd .form_group {
  margin-top: 14px;
}
.user_group_zaezd .form_group:first-child {
  margin-top: 0;
}
.user_group_zaezd .form_group .sub_group {
  align-items: center;
  margin-top: 0;
}
.user_footer {
  margin: 54px -20px -30px;
  border-radius: 0 0 20px 20px;
  background: #E3E5F6;
  padding: 20px 20px 30px;
}
.user_footer .row {
  align-items: flex-end;
}
.user_footer .btn {
  width: 100%;
  display: block;
  text-align: center;
}
.user_footer.user_footer_hotel {
  margin-top: 20px;
}
.user_footer.user_footer_hotel .row {
  align-items: flex-start;
}
.user_footer.user_footer_hotel .fprice_line + .fprice_line {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 0;
}
.user_footer.user_footer_hotel .fprice_line.fprice_line_itog {
  border-bottom: 1px solid rgba(100, 94, 252, 0.4);
  font-size: 18px;
  line-height: 24px;
  padding: 12px 0 24px;
}
.user_footer.user_footer_hotel .fprice_line.fprice_line_itog + .fprice_line {
  padding-top: 2px;
  padding-bottom: 2px;
}
.user_footer.user_footer_hotel .fprice_line.fprice_line_inf {
  font-size: 13px;
  padding-bottom: 0;
}
.user_footer.user_footer_hotel .fprice_line.fprice_line_allrt {
  font-size: 12px;
  line-height: 18px;
}
.user_footer.user_footer_hotel .privacy {
  margin-top: 20px;
}
.user_footer.user_footer_hotel .btn {
  margin-top: 25px;
}
.pass_bron {
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: background-color 150ms, color 150ms ease-in-out;
  white-space: nowrap;
  width: 100%;
}
.pass_bron ul {
  margin: 0;
  padding: 0;
  transition: transform 300ms cubic-bezier(0, 0.65, 0.7, 0.9);
  transform-style: preserve-3d;
}
.pass_bron li {
  --rotateX: 0deg;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  transition: opacity 150ms;
  transform-origin: 50% 50%;
  transform: rotateX(var(--rotateX)) translateZ(10px);
  width: 100%;
}
.pass_bron li:nth-child(2),
.pass_bron li:nth-child(3),
.pass_bron li:nth-child(4) {
  opacity: 0;
  position: absolute;
}
.pass_bron li:nth-child(2) {
  --rotateX: -90deg;
}
.pass_bron li:nth-child(3) {
  --rotateX: -180deg;
}
.pass_bron li:nth-child(4) {
  --rotateX: -270deg;
}
.pass_bron span {
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  max-width: 0;
  opacity: 0;
  position: relative;
  transform: translateX(-100%);
  transition: max-width 50ms ease, opacity 100ms ease, transform 150ms ease-in-out;
  width: 20px;
}
.pass_bron svg {
  position: absolute;
}
.pass_bron svg:nth-child(1) {
  --stroke: 40;
  fill: transparent;
  height: 20px;
  left: 0;
  stroke: white;
  stroke-dasharray: var(--stroke);
  stroke-dashoffset: calc(var(--stroke) * 2);
  top: 0;
  width: 20px;
}
.pass_bron svg:nth-child(2) {
  --stroke: 16;
  fill: transparent;
  height: 16px;
  left: 50%;
  stroke: transparent;
  stroke-dasharray: var(--stroke);
  stroke-dashoffset: var(--stroke);
  top: 9px;
  transform: translate3d(-4px, -3px, 0);
  transition: stroke 300ms;
  width: 16px;
}
.pass_bron.-request,
.pass_bron.-success,
.pass_bron.-error {
  text-align: left;
  pointer-events: none;
}
.pass_bron.-request span,
.pass_bron.-success span,
.pass_bron.-error span {
  margin-right: 12px;
  max-width: initial;
  opacity: 1;
  transform: translateX(0);
}
.pass_bron.-request li:nth-child(1),
.pass_bron.-success li:nth-child(1),
.pass_bron.-error li:nth-child(1) {
  opacity: 0;
  position: absolute;
}
.pass_bron.-request {
  background-color: #6553D8;
}
.pass_bron.-request svg:nth-child(1) {
  animation: stroke 2s linear infinite forwards, rotate 1s linear infinite forwards;
}
.pass_bron.-request ul {
  transform: rotateX(90deg);
}
.pass_bron.-request li:nth-child(2) {
  opacity: 1;
  position: relative;
}
.pass_bron.-success {
  background-color: #47B795;
}
.pass_bron.-success svg:nth-child(1) {
  fill: #fff;
  top: 0;
  stroke-dasharray: 0;
}
.pass_bron.-success svg:nth-child(2) {
  animation: stroke 300ms ease-in-out forwards;
  stroke: #47B795;
}
.pass_bron.-success ul {
  transform: rotateX(180deg);
}
.pass_bron.-success li:nth-child(3) {
  opacity: 1;
  position: relative;
}
.pass_bron.-error {
  background-color: rgba(90, 90, 90, 0.1);
  color: rgba(90, 90, 90, 0.6);
}
.pass_bron.-error svg:nth-child(1) {
  fill: #fff;
  top: 0;
  stroke-dasharray: 0;
}
.pass_bron.-error svg:nth-child(2) {
  animation: stroke 300ms ease-in-out forwards;
  stroke: #47B795;
}
.pass_bron.-error ul {
  transform: rotateX(270deg);
}
.pass_bron.-error li:nth-child(4) {
  opacity: 1;
  position: relative;
}
@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
.fprice_line {
  display: flex;
  justify-content: space-between;
}
.fprice_line.fprice_line_itog {
  color: #5A5A5A;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
}
.fprice_line > a {
  font-size: 13px;
}
.fprice_line > div {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}
.fprice_line > div i {
  font-style: normal;
  padding-left: 5px;
}
.fprice_line + .fprice_line {
  margin-top: 10px;
}
.privacy {
  margin-top: 30px;
  line-height: 20px;
  padding-bottom: 4px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}
.row > div {
  padding: 10px;
}
.row.row-center {
  align-items: center;
}
.col3 {
  width: 25%;
}
.col4 {
  width: 33.3333%;
}
.col5 {
  width: 41.6666%;
}
.col6 {
  width: 50%;
}
.col8 {
  width: 66.6666%;
}
.col9 {
  width: 75%;
}
.col12 {
  width: 100%;
}
.col_razdel {
  padding-bottom: 3px !important;
}
.col_razdel + .col_razdel {
  margin-top: 17px;
  margin-bottom: 21px;
}
.razdel {
  width: 100%;
  height: 1px;
  background: #E3E5F6;
}
.user_stroka {
  display: flex;
  align-items: center;
}
.user_stroka svg {
  margin: 0 16px;
  color: #A2A2BA;
}
.user_stroka span {
  font-weight: 500;
  color: #A2A2BA;
}
.access_wrap {
  display: flex;
  margin: 0 -5px;
  padding: 8px 0;
}
.access_wrap a {
  display: block;
  margin: 0 5px;
  padding: 4px;
  border-radius: 8px;
  background: rgba(227, 229, 246, 0.3);
}
.access_ico {
  display: block;
  margin: 0 5px;
  padding: 3px;
  border-radius: 4px;
  background: rgba(227, 229, 246, 0.3);
}
.access_ico svg {
  width: 14px;
  height: 14px;
}
.access_1 {
  color: #D52F2F;
}
.access_2 {
  color: #E9874F;
}
.access_3 {
  color: #37A786;
}
.access_4 {
  color: #A278FB;
}
.access_5 {
  color: #3778D2;
}
.check_row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.check_row .checkbox-container {
  align-items: center;
  min-height: 28px;
  margin-top: 14px;
}
.check_row .checkbox-container:first-child {
  margin-top: 0;
}
.check_row .radio-container {
  align-items: center;
  min-height: 18px;
  margin: 5px 0;
}
.check_row_hor {
  flex-direction: row;
}
.check_row_hor .checkbox-container {
  margin-top: 0;
  width: 33.3333%;
}
.check_row_hor .checkbox-container:nth-child(n+4) {
  margin-top: 14px;
}
.down_avatar {
  display: flex;
  max-width: 390px;
}
.down_avatar .avatar_href {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  background: rgba(227, 229, 246, 0.3);
  margin-right: 12px;
}
.down_avatar .down_area {
  width: calc(100% - 112px);
  padding: 5px 12px;
  border: 1px dashed #7378FC;
  border-radius: 8px;
  line-height: 20px;
}
.down_avatar .down_area .btn {
  display: block;
  text-align: center;
  width: 100%;
  margin: 6px 0 10px;
}
.down_avatar .down_area .area_inf {
  color: rgba(64, 64, 64, 0.4);
}
.down_avatar .down_area .flex_group_but {
  display: none;
}
.down_avatar .down_area .flex_group_but .btn {
  margin-right: 3px;
  margin-left: 3px;
}
.down_avatar.down_complited .down_area > .btn {
  display: none;
}
.down_avatar.down_complited .flex_group_but {
  display: flex;
  margin: 0 -3px;
}
.but_block_center .btn {
  max-width: 392px;
  margin: 0 auto;
  border-radius: 20px;
  display: block;
  text-align: center;
}
.col12.sep {
  padding: 0 !important;
}
.ofert_title {
  margin-bottom: 20px;
}
.ofert_title .txt_light40 {
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}
.text_wrap {
  max-width: 844px;
  line-height: 18px;
  color: #5A5A5A;
}
.text_wrap h2 {
  margin: 0 0 20px;
}
.text_wrap h4 {
  margin: 0 0 20px;
}
.text_wrap a {
  text-decoration: underline;
}
.text_wrap .text_sub {
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.text_wrap .text_block {
  margin-bottom: 28px;
}
.text_wrap .text_block b {
  color: #23232B;
  font-weight: 800;
  line-height: 20px;
}
.text_wrap .text_block p {
  margin-bottom: 12px;
  text-align: justify;
}
.text_wrap .text_block p:last-child {
  margin-bottom: 0;
}
.text_wrap .text_block:last-child {
  margin-bottom: 0;
}
.text_wrap .text_block ul {
  margin: 0 0 12px;
  padding: 0 32px 0 46px;
  text-align: justify;
}
.text_wrap .text_block ul:last-child {
  margin-bottom: 0;
}
.text_wrap .text_block ul li {
  margin-bottom: 12px;
}
.text_wrap .text_block ul li:last-child {
  margin-bottom: 0;
}
.text_flex_item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  line-height: 20px;
}
.text_flex_item:last-child {
  margin-bottom: 0;
}
.text_flex_item .text_flex_title {
  width: 245px;
  min-width: 245px;
  margin-right: 20px;
  padding: 4px 0;
}
.text_flex_item .text_flex_inf {
  padding: 4px 0;
}
.flex_title_but {
  display: flex;
  margin: 0 -10px;
}
.flex_title_but > * {
  flex: 1 1 auto;
  margin: 0 10px;
}
.flex_title_but .btn {
  border-radius: 20px;
}
.flex_title_but .fake_select {
  height: 32px;
  color: #FFFFFF;
}
.grid_table {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -13px;
}
.grid_table_two_col .user_line {
  width: 50%;
}
.grid_table_two_col .user_line:nth-child(2n+1) {
  padding-right: 10px;
}
.grid_table_two_col .user_line:nth-child(2n+2) {
  padding-left: 10px;
}
.grid_table_two_col .user_line:nth-child(2) {
  border-top-width: 0;
  padding-top: 0;
}
.grid_table_two_col .user_line .access_ico {
  margin: 0 6px 0 0;
}
.grid_table_col4 {
  margin: -17px -8px;
  justify-content: space-between;
}
.grid_table_col4 .user_line {
  width: calc(25% - 15px);
  align-items: center;
  font-size: 13px;
  color: #5A5A5A;
  padding: 10px 0;
  border: none;
  position: relative;
}
.grid_table_col4 .user_line a {
  color: #5A5A5A;
}
.grid_table_col4 .user_line:nth-child(1) {
  padding-top: 10px;
}
.grid_table_col4 .user_line:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 1px;
  width: calc(400% + 60px);
  background: #E3E5F6;
}
.grid_table_col4 .user_line:nth-child(4n+5):after {
  content: "";
}
.user_group .grid_table_col4 {
  justify-content: inherit;
}
.grid_table_line .user_line {
  flex-wrap: wrap;
}
.grid_table_line h5 {
  margin-top: -5px;
}
.grid_table_line h5 sup {
  font-size: 14px;
  color: rgba(35, 35, 43, 0.4);
}
.grid_table_line .text_titl_inf {
  line-height: 20px;
}
.grid_table_line .text_titl_inf > span {
  display: block;
  width: 100% !important;
  margin-right: 0 !important;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: rgba(35, 35, 43, 0.6);
}
.grid_table_line .text_titl_inf > span + div {
  margin-top: 12px;
  width: 100%;
}
.grid_table_line .text_titl_inf > span + div * + * {
  margin-top: 6px;
}
.user_line {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  padding: 14px 0;
  border-top: 1px solid #E3E5F6;
  width: 100%;
}
.user_line > span {
  padding: 0;
  width: 100%;
}
.user_line > span:nth-child(1) {
  width: 175px;
  min-width: 175px;
  margin-right: 20px;
}
.user_line:nth-child(1) {
  border-top-width: 0;
  padding-top: 0;
}
.grid_manag {
  display: flex;
  margin: 3px -10px;
}
.grid_manag .user_manag {
  display: flex;
  align-items: center;
  background: #E3E5F6;
  border-radius: 12px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding: 8px 6px;
  width: calc(25% - 20px);
  margin: 3px 10px;
  color: #5A5A5A;
}
.grid_manag .user_manag .access_ico {
  margin: 0 6px;
}
.grid_manag .user_manag span {
  flex: 1 1 auto;
}
.grid_manag .user_manag > svg {
  margin: 0 6px;
}
.text_titl_inf span {
  line-height: 20px;
  word-break: break-word;
}
.text_titl_inf span:nth-child(1) {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: rgba(35, 35, 43, 0.6);
  margin-top: 1px;
}
.fill_box > div > div {
  border-radius: 20px;
  padding: 30px 20px;
  height: 100%;
}
.user_table_company .user_group_head {
  margin-bottom: 14px;
}
.user_table_company .user_group_head h5 {
  padding: 0 0 17px;
  width: 100%;
}
.user_table_company .user_group {
  margin-top: 0;
  height: 100%;
}
.fill_box_company .user_group_head {
  margin-bottom: 30px;
}
.fill_box_company .text_titl_inf span {
  display: block;
}
.fill_box_company .text_titl_inf span:nth-child(1) {
  margin-bottom: 6px;
}
.fill_box_company .marg_minus > .row {
  margin-top: -20px;
}
.profile_img {
  order: -2;
}
.profile_img > div {
  width: 304px;
  height: 323px;
  border-radius: 20px;
  border: 2px solid #E3E5F6;
  overflow: hidden;
}
.profile_img > div img {
  width: 100%;
}
.profile_second {
  width: calc(100% - 324px);
  order: -1;
}
.flex_with_ico {
  display: flex;
  align-items: center;
}
.flex_with_ico svg + span {
  margin-left: 6px;
}
.flex_with_ico span + svg {
  margin-left: 6px;
}
.user_group.alert {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #5A5A5A;
}
.user_group.alert svg {
  margin-right: 26px;
  min-width: 32px;
}
.user_group.alert .user_group_head {
  flex-wrap: wrap;
  margin: 0;
}
.user_group.alert .user_group_head h5 {
  padding: 0;
  width: 100%;
  margin-bottom: 14px;
}
.user_group.alert-red {
  border: 1px solid #BB3030;
}
.user_group.alert-red svg {
  color: #BB3030;
}
.user_group.alert-green {
  border: 1px solid #6ACBAE;
}
.user_group.alert-green svg {
  color: #6ACBAE;
}
.user_group.alert-orange {
  border: 1px solid #FDA97A;
}
.user_group_news {
  margin-top: 40px;
}
.user_group_news .newstbl_item {
  margin: 4px 0 0;
  padding: 12px 10px;
  border-radius: 12px;
  border: 0;
  background: #EEEEF8;
}
.user_group_news .newstbl_item.no_read {
  background: rgba(183, 151, 251, 0.4);
}
.user_group_news .newstbl_item > div {
  padding: 0 10px;
}
.user_group_news .newstbl_item:before {
  background: #645EFC;
}
.user_group_news .newstbl_item .newstbl_text {
  width: calc(100% - 152px);
}
.user_group_news .newstbl_item .newstbl_date {
  color: #5A5A5A;
}
.user_group_news .newstbl_item .newstbl_but {
  width: 52px;
}
.user_group_news .notify_none {
  text-align: center;
  line-height: 20px;
}
.login_wrap {
  width: 100%;
  max-width: 1358px;
  padding: 12px 14px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.login_el1,
.login_el2 {
  position: absolute;
  z-index: -1;
  opacity: 0.7;
  transform: translate(-50%, 0);
}
.login_el1 {
  top: 55px;
  left: 25%;
  width: 240px;
  height: 280px;
  filter: blur(170px);
  color: #A07EFF;
}
.login_el2 {
  top: 516px;
  left: 70%;
  width: 262px;
  height: 262px;
  filter: blur(200px);
  color: #7E9BFF;
}
.box_login {
  background: #FFFFFF;
  padding: 40px 80px;
  border-radius: 40px;
  max-width: 552px;
  width: 100%;
  margin: 78px auto 0;
  color: #5A5A5A;
}
.box_login a {
  color: #7378FC;
}
.box_login .box_login_head {
  text-align: center;
  margin-bottom: 28px;
}
.box_login .box_login_head .loader {
  margin: 0 auto 24px;
  background-size: contain;
  width: 120px;
  height: 70px;
}
.box_login .box_login_head .loader img {
  width: 25px;
  margin-top: -10px;
  left: 63%;
}
.box_login .box_login_head h2 {
  margin-top: 30px;
}
.box_login .box_login_head .sub_title {
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.box_login .form_group_center {
  margin-top: 35px;
}
.box_login .form_group_center a {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.box_login .error_inf {
  position: relative;
  bottom: 0;
  margin-top: 5px;
}
.box_login .user_group.alert {
  margin-top: -4px;
  border-width: 2px;
  padding: 14px 16px 16px 16px;
  margin-bottom: 28px;
}
.box_login .user_group.alert .user_group_head h5 {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 20px;
}
.box_login .user_group.alert svg {
  margin-right: 17px;
}
.box_login.box_login_send .form_group {
  display: none;
}
.box_login.box_login_send .form_group_center {
  display: none;
}
.box_login.box_login_send .form_group_center + .form_group_center {
  display: flex;
}
.box_login.box_login_reg {
  max-width: 900px;
  border-radius: 20px;
  margin-top: 88px;
  padding: 10px 40px 40px;
}
.box_login.box_login_reg .box_login_head {
  text-align: left;
  margin-bottom: 32px;
}
.box_login.box_login_reg .box_login_head .sub_title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.box_login.box_login_reg .box_login_head .sub_title span {
  white-space: nowrap;
}
.box_login.box_login_reg .user_group_nobord.user_group .form_group {
  margin-bottom: 33px;
}
.box_login.box_login_reg .form_group_center {
  margin-top: -13px;
}
.box_login.box_login_reg .user_group_head h5 {
  width: 100%;
}
.box_login .checkbox-container .checkmark {
  border-color: rgba(64, 64, 64, 0.3);
}
.box_login .btn-disable {
  background: #E3E5F6;
}
.box_login .btn-disable:hover,
.box_login .btn-disable.active,
.box_login .btn-disable:focus {
  background: #7378FC;
}
.box_login .loader span {
  height: 30px;
  background-size: contain;
}
.show_pass {
  position: relative;
}
.show_pass .input_field {
  padding-right: 32px;
}
.show_pass a {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  color: #5A5A5A;
}
.show_pass a svg + svg {
  display: none;
}
.show_pass a.active svg {
  display: none;
}
.show_pass a.active svg + svg {
  display: block;
}
.user_group .sub_group.sub_group_just {
  justify-content: space-between;
}
.user_group .sub_group.sub_group_just > * {
  width: auto;
}
.login_info {
  margin: 90px 0 76px;
}
.login_info .login_col {
  height: 100%;
  padding: 47px 33px 40px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}
.login_info .login_col .login_col_img {
  width: 80px;
  height: 80px;
  background: rgba(100, 94, 252, 0.1);
  border-radius: 100px;
  padding: 10px;
  color: #645EFC;
}
.login_info .login_col .login_col_title {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  color: #000044;
  margin: 54px 0 36px;
}
.login_info .login_col .login_col_text {
  margin: auto 0 0 0;
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
  color: #645EFC;
}
.login_info .login_col .login_col_text p {
  margin-top: 7px;
}
.login_info .login_col .login_col_text a {
  color: #645EFC;
}
.login_info .login_col.active {
  background: rgba(100, 94, 252, 0.1);
}
.login_info .login_col.active .login_col_img {
  background: #FFFFFF;
}
.captch_flex {
  display: flex;
}
.captch_flex .captch_img {
  border: 1px solid rgba(90, 90, 90, 0.3);
  border-radius: 14px;
  width: 136px;
  height: 48px;
  min-width: 136px;
  margin-right: 20px;
  overflow: hidden;
}
.captch_flex .captch_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.captch_group .sub_group > * {
  width: auto;
  margin-left: 156px;
}
@media (max-width: 1299px) {
  .regdetail_wrap {
    flex-wrap: wrap;
    margin: 0;
  }
  .regdetail_wrap .col8,
  .regdetail_wrap .col4 {
    width: 100%;
    padding: 0;
  }
  .regdetail_wrap .col4 {
    padding-top: 20px;
  }
  .user_stroka svg {
    margin: 0 8px;
  }
}
@media (max-width: 991px) {
  .colmd8 {
    width: 66.6666%;
  }
  .user_footer .row .col4 {
    width: 50%;
  }
  .user_footer .row .col4:first-child {
    width: 100%;
  }
  .user_group_head {
    flex-wrap: wrap;
  }
  .user_group_head h5,
  .user_group_head .form_group_center {
    width: 50%;
  }
  .user_group .alert {
    width: 100%;
    order: 1;
    margin-top: 10px;
  }
  .user_group.user_group_alert .user_group_head > .btn {
    margin: 14px 0 0;
  }
  .user_group.user_group_alert h5 {
    width: 100%;
    padding: 0;
  }
  .relat > .flex_group {
    flex-wrap: wrap;
    justify-content: normal;
  }
  .flex_title_but:not(.flex_title_but_left) {
    width: calc(100% + 20px);
    margin-top: 10px;
  }
  .flex_title_but:not(.flex_title_but_left) .btn-fix .btn {
    width: 100%;
  }
  .flex_group_wis_but .flex_title_but {
    width: auto;
  }
  .fill_box_company > div {
    width: 100%;
  }
  .fill_box_company > .col4 {
    margin-top: -10px;
  }
  .fill_box_company > .col4 .col6,
  .fill_box_company > .col4 .col12 {
    width: 33.3333%;
  }
  .user_group.user_table_company {
    margin-top: 10px;
  }
  .user_table_company .col6 {
    width: 100%;
  }
  .user_table_company .col6:last-child {
    padding-top: 0;
    margin-top: -10px;
  }
  .user_table_company .user_group_head h5 {
    font-size: 18px;
    width: 100%;
  }
  .grid_table_two_col:not(.grid_table_line) .user_line {
    width: 100%;
  }
  .grid_table_two_col:not(.grid_table_line) .user_line:nth-child(2) {
    border-top-width: 1px;
    padding-top: 14px;
  }
  .grid_table_two_col:not(.grid_table_line) .user_line:nth-child(2n+1) {
    padding-right: 0;
  }
  .grid_table_two_col:not(.grid_table_line) .user_line:nth-child(2n+2) {
    order: 1;
    padding-left: 0;
  }
  .grid_table_col4 .user_line {
    width: calc(33.3333% - 13.3334px);
    padding: 8px 0;
  }
  .grid_table_col4 .user_line:nth-child(4n+5):after {
    content: none;
  }
  .grid_table_col4 .user_line:nth-child(3n+4):after {
    content: "";
    width: calc(300% + 40px);
  }
  .user_table_left .user_line {
    align-items: flex-start;
    margin-right: 0;
    padding-right: 0;
  }
  .user_table_right .user_line {
    align-items: flex-start;
    margin-left: 0;
    padding-left: 0;
  }
  .profile_img {
    order: 0;
  }
  .profile_img > div {
    width: 213px;
    height: 227px;
  }
  .profile_img + div.col6 {
    width: calc(100% - 233px);
  }
  .profile_img + div.col6 .user_group_head {
    margin-bottom: 20px;
  }
  .profile_img + div.col6 .col6 {
    width: 50%;
  }
  .profile_second {
    width: 100%;
    order: 0;
    margin-bottom: 10px;
    margin-top: -10px;
  }
  .check_row_hor {
    flex-direction: column;
  }
  .check_row_hor .checkbox-container {
    margin-top: 14px;
    width: 100%;
  }
  .check_row_hor .checkbox-container:nth-child(1) {
    margin-top: 0;
  }
  .grid_manag .user_manag {
    width: calc(50% - 20px);
  }
  .login_info {
    margin: 80px 0 66px;
  }
  .login_info .login_col {
    padding: 30px 20px 30px;
  }
  .login_info .login_col .login_col_text {
    font-size: 18px;
    line-height: 24px;
  }
  .login_info .login_col .login_col_title {
    margin: 45px 0 50px;
  }
  .captch_flex {
    flex-wrap: wrap;
  }
  .captch_flex .captch_img {
    margin-bottom: 12px;
  }
  .box_login .box_login_head .sub_title {
    font-size: 14px;
    line-height: 20px;
  }
  .box_login.box_login_reg .user_group_nobord.user_group .form_group {
    margin-bottom: 24px;
  }
  .box_login.box_login_reg .col4 {
    width: 50%;
  }
  .captch_group .sub_group > * {
    margin-left: 0;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .col4,
  .col6,
  .col8 {
    width: 100%;
  }
  .regdetail_wrap .col4 {
    padding-top: 10px;
  }
  .regdetail_wrap .regdetail .detail_wrap {
    flex-wrap: wrap;
  }
  .regdetail_wrap .regdetail .detail_item {
    width: 100%;
  }
  .regdetail_wrap .regdetail .detail_item .detail_title {
    font-size: 16px;
  }
  .regdetail_wrap .regdetail .detail_item .detail_airport {
    padding: 12px 0 12px 0;
  }
  .regdetail_wrap .regdetail .detail_item .detail_airport span {
    font-size: 12px;
  }
  .regdetail_wrap .regdetail .detail_item .detail_airport .modal_rules {
    font-size: 12px;
    margin-top: 0;
  }
  .regdetail_wrap .regdetail .detail_item .detail_line {
    font-size: 13px;
    padding-bottom: 10px;
  }
  .regdetail_wrap .regdetail .detail_item .detail_line .detail_time {
    font-size: 16px;
  }
  .regdetail_wrap .regdetail .detail_item .detail_dopinfo {
    font-size: 12px;
    margin-top: 0;
  }
  .regdetail_wrap .regdetail .detail_item .detail_dopinfo span {
    margin: 3px 0;
    display: block;
  }
  .regdetail_wrap .regdetail .detail_peresad {
    width: 100%;
    max-width: none;
    padding: 20px 0;
    justify-content: flex-start;
    font-size: 13px;
  }
  .regdetail_wrap .regdetail .detail_peresad div {
    text-align: left;
  }
  .regdetail_wrap .regdetail .reg_title {
    font-size: 18px;
    line-height: 24px;
  }
  .user_group {
    margin-top: 10px;
    padding: 28px 18px;
  }
  .user_group .form_group .form_label {
    font-size: 13px;
    margin-bottom: 4px;
  }
  .user_group .sub_group {
    font-size: 13px;
  }
  .user_group .alert {
    width: 100%;
    order: 1;
    margin-top: 10px;
  }
  .dupl_group .btn {
    font-size: 16px;
    margin: 0 0 0 auto;
  }
  .col_razdel {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  .col_razdel + .col_razdel {
    display: none;
  }
  .col_razdel_dvoin {
    padding-bottom: 24px !important;
    padding-top: 14px !important;
  }
  .mili_group {
    margin-top: -25px;
  }
  .mili_group .btn {
    min-width: 236px;
    font-size: 16px;
  }
  .form_group .check_oform {
    margin-top: -25px;
    min-height: 0;
  }
  .user_footer {
    margin: 22px -20px -30px;
    font-size: 13px;
  }
  .user_footer .row .col4 {
    width: 100%;
  }
  .fprice_line > div {
    justify-content: flex-end;
  }
  .fprice_line > div span,
  .fprice_line > div i {
    width: auto;
  }
  .user_stroka span {
    font-size: 14px;
  }
  .form_group_mlad {
    flex-wrap: wrap;
  }
  .form_group_mlad .btn {
    width: 100%;
  }
  .form_group_mlad .btn + .btn {
    margin: 10px 0 0;
  }
  .text_wrap .text_block ul {
    padding: 0 0 0 28px;
  }
  .text_flex_item .text_flex_title {
    width: 200px;
    min-width: 200px;
  }
  .flex_group_wis_but .flex_title_but {
    width: calc(100% + 20px);
  }
  .fill_box_company .row > div {
    width: 50%;
  }
  .fill_box_company .row > div.text-right {
    text-align: left;
    width: 100%;
    margin-top: 0;
  }
  .fill_box_company > .col4 .col6 {
    width: 50%;
  }
  .fill_box_company > .col4 .col12 {
    width: 100%;
  }
  .fill_box_company .user_group_head {
    margin-bottom: 10px;
  }
  .user_line span:nth-child(1) {
    width: 145px;
    min-width: 145px;
  }
  .grid_table_line .user_line {
    width: 100%;
  }
  .grid_table_line .user_line:nth-child(2) {
    border-top-width: 1px;
    padding-top: 14px;
  }
  .grid_table_line .user_line:nth-child(2n+1) {
    padding-right: 0;
  }
  .grid_table_line .user_line:nth-child(2n+2) {
    order: 1;
    padding-left: 0;
  }
  .grid_table_col4 .user_line {
    width: calc(50% - 10px);
    padding: 8px 0;
  }
  .grid_table_col4 .user_line:nth-child(3n+4):after {
    content: none;
  }
  .grid_table_col4 .user_line:nth-child(2n+3):after {
    content: "";
    width: calc(200% + 20px);
  }
  .profile_img {
    padding: 0 0 0 30px !important;
    position: relative;
    margin-bottom: -64px;
    margin-top: 20px;
  }
  .profile_img > div {
    width: 61px;
    height: 64px;
  }
  .profile_img + div.col6 {
    width: 100%;
    order: -1;
  }
  .profile_second {
    margin-top: -30px;
  }
  .profile_second .user_group_head {
    margin-left: 85px;
    padding-top: 10px;
  }
  .login_wrap {
    padding-right: 20px;
    padding-left: 20px;
  }
  .box_login {
    padding: 30px 40px;
    border-radius: 30px;
    max-width: 340px;
  }
  .box_login .box_login_head {
    margin-bottom: 16px;
  }
  .box_login .box_login_head .loader {
    margin: 0 auto 10px;
    height: 63px;
  }
  .box_login .box_login_head img {
    width: 130px;
  }
  .box_login .box_login_head h2 {
    margin-top: 22px;
    font-size: 26px;
    line-height: 36px;
  }
  .box_login .box_login_head .sub_title {
    font-size: 12px;
    line-height: 18px;
  }
  .box_login .user_group .form_group {
    margin-bottom: 20px !important;
  }
  .box_login .user_group .form_group .form_label {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .box_login .user_group .sub_group {
    font-size: 12px;
  }
  .box_login .user_group.alert {
    margin-top: 0;
    padding: 16px 16px 16px 16px;
    margin-bottom: 18px;
    font-size: 12px;
    line-height: 18px;
  }
  .box_login .user_group.alert svg {
    min-width: 24px;
    width: 24px;
  }
  .box_login .form_group_center {
    margin-top: 25px;
  }
  .box_login .form_group_center .btn {
    font-size: 14px;
    padding: 6px 16px;
  }
  .box_login .form_group_center a {
    font-size: 12px;
    line-height: 20px;
  }
  .box_login.box_login_reg {
    margin-top: 80px;
    padding: 23px 25px;
  }
  .box_login.box_login_reg h2 {
    margin-top: 0;
    font-size: 30px;
    line-height: 40px;
  }
  .box_login.box_login_reg .box_login_head {
    margin-bottom: 22px;
  }
  .box_login.box_login_reg .box_login_head .sub_title {
    font-size: 14px;
    line-height: 20px;
  }
  .box_login.box_login_reg .user_group_nobord.user_group .form_group {
    margin-bottom: 20px;
  }
  .box_login.box_login_reg .user_group .form_group {
    margin-bottom: 2px !important;
  }
  .box_login.box_login_reg .row + .user_group_head {
    margin-top: 18px;
  }
  .box_login.box_login_reg .col4 {
    width: 100%;
  }
  .box_login.box_login_reg .form_group_center {
    margin-top: 20px;
  }
  .box_login.box_login_reg .form_group_center .btn {
    font-size: 16px;
    padding: 16px 16px;
  }
  .login_info {
    margin: 60px 0 46px;
  }
  .login_info .login_col {
    padding: 20px;
  }
  .login_info .login_col .login_col_img {
    width: 40px;
    height: 40px;
    padding: 5px;
  }
  .login_info .login_col .login_col_img svg {
    width: 30px;
    height: 30px;
  }
  .login_info .login_col .login_col_title {
    margin: 18px 0 14px;
    font-size: 16px;
    line-height: 24px;
  }
  .login_info .login_col .login_col_text {
    font-size: 14px;
    line-height: 20px;
  }
  .login_info .login_col .login_col_text.login_col_text_p {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  .login_info .login_col .login_col_text.login_col_text_p p {
    margin: 0;
    padding: 0 10px;
  }
}
@media (max-width: 575px) {
  .regdetail_wrap .regdetail .detail_wrap {
    margin-top: 15px;
  }
  .regdetail_wrap .regdetail .detail_item .detail_title {
    font-size: 14px;
  }
  .regdetail_wrap .regdetail .reg_title {
    font-size: 16px;
  }
  .user_group_head h5 {
    font-size: 16px;
  }
  .user_group_head .btn-small {
    padding: 6px 16px;
  }
  .user_group_head h5.user_stroka {
    width: 100%;
  }
  .user_group_head .form_group_center {
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }
  .user_group .sub_group {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .user_group_news .user_group_head h5 {
    width: 100%;
  }
  .user_group_news .user_group_head .sort_wrap {
    margin: 7px 0 2px;
  }
  .user_group_news .newstbl_item .newstbl_text {
    width: calc(100% - 73px);
  }
  .user_group_news .newstbl_item .newstbl_text span {
    white-space: normal;
  }
  .user_group_news .newstbl_item .newstbl_date {
    width: 73px;
    padding: 44px 0 0 10px;
    text-align: center;
  }
  .user_group_news .newstbl_item .newstbl_but {
    width: 63px;
    padding: 0;
    position: absolute;
    right: 10px;
  }
  .user_group_news .newstbl_item .newstbl_but .newstbl_but_mail {
    margin: 0 auto;
  }
  .dupl_group {
    flex-wrap: wrap;
  }
  .dupl_group span {
    margin: 5px 0 7px;
  }
  .dupl_group .btn {
    margin-left: 0;
  }
  .mili_group {
    flex-wrap: wrap;
    justify-content: center;
  }
  .mili_group .btn {
    margin: 0 0 8px;
    width: 100%;
  }
  .card_mili .btn {
    margin-left: 6px;
    padding: 4px;
    min-width: 32px;
    width: 32px;
    height: 32px;
    font-size: 0;
  }
  .card_mili .btn svg + span {
    margin-left: 0;
  }
  .fprice_line > div {
    width: auto;
  }
  .fprice_line + .fprice_line {
    margin-top: 8px;
  }
  .user_footer .row .col4:first-child {
    margin-bottom: 5px;
  }
  .privacy {
    font-size: 14px;
  }
  .down_avatar {
    flex-wrap: wrap;
  }
  .down_avatar .down_area {
    width: 100%;
    line-height: 20px;
    margin-top: 6px;
  }
  .text_flex_item {
    align-items: flex-start;
  }
  .text_flex_item .text_flex_title {
    width: 140px;
    min-width: 140px;
  }
  .btn-wide-tret {
    min-width: 100%;
  }
  .btn-fix {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    width: 100%;
    height: 109px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-top: 1px solid #E3E5F6;
    padding: 0 20px;
    z-index: 10;
  }
  .btn-fix .btn-wide-tret {
    width: 100%;
  }
  .flex_group_wis_but {
    margin-bottom: 30px;
  }
  .fill_box_company .marg_minus > .row {
    margin-top: -10px;
  }
  .fill_box_company .row > div {
    width: 100%;
    margin-top: 0;
  }
  .fill_box_company .row > div.text-right .btn {
    width: 100%;
  }
  .fill_box_company > .col4 .col6 {
    width: 100%;
  }
  .fill_box_company .user_group_head h5 {
    font-size: 18px;
  }
  .user_line span:nth-child(1) {
    width: 130px;
    min-width: 130px;
    margin-right: 6px;
  }
  .text_titl_inf span:nth-child(1) {
    margin-top: 0;
  }
  .profile_second .user_group_head {
    padding-top: 0;
  }
  .grid_table_col4 .user_line {
    width: 100%;
    padding: 8px 0;
  }
  .grid_table_col4 .user_line:nth-child(2n+3):after {
    content: none;
  }
  .grid_table_col4 .user_line:nth-child(n+2):after {
    content: "";
    width: 100%;
  }
  .flex_title_but {
    flex-wrap: wrap;
  }
  .flex_title_but .btn + .btn {
    margin-top: 10px;
  }
  .grid_manag .user_manag {
    width: calc(100% - 20px);
  }
  .box_login {
    padding: 24px;
    max-width: 306px;
    margin: 40px auto 0;
  }
  .box_login.box_login_reg {
    margin-top: 40px;
  }
  .box_login.box_login_reg .box_login_head {
    margin-bottom: 26px;
  }
  .box_login.box_login_reg .user_group_head h5 {
    font-size: 18px;
  }
  .box_login.box_login_reg .user_group .form_group {
    margin-bottom: -2px !important;
  }
  .box_login.box_login_reg .row + .user_group_head {
    margin-top: 25px;
  }
  .login_info {
    margin: 40px 0 46px;
  }
  .login_info .login_col .login_col_title {
    margin: 12px 0 14px;
    font-size: 14px;
    line-height: 20px;
  }
  .login_info .login_col .login_col_text {
    font-size: 12px;
    line-height: 18px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.search_widget {
  padding: 30px 20px 0;
  border-radius: 20px;
  color: #404040;
  background: url('./img/search_widget_fon.png') no-repeat center center / cover;
}
.search_widget .tabs-but a + a {
  margin-left: 7px;
}
h1 + .search_widget {
  margin-top: 38px;
}
.search_service {
  display: flex;
}
.search_service .search_all {
  color: #645EFC;
  position: relative;
}
.search_service .search_all:before {
  content: "";
  display: block;
  width: 1px;
  height: 32px;
  background: #5A5A5A;
  opacity: 0.3;
  position: absolute;
  top: 1px;
  left: 0;
}
.search_route {
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #23232B;
  margin: 0 0 20px;
}
.search_route svg {
  margin-right: 10px;
}
.search_widget_dark .search_route {
  color: #FFFFFF;
}
.search_widget_dark .search_service a {
  color: #FFFFFF;
}
.search_widget_dark .search_service .search_all {
  color: #C4CAFD;
}
.search_widget_dark .search_service .search_all:before {
  background: rgba(255, 255, 255, 0.3);
}
.search_widget_dark .view_filtr {
  color: #C4CAFD;
}
.search_widget_dark .search_checkbox .checkmark {
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.6);
}
.search_widget_dark .search_checkbox input:checked ~ .checkmark {
  background: #645EFC url(./img/icon/ico_check2.svg) no-repeat center center;
  color: #fff;
}
.search_widget_dark .search_checkbox input:checked ~ .checkmark svg {
  opacity: 0;
}
.search_widget_dark .search_checkbox .checkbox-container {
  color: #FFFFFF;
}
.fields {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.fields .fields_line {
  width: 79.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 20px 0 0 20px;
  border-color: rgba(90, 90, 90, 0.3);
  border-style: solid;
  border-width: 1px 0 1px 1px;
  background: #FFF;
  position: relative;
}
.fields .fields_direction {
  width: 62.5%;
}
.fields .dates {
  width: 37.5%;
}
.fields .dates .form_input {
  border: 1px solid transparent;
  padding: 12px 12px;
  line-height: 36px;
  border-radius: 20px;
}
.fields .dates .form_input:focus,
.fields .dates .form_input:active,
.fields .dates .form_input.focused {
  border: 1px solid #645EFC;
}
.fields .dates .form_input:hover {
  border: 1px solid rgba(100, 94, 252, 0.4);
}
.fields .fields_subm {
  width: 20.5%;
  position: relative;
  padding-right: 64px;
}
.fields .fields_hotels {
  width: 44.5%;
}
.fields .fields_hotels + .dates {
  width: 55.5%;
}
.fields .pass-clas {
  background: #fff;
  border-color: rgba(90, 90, 90, 0.3);
  border-style: solid;
  border-width: 1px 0 1px 0;
}
.fields .fields_add_line {
  display: none;
}
.fields .fields_add_line a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #645EFC;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  height: 64px;
  border-radius: 20px;
  border: 1px solid rgba(100, 94, 252, 0.2);
  background: #FFF;
}
.fields .to_result {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 20px 20px 0;
  background: #DB3931;
  width: 64px;
  height: 100%;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.fields .to_result span {
  width: 0;
  overflow: hidden;
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
  transition: 0.3s all;
}
.fields.active .fields_line {
  width: 100%;
  border: 1px solid rgba(90, 90, 90, 0.3);
  border-radius: 20px;
  height: 64px;
}
.fields.active .fields_line + .fields_line {
  margin-top: 10px;
}
.fields.active .fields_direction {
  width: 66.5%;
}
.fields.active .dates {
  width: 33.5%;
}
.fields.active .fields_subm {
  margin: 10px -5px 0;
  width: calc(100% + 10px);
  display: flex;
  align-items: center;
}
.fields.active .fields_subm > div {
  width: 33.3333%;
  padding: 0 5px;
}
.fields.active .fields_pass .pass-clas {
  border-radius: 20px;
  border: 1px solid rgba(90, 90, 90, 0.3);
}
.fields.active .fields_add_line {
  display: block;
}
.fields.active .fields_result a {
  position: relative;
  width: 100%;
  height: 64px;
  border-radius: 20px;
}
.fields.active .fields_result a span {
  width: 132px;
}
.search_hotels .search_widget_form {
  padding-bottom: 20px;
}
.search_hotels .fields .fields_line {
  width: 74%;
}
.search_hotels .fields .fields_subm {
  width: 26%;
}
.search_hotels .fields .to_result {
  background: #645EFC;
}
.search_hotels .fields .to_result:hover {
  background: #7378FC;
}
.search_hotels .hotel_room {
  color: rgba(35, 35, 43, 0.6);
  font-size: 12px;
  font-weight: 500;
  opacity: 0.7;
}
.direction {
  position: relative;
}
.fields_direction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
}
.fields_direction > div {
  width: calc(50% - 16px);
}
.fields_direction .swap-directions {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 32px;
  padding: 4px;
  transform: translate(-50%, -50%);
  border-radius: 8px;
}
.swap-directions {
  color: #5A5A5A;
  background: #E3E5F6;
}
.fields_dates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
}
.fields_dates > div {
  width: 50%;
}
.fields_dates > div.fake_date_all {
  width: 100%;
}
.remove_line {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 42px;
  height: 64px;
  right: -1px;
  top: -1px;
  border-radius: 0 20px 20px 0;
  background: #FF9090;
  color: #ffffff;
  border-color: rgba(90, 90, 90, 0.3);
  border-style: solid;
  border-width: 1px 1px 1px 0;
}
.pass-clas .form_input {
  padding-top: 0;
  padding-bottom: 0;
}
.pass-clas #pass_comf {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.search_widget_content {
  display: none;
  margin-top: 30px;
}
.search_widget_form {
  padding-bottom: 60px;
  position: relative;
}
.search_widget_form.active .search_checkbox label:nth-child(n + 5) {
  opacity: 1;
  width: 25%;
  padding: 6px 10px;
  margin-top: 10px;
}
.search_widget_form.active .view_filtr .filtr_text span {
  opacity: 0;
  max-height: 0;
}
.search_widget_form.active .view_filtr .filtr_text span + span {
  opacity: 1;
  max-height: 20px;
}
.search_widget_form.active .view_filtr svg {
  transform: rotate(180deg);
}
.search_widget_form.active .search_card_wrap {
  max-height: 160px;
}
.search_widget_form .input_field {
  margin: -1px 0;
  line-height: 36px;
  border-color: transparent;
}
.search_widget_form .input_field:focus,
.search_widget_form .input_field:active,
.search_widget_form .input_field.focused {
  border: 1px solid #645EFC;
}
.search_widget_form .input_field:hover {
  border: 1px solid rgba(100, 94, 252, 0.4);
}
.search_widget_form .fake_text {
  border: 1px solid transparent;
  padding: 12px 12px;
  line-height: 36px;
  border-radius: 14px;
}
.search_widget_form .fake_text:focus,
.search_widget_form .fake_text:active,
.search_widget_form .fake_text.focused {
  border: 1px solid #645EFC;
}
.search_widget_form .fake_text:hover {
  border: 1px solid rgba(100, 94, 252, 0.4);
}
.search_widget_form .fake_text .simple-typeahead {
  width: 80%;
}
.search_widget_form .fake_text .simple-typeahead input {
  border: 0;
  width: 100%;
}
.search_widget_form .fake_text .simple-typeahead input:focus {
  outline: none;
}
.search_widget_form .pass-clas .fake_text {
  flex-wrap: wrap;
}
.search_widget_form .pass-clas .fake_text > div {
  color: #23232B;
  font-weight: 600;
  line-height: 18px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search_widget_form .pass-clas .fake_text > div.pass_comf {
  opacity: 0.7;
  color: rgba(90, 90, 90, 0.6);
}
.search_widget_form .form_error {
  border-radius: 0 0 20px 20px !important;
  border: 2px solid #EE5C5C !important;
  background: #FFF;
  position: relative;
  margin-bottom: -2px;
  margin-top: -2px;
}
.search_widget_form .form_error:before {
  content: attr(data-error);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  top: -20px;
  left: -2px;
  border-radius: 8px 8px 0 0;
  background: #EE5C5C;
  right: -2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #fff;
  padding: 0 8px;
}
.search_widget_form .form_error .form_input {
  border: 0 !important;
}
.search_widget_form.has_error .fields {
  padding-top: 20px;
}
.search_widget_form.has_error .fields.active {
  padding-top: 0;
}
.search_widget_form.has_error .fields.active .fields_line {
  margin-top: 20px;
}
.search_widget_form.has_error .fields.active .fields_line + .fields_line {
  margin-top: 30px;
}
.search_wrap {
  margin-top: 10px;
}
.search_checkbox {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 15px;
  overflow: hidden;
  transition: 0.3s all;
}
.search_checkbox .checkbox-container {
  width: 25%;
  padding: 6px 10px;
  margin-top: 10px;
  transition: 0.3s all;
}
.search_checkbox .checkbox-container:nth-child(n + 5) {
  opacity: 0;
  width: 0;
  padding: 0;
  margin-top: 0;
}
.search_checkbox .checkmark svg {
  opacity: 0;
}
.search_checkbox input:checked ~ .checkmark {
  background: none;
  color: #645EFC;
}
.search_checkbox input:checked ~ .checkmark svg {
  opacity: 1;
}
.view_filtr_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 26px -20px 0;
  width: calc(100% + 40px);
  border-top: 2px solid rgba(100, 94, 252, 0.1);
  padding: 14px 20px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.view_filtr {
  color: #645EFC;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.view_filtr .filtr_text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.view_filtr .filtr_text span {
  opacity: 1;
  max-height: 20px;
  transition: 0.3s all;
}
.view_filtr .filtr_text span + span {
  opacity: 0;
  max-height: 0;
}
.view_filtr svg {
  margin-left: 10px;
  display: block;
}
.filtr_edit_check {
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 4px;
  color: #645EFC;
}
.search_card_wrap {
  max-height: 0;
  overflow: hidden;
  transition: 0.3s all;
}
.search_card_wrap > div {
  display: flex;
  margin: 20px -10px 5px;
  overflow-y: hidden;
  overflow-x: auto;
}
.search_card_wrap .search_card {
  width: 25%;
  min-width: 25%;
  padding: 10px;
}
.search_card_wrap .search_card > div {
  border-radius: 20px;
  border: 1px solid #EEEEF8;
  background: rgba(255, 255, 255, 0.8);
  padding: 16px;
  line-height: 20px;
  height: 100%;
}
.search_card_wrap .search_card > div:hover {
  border: 1px solid rgba(100, 94, 252, 0.4);
}
.search_card_wrap .search_card_foot {
  display: flex;
  color: rgba(90, 90, 90, 0.4);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}
.search_card_wrap .search_card_foot .search_card_pass {
  margin-left: 20px;
}
.search_zd .fields .fields_line {
  width: calc(100% - 64px);
}
.search_zd .fields .fields_subm {
  width: 64px;
}
.search_zd .fields .to_result {
  background: #645EFC;
}
.search_zd .fields .to_result:hover {
  background: #7378FC;
}
.search_zd .fields .fields_direction {
  width: 50%;
}
.search_zd .fields .dates {
  width: 50%;
}
.search_zd .search_widget_form {
  padding-bottom: 5px;
}
.search_zd .search_card_wrap {
  max-height: none;
  margin-top: 22px;
}
.direct_variants {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 5px;
  width: 100%;
  padding: 0 14px;
  color: rgba(100, 94, 252, 0.6);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.direct_variants * {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: rgba(100, 94, 252, 0.6);
  cursor: pointer;
}
.search__modal .search_widget_form {
  padding-bottom: 0;
}
.search__modal .fields .fields_line {
  width: 78.6%;
}
.search__modal .fields .fields_subm {
  width: 21.4%;
  position: relative;
}
.search__modal .fields .to_result {
  background: #645EFC;
  width: 100%;
}
.search__modal .fields .to_result span {
  width: auto;
}
.search__modal .fields .to_result svg {
  display: none;
}
.search__modal .fields .fields_dir {
  width: 72.7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
}
.search__modal .fields .fields_dir > div {
  width: calc(50% - 16px);
}
.search__modal .fields .fields_dir .swap-directions {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  color: #5A5A5A;
  width: 32px;
  height: 32px;
  padding: 4px;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #E3E5F6;
}
.search__modal .fields .dates {
  width: 27.3%;
}
.search__modal .fields_dates > div {
  width: 100%;
}
.search__modal .fields_dates .date-to {
  display: none;
}
.search__modal.search__modal_two .fields .fields_line {
  width: 86.1%;
  background: #FFF;
  border-width: 1px 0 1px 1px;
  border-color: rgba(64, 64, 64, 0.3);
  border-style: solid;
}
.search__modal.search__modal_two .fields .fields_subm {
  width: 13.9%;
}
.search__modal.search__modal_two .fields .fields_dir {
  width: 66.1%;
}
.search__modal.search__modal_two .fields .dates {
  width: 33.9%;
}
.search__modal.search__modal_two .fields_dates > div {
  width: 50%;
}
.search__modal.search__modal_two .fields_dates .date-to {
  display: block;
}
.search__phone {
  display: none;
}
.table_new {
  margin-top: 30px;
}
.table_new .balans_accord {
  margin-top: 0;
  background: transparent;
}
.table_new .balans_accord .accord_head.side_title {
  background: #C4CAFD;
  padding: 8px 12px;
}
.table_new .balans_accord .accord_head.side_title .balans_accord_head {
  font-size: 15px;
  font-weight: 800;
  color: #23232B;
}
.table_new .balans_accord + .balans_accord {
  margin-top: 20px;
}
.table_new_one .accord_head.side_title {
  display: none;
}
.table_head {
  display: grid;
  grid-template-columns: 90fr 110fr 78fr 110fr 90fr 80fr 38fr 43fr;
  background: #A2A2BA;
  border-radius: 20px;
  padding: 0 8px;
}
.table_head > div {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 8px 4px;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.table_head .sort a {
  background: url(./img/icon/chevron-up_white.svg) no-repeat 0 0;
}
.table_wrap {
  width: 100%;
}
.table_wrap .table_item {
  display: grid;
  grid-template-columns: 90fr 110fr 78fr 110fr 90fr 80fr 38fr 43fr;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #404040;
  padding: 12px 8px;
  position: relative;
}
.table_wrap .table_item + .table_item {
  border-top: 1px solid #EEEEF8;
}
.table_wrap .table_item > div {
  padding: 4px;
}
.table_wrap .table_item > div:nth-child(1) b span {
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}
.table_wrap b {
  color: #404040;
  font-size: 15px;
  font-weight: 800;
  line-height: 18px;
  display: block;
  margin-bottom: 2px;
}
.table_wrap b span {
  font-weight: 500;
  margin-left: 2px;
}
.table_wrap .table_price {
  text-align: right;
}
.table_wrap .table_price b {
  border-bottom: 1px dashed #645EFC;
  display: inline-block;
  margin: 0;
}
.table_wrap .name_station {
  font-size: 13px;
  font-weight: 800;
  border-bottom: 1px dashed #645EFC;
}
.table_wrap .name_station .name_station_sub {
  font-size: 12px;
  font-weight: 600;
}
.table_wrap .table_date {
  text-align: center;
}
.table_wrap .table_date span {
  display: block;
}
.table_wrap .table_date .table_date_val {
  font-size: 13px;
}
.table_wrap .table_date .table_linepoint {
  position: relative;
  width: calc(100% - 16px);
  margin: 8px 8px;
  height: 2px;
  background: #645EFC;
}
.table_wrap .table_date .table_linepoint:after,
.table_wrap .table_date .table_linepoint:before {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  top: -1px;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #645EFC;
}
.table_wrap .table_date .table_linepoint:after {
  left: -4px;
}
.table_wrap .table_date .table_linepoint:before {
  right: -4px;
}
.table_wrap sup {
  color: #FF3A3A;
  font-size: 10px;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.table_new .table_wrap .table_item > div .logo_trans {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 800;
  line-height: 20px;
  margin-left: 0;
}
.table_new .table_wrap .table_item > div .logo_trans img {
  margin-right: 8px;
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.table_new .table_price b {
  color: #645EFC;
  font-size: 18px;
  line-height: 24px;
}
.table_time_txt {
  font-size: 22px;
  line-height: 28px;
  font-weight: 800;
}
.table_time_txt span {
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
}
.table_tarif {
  grid-column: 1 / span 8;
  padding: 10px 135px 10px 4px !important;
}
.table_tarif .table_tarif_btn {
  display: flex;
  align-items: center;
}
.table_tarif .table_tarif_btn b {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
.table_tarif .table_tarif_btn span,
.table_tarif .table_tarif_btn a {
  color: #404040;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
  padding: 0 10px;
  border-radius: 8px;
  background: rgba(64, 64, 64, 0.1);
  margin: 0 2px;
  white-space: nowrap;
}
.table_tarif .table_tarif_btn a {
  background: #EEEEF8;
  color: #645EFC;
}
.table_tarif .table_scrol {
  /*margin-left: -8px;*/
  margin-left: 0;
}
.table_icon {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  color: #A2A2BA;
  margin: -5px;
}
.table_icon svg {
  margin: 5px;
}
.table_icon + div,
.table_icon + div + div {
  line-height: 25px;
}
.table_item .table_vib_btn {
  position: absolute;
  right: 0;
  bottom: 12px;
}
.table_item .table_vib_btn svg {
  display: none;
}
.table_item .table_vib_btn span {
  margin-left: 5px;
}
.table_item .table_vib_btn span + span {
  display: none;
  margin-left: 5px;
}
.table_item.selected,
.table_item:hover {
  background: #F1EAFF;
}
.table_item.active .table_vib_btn .btn {
  background: #645EFC;
  color: #FFF;
}
.table_item.active .table_vib_btn .btn:hover,
.table_item.active .table_vib_btn .btn:focus {
  background: #7378FC;
}
.table_item.active .table_vib_btn svg {
  display: block;
}
.table_item.active .table_vib_btn span {
  display: none;
}
.table_item.active .table_vib_btn span + span {
  display: block;
}
.table_head_date {
  margin-left: 5px;
}
.grey_txt {
  color: rgba(90, 90, 90, 0.4);
}
.grey_txt2 {
  color: rgba(35, 35, 43, 0.3);
}
.table_item .grey_txt {
  color: rgba(64, 64, 64, 0.4);
}
.accord_ico {
  background: #7378FC;
  border-radius: 100px;
  padding: 10px;
  width: 44px;
  height: 44px;
  margin-right: 20px;
  color: #fff;
}
.accord_arrow_reis {
  margin: 0 8px;
}
.col6 > .accord_item > .modal_body > .modal_col_body {
  padding: 0 10px;
}
.side_filtr .side_title {
  position: relative;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  border-bottom: 1px solid #EEEEF8;
}
.side_filtr .reset_filtr {
  margin-right: 55px;
  font-size: 14px;
}
.side_filtr .modal_head h5 {
  font-size: 14px;
  line-height: 20px;
}
.modal_tabl_scroll {
  max-height: 260px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: -7px;
  padding-right: 7px;
}
.modal_tabl {
  border: 2px solid #EEEEF8;
  border-radius: 20px;
  overflow: hidden;
}
.modal_tabl .modal_tabl_item {
  padding: 0 16px;
  display: flex;
}
.modal_tabl .modal_tabl_item div {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding: 12px 6px 11px 6px;
}
.modal_tabl .modal_tabl_item div:nth-child(1) {
  width: 274px;
}
.modal_tabl .modal_tabl_item div:nth-child(2) {
  width: 80px;
}
.modal_tabl .modal_tabl_item div:nth-child(3) {
  width: 80px;
}
.modal_tabl .modal_tabl_item div:nth-child(4) {
  width: 94px;
}
.modal_tabl .modal_tabl_item + .modal_tabl_item {
  border-top: 1px solid #EEEEF8;
}
.modal_tabl .modal_tabl_head {
  background: #EEEEF8;
  align-items: center;
}
.modal_tabl .modal_tabl_head div {
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 9px 6px;
}
.vg_zag svg {
  color: #645EFC;
  padding: 0 12px;
  margin: 0;
  width: 42px;
  height: 29px;
}
.vg_zag span {
  margin-left: 12px;
}
.vg_zag.vg_zag_duo span {
  color: #A2A2BA;
}
.kviborymest .btn {
  max-width: 392px;
  margin: 0 auto;
  border-radius: 20px;
}
.flex_title_sel1 > *:nth-child(-n+3) {
  border-bottom: 1px solid #5A5A5A;
}
.flex_title_sel2 > *:nth-child(n+3) {
  border-bottom: 1px solid #5A5A5A;
}
.flex_title_sel2 > span {
  border-bottom: 0 !important;
}
.vagon_block {
  background: #C4CAFD;
  border-radius: 14px;
}
.vagon_block .vagon_block_item {
  display: grid;
  grid-template-columns: 317fr 230fr 211fr 230fr 168fr;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding: 16px 10px;
}
.vagon_block .vagon_block_item > div {
  padding: 8px 10px;
}
.vagon_block .vagon_block_item + .vagon_block_item {
  border-top: 1px solid #FFFFFF;
}
.vagon_block .vagons_item .vagons_head {
  background: #C5C3DD;
}
.but_sel_vagon a {
  background: #97A1FB;
  border-radius: 14px;
  padding: 12px;
  height: 100%;
  color: #404040;
}
.but_sel_vagon a b {
  margin-left: 6px;
  font-size: 12px;
}
.vg_zaglav {
  display: flex;
  align-items: center;
  width: 90px;
  margin-right: 8px;
}
.vg_zaglav .vg_zaglav_ico {
  background: #7378FC;
  border-radius: 100px;
  padding: 7px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  color: #fff;
}
.vg_zaglav .vg_zaglav_num {
  font-size: 15px;
  font-weight: 800;
  line-height: normal;
  color: #5A5A5A;
}
.vg_zaglav_reis span + span {
  margin-left: 6px;
}
.vagon_class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 18px;
  border-radius: 14px;
  border: 2px solid #C5C3DD;
}
.vagon_class .flex_group_but > .btn {
  display: none;
}
.vagon_span {
  display: flex;
}
.vagon_span span {
  background: #E3E5F6;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 5px 16px;
  border-radius: 12px;
  display: block;
  cursor: pointer;
}
.vagon_span span + span {
  margin-left: 6px;
}
.vagon_span span b {
  font-weight: 800;
  color: #645EFC;
}
.vagon_span span:hover,
.vagon_span span.active {
  color: #FFFFFF;
  background: #645EFC;
}
.vagon_span span:hover b,
.vagon_span span.active b {
  color: #FFFFFF;
}
.line_zag_text_name {
  color: rgba(64, 64, 64, 0.6);
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.line_zag_text_value {
  font-size: 15px;
  font-weight: 800;
  line-height: normal;
  color: #5A5A5A;
}
.vagons_item {
  border-radius: 14px;
  border: 2px solid #C5C3DD;
  display: flex;
  overflow: hidden;
}
.vagons_item .vagons_head {
  background: rgba(197, 195, 221, 0.6);
  padding: 6px 18px;
  width: 26.4%;
}
.vagons_item .vagons_head .table_icon {
  color: #757478;
}
.vagons_item .vagons_body {
  width: 73.6%;
  overflow: hidden;
}
.vagons_item .vagons_body_item {
  width: 100%;
  display: flex;
  padding: 6px 8px 11px;
  border-bottom: 1px solid #C5C3DD;
}
.vagons_item .vagons_body_item > div {
  padding: 4px 10px;
}
.vagons_item .vagons_body_item:last-child {
  margin-bottom: -1px;
}
.vagons_item .vagons_body_item .vagons_body_num {
  width: 14.1%;
}
.vagons_item .vagons_body_item .vagons_body_place {
  width: 54.8%;
}
.vagons_item .vagons_body_item .vagons_body_serv {
  width: 31.1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.vagons_item .vagons_body_item .vagons_body_serv .vagons_body_serv_btn {
  margin-top: 8px;
}
.vagons_item .vagons_body_item .vagons_body_serv .vagons_body_serv_btn .btn {
  margin: 0 0 0 auto;
}
.vagons_item .vagons_body_item .vagons_body_serv .vagons_body_serv_btn span + span {
  display: none;
}
.vagons_item .vagons_body_item .vagons_body_serv .vagons_body_serv_btn svg {
  display: none;
}
.vagons_item .vagons_body_item .vagons_body_serv .vagons_body_serv_btn svg + span {
  margin-left: 0;
}
.vagons_item .vagons_body_item.selected,
.vagons_item .vagons_body_item:hover {
  background: #E3E5F6;
}
.vagons_item .vagons_body_item.active .vagons_body_serv .vagons_body_serv_btn .btn {
  background: #645EFC;
  color: #FFF;
}
.vagons_item .vagons_body_item.active .vagons_body_serv .vagons_body_serv_btn .btn:hover,
.vagons_item .vagons_body_item.active .vagons_body_serv .vagons_body_serv_btn .btn:focus {
  background: #7378FC;
}
.vagons_item .vagons_body_item.active .vagons_body_serv .vagons_body_serv_btn span {
  display: none;
}
.vagons_item .vagons_body_item.active .vagons_body_serv .vagons_body_serv_btn span + span {
  display: block;
  margin-left: 4px;
}
.vagons_item .vagons_body_item.active .vagons_body_serv .vagons_body_serv_btn svg {
  display: block;
}
.vagons_item .vagons_body_item.active .vagons_body_serv .vagons_body_serv_btn svg + span {
  margin-left: 5px;
}
.vagons_item .table_tarif .table_tarif_btn {
  flex-wrap: wrap;
}
.vagons_item .table_tarif .table_tarif_btn span,
.vagons_item .table_tarif .table_tarif_btn a {
  margin: 2px;
}
.vagons_item .row {
  margin: 0 -4px;
}
.vagons_item .row > div {
  padding: 4px;
}
.vagons_item .row .col12 {
  margin-top: 8px;
}
.vagons_item + .vagons_item {
  margin-top: 8px;
}
.vagons_item.vagons_item_sel {
  flex-wrap: wrap;
}
.vagons_item.vagons_item_sel .vagons_head {
  width: 100%;
}
.vagons_item.vagons_item_sel .vagons_head .row {
  justify-content: space-between;
}
.vagons_item.vagons_item_sel .vagons_head .row > div {
  width: auto;
  margin-top: 0;
}
.vagons_item.vagons_item_sel .vagons_body {
  width: 100%;
}
.vagons_item.vagons_item_sel .vagons_body_place {
  width: 46%;
}
.vagons_item.vagons_item_sel .vagons_body_serv {
  width: 39.9%;
}
.vagons_item_mesta {
  flex-wrap: wrap;
}
.vagons_item_mesta .vagon_block {
  border-radius: 12px 12px 0 0;
}
.vagons_item_mesta .vagons_head {
  border-radius: 0 0 14px 14px;
  width: 100%;
}
.vagons_item_mesta .vagons_body {
  width: 100%;
}
.vagons_check {
  display: flex;
  align-items: center;
}
.vagons_check .pay_group {
  margin: 0 0 0 24px;
}
.vagons_check .pay_group label {
  width: auto;
}
.but_vagon_wrap {
  display: flex;
  margin: 0 -4px;
}
.but_vagon_wrap button {
  background: #E3E5F6;
  cursor: pointer;
  width: 92px;
  min-width: 92px;
  height: 54px;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  border-radius: 14px;
  margin: 0 4px;
  text-align: left;
}
.but_vagon_wrap button span {
  color: rgba(90, 90, 90, 0.4);
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
  display: block;
  margin-top: 2px;
}
.but_vagon_wrap button.active {
  background: #645EFC;
  color: #FFF;
}
.but_vagon_wrap button.active span {
  color: rgba(255, 255, 255, 0.8);
}
.but_vagon_wrap button:hover {
  background: #7378FC;
  color: #FFF;
}
.but_vagon_wrap button:hover span {
  color: rgba(255, 255, 255, 0.8);
}
.vagons_place_price {
  display: flex;
  justify-content: space-between;
}
.vagons_place_price > div:nth-child(2) {
  display: flex;
  align-items: center;
}
.vagons_place_price > div:nth-child(2) span {
  display: block;
  margin-left: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
}
.vagons_place_price > div:nth-child(2) span b {
  font-weight: 800;
}
.flex_title_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search__wrap + .flex_title_head {
  padding-top: 20px;
}
.line_dash {
  border-bottom: 1px dashed #645EFC;
}
.check_group {
  display: flex;
  margin: 0 -10px;
  padding: 10px 0;
}
.check_group label {
  padding: 5px 10px;
}
.check_group.check_group_trio label {
  padding: 5px 8px;
}
.vagons_row_place .vagons_body_item {
  border-bottom: 0;
  align-items: center;
}
.vagons_row_place .vagons_body_item.salon_filter {
  flex-wrap: wrap;
  padding: 11px 8px 6px 8px;
}
.vagons_row_place .vagons_body_item.salon_filter .salon_filter_dp {
  display: flex;
  align-items: center;
  max-width: 100%;
  flex: 1 1 auto;
}
.vagons_row_place .check_group {
  max-width: 384px;
  width: 100%;
}
.vagons_row_place .check_group.check_group_trio {
  max-width: none;
  width: auto;
}
.razm_group > div {
  width: 206px;
  padding: 0 3px;
}
.razm_group .fake_select {
  color: #404040;
  padding: 14px 12px;
  justify-content: space-between;
  border-radius: 14px;
  border: 1px solid rgba(90, 90, 90, 0.3);
}
.razm_group.razm_group_trio > div {
  width: 169px;
}
.vagons_diapazon {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
}
.vagons_diapazon > * {
  padding-left: 8px;
}
.vagons_diapazon .form_group {
  width: 68px;
}
.vagons_diapazon .form_group .input_field {
  text-align: center;
}
.vagons_diapazon b {
  width: 87px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}
.vagons_diapazon span {
  color: #645EFC;
}
.salon {
  display: flex;
  width: 1236px;
  height: 165px;
  padding: 0.4px 10px 7px 69px;
  background: url(./img/storey.png) no-repeat 0 0 / contain;
}
.salon .salon_group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 160px;
  width: 263px;
}
.salon .salon_group .seat {
  position: relative;
  cursor: pointer;
  width: 27px;
  height: 27px;
  margin-right: 26px;
  margin-top: 6.26px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  padding: 4px 5px 0 0;
  z-index: 1;
  font-family: Arial;
  text-align: right;
}
.salon .salon_group .seat:nth-child(4n+3) {
  margin-top: 32.24px;
}
.salon .salon_group .seat:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(./img/seat/seat_norm.svg) no-repeat 0 0;
  z-index: -1;
}
.salon .salon_group .seat.seat_free:before {
  background: url(./img/seat/seat_free.svg) no-repeat 0 0;
}
.salon .salon_group .seat.seat_clos {
  font-size: 0;
}
.salon .salon_group .seat.seat_clos:before {
  background: url(./img/seat/seat_clos.svg) no-repeat 0 0;
  font-size: 0;
}
.salon .salon_group .seat.seat_nofilt:before {
  background: url(./img/seat/seat_nofilt.svg) no-repeat 0 0;
}
.salon .salon_group .seat.seat_sel {
  color: #fff;
}
.salon .salon_group .seat.seat_sel:before {
  background: url(./img/seat/seat_sel.svg) no-repeat 0 0;
}
.salon .salon_group .seat.seat_empty:before {
  content: none;
}
.salon .salon_group.salon_group2 {
  margin-left: 57px;
  width: 451px;
}
.salon .salon_group.salon_group2 .seat {
  margin-right: 32px;
  padding: 2px 5px 0 0;
}
.salon .salon_group.salon_group2 .seat:before {
  transform: scaleX(-1);
}
.salon .salon_group.salon_group3 {
  margin-left: -3px;
  width: 162px;
}
.salon .salon_group.salon_group3 .seat {
  margin-right: 28px;
}
.salon .salon_group.salon_group4 {
  margin-left: 62px;
  width: 76px;
}
.salon .salon_group.salon_group4 .seat {
  margin-right: 10px;
}
.salon .salon_group.salon_group4 .seat:before {
  transform: scaleX(-1);
}
.salon_legend {
  display: flex;
  margin: 14px -6px 0;
  width: 560px;
}
.salon_legend span {
  display: flex;
  align-items: center;
  padding: 4px 20px 4px 6px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}
.salon_legend span:before {
  content: "";
  width: 18px;
  height: 18px;
  margin-right: 10px;
  border-radius: 4px;
}
.salon_legend span:last-child {
  padding-right: 0;
}
.salon_legend_free:before {
  background: #6ACBAE;
}
.salon_legend_close:before {
  background: rgba(90, 90, 90, 0.4);
}
.salon_legend_sel:before {
  background: #645EFC;
}
.salon_legend_nofilt:before {
  background: #FFD8B4;
}
.select_vagon {
  display: none;
}
.salon_alert {
  width: 100%;
}
.salon_alert .salon_alert_inf {
  border-radius: 14px;
  border: 1px dashed #404040;
  background: rgba(90, 90, 90, 0.1);
  display: flex;
  height: 165px;
  padding: 0 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}
.search_area {
  border: 1px solid rgba(64, 64, 64, 0.3);
  padding: 11px 11px;
  display: flex;
  border-radius: 14px;
}
.search_area input {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border: 0;
  outline: none;
  padding: 0;
  height: 24px;
}
.search_area button {
  width: 24px;
  min-width: 24px;
  margin-left: 6px;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
}
.alpha_wrap .alpha_head {
  overflow: hidden;
  border-radius: 14px 14px 0 0;
  background: #A2A2BA;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
}
.alpha_wrap .alpha_head a {
  padding: 19px 12px;
  color: #FFFFFF;
  display: block;
}
.alpha_wrap .alpha_head a.active,
.alpha_wrap .alpha_head a:hover {
  background: rgba(64, 64, 64, 0.3);
  border-radius: 14px;
}
.alpha_wrap .alpha_body {
  padding: 6px 12px;
  border: 1px solid rgba(64, 64, 64, 0.3);
  border-radius: 0 0 20px 20px;
  display: flex;
}
.alpha_wrap .alpha_body .alpha_zag {
  width: 157px;
  min-width: 157px;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 0 4px;
  height: 32px;
  display: flex;
  align-items: center;
}
.alpha_wrap .alpha_body .alpha_alfavit {
  display: flex;
  overflow: hidden;
  margin-right: 6px;
}
.alpha_wrap .alpha_body .alpha_alfavit > * {
  cursor: pointer;
  margin-left: 6px;
  padding: 6px 8px;
  border-radius: 12px;
  background: #E3E5F6;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  text-transform: uppercase;
  color: #645EFC;
}
.alpha_wrap .alpha_body .alpha_alfavit > *.active,
.alpha_wrap .alpha_body .alpha_alfavit > *:hover {
  background: #645EFC;
  color: #FFFFFF;
}
.alpha_wrap .alpha_body .alpha_reset {
  cursor: pointer;
  width: 142px;
  min-width: 142px;
  margin: 0 0 0 auto;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  padding: 0 8px;
  height: 32px;
  display: flex;
  align-items: center;
}
.alpha_wrap .alpha_body .alpha_more {
  cursor: pointer;
  width: 30px;
  min-width: 30px;
  border-radius: 12px;
  margin: 0 0 0 auto;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  padding: 0;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E3E5F6;
  color: #645EFC;
}
.alpha_wrap .alpha_body .alpha_more.active,
.alpha_wrap .alpha_body .alpha_more:hover {
  background: #645EFC;
  color: #FFFFFF;
}
.alpha_wrap .alpha_body .alpha_hide {
  display: none;
  margin-left: 6px;
  background: none;
  color: rgba(35, 35, 43, 0.3);
  width: auto;
  min-width: 0;
}
.alpha_wrap .alpha_body .alpha_hide:hover {
  background: none;
  color: rgba(35, 35, 43, 0.3);
}
.alpha_wrap .alpha_body.active .alpha_alfavit {
  flex-wrap: wrap;
  margin-top: -6px;
}
.alpha_wrap .alpha_body.active .alpha_alfavit > * {
  margin-top: 6px;
}
.alpha_wrap .alpha_body.active .alpha_more {
  display: none;
}
.alpha_wrap .alpha_body.active .alpha_hide {
  display: flex;
}
@media (max-width: 1199px) {
  .search_widget {
    margin-top: 0;
  }
  .fields:not(.active) .fields_line {
    width: 100%;
    flex-wrap: wrap;
    border: 0;
    background: none;
  }
  .fields:not(.active) .fields_line .fields_direction {
    width: 100%;
    height: 64px;
    border-radius: 20px;
    border-color: rgba(90, 90, 90, 0.3);
    border-style: solid;
    border-width: 1px;
    background: #FFF;
    margin-bottom: 2px;
  }
  .fields:not(.active) .fields_line .fields_direction + .dates {
    width: 60%;
  }
  .fields:not(.active) .fields_line .fields_dates {
    height: 64px;
    padding-left: 0;
    border-radius: 20px 0 0 20px;
    border-color: rgba(90, 90, 90, 0.3);
    border-style: solid;
    border-width: 1px 0 1px 1px;
    background: #FFF;
  }
  .fields:not(.active) .fields_subm {
    width: 40%;
    position: absolute;
    left: 60%;
    top: 66px;
  }
  .fields:not(.active) .fields_subm .pass-clas {
    /*border-radius: 0 @radius-20 @radius-20 0;*/
  }
  .fields:not(.active) .fields_subm .pass-clas .form_input {
    padding: 0;
  }
  .fields.active .fields_line + .fields_line {
    margin-top: 2px;
  }
  .fields.active .fields_subm {
    margin-top: 2px;
  }
  .search_hotels .fields:not(.active) .fields_line {
    width: 66%;
    background: #FFF;
    border-width: 1px 0 1px 1px;
    border-color: rgba(64, 64, 64, 0.3);
    border-style: solid;
  }
  .search_hotels .fields:not(.active) .fields_dates {
    border: 0;
    height: 62px;
  }
  .search_hotels .fields:not(.active) .fields_subm {
    width: 34%;
    position: static;
  }
  .search_hotels .fields:not(.active) .fields_subm .pass-clas {
    border-radius: 0;
  }
  .search_zd .fields:not(.active) .fields_line {
    width: calc(100% - 64px);
    border-width: 1px;
    background: #fff;
  }
  .search_zd .fields:not(.active) .fields_line .fields_direction {
    width: 50%;
    border-radius: 20px 0 0 20px;
    margin-bottom: 0 !important;
    border-width: 0;
  }
  .search_zd .fields:not(.active) .fields_line .dates {
    width: 50%;
  }
  .search_zd .fields:not(.active) .fields_subm {
    width: 64px;
    position: relative;
    top: 0 !important;
    left: auto;
  }
  .search_checkbox .checkbox-container {
    width: 50%;
    margin-top: 2px;
  }
  .search_widget_form {
    padding-bottom: 58px;
  }
  .search_widget_form.active .search_checkbox label:nth-child(n+5) {
    width: 50%;
  }
  .search_wrap {
    margin-top: 19px;
  }
  .search_card_wrap > div {
    overflow: auto;
    padding-bottom: 8px;
    margin-bottom: -8px;
  }
  .search_card_wrap .search_card {
    width: 245px;
    min-width: 245px;
  }
  .has_error .fields:not(.active) .fields_direction {
    margin-bottom: 22px;
  }
  .has_error .fields:not(.active) .fields_subm {
    top: 106px;
  }
  .search__modal .fields:not(.active) .fields_dates {
    border: 0;
    height: 62px;
  }
  .search__modal .fields:not(.active) .fields_subm {
    width: 13.9%;
    position: relative;
    left: 0;
    top: 0;
  }
  .table_new .table_price b {
    font-size: 15px;
    line-height: 20px;
  }
  .table_time_txt {
    font-size: 18px;
    line-height: 24px;
  }
  .table_wrap .table_item .table_icon + div,
  .table_wrap .table_item .table_icon + div + div {
    line-height: 21px;
  }
}
@media (min-width: 576px) and (max-width: 1439px) {
  .fields.active .fields_dates .date {
    display: none;
  }
  .fake_date_all {
    display: block !important;
    width: 100% !important;
  }
}
@media (max-width: 991px) {
  .search_hotels .fields:not(.active) .fields_line {
    width: 100%;
    background: none;
    border: none;
  }
  .search_hotels .fields:not(.active) .fields_hotels {
    width: 100%;
    height: 64px;
    border-radius: 20px;
    border-color: rgba(64, 64, 64, 0.3);
    border-style: solid;
    border-width: 1px;
    background: #FFF;
    margin-bottom: 2px;
  }
  .search_hotels .fields:not(.active) .fields_hotels + .dates {
    width: calc(66.6666% - 42px);
  }
  .search_hotels .fields:not(.active) .fields_hotels + .dates .fields_dates {
    border-radius: 20px 0 0 20px;
    border-color: rgba(64, 64, 64, 0.3);
    border-style: solid;
    border-width: 1px 0 1px 1px;
  }
  .search_hotels .fields:not(.active) .fields_hotels + .dates .form_input {
    padding: 11px 12px;
  }
  .search_hotels .fields:not(.active) .fields_subm {
    width: calc(33.3333% + 40px);
    position: absolute;
    top: 66px;
    left: calc(66.6666% - 42px);
  }
  .search_hotels .fields:not(.active) .fields_subm .pass-clas {
    border-width: 1px 0 1px 0;
  }
  .search_hotels .fields:not(.active) .fields_subm .pass-clas .fake_text {
    padding: 11px 12px;
  }
  .search_hotels .has_error .fields:not(.active) .fields_hotels {
    margin-bottom: 22px;
  }
  .search_hotels .has_error .fields:not(.active) .fields_subm {
    top: 106px;
  }
  .search_zd .fields:not(.active) .fields_line {
    width: 100%;
    border: 0;
    background: transparent;
  }
  .search_zd .fields:not(.active) .fields_line .fields_direction {
    width: 100%;
    border-radius: 20px;
    border-width: 1px;
    margin-bottom: 40px !important;
  }
  .search_zd .fields:not(.active) .fields_line .dates {
    width: 100%;
    padding-right: 64px;
  }
  .search_zd .fields:not(.active) .fields_subm {
    position: absolute;
    left: calc(100% - 64px);
    top: 104px !important;
    width: 64px;
    height: 64px;
  }
  .search_zd .has_error .fields:not(.active) .fields_direction {
    margin-bottom: 50px !important;
  }
  .search_zd .has_error .fields:not(.active) .fields_subm {
    top: 134px !important;
  }
  .search__modal {
    display: none;
  }
  .search__phone {
    display: flex;
    align-items: center;
    background: #E3E5F6;
    padding: 12px 20px;
    margin: 0 -14px 4px;
  }
  .search__phone .search_phone_data {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 52px);
    background: #FFFFFF;
    border-radius: 8px;
    padding: 11px 20px;
  }
  .search__phone .search_phone_data .search_phone_data_title {
    color: #645EFC;
    display: flex;
  }
  .search__phone .search_phone_data .search_phone_data_title svg {
    margin: 0 3px;
  }
  .search__phone .search_phone_data .search_phone_data_date {
    font-weight: 500;
  }
  .search__phone .search_phone_btn {
    display: flex;
    margin-left: 11px;
  }
  .search__phone .search_phone_btn a {
    display: block;
    margin-left: 9px;
    width: 32px;
    height: 32px;
    color: #404040;
  }
  .table_head {
    display: none;
  }
  .table_wrap .table_item {
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
  }
  .table_wrap .table_item .table_tarif {
    grid-column: 1 / span 3;
  }
  .table_wrap .table_item > div {
    background: #E3E5F6;
  }
  .table_wrap .table_item > div:nth-child(n+5) {
    background: #FFFFFF;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .table_wrap .table_item > div:nth-child(1) {
    grid-column: 1 / span 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px 10px 12px;
  }
  .table_wrap .table_item > div:nth-child(1) b {
    display: flex;
    align-items: center;
  }
  .table_wrap .table_item > div:nth-child(1) b span {
    margin-left: 25px;
  }
  .table_wrap .table_item > div:nth-child(5) {
    grid-column: 1 / span 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px;
  }
  .table_wrap .table_item > div:nth-child(2) {
    padding-left: 12px;
    padding-bottom: 16px;
  }
  .table_wrap .table_item > div:nth-child(4) {
    padding-right: 12px;
    padding-bottom: 16px;
  }
  .table_wrap .table_item > div:nth-child(2),
  .table_wrap .table_item > div:nth-child(6) {
    padding-left: 12px;
  }
  .table_wrap .table_item > div:nth-child(4),
  .table_wrap .table_item > div:nth-child(8) {
    padding-right: 12px;
  }
  .table_wrap .table_item > .table_tarif {
    padding: 8px 135px 16px 12px !important;
    border-top: 1px solid #EEEEF8;
  }
  .table_wrap .table_icon {
    margin: 0 -5px;
  }
  .table_wrap .table_price {
    text-align: right;
  }
  .table_wrap .table_tarif_btn b {
    font-size: 10px !important;
    line-height: 12px !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 75px;
    min-width: 75px;
  }
  .table_wrap .table_tarif_btn span,
  .table_wrap .table_tarif_btn a {
    padding: 8px 10px !important;
  }
  .table_item .table_vib_btn {
    bottom: 10px;
    padding: 4px !important;
  }
  .font_22 {
    font-size: 18px;
  }
  .vg_zaglav_reis {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .vagon_block .vagon_block_item {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 16px 2px;
  }
  .vagon_block .vagon_block_item > div:nth-child(1),
  .vagon_block .vagon_block_item > div:nth-child(5) {
    grid-column: 1 / span 3;
  }
  .vagons_item {
    flex-wrap: wrap;
  }
  .vagons_item .vagons_head {
    width: 100%;
    padding: 6px 10px;
  }
  .vagons_item .vagons_body {
    width: 100%;
  }
  .vagons_item .vagons_body_item {
    padding: 6px 0 11px;
  }
  .vagons_item .vagons_body_item .vagons_body_num {
    display: none;
  }
  .vagons_item .vagons_body_item .vagons_body_place {
    width: 60.8%;
  }
  .vagons_item .vagons_body_item .vagons_body_serv {
    width: 39.2%;
  }
  .vagons_item .row .col12 {
    margin-top: 0;
  }
  .vagons_item .row > div:nth-child(1) {
    width: 11.5%;
  }
  .vagons_item .row > div:nth-child(2) {
    width: 11.8%;
  }
  .vagons_item .row > div:nth-child(3) {
    width: 27.1%;
  }
  .vagons_item .row > div:nth-child(4) {
    width: 100%;
    order: 1;
  }
  .vagons_item .row > div:nth-child(5) {
    width: 49.2%;
  }
  .vagons_item .table_tarif .table_tarif_btn {
    flex-wrap: nowrap;
  }
  .vagons_item.vagons_item_sel .vagons_head .row > div:nth-child(1) {
    width: 11.5%;
  }
  .vagons_item.vagons_item_sel .vagons_head .row > div:nth-child(2) {
    width: 11.8%;
  }
  .vagons_item.vagons_item_sel .vagons_head .row > div:nth-child(3) {
    width: 27.1%;
  }
  .vagons_item.vagons_item_sel .vagons_head .row > div:nth-child(4) {
    width: 100%;
  }
  .vagons_item.vagons_item_sel .vagons_head .row > div:nth-child(5) {
    width: 49.2%;
  }
  .vagons_item .table_tarif .table_tarif_btn {
    display: flex;
    white-space: nowrap;
  }
  .vagons_item .table_tarif .table_tarif_btn span {
    background: rgba(90, 90, 90, 0.1);
    border-radius: 8px;
    font-size: 10px;
    line-height: 12px;
    padding: 8px 10px;
  }
  .vagon_class .flex_group_but {
    width: auto;
    margin-top: 0;
  }
  .vagon_class .flex_group_but > .btn {
    display: block;
    width: auto;
    margin: 0;
  }
  .vagon_class .flex_group_but > div {
    display: none;
  }
  .vagons_row_place .vagons_body_item.salon_filter {
    padding: 11px 0 6px;
    border-top: 1px solid #C5C3DD;
  }
  .vagons_row_place .vagons_body_item.salon_filter .salon_filter_dp {
    margin-top: 10px;
    margin-bottom: -5px;
  }
  .vagons_diapazon b {
    width: 68px;
    font-size: 10px;
    font-weight: 800;
    line-height: 12px;
  }
}
@media (max-width: 767px) {
  .fields:not(.active) .fields_line {
    padding-bottom: 2px;
    padding-top: 0;
  }
  .fields:not(.active) .fields_line .fields_direction + .dates {
    width: 100%;
  }
  .fields:not(.active) .fields_line .fields_dates {
    border-radius: 20px;
    border-width: 1px;
  }
  .fields:not(.active) .fields_subm {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
  }
  .fields:not(.active) .fields_subm .pass-clas {
    border-radius: 20px 0 0 20px;
    border-width: 1px 0 1px 1px;
  }
  .fields.active .fields_line {
    padding: 0;
  }
  .search_widget_form .fake_text {
    border-radius: 20px;
  }
  .fields .dates .form_input {
    border-radius: 20px;
  }
  .search_hotels .fields:not(.active) .fields_line {
    width: 100%;
    background: none;
    border: none;
  }
  .search_hotels .fields:not(.active) .fields_hotels {
    width: 100%;
    height: 64px;
    border-radius: 20px;
    border-color: rgba(64, 64, 64, 0.3);
    border-style: solid;
    border-width: 1px;
    background: #FFF;
    margin-bottom: 2px;
  }
  .search_hotels .fields:not(.active) .fields_hotels + .dates {
    width: calc(66.6666% - 42px);
  }
  .search_hotels .fields:not(.active) .fields_hotels + .dates .fields_dates {
    border-radius: 20px 0 0 20px;
    border-color: rgba(64, 64, 64, 0.3);
    border-style: solid;
    border-width: 1px 0 1px 1px;
  }
  .search_hotels .fields:not(.active) .fields_hotels + .dates .form_input {
    padding: 11px 12px;
  }
  .search_hotels .fields:not(.active) .fields_subm {
    width: calc(33.3333% + 43px);
    position: absolute;
    top: 66px;
    left: calc(66.6666% - 42px);
  }
  .search_hotels .fields:not(.active) .fields_subm .pass-clas {
    border-width: 1px 0 1px 0;
  }
  .search_hotels .fields:not(.active) .fields_subm .pass-clas .fake_text {
    padding: 11px 12px;
  }
  .search_zd .fields:not(.active) .fields_line .fields_dates {
    border-radius: 20px 0 0 20px;
  }
  .search_service {
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 8px;
    margin-bottom: -8px;
  }
  h1 + .search_widget {
    margin-top: 20px;
  }
  .has_error .fields:not(.active) .fields_subm {
    top: 0;
  }
  .search__phone {
    margin: 0 -20px 4px;
  }
  .side_filtr .col6 {
    width: 50%;
  }
  .modal_tabl_scroll {
    max-height: 348px;
  }
  .modal_side.modal_mob_bottom {
    height: auto;
    top: auto;
    bottom: 0;
    transform: translate(-50%, 0%);
    border-radius: 30px 30px 0 0;
  }
  .modal_side.modal_mob_bottom .modal_head {
    margin-top: 28px;
    margin-bottom: -15px;
  }
  .modal_side.modal_mob_bottom .modal_close {
    width: 67px;
    height: 5px;
    border-radius: 100px;
    background: #EEEEF8;
    left: 50%;
    right: auto;
    top: -25px;
    transform: translate(-50%, 0%);
  }
  .modal_side.modal_mob_bottom .modal_close svg {
    display: none;
  }
  .modal_tabl .modal_tabl_head {
    display: none;
  }
  .modal_tabl .modal_tabl_item {
    flex-wrap: wrap;
    padding: 0 14px;
  }
  .modal_tabl .modal_tabl_item div {
    padding: 3px 4px 4px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .modal_tabl .modal_tabl_item div:before {
    margin-bottom: 2px;
    font-size: 10px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(90, 90, 90, 0.6);
    white-space: nowrap;
  }
  .modal_tabl .modal_tabl_item div:nth-child(1) {
    height: 24px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% + 28px);
    background: #EEEEF8;
    margin: 0 -14px;
    padding: 2px 14px 3px;
  }
  .modal_tabl .modal_tabl_item div:nth-child(1):before {
    content: "станция";
    margin-right: 8px;
    display: inline;
    margin-bottom: 0;
  }
  .modal_tabl .modal_tabl_item div:nth-child(2) {
    width: 33.3333%;
  }
  .modal_tabl .modal_tabl_item div:nth-child(2):before {
    content: "прибытие";
  }
  .modal_tabl .modal_tabl_item div:nth-child(3) {
    width: 33.3333%;
  }
  .modal_tabl .modal_tabl_item div:nth-child(3):before {
    content: "стоянка, мин.";
  }
  .modal_tabl .modal_tabl_item div:nth-child(4) {
    width: 33.3333%;
  }
  .modal_tabl .modal_tabl_item div:nth-child(4):before {
    content: "отправление";
  }
  .font_22 {
    font-size: 15px;
  }
  .flex_title_head .btn-icon-text {
    padding: 7px 12px;
  }
  .flex_title_head .btn-icon-text span {
    display: none;
  }
  .flex_title_head .btn-icon-text span + svg {
    margin-left: 0;
  }
  .vagons_item .vagons_body_item {
    flex-wrap: wrap;
  }
  .vagons_item .vagons_body_item .vagons_body_place {
    width: 100%;
  }
  .vagons_item .vagons_body_item .vagons_body_place .flex_group_but {
    margin-top: 0;
  }
  .vagons_item .vagons_body_item .vagons_body_serv {
    width: 100%;
    flex-direction: row;
  }
  .vagons_item .vagons_body_item .vagons_body_serv .vagons_body_serv_btn {
    margin-top: 14px;
  }
  .vagons_item .row > div:nth-child(1) {
    width: 18%;
  }
  .vagons_item .row > div:nth-child(2) {
    width: 18.4%;
  }
  .vagons_item .row > div:nth-child(3) {
    width: 26.5%;
  }
  .vagons_item .row > div:nth-child(5) {
    width: 37.1%;
  }
  .vagons_item .table_tarif .table_tarif_btn span {
    padding: 8px 10px;
  }
  .vagon_span {
    max-width: calc(100% - 52px);
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 8px;
    margin-bottom: -8px;
  }
  .vagon_span span {
    white-space: nowrap;
  }
  .vg_zag svg {
    padding: 0 4px;
    width: 26px;
  }
  .font_22 b + span {
    margin-left: 4px;
  }
  .vagons_row_place .vagons_body_item.salon_filter {
    padding: 6px 0 6px;
  }
  .vagons_row_place .vagons_body_item.salon_filter .salon_filter_dp {
    margin-top: 10px;
    margin-bottom: -5px;
  }
  .razm_group {
    width: calc(100% - 240px);
  }
  .razm_group > div {
    width: 50%;
  }
  .razm_group.razm_group_trio > div {
    width: 33.3333%;
  }
  .salon_filter_dp {
    flex-wrap: wrap;
  }
  .salon_filter_dp .razm_group_trio {
    width: 100%;
  }
  .salon_filter_dp .razm_group_trio + .vagons_diapazon {
    width: 100%;
    margin-top: 8px;
  }
  .salon_filter_dp .razm_group_trio + .vagons_diapazon b {
    width: 95px;
    padding-left: 0;
  }
  .salon_filter_dp .razm_group_trio + .vagons_diapazon .form_group {
    width: calc(50% - 60px);
  }
  .salon_alert .salon_alert_inf {
    height: auto;
    padding: 30px;
  }
  .alpha_wrap .alpha_body .alpha_zag {
    width: 92px;
    min-width: 92px;
  }
}
@media (max-width: 575px) {
  .search_widget {
    padding: 22px 10px 0;
  }
  .search_widget_form {
    padding-bottom: 48px;
  }
  .search_route {
    margin: 20px 0 8px;
  }
  .search_wrap {
    margin-top: 8px;
  }
  .view_filtr_wrap {
    margin: 26px -10px 0;
    width: calc(100% + 20px);
  }
  .fields:not(.active) .fields_line .fields_direction {
    border: none;
    background: none;
    flex-wrap: wrap;
  }
  .search_checkbox .checkbox-container {
    width: 100%;
  }
  .search_widget_form.active .search_checkbox label:nth-child(n+5) {
    width: 100%;
  }
  .fields.active .fields_line {
    border: none;
    height: auto;
    background: none;
    flex-wrap: wrap;
  }
  .fields.active .fields_direction {
    width: 100%;
    flex-wrap: wrap;
  }
  .fields.active .dates {
    width: 100%;
  }
  .fields.active .fields_dates {
    width: 100%;
    flex-wrap: wrap;
    padding: 0;
  }
  .fields.active .fields_dates > div {
    width: 100%;
    height: 64px;
    border-radius: 20px;
    border: 1px solid rgba(90, 90, 90, 0.3);
    background: #ffffff;
    margin-top: 2px;
    display: flex;
    align-items: center;
  }
  .fields.active .fields_subm {
    margin: 2px 0 0;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
  }
  .fields.active .fields_subm > div {
    width: 100%;
    padding: 0;
  }
  .fields.active .fields_subm .fields_result a {
    position: absolute;
    width: 64px;
    border-radius: 20px;
    top: auto;
    bottom: 0;
  }
  .fields.active .fields_subm .fields_result a span {
    display: none;
  }
  .fields.active .fields_add_line {
    margin-top: 2px;
  }
  .fields_direction {
    height: auto !important;
  }
  .fields_direction > div {
    width: 100%;
    height: 64px;
    border-radius: 20px;
    border: 1px solid rgba(90, 90, 90, 0.3);
    background: #ffffff;
    display: flex;
    align-items: center;
    margin-top: 2px;
  }
  .fields_direction > div .fake_input {
    width: 100%;
  }
  .fields_direction .swap-directions {
    display: none;
  }
  .search_hotels .fields:not(.active) .fields_hotels + .dates {
    width: 100%;
  }
  .search_hotels .fields:not(.active) .fields_hotels + .dates .fields_dates {
    border-radius: 20px;
  }
  .search_hotels .fields:not(.active) .fields_subm {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
  }
  .search_hotels .fields:not(.active) .fields_subm .pass-clas {
    border-width: 1px 0 1px 1px;
    border-radius: 20px 0 0 20px;
  }
  .search_hotels .has_error .fields:not(.active) .fields_subm {
    top: 0;
  }
  .search_zd .fields:not(.active) .fields_subm {
    top: 210px !important;
  }
  .search_zd .fields:not(.active) .direction + .direction {
    margin-top: 40px;
  }
  .search_zd .has_error .fields:not(.active) .fields_subm {
    top: 250px !important;
  }
  .search_zd .has_error .fields:not(.active) .direction + .direction {
    margin-top: 50px;
  }
  .direct_variants {
    margin-top: 1px;
  }
  .remove_line {
    top: auto;
    bottom: 0;
  }
  .has_error .fields:not(.active) .direction + .direction {
    margin-top: 22px;
  }
  .has_error .fields:not(.active) .fields_subm {
    top: 0;
  }
  .has_error .fields.active .direction {
    margin-bottom: 20px;
  }
  .has_error .fields.active .fields_line + .fields_line {
    margin-top: 22px !important;
  }
  .table_wrap .table_item {
    display: flex;
    flex-wrap: wrap;
    background: #F1EAFF;
  }
  .table_wrap .table_item .table_tarif {
    width: 100%;
  }
  .table_wrap .table_item .table_icon {
    width: calc(100% + 10px);
  }
  .table_wrap .table_item > div:nth-child(1) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px 10px 12px;
  }
  .table_wrap .table_item > div:nth-child(1) b span {
    margin-left: 15px;
  }
  .table_wrap .table_item > div:nth-child(2),
  .table_wrap .table_item > div:nth-child(4) {
    width: 75%;
    padding-left: 12px;
    min-height: 80.5px;
  }
  .table_wrap .table_item > div:nth-child(3) {
    width: 25%;
    position: absolute;
    top: 45px;
    right: 0;
    height: 159px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .table_wrap .table_item > div:nth-child(3) .table_linepoint {
    width: 2px;
    margin: 8px auto;
    flex: 1;
  }
  .table_wrap .table_item > div:nth-child(3) .table_linepoint:before {
    right: -1px;
  }
  .table_wrap .table_item > div:nth-child(3) .table_linepoint:after {
    left: -1px;
    top: auto;
    bottom: -3px;
  }
  .table_wrap .table_item > div:nth-child(5) {
    justify-content: flex-start;
  }
  .table_wrap .table_item > div:nth-child(6) {
    width: 50%;
  }
  .table_wrap .table_item > div:nth-child(7) {
    width: 20%;
  }
  .table_wrap .table_item > div:nth-child(8) {
    width: 30%;
  }
  .side_filtr .col6 {
    width: 100%;
  }
  .font_22 {
    font-size: 14px;
    white-space: nowrap;
    flex-wrap: wrap;
    line-height: 20px;
  }
  .vg_zag svg {
    height: 22px;
  }
  .vagon_block .vagon_block_item {
    padding: 4px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
  .vagon_block .vagon_block_item > div:nth-child(2) {
    width: 75%;
  }
  .vagon_block .vagon_block_item > div:nth-child(3) {
    width: 25%;
    position: absolute;
    top: 41px;
    right: 0;
    height: 159px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .vagon_block .vagon_block_item > div:nth-child(3) .table_linepoint {
    width: 2px;
    margin: 8px auto;
    flex: 1;
  }
  .vagon_block .vagon_block_item > div:nth-child(3) .table_linepoint:before {
    right: -3px;
  }
  .vagon_block .vagon_block_item > div:nth-child(3) .table_linepoint:after {
    left: -3px;
    top: auto;
    bottom: -3px;
  }
  .vagon_block .vagon_block_item > div:nth-child(4) {
    width: 75%;
  }
  .vagon_block .vagon_block_item > div:nth-child(5) {
    width: 100%;
  }
  .vg_zaglav {
    width: 50px;
    margin-right: 4px;
  }
  .vg_zaglav .vg_zaglav_ico {
    display: none;
  }
  .vagon_class {
    padding: 10px 10px;
  }
  .vagons_item .row > div:nth-child(1) {
    width: 36%;
  }
  .vagons_item .row > div:nth-child(2) {
    width: 36%;
  }
  .vagons_item .row > div:nth-child(3) {
    width: 50%;
  }
  .vagons_item .row > div:nth-child(5) {
    width: 50%;
  }
  .vagons_item .vagons_body_item .vagons_body_serv {
    flex-direction: column;
  }
  .vagons_item .vagons_body_item .vagons_body_serv .vagons_body_serv_btn {
    margin-top: 8px;
  }
  .vagons_item .vagons_body_item .vagons_body_serv .vagons_body_serv_btn .btn {
    margin: 0;
    width: 100%;
  }
  .vagons_item.vagons_item_sel .vagons_head .row {
    justify-content: inherit;
  }
  .vagons_item.vagons_item_sel .vagons_head .row > div:nth-child(1) {
    width: 36%;
  }
  .vagons_item.vagons_item_sel .vagons_head .row > div:nth-child(2) {
    width: 36%;
  }
  .vagons_item.vagons_item_sel .vagons_head .row > div:nth-child(3) {
    width: 50%;
  }
  .vagons_item.vagons_item_sel .vagons_head .row > div:nth-child(5) {
    width: 50%;
  }
  .vagon_slide {
    display: none;
  }
  .select_vagon {
    display: block;
    width: 100%;
    z-index: 6;
    margin: -2px 0 -6px 0;
    padding: 4px 6px !important;
  }
  .select_vagon .fake_select {
    border: 1px solid rgba(90, 90, 90, 0.3);
    padding: 14px 12px;
    justify-content: space-between;
    font-weight: 700;
    border-radius: 14px;
  }
  .select_vagon .fake_select > span {
    margin: 0 0 0 auto;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.4;
  }
  .select_vagon .fake_dropdown ul li {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
  }
  .select_vagon .fake_dropdown ul li > span {
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.4;
  }
  .vagons_check .line_zag_text_value {
    width: 52px;
  }
  .vagons_check .pay_group {
    margin: -6px 0 0 6px;
    flex-wrap: wrap;
    width: calc(100% - 58px);
    padding: 0;
  }
  .check_group {
    flex-wrap: wrap;
    margin-top: -10px;
  }
  .check_group label {
    width: 100%;
    padding: 10px;
  }
  .check_group.check_group.check_group_trio label {
    padding: 10px 10px;
  }
  .vagons_row_place .vagons_body_item.salon_filter .salon_filter_dp {
    margin-top: -4px;
    flex-wrap: wrap;
  }
  .razm_group {
    width: calc(100% + 6px);
    flex-wrap: wrap;
  }
  .razm_group > div {
    width: 100%;
    margin-top: 4px;
  }
  .vagons_diapazon {
    margin: 10px 0 0;
    width: 100%;
  }
  .vagons_diapazon b {
    width: 95px;
    padding-left: 0;
  }
  .vagons_diapazon .form_group {
    width: 94px;
  }
  .razm_group.razm_group_trio > div {
    width: 100%;
  }
  .salon_alert .salon_alert_inf {
    padding: 20px;
  }
}
.shem2 .search_widget_form .fake_text .simple-typeahead input {
  color: #645EFC;
}
.shem2 .fields .dates .form_input {
  color: #645EFC;
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.suggestion-block {
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 8px;
  /*border-radius: @radius-20;
  border: 1px solid fade(@primary-color-main, 30%);*/
  background: #FFF;
  max-width: 360px;
  max-height: 390px;
  overflow: auto;
}
.suggestion-block li {
  padding: 12px 16px 12px 40px;
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.suggestion-block li:hover {
  border-radius: 14px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), #645EFC;
}
.suggestion-block li .suggestion_ico {
  position: absolute;
  top: 13px;
  left: 16px;
  opacity: 0.4;
}
.suggestion-block li .suggestion_text {
  overflow: hidden;
  color: #404040;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-right: 15px;
  width: 220px;
  white-space: nowrap;
}
.suggestion-block li .suggestion_kod {
  color: rgba(64, 64, 64, 0.4);
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}
.suggestion-block li button {
  padding: 0;
}
.suggestion-hotels li {
  padding: 13px 16px;
  flex-wrap: wrap;
}
.suggestion-hotels li.suggestion_otel {
  padding: 4px 16px;
}
.suggestion-hotels li.suggestion_cat {
  padding-bottom: 4px;
}
.suggestion-hotels li.suggestion_cat .suggestion_text {
  color: #23232B;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.suggestion-hotels li .suggestion_info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: rgba(35, 35, 43, 0.6);
  opacity: 0.7;
}
.fake_input + .suggestion-block {
  position: absolute;
  top: 100%;
  left: 0;
}
.fake_select {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: #404040;
  border: 1px solid transparent;
}
.fake_select .icon18 {
  margin-left: 10px;
}
.fake_select + .fake_dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 2;
  margin-top: 10px;
}
.fake_select + .fake_dropdown.fake_dropdown_right {
  left: auto;
  right: 0;
}
.fake_select-disable {
  background: rgba(90, 90, 90, 0.1);
  color: rgba(90, 90, 90, 0.6) !important;
  border-color: rgba(90, 90, 90, 0.1) !important;
}
.fake_select:focus,
.fake_select:active,
.fake_select.focused {
  outline: none;
  color: #404040;
  background: rgba(100, 94, 252, 0.3);
  border: 1px solid #645EFC;
}
.fake_select:hover {
  border: 1px solid rgba(100, 94, 252, 0.4);
}
.fake_dropdown {
  background: #FFFFFF;
  outline: 0;
  overflow: hidden;
  border-radius: 14px;
  padding: 10px;
  min-width: 100%;
  display: none;
  /*&.active {
    opacity: 1;
    width: 100%;
    transition: all 279ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }*/
}
.fake_dropdown ul {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  max-height: 176px;
  min-height: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.fake_dropdown ul li {
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
}
.fake_dropdown ul li:hover,
.fake_dropdown ul li.active {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), #645EFC;
  border-radius: 14px;
}
.fake_dropdown ul.suggestion-block {
  max-height: 300px;
  margin-right: -10px;
  padding-right: 10px;
}
.fake_dropdown ul.suggestion-block li {
  padding: 8px 12px;
  white-space: normal;
}
.fake_dropdown ul.suggestion-block li .suggestion_text {
  width: auto;
}
.fake_dropdown ul.suggestion-block li .input_field {
  border: 0;
  padding: 5px 0;
}
.fake_wrap_subduo {
  text-align: left;
}
.fake_wrap_subduo > a {
  padding: 12px 20px;
}
.fake_wrap_subduo .fake_dropdown {
  overflow: visible;
}
.fake_wrap_subduo .fake_dropdown ul {
  overflow: visible;
}
.fake_wrap_subduo .fake_dropdown .fake_wrap {
  margin: -12px -20px;
}
.fake_wrap_subduo .fake_dropdown .fake_wrap > a {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), #645EFC;
  border-radius: 14px;
  padding: 12px 20px;
  border: 0;
}
.fake_wrap_subduo .fake_dropdown .fake_wrap > a svg {
  transform: rotate(-90deg);
}
.fake_wrap_subduo .fake_dropdown .fake_select:focus,
.fake_wrap_subduo .fake_dropdown .fake_select:active,
.fake_wrap_subduo .fake_dropdown .fake_select:hover,
.fake_wrap_subduo .fake_dropdown .fake_select.focused {
  border: 0;
  background: none;
}
.fake_wrap_subduo .fake_dropdown .fake_select + .fake_dropdown {
  left: 100%;
  top: 0;
  margin: 0 0 0 10px;
}
.select_pass_wrap {
  padding: 8px;
  max-width: 352px;
}
.select_pass_wrap .checkbox-container {
  padding: 13px 14px;
}
.select_pass_wrap.select_pass_wrap_top {
  bottom: 100%;
}
.pass_numb {
  display: flex;
  justify-content: space-between;
  padding: 13px 14px;
}
.pass_numb .pass_numb_text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pass_numb .pass_numb_text span {
  display: block;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 0.4;
}
.quantity_inner {
  display: flex;
  align-items: center;
}
.quantity_inner input {
  border: 0;
  background: none;
  line-height: 22px;
  font-size: 14px;
  text-align: center;
  color: rgba(35, 35, 43, 0.6);
}
.quantity_inner button {
  border: 0;
  background: rgba(227, 229, 246, 0.3);
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s all;
  padding: 0;
  margin: 0;
}
.quantity_inner button:hover {
  background: #e3e5f6;
}
.quantity_inner.quantity_inner_ramm {
  border-radius: 14px;
  justify-content: space-between;
  padding: 0 11px;
  border: 1px solid rgba(90, 90, 90, 0.3);
}
.select_class {
  padding: 12px 14px;
  border-top: 1px solid rgba(100, 94, 252, 0.3);
}
.select_class input {
  display: none;
}
.select_class input:checked + span {
  box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.3);
  border: 5px solid #7367F0;
}
.select_class label {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.select_class label + label {
  margin-top: 14px;
}
.select_class label span {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 30px;
  border: 1px solid rgba(35, 35, 43, 0.2);
  margin-right: 10px;
}
.select_room_wrap.shadow {
  border-radius: 20px;
  max-height: 390px;
  overflow: auto;
}
.select_room {
  border-bottom: 1px solid rgba(100, 94, 252, 0.3);
}
.room_child_wrap {
  flex-wrap: wrap;
  padding: 0 14px 13px;
}
.room_child_wrap .room_child_title {
  display: none;
}
.room_child_wrap .room_child_cont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.room_child_wrap .room_child_cont > span {
  border-radius: 14px;
  border: 1px solid rgba(90, 90, 90, 0.3);
  padding: 6px 16px;
  display: flex;
  align-items: center;
  margin: 4px 4px 0 0;
}
.room_child_wrap .room_child_cont > span svg {
  margin-left: 9px;
}
.room_child_wrap:not(.active) .add-child {
  width: 100%;
  text-align: center;
}
.room_child_wrap:not(.active) .add-child svg {
  display: none;
}
.room_child_wrap.active .room_child_title {
  display: block;
  width: 100%;
  margin-bottom: 6px;
}
.room_child_wrap.active .add-child {
  border-radius: 8px;
  padding: 0;
  height: 32px;
  margin-top: 4px;
}
.room_child_wrap.active .add-child span {
  display: none;
}
.room_child_wrap.active .add-child svg {
  display: block;
}
.room_numb {
  padding: 10.5px 14px 0;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  color: #5A5A5A;
}
.room_numb + .pass_numb {
  padding-top: 0;
  padding-bottom: 10px;
}
.room_footer {
  padding: 13px 14px;
  display: flex;
}
.room_footer .add-room {
  width: 100%;
  margin-right: 20px;
  text-align: center;
}
.range-wrap {
  width: 100%;
}
.range-wrap .range_selected .range .range-from-text,
.range-wrap .range_selected .range .range-to-text {
  background: #645EFC;
}
.range {
  height: 50px;
  position: relative;
  display: block;
  user-select: none;
}
.range .range-body {
  position: relative;
  display: block;
  user-select: none;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.range .range-body .range-line {
  border-radius: 30px 0 0 30px;
  top: 36px;
  height: 4px;
  background-color: #E3E5F6;
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}
.range .range-body .range-from-text,
.range .range-body .range-to-text {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
  background: #A2A2BA;
  color: #FFF;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 2px 10px;
}
.range .range-body .range-from-text {
  border-radius: 4px 4px 4px 0;
}
.range .range-body .range-to-text {
  border-radius: 4px 4px 0 4px;
}
.range .range-bar {
  top: 36px;
  height: 4px;
  background: #97A1FB;
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}
.range .range-handle {
  top: 26px;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background: #7378FC;
  position: absolute;
  display: block;
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
}
.range .range-handle.type_last {
  z-index: 2;
}
.range_flex_no_dots {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 384px;
  height: 350px;
  padding: 20px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
}
.range_flex_no_dots .range_scroll {
  height: 310px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 10px;
  margin-right: -10px;
}
.range_flex_no_dots .range {
  height: 36px;
}
.range_flex_no_dots .range_title {
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  color: #23232B;
  margin-bottom: -2px;
  display: block;
}
.range_flex_no_dots .range-wrap {
  margin-top: 14px;
}
.range_flex_no_dots .range-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.range_flex_no_dots .range-body .range-line {
  top: 6px;
}
.range_flex_no_dots .range-body .range-from-text,
.range_flex_no_dots .range-body .range-to-text {
  top: 17px;
  color: #23232B;
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  padding: 0;
  background: none;
}
.range_flex_no_dots .range-handle {
  display: none;
}
.range_flex_no_dots .range-bar {
  top: 6px;
  background-color: #47B795;
}
.range_flex_no_dots .range-wrap-err .range-bar {
  background-color: #EE5C5C;
}
.range_flex_no_dots .range-wrap-err .range-to-text {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.range_flex_no_dots .range-wrap-err .range-to-text i {
  font-style: normal;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FF9090;
}
.range_flex_no_dots .range_stat {
  display: block;
  padding: 2px 6px;
  border-radius: 30px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0;
}
.range_flex_no_dots .range_stat_err {
  color: #DB3931;
  background: #FFE5E5;
}
.range_flex_no_dots .rang_bron_info {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #DB3931;
}
.range_flex_no_dots .btn {
  margin-top: 12px;
}
.range_flex_no_dots .range_cont + .range_cont {
  margin-top: 23px;
  padding-top: 23px;
  border-top: 1px solid #E3E5F6;
}
@media (max-width: 1439px) {
  .select_pass_wrap {
    right: 0;
    left: auto;
  }
}
@media (max-width: 1299px) {
  .select_pass_wrap.select_pass_wrap_top {
    bottom: auto;
  }
}
@media (max-width: 991px) {
  .fake_select + .fake_dropdown.fake_dropdown_right {
    left: 0;
    right: auto;
  }
}
@media (max-width: 575px) {
  .range_flex_no_dots {
    width: 375px;
  }
  .fake_select + .fake_dropdown {
    margin-top: 6px;
  }
  .fake_select + .fake_dropdown.fake_dropdown_right {
    max-width: 228px;
    overflow: visible;
    white-space: normal;
  }
  .fake_select + .fake_dropdown.fake_dropdown_right.fake_dropdown_right_mob {
    left: auto;
    right: 0;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.services_wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px 0;
}
.services_wrap .services_item {
  width: 25%;
  padding: 10px;
}
.services_wrap .services_item > div {
  border-radius: 20px;
  background: #E3E5F6;
  padding: 30px;
}
.services_head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}
.services_head .services_img {
  width: 36px;
  height: 36px;
  border-radius: 7px;
  background: #645EFC;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.services_head .services_img img {
  display: block;
}
.services_head .services_burg {
  display: block;
  width: 24px;
  height: 24px;
  background: url(img/icon-service/services_burg.svg) no-repeat 0 0;
}
.services_footer h3 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}
.services_footer .services_info {
  color: #C4C4C4;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.services_footer .services_info span {
  color: #5A5A5A;
}
@media (max-width: 991px) {
  .services_wrap .services_item {
    width: 33.333%;
  }
}
@media (max-width: 767px) {
  .services_wrap {
    margin: 10px -5px 0;
  }
  .services_wrap .services_item {
    width: 50%;
    padding: 5px;
  }
  .services_wrap .services_item > div {
    padding: 20px;
  }
  .services_wrap .services_item > div h3 {
    font-size: 16px;
    margin-bottom: 4px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sidebar {
  width: 224px;
  color: #23232B;
  transition: 0.3s all;
}
.sidebar.active {
  width: 103px;
}
.sidebar.active + .wrapper {
  width: calc(100% - 103px);
}
.sidebar.active .side_logo {
  flex-wrap: wrap;
}
.sidebar.active .side_logo a {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.sidebar.active .group_block:first-child {
  padding: 20px 16px 15px;
}
.sidebar.active .side_plane {
  margin: 0;
  text-align: center;
}
.sidebar.active .plane_name {
  font-weight: 500;
  margin: 13px 0 0;
}
.sidebar.active .plane_price {
  font-size: 12px;
}
.sidebar.active .side_nav a {
  font-size: 0;
}
.sidebar.active .side_nav a .icon24 {
  margin: 0 auto;
}
.sidebar .group_block {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid rgba(64, 64, 64, 0.1);
}
.sidebar .group_block:first-child {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 37px;
  padding-bottom: 18px;
}
.sidebar .side_logo {
  display: flex;
  align-items: center;
}
.sidebar .side_logo.side_logo_shab > a {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: #7378FC;
  color: #EEEEF8;
  font-size: 32px;
  line-height: 42px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sidebar .side_logo.side_logo_shab .plane_price {
  font-size: 14px;
}
.sidebar .group_block:last-child {
  border-bottom: 0;
}
.sidebar .group_block:last-child .side_nav li a {
  opacity: 0.6;
}
.sidebar .group_block:last-child .side_nav li:hover a {
  opacity: 1;
}
.side_plane {
  font-size: 18px;
  font-weight: 800;
  margin-left: 15px;
  line-height: 24px;
}
.side_plane .plane_name {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 2px;
}
.side_nav {
  padding: 0;
  margin: 0;
  list-style: none;
}
.side_nav li {
  padding: 12px 30px 12px 26px;
  border-left: 4px solid transparent;
  transition: 0.3s all;
}
.side_nav li a {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.14px;
  display: flex;
  align-items: center;
  color: #23232B;
  transition: 0.3s all;
}
.side_nav li .icon24 {
  margin-right: 20px;
}
.side_nav li.active,
.side_nav li:hover {
  border-left: 4px solid #DB3931;
  background: rgba(219, 57, 49, 0.1);
}
.side_nav li.active a,
.side_nav li:hover a {
  color: #DB3931;
}
@media (max-width: 1199px) {
  .sidebar {
    left: -278px;
    position: fixed;
    top: 67px;
    width: 278px;
    background: #EEEEF8;
    height: calc(100% - 67px);
    z-index: 30;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .sidebar.open {
    left: 0;
  }
  .sidebar.active + .wrapper {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sidebar {
    left: -220px;
    width: 220px;
  }
  .sidebar.active {
    width: 220px;
  }
  .sidebar.active .side_logo {
    flex-wrap: nowrap;
  }
  .sidebar.active .group_block:first-child {
    padding: 37px 30px 18px 30px;
  }
  .sidebar.active .side_logo a {
    margin: 0;
  }
  .sidebar.active .side_plane {
    margin: 0 0 0 15px;
    text-align: left;
  }
  .sidebar.active .plane_name {
    font-weight: 600;
    margin: 0 0 2px;
  }
  .sidebar.active .plane_price {
    font-size: 18px;
  }
  .sidebar.active .side_nav a {
    font-size: 14px;
  }
  .sidebar.active .side_nav a .icon24 {
    margin: 0 20px 0 0;
  }
  .sidebar {
    position: absolute;
    height: auto;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slide_track {
  display: flex;
}
.slide_arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 32px;
  height: 32px;
  padding: 4px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #404040;
  outline: none;
  border-radius: 8px;
  background: #E3E5F6;
  z-index: 3;
}
.slide_arrow:hover {
  background: #645EFC;
  color: #ffffff;
}
.slide_prev {
  left: 6px;
}
.slide_prev svg {
  transform: rotate(90deg);
}
.slide_next {
  right: 0;
}
.slide_next svg {
  transform: rotate(-90deg);
}
.carousel__slide {
  padding: 0 5px;
}
.carousel__next {
  right: -10px;
}
.carousel__prev {
  left: -10px;
}
.slider-tooltip {
  background-color: #645EFC;
  border-color: #645EFC;
}
.slider-connect {
  background-color: #645EFC;
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tabs {
  display: flex;
}
.tabs-but a {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 18px;
  border-radius: 8px;
  color: #23232B;
  white-space: nowrap;
  transition: 0.3s all;
}
.tabs-but a:hover {
  color: #fff;
  background: #7378FC;
  opacity: 1;
}
.tabs-but a.active {
  color: #fff;
  background: #645EFC;
  border-radius: 8px;
  opacity: 1;
}
.tabs-fill {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-flex;
  border-radius: 14px 14px 0px 0px;
  background: #E3E5F6;
}
.tabs-fill li {
  padding: 7px 17px 7px 16px;
  display: flex;
  align-items: center;
  color: rgba(90, 90, 90, 0.4);
  cursor: pointer;
  transition: 0.3s all;
}
.tabs-fill li span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin: 0 10px;
  white-space: nowrap;
}
.tabs-fill li a {
  color: #404040;
  transition: 0.3s all;
}
.tabs-fill li + li {
  border-left: 1px solid #E3E5F6;
}
.tabs-fill li.active,
.tabs-fill li:hover {
  border-radius: 14px 14px 0 0;
  border-left-color: transparent;
  background: #7378FC;
  color: #ffffff;
}
.tabs-fill li.active + li,
.tabs-fill li:hover + li {
  border-left-color: transparent;
}
.tabs-fill li.active a,
.tabs-fill li:hover a {
  color: #ffffff;
}
.tabs-fill li.active {
  background: #A2A2BA;
}
.menu_flex {
  display: flex;
}
.menu_flex a {
  display: block;
  padding: 6px 16px;
  border-radius: 12px;
  color: #5A5A5A;
}
.menu_flex a.active {
  color: #fff;
  background: #645EFC;
}
@media (max-width: 991px) {
  .tabs-fill li {
    width: 33.3333%;
  }
  .tabs-fill li span {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media (max-width: 767px) {
  .tabs-fill li {
    width: 216px;
    padding: 7px 8px;
  }
  .tabs-fill li span {
    margin: 0 9px;
  }
}
@media (max-width: 575px) {
  .tabs-fill li {
    width: 150px;
  }
  .tabs-fill li span {
    margin: 0 8px;
    font-size: 12px;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card_tarif_wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 432px;
  padding-right: 10px;
}
.card_tarif_wrap_one .card_tarif_item {
  width: 25%;
  padding: 10px;
}
.card_tarif_wrap_one.card_tarif_wrap_wide .card_tarif_item {
  width: 100%;
}
.card_tarif_wrap_one.card_tarif_wrap_wide .card_tarif_item .tarif_more {
  display: none;
}
.card_tarif_wrap_one.card_tarif_wrap_wide .card_tarif .card_body {
  display: flex;
  align-items: flex-end;
  margin: 0 -10px;
  width: calc(100% + 20px);
  padding-bottom: 3px;
}
.card_tarif_wrap_one.card_tarif_wrap_wide .card_tarif .card_body .card_main {
  width: 50%;
  padding: 0 10px;
}
.card_tarif_wrap_one.card_tarif_wrap_wide .card_tarif .card_body .card_main .tarif_foot {
  margin-top: 56px;
  height: auto;
}
.card_tarif_wrap_one.card_tarif_wrap_wide .card_tarif .card_body .tarif_dopinfo {
  display: block !important;
  margin: 0;
  width: 50%;
  padding: 0 10px;
}
.card_tarif_wrap_duo .card_tarif_item {
  width: 50%;
  padding: 10px;
}
.card_tarif_wrap_duo.card_tarif_wrap_wide .card_tarif_item {
  width: 100%;
}
.tarif_switch.tabs-but {
  width: 100%;
  padding: 10px 0;
  margin-top: 25px;
}
.tarif_switch.tabs-but a {
  padding: 5px 16px;
  font-size: 16px;
  white-space: normal;
}
.tarif_switch.tabs-but a .icon18 {
  display: inline-block;
  vertical-align: top;
  margin: 1px 3px;
}
.razdel_switch.tabs-but {
  width: 100%;
  padding: 10px 0;
  margin-top: 10px;
  border-bottom: 1px solid #645EFC;
}
.razdel_switch.tabs-but a {
  border-radius: 30px;
  padding: 1px 6px;
  background: #A2A2BA;
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: uppercase;
}
.razdel_switch.tabs-but a + a {
  margin-left: 10px;
}
.rules_wrap {
  margin-top: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 249px);
  font-weight: 500;
  line-height: 20px;
  margin-right: -7px;
  padding-right: 17px;
}
.rules_item .rules_title {
  display: flex;
  justify-content: space-between;
  margin: 0 0 12px;
  font-size: 18px;
  font-weight: 800;
  line-height: normal;
}
.rules_item + .rules_item {
  margin-top: 24px;
}
.modal_side.modal_side_detail {
  border: 0;
}
.modal_side.modal_side_detail h5 {
  font-size: 22px;
}
.detail_wrap {
  margin-top: 18px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 10px;
  max-height: calc(100vh - 119px);
}
.detail_item .detail_title {
  color: #5A5A5A;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
}
.detail_item .detail_airport {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 12px 0;
}
.detail_item .detail_airport .detail_airport_val {
  display: flex;
  align-items: center;
}
.detail_item .detail_airport .detail_airport_val img {
  display: block;
  width: 30px;
}
.detail_item .detail_airport .detail_airport_val span {
  display: block;
  font-size: 13px;
  opacity: 0.8;
  margin-left: 8px;
}
.detail_item .detail_airport .modal_rules {
  font-size: 13px;
}
.detail_item .detail_reis {
  position: relative;
  padding-left: 20px;
}
.detail_item .detail_line {
  display: flex;
  padding-bottom: 11px;
}
.detail_item .detail_line .detail_time {
  color: #5A5A5A;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  width: 75px;
}
.detail_item .detail_line .detail_city {
  display: flex;
  line-height: 24px;
}
.detail_item .detail_line .detail_city div {
  color: #5A5A5A;
}
.detail_item .detail_line .detail_city span {
  opacity: 0.8;
  display: block;
  margin-left: 6px;
}
.detail_item .detail_line .detail_path {
  display: flex;
  line-height: 20px;
  margin-top: 13px;
}
.detail_item .detail_line .detail_path div {
  color: #5A5A5A;
}
.detail_item .detail_line .detail_path span {
  opacity: 0.8;
  display: block;
  margin-right: 6px;
}
.detail_item .detail_line .detail_more {
  margin-top: 12px;
}
.detail_item .detail_line .detail_more .tarif_icon div + div {
  margin-left: 8px;
}
.detail_item .detail_from,
.detail_item .detail_to {
  position: relative;
}
.detail_item .detail_from:after,
.detail_item .detail_to:after {
  content: "";
  display: block;
  position: absolute;
  left: -20px;
  /*width: 4px;
      height: 4px;*/
  width: 8px;
  height: 8px;
  top: 8px;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #645EFC;
}
.detail_item .detail_from:before {
  content: "";
  display: block;
  position: absolute;
  left: -17px;
  top: 17px;
  width: 2px;
  height: 60px;
  background: #645EFC;
}
.detail_item .detail_from:after {
  top: 12px;
}
.detail_item .detail_dopinfo {
  display: flex;
  padding: 0 0 4px 0;
  border-top: 1px solid #E3E5F6;
  font-size: 13px;
  margin-top: 9px;
}
.detail_item .detail_dopinfo .dopinfo_bagaz {
  margin-left: 20px;
}
.detail_item .detail_dopinfo span {
  font-size: 10px;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 0.6;
}
.detail_peresad {
  margin: 30px 0 40px 0;
  display: flex;
  align-items: center;
}
.detail_peresad span {
  display: block;
  opacity: 0.8;
  margin: 0 6px 0 20px;
}
.detail_peresad div {
  color: #404040;
}
.detail_extr_buttons {
  width: 100%;
  padding: 30px 20px !important;
  align-items: flex-end;
  border-radius: 20px;
  background: #E3E5F6;
  margin-top: 30px;
}
.detail_extr_buttons .compact_price {
  display: block;
  margin: 0 auto 0 0;
}
.detail_extr_buttons .extr_tarif {
  background: #ffffff;
  color: #7378FC;
}
.detail_extr_buttons .extr_tarif:hover {
  background: #7378FC;
  color: #fff;
}
.detail_extr_buttons .extr_bron,
.detail_extr_buttons .extr_tarif {
  width: calc(50% - 115px);
  text-align: center;
}
.detail_extr_buttons .price_flex.price_flex_bold {
  color: #404040;
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
  margin-bottom: 0;
  margin-top: 10px;
}
.detail_extr_buttons .gray_but {
  font-size: 13px;
}
.modal_side_detail .search_card_wrap {
  display: none;
  max-height: none;
}
.modal_side_detail .search_card_wrap > div {
  margin: 22px -2.5px -8px;
}
.modal_side_detail .search_card_wrap .search_card {
  width: 137px;
  min-width: 137px;
  padding: 0 2.5px;
}
.modal_side_detail .search_card_wrap .search_card > div {
  padding: 10px 6px;
}
.modal_side_detail .search_card_wrap .tarif_icon {
  margin: 0;
  justify-content: center;
}
.modal_side_detail .search_card_wrap .tarif_icon div + div {
  margin-left: 4px;
}
.modal_side_detail .search_card_wrap .price_flex {
  text-transform: uppercase;
  color: #5A5A5A;
  font-size: 13px;
  font-weight: 800;
  line-height: 18px;
  justify-content: center;
  margin-top: 9px;
}
.modal_side_detail .search_card_wrap .price_flex span,
.modal_side_detail .search_card_wrap .price_flex i {
  width: auto;
}
.modal_side_detail .detail_extr_buttons .compact_price {
  margin: 0;
  width: 100%;
}
.modal_side_detail .detail_extr_buttons .compact_price span,
.modal_side_detail .detail_extr_buttons .compact_price i {
  width: auto;
}
.modal_side_detail .detail_extr_buttons .extr_bron,
.modal_side_detail .detail_extr_buttons .extr_tarif {
  width: calc(50% - 5px);
  margin-top: 9px;
}
.modal_side_detail .switch {
  margin-left: 0;
  left: auto;
  right: 60px;
}
.modal_side_detail .switch .btn-icon span {
  display: block;
  width: 16px;
  height: 24px;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
  margin: 4px 8px;
}
.modal_side_detail .switch .btn-icon span.switch_niz {
  transform: rotate(90deg);
}
@media (max-width: 1439px) {
  .card_tarif_wrap_one .card_tarif_item {
    width: 33.3333%;
  }
}
@media (max-width: 1299px) {
  .card_tarif_wrap {
    max-height: calc(100vh - 163px);
  }
}
@media (min-width: 1200px) {
  .modal_side_detail_six .modal_head h5 {
    font-size: 18px;
  }
  .modal_side_detail_six .modal_side_wrap {
    padding: 6px 30px 0 16px;
  }
  .modal_side_detail_six .detail_wrap {
    margin-top: 20px;
    margin-bottom: 58px;
    position: relative;
  }
  .modal_side_detail_six .detail_wrap .detail_wrap_items {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    border-radius: 20px;
    background: #E3E5F6;
  }
  .modal_side_detail_six .detail_item {
    width: calc(33.3333% - 132px);
    padding: 20px 10px;
  }
  .modal_side_detail_six .detail_item .detail_airport {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  .modal_side_detail_six .detail_item .detail_airport .modal_rules {
    margin-top: 6px;
  }
  .modal_side_detail_six .detail_item .detail_line {
    padding-bottom: 10px;
  }
  .modal_side_detail_six .detail_item .detail_line .detail_path {
    margin-top: 8px;
  }
  .modal_side_detail_six .detail_item .detail_line .detail_more {
    display: none;
  }
  .modal_side_detail_six .detail_peresad {
    width: 132px;
    padding: 20px 10px 20px 34px;
    flex-wrap: wrap;
    margin: 0;
    position: relative;
    background: url(./img/arrow_dots.svg) no-repeat bottom center;
  }
  .modal_side_detail_six .detail_peresad span {
    margin: 0 0 0 8px;
  }
  .modal_side_detail_six .detail_peresad div {
    margin: 8px 0 0 8px;
  }
  .modal_side_detail_six .detail_peresad svg {
    position: absolute;
    left: 10px;
    top: 34px;
  }
  .modal_side_detail_six .detail_extr_buttons {
    margin: 20px 0 0;
    justify-content: flex-start;
  }
  .modal_side_detail_six .detail_extr_buttons .compact_price {
    margin: 0 30px 0 0;
    width: 190px;
    min-width: 190px;
  }
  .modal_side_detail_six .detail_extr_buttons .extr_bron,
  .modal_side_detail_six .detail_extr_buttons .extr_tarif {
    width: 164px;
  }
}
@media (max-width: 1199px) {
  .modal_side_detail .switch {
    display: none;
  }
  .modal_side.modal_side_detail_six {
    right: 0;
    left: auto;
  }
  .detail_wrap {
    max-height: calc(100vh - 110px);
  }
}
@media (max-width: 991px) {
  .card_tarif_wrap_one .card_tarif_item {
    width: 50%;
  }
  .detail_extr_buttons .extr_bron,
  .detail_extr_buttons .extr_tarif {
    width: 109px;
  }
  .detail_extr_buttons .price_flex.price_flex_bold span,
  .detail_extr_buttons .price_flex.price_flex_bold i {
    width: auto;
  }
}
@media (max-width: 767px) {
  .modal_side.modal_right {
    width: 100%;
    border-radius: 0;
  }
  .modal_side.modal_right .modal_side_wrap {
    padding: 4px 10px 4px 20px;
  }
  .modal_side.modal_right .modal_head_tarif {
    margin-top: 0;
  }
  .modal_side.modal_side_detail_six {
    width: 100%;
    border-radius: 0;
  }
  .modal_side.modal_side_detail_six .modal_side_wrap {
    padding: 4px 10px 4px 20px;
  }
  .modal_side.modal_side_detail_six .modal_head_tarif {
    margin-top: 0;
  }
  .razdel_switch.tabs-but {
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 8px;
    margin-bottom: -8px;
  }
  .tarif_switch.tabs-but {
    margin-top: 20px;
  }
  .tarif_switch.tabs-but a {
    padding: 5px 14px;
  }
  .tarif_switch.tabs-but a + a {
    margin-left: 20px;
  }
  .rules_wrap {
    margin-top: 33px;
  }
  .modal_side.modal_side_detail {
    padding-top: 10px;
  }
  .detail_wrap {
    margin-top: 28px;
  }
  .detail_item .detail_airport {
    flex-wrap: wrap;
    padding: 18px 0 14px 0;
  }
  .detail_item .detail_airport .modal_rules {
    font-size: 13px;
    margin-top: 17px;
  }
  .detail_item .detail_more {
    display: none;
  }
  .detail_peresad {
    margin: 18px 0 30px 0;
  }
  .modal_side_detail .search_card_wrap {
    display: flex;
  }
}
@media (max-width: 575px) {
  .card_tarif_wrap_one .card_tarif_item {
    width: 100%;
  }
  .detail_extr_buttons {
    flex-wrap: wrap;
  }
}

.clean {
  padding: 0;
  margin: 0;
}
.clean-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bordered {
  border-radius: 20px;
  border: 2px solid #E3E5F6;
}
.anim-fade {
  transition: 0.3s all;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow {
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px;
  border: 1px solid #E3E5F6;
}
.shadow-20 {
  border-radius: 20px;
}
.boxshadow {
  box-shadow: 0px -10px 50px 0px rgba(64, 64, 64, 0.15);
}
.img_fit img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}








body {
  --input-bg: white;
  --input-border: 1px solid rgba(90, 90, 90, 0.3);
  --input-prefix-bg: #EEEEF8;
  --input-focus-bg: rgba(100, 94, 252, 0.3);
  --input-focus-border: 1px solid #645EFC;
  --input-hover-border: 1px solid rgba(100, 94, 252, 0.4);
  --text-color: 0, 0, 0;
  --disabled-color: #23232B;
  --content-bg: white;
  --block-header-bg: #EEEEF8;
  --block-border: 1px solid #EEEEF8;
  --input-focus: inherit;
  --input-focus-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  --input-disabled-bg: #C5C3DD;
  --input-selected-bg: #645EFC;
  --input-selected-color: white;
  --input-error-color: #DB3931;
  --input-error-border: 1px solid #DB3931;
  --input-error-label-color: #DB3931;
  --button-bg: #645EFC;
  --button-border: 1px solid #645EFC;
  --button-color: white;
  --button-disabled-bg: white;
  --button-disabled-color: #23232B;
  --button-disabled-border: 1px solid #C5C3DD;
  --button-secondary-bg: white;
  --button-secondary-color: #333;
  --button-secondary-border: 1px solid #C5C3DD;
}



