@import "partials/params";
@import "partials/mixins";


button.btn {
  cursor: pointer;
}
button {
  border: 0;
  background: none;
  font-family: 'Manrope', sans-serif;
}
.btn {
  line-height: 20px;
  font-weight: 600;
  border-radius: @radius-btn;
  .anim-fade;
  &-small {
    font-size: 14px;
    padding: 6px 16px;
  }
  &-medium {
    font-size: 16px;
    padding: 14px 16px;
  }
  &-large {
    font-size: 18px;
    padding: 20px 32px;
  }
  &-blue_fill {
    background: @primary-color-main;
    color: #FFF;
    &:hover, &.active, &:focus {
      background: @primary-color-bl400;
      color: #FFF;
    }
  }
  &-gray_fill {
    background: @bg-btn-gray;
    color: @font-gray-color;
    &:hover, &.active, &:focus {
      background: @primary-color-bl400;
      color: #FFF;
    }
  }
  &-disable {
    background: @bg-btn-gray;
    color: @font-gray-color;
    &:hover, &.active, &:focus {
      background: @primary-color-bl400;
      color: #FFF;
    }
  }
  &-gray_dark_fill {
    background: @bg-btn-dark;
    color: @font-gray-color;
    &:hover, &.active, &:focus {
      background: @primary-color-bl400;
      color: #FFF;
    }
  }
  &-red_fill {
    background: @wings_red_100;
    color: @red_500;
    &:hover, &.active, &:focus {
      background: @primary-color-bl400;
      color: #FFF;
    }
  }
  &-red2_fill {
    background: @red_500;
    color: #FFFFFF;
    &:hover, &.active, &:focus {
      background: @primary-color-bl400;
      color: #FFF;
    }
  }
  &-green_fill {
    background: @font-mint-color;
    color: #FFFFFF;
    &:hover, &.active, &:focus {
      background: @mint_600;
      color: #FFF;
    }
  }
  &-orange_fill {
    background: @Peach_500;
    color: #FFFFFF;
    &:hover, &.active, &:focus {
      background: @primary-color-bl400;
      color: #FFF;
    }
  }
  &-transp_fill {
    background: #fff;
    color: @font-gray-color;
    border: 1px solid @font-gray-color;
    &:hover, &.active, &:focus {
      background: @primary-color-bl400;
      color: #FFF;
      border: 1px solid #FFF;
    }
  }
  &-dop_fill.btn {
    background: @primary-color-gray;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    text-transform: uppercase;
    padding: 2px 6px;
    &:hover, &.active, &:focus {
      background: @primary-color-bl400;
      color: #FFF;
    }
  }
  &-blue {
    color: @primary-color-main;
  }
  &-gray {
    color: @font-gray-color;
  }
  &-border {
    color: @font-gray-color;
    border: 1px solid @font-color;
    line-height: 18px;
    &:not(.btn-medium) {
      padding: 6px 15px;
    }
    &.btn-blue {
      color: @primary-color-main;
      border-color: @primary-color-main;
      &:hover, &.active, &:focus {
        background: @primary-color-bl400;
        color: #FFF;
      }
    }
  }
  &-icon {
    padding: 4px;
    border-radius: 8px;
  }
  &-icon-text {
    .flex-center(row);
    & svg + span {
      margin-left: 5px;
      display: block;
    }
    & span + svg {
      margin-left: 5px;
    }
  }
  &-white {
    background: #ffffff;
    color: @font-gray-color;
  }
  &-alert {
    background: rgba(231, 61, 61, 0.10);
    padding: 2px 10px;
    border-radius: 30px;
    color: #BB3030;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    text-transform: uppercase;
  }
  &-inl_width {
    width: 196px;
    text-align: center;
    display: inline-block;
  }
  &-wide-full {
    width: 100%;
    text-align: center;
    display: block;
  }
  &-wide-tret {
    min-width: 33.3333%;
    text-align: center;
    display: block;
    margin: 0 auto;
  }
}
.tc_but {
  .btn {
    border-radius: 30px;
  }
}

/**********other*************/
.onyx_gray {
  color: fade(@font-gray-color, 60%);
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
  &.hide_pk {
    line-height: 12px;
  }
}
.gray_but {
  color: @font-color;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 30px;
  display: inline-flex;
  padding: 0 10px;
  background: fade(@font-color, 10%);
}
.loader {
  width: 150px;
  height: 127px;
  position: relative;
  text-align: center;
  margin: 42px auto 10px;
  color: @font-color;
  padding: 11px 22.5px;
  background: url(@name-loader) no-repeat center center;
  & span {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    left: 50%;
    margin-left: -40px;
    width: 52px;
    height: 40px;
    background: url(@img-loader-plane) no-repeat center center / contain;
  }
  &-hotel {
    background: url(@name-loader-hotel) no-repeat center center;
    & span {
      background: url(@img-loader-hotel) no-repeat center center;
      width: 37px;
      height: 37px;
    }
  }
  &-nohotel {
    /*background: url(./img/loader_old.svg) no-repeat center center;*/
  }
  &-train {
    overflow: hidden;
    & span {
      background: url(@img-loader-train) no-repeat center center;
      width: 80px;
      height: 23px;
    }
  }
  & a {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    margin: 0 auto;
    .anim-fade;
    & svg {
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -23px;
      left: 50%;
      margin-left: -23px;
      color: @primary-color-main;
      width: 47px;
      height: 46px;
      .anim-fade;
    }
    &:hover {
      background: radial-gradient(94.15% 94.15% at 32.10% 26.23%, #3778D2 0%, #3778D2 43.24%, #DCE8F8 100%);
      & svg {
        color: #fff;
      }
    }
  }
  &-anim {
    & span {
      animation: plane 7s infinite linear;
      opacity: 0;
      left: 0;
      margin-left: -40px;
    }
  }
  &-anim_hotel {
    & span {
      animation: myOrbit 7s infinite linear;
      opacity: 1;
      left: 56px;
      margin-left: 0;
    }
  }
}
.loader_info {
  text-align: center;
  max-width: 520px;
  font-size: 18px;
  line-height: 24px;
  margin: 0 auto;
  & .loader_text {
    color: fade(@font-color, 60%);
  }
}

.loader_no_found {
  & .loader_text {
    font-size: 14px;
    line-height: 20px;
    color: @font-gray-color;
    margin: 10px 0 35px;
  }
}


@keyframes plane {
  0% {margin-left: -40px; opacity: 0;}
  30% {opacity: 1;}
  70% {opacity: 1;}
  100% {margin-left: 170px; opacity: 0;}
}
@keyframes myOrbit {
  from { transform: rotate(0deg) translateX(20px) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(20px) rotate(-360deg); }
}


@media (max-width: 1439px) {

}
@media (max-width: 575px) {
  .btn-small {
    padding: 6px 10px;
  }
  .btn-border {
    padding: 6px 9px;
  }
}