@import "partials/params";
@import "partials/mixins";

.modal_wrap {
  padding: 15px 22px;
  max-width: 384px;
  &.modal_wrap_wide {
    max-width: 742px;
    position: relative;
    & .slide_prev {
      left: -12px;
    }
    & .slide_next {
      right: -12px;
    }
  }
  &.modal_accord {
    max-width: 768px;
    padding: 20px 14px;
  }
}
.modal_head {
  .flex-center(row,space-between);
  padding: 6px 0;
  width: 100%;
  position: relative;
  & h5 {
    color: @onix_600;
    .ellipsis;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
  }
}
.modal_head_long_text {
  & h5 {
    white-space: normal;
    width: 80%;
  }
}
.modal_h400 {
  height: 400px;
}
.overflow_h {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 284px;
}
.modal_body {
  display: flex;
  margin: 10px -10px 0;
  & .modal_col_body {
    width: 100%;
    &-duo {
      padding: 10px;
    }
  }
  & .modal_col {
    width: 50%;
    padding: 0 10px;
  }
  & .modal_col_head {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 4px 0;
    margin-bottom: 4px;
    & svg {
      margin: 0 10px;
    }
  }
  & .modal_col_body {
    & .checkbox-container {
      padding: 5px 0;
    }
  }
}
.modal_footer {
  padding: 10px 0 0 ;
}
.modal_wrap_wide {
  & .overflow_h {
    max-height: 255px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
}
.modal_slide {
  display: flex;
  margin: 0 -10px;
  & .modal_item {
    width: 360px;
    min-width: 360px;
    padding: 0 10px;
  }
}

.accord_item {
  margin-top: 10px;
  & .accord_body {
    display: none;
    & .accord_body_wrap {
      width: 100%;
      padding: 0 10px;
    }
  }
  & .accord_head {
    position: relative;
    & .reset_filtr {
      position: absolute;
      right: 0;
      top: 12px;
      display: none;
    }
    &.has_sel {
      padding-right: 95px;
      & .reset_filtr {
        display: block;
      }
    }
  }
  &.accord_active {
    & .extr_toggle svg {
      transform: rotate(180deg);
    }
    & .accord_body {
      display: flex;
    }
  }
}

.lay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: fade(@font-color, 60%);
}
.modal_side {
  display: none;
  position: fixed;
  padding: 16px 0;
  z-index: 25;
  bottom: 0;
  border-radius: @radius-30 @radius-30 0 0;
  background: #FFF;
  width: 100%;
  left: 0;
  &.modal_side_md {
    max-width: 936px;
    left: 50%;
    transform: translate(-50%, 0);
    & .modal_side_wrap {
      padding: 0 30px 0 40px;
    }
    & .modal_side_air {
      padding: 0 40px 0 40px;
      & .zabron {
        margin: 0 54px 0 auto;
      }
      & .modal_close {
        right: 0;
      }
    }
  }
  &.modal_notify {
    width: 556px !important;
    & .modal_side_wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 22px 30px 22px 30px !important;
    }
    & .notify_head {
      flex-wrap: wrap;
      padding: 0 0 10px 0;
      & h5 {
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
      }
    }
    & .notify_body {
      margin: 0 -7px auto 0;
      overflow: auto;
      padding-right: 7px;
    }
    & .newstbl {
      margin-top: 30px;
      &:first-child {
        margin-top: 0;
      }
    }
    & .newstbl_head {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin: 0 0 16px;
      padding-bottom: 4px;
      border-bottom: 1px solid @bg-main;
      & span {
        color: fade(@font-color-dark, 40%);
        margin-right: 20px;
        white-space: nowrap;
      }
      & a {
        text-align: right;
      }
      & .link_read_yes {
        color: fade(@font-color-dark, 40%);
      }
    }
    & .newstbl_item {
      margin: 4px 0 0;
      padding: 10px 7px;
      border-radius: 12px;
      align-items: normal;
      border: 0;
      background: @wings_blue_100;
      &.no_read {
        background: @bg-noread;
      }
      &:before {
        content: none;
      }
      & > div {
        padding: 0 5px;
      }
      & .newstbl_but {
        width: 42px;
        min-width: 42px;
        align-items: normal;
      }
      .newstbl_text {
        width: 100%;
        color: @font-color;
        font-weight: 500;
        display: flex;
        align-items: center;
        & .status {
          display: inline;
        }
      }
      & .newstbl_date {
        margin: 6px 0;
        font-size: 14px;
        line-height: 20px;
        width: auto;
        white-space: nowrap;
        color: fade(@font-color-dark, 40%);
      }
    }
    & .notify_footer {
      padding-top: 30px;
      & .btn {
        width: 100%;
        border-radius: 20px;
      }
    }
    & .notify_none {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
    & .notify_inf {
      width: 144px;
      height: 40px;
      padding: 10px 12px 10px 12px;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      background: @font-white-color;
      color: @font-color;
    }
    & .tarif_switch.tabs-but {
      margin-top: 23px;
      & a {
        font-size: 14px;
      }
    }
    &.notify_empty {
      & .notify_footer {
        visibility: hidden;
        height: 1px;
      }
    }
  }
  &.modal_side_600 {
    max-width: 600px;
    width: 100%;
    left: 50%;
    bottom: auto;
    top: 50%;
    border-radius: @radius-30;
    transform: translate(-50%, -50%);
    height: auto;
    & .modal_side_wrap {
      padding: 0 20px;
    }
    & .modal_close {
      right: 0;
    }
  }
  &.modal_height_600 {
    max-height: 600px;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &.modal_right {
    .boxshadow;
    width: 50%;
    top: 0;
    right: 0;
    left: auto;
    border-radius: @radius-20 0 0 0;
    & .modal_side_wrap {
      padding: 22px 20px 22px 30px;
    }
  }
  & .modal_side_wrap {
    padding: 0 20px 0 134px;
  }
  & .modal_head {
    justify-content: flex-start;
    padding-right: 10px;
  }
  & .modal_head_tarif {
    flex-wrap: wrap;
  }
  & .zabron {
    margin: 0 108px 0 auto;
  }
  & .switch {
    margin-left: -32px;
    position: absolute;
    left: 50%;
  }
  &.modal_side_otchet {
    & .modal_head {
      position: static;
      flex-wrap: nowrap;
    }
  }
}
.modal_full {
  border-radius: 0;
  max-width: 1440px;
  max-height: 862px;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50% !important;
  transform: translate(-50%, -50%);
  padding: 0;
  & .modal_close {
    top: 40px;
    right: 30px;
  }
}
.head_with_price {
  margin-top: 7px;
  margin-bottom: 14px;
  .switch {
    margin-left: 11%;
    position: relative;
    left: 0;
  }
  & .tarif_head_price {
    margin: 0 0 0 auto;
    color: #404040;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    display: flex;
    & span {
      display: block;
      margin: 0 4px 0 10px;
    }
  }
  & .zabron {
    margin: 0 8.4% 0 6.2%;
  }
}
.modal_close {
  border-radius: @radius-30;
  background: fade(@primary-color-main, 50%);
  position: absolute;
  top: 5px;
  right: 10px;
  .anim-fade;
  &:hover {
    background: fade(@primary-color-main, 100%);
  }
}

.modal_side_search {
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
}

.download_area {
  margin-top: 10px;
  display: flex;
  align-items: center;
  & .download_area_inp {
    border-radius: 8px;
    background: @wings_blue_100;
    padding: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: @font-color-dark;
    flex: 1 1 auto;
    align-items: center;
    display: none;
    & .btn {
      margin-left: 10px;
      padding: 2px 12px;
      font-size: 12px;
      line-height: 18px;
      color: @primary-color-main;
    }
    & + .btn_download {
      margin-left: 8px;
    }
  }
  & .btn_download {
    padding: 2px 12px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    border-radius: 14px;
    background: @wings_blue_100;
    white-space: nowrap;
    .anim-fade;
    &:hover {
      background: @primary-color-bl400;
      color: #FFFFFF;
    }
  }
  & .btn_download_add {
    display: inline-block;
  }
  & .btn_download_replace {
    display: none;
  }
  &.active {
    & .download_area_inp {
      display: flex;
    }
    & .btn_download_add {
      display: none;
    }
    & .btn_download_replace {
      display: inline-block;
    }
  }
}
.add_other_file {
  margin: 4px 0 24px;
  display: inline-block;
}
.download_del {
  position: absolute;
  top: 18px;
  right: 0;
}
.form_group_del {
  height: 25px;
}

@media (max-width: 1439px) {
  .modal_side {
    & .modal_side_wrap {
      padding: 0 20px 0 114px;
    }
  }
}
@media (max-width: 1299px) {
  .modal_side:not(.modal_side_otchet) {
    height: 100%;
    border-radius: 0;
    top: 0;
  }
}
@media (max-width: 1199px) {
  .modal_side {
    & .modal_side_wrap {
      padding: 0 4px 0 14px;
    }
    &.modal_side_md {
      & .modal_side_wrap {
        padding: 0 10px 0 20px;
      }
      & .modal_side_air {
        padding: 2px 14px 0 14px;
      }
    }
    &.modal_side_detail {
      width: 70%;
      & .modal_side_wrap {
        padding: 4px 4px 4px 14px;
      }
    }
  }
  .modal_full {
    height: 400px;
    & .modal_close {
      top: 20px;
      right: 20px;
      position: fixed;
      z-index: 40;
    }
  }
}
@media (max-width: 991px) {

}
@media (max-width: 767px) {
  .modal_side {
    & .modal_side_wrap {
      padding: 0 10px 0 20px;
    }
    & .modal_head {
      margin-top: 3px;
      margin-bottom: 21px;
      flex-wrap: wrap;
      & h5 {
        width: 90%;
      }
      & .modal_head_check {
        order: 1;
      }
    }
    & .tarif_head_price {
      margin: 37px 0 0 0;
      width: 50%;
      font-size: 16px;
      & span {
        margin: 0 4px 0 20px;
      }
      & + .zabron {
        width: 50%;
      }
    }
    & .zabron {
      margin: 37px 0 0 0;
      width: 100%;
      text-align: right;
    }
    & .switch {
      top: 2px;
      margin-left: -37px;
    }
    &.modal_side_md .modal_side_air {
      padding: 0 20px;
      & .modal_head {
        margin-top: 0;
        margin-bottom: 0;
      }
      & .zabron {
        margin: 17px auto 0;
        text-align: center;
      }
    }
    &.modal_side_otchet .modal_head h5 {
      white-space: normal;
    }
  }

  .head_with_price {
    margin-top: 2px;
    margin-bottom: 18px;
    & .switch {
      margin-left: -37px;
      position: absolute;
      left: 50%;
    }
  }
  .modal_close {
    top: 5px;
    right: 11px;
  }
  .modal_side.modal_side_600 {
    & .modal_close {
      right: 0;
    }
  }
  .modal_side.modal_notify {
    width: 371px !important;
    & .modal_side_wrap {
      padding: 22px 20px 22px 20px !important;
    }
    & .newstbl_item .newstbl_date {
      width: 70px;
      min-width: 70px;
      white-space: normal;
    }
  }
  .modal_full {
    height: 250px;
    overflow: visible;
    & .modal_close {
      top: calc(202px - 50vh);
      right: 20px;
    }
  }
  .download_area {
    flex-wrap: wrap;
    & .download_area_inp + .btn_download {
      margin: 8px 0 0;
    }
  }
}

@media (max-width: 575px) {
  .modal_side.modal_side_600 {
    width: calc(100% - 20px);
  }
  .modal_side_wrap .form_group_but {
    flex-wrap: wrap;
    & .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .modal_side.modal_notify {
    width: 100% !important;
    & .notify_head {
      margin-bottom: 0;
    }
    & .tarif_switch.tabs-but {
      margin-top: 20px;
      & a {
        margin: 0;
        padding: 5px 16px;
      }
    }
    & .newstbl_item {
      flex-wrap: nowrap;
    }
  }
}
