@import "partials/params";
@import "partials/mixins";

.search_widget.search_contract {
  background: @bg-main;
  padding: 20px 20px;
}
.contract_line_search {
  display: flex;
  padding: 10px 0;
  font-size: 18px;
  & .form_group {
    width: 100%;
    & .input_field {
      background: #FFFFFF;
      border-radius: 14px 0 0 14px;
    }
  }
  & .fields_result {
    flex: 0 0 auto;
    width: 119px;
    & .to_result {
      border-radius: 0 14px 14px 0;
      width: 100%;
      height: 100%;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      background: @primary-color-main;
      & svg {
        display: none;
      }
      &:hover {
        background: @primary-color-bl400;
      }
    }
  }
}
.search_contract_form {
  & .user_group {
    margin: 0;
    border: 0;
    padding: 10px 0;
    & .fake_select {
      background: #FFFFFF;
    }
    & .input_field {
      background: #FFFFFF;
    }
  }
  & .view_filtr_wrap {
    display: none;
  }
}
.inp_date {
  position: relative;
  & .inp_open_date {
    position: absolute;
    top: 12px;
    right: 12px;
    color: @font-gray-color;
  }
}
.user_group.user_group_dogovor {
  padding: 18px 16px;
  & .user_group_head {
    margin: 0;
    & .fake_select {
      padding: 5px 12px;
    }
  }
}
.work_contr {
  & span {
    display: flex;
  }
  & > svg {
    display: none;
  }
}

.dogovora_wrap {
  max-height: 1045px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 7px;
  margin-right: -7px;
}
.dogovora {
  margin-top: 20px;
  color: @font-color;
  border-radius: 20px;
  overflow: hidden;
  border-bottom: 2px solid @bg-main;
  & .dogovora_head {
    padding: 8px 12px;
    background: @bg-main;
    font-size: 13px;
  }
  & .dogovora_item {
    display: flex;
    padding: 6px 12px 10px 12px;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: 800;
    border-top: 2px solid @bg-main;
    border-right: 2px solid @bg-main;
    border-left: 2px solid @bg-main;
    min-height: 64px;
    & > div {
      padding: 0 4px;
      &.dogovor_but {
        padding: 0;
      }
      & > div {
        margin-top: 4px;
      }
      &.dogovor_data_start, &.dogovor_data_end {
        display: none;
      }
    }
    & span:not(.status) {
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: @font-onyx-color;
    }
    &.active {
      background: @bg-main;
    }
  }
}
.dogovor_status {
  width: 104px;
}
.dogovor_but a {
  display: block;
}

.contract_line {
  display: flex;
  align-items: center;
  padding: 10px 0 10px;
  color: @font-gray-color;
  & .contract_line_info {
    display: flex;
    border-right: 1px solid fade(@primary-color-main, 30%);
    & > div {
      margin-right: 10px;
      padding: 5px 16px;
      line-height: 20px;
      border-radius: 12px;
      white-space: nowrap;
    }
    & .contract_line_dolz {
      background: fade(@red_500, 10%);
      color: fade(@red_500, 60%);
      & span {
        color: fade(@red_500, 30%);
      }
    }
    & .contract_line_closed {
      background: @primary-color-main;
      color: #fff;
    }
  }
  & .fake_wrap {
    margin-left: 20px;
    & .fake_select {
      color: @primary-color-main;
    }
  }
  & .add_contract {
    margin-left: 20px;
    color: @primary-color-main;
    border-radius: 14px;
    & svg {
      /*color: @blue_400;*/
      padding: 4.5px;
    }
    & span {
      margin-left: 8px;
    }
  }
  & .clear_contract {
    margin: 0 0 0 auto;
    color: @primary-color-main;
  }
}

.flex_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.flex_group_but {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  & > .btn {
    margin-left: 20px;
  }
}
.flex_group_wis_but {
  margin-bottom: 20px;
}

.form_group_down {
  & .fake_select {
    padding: 15px 16px;
    color: @primary-color-main;
    background: @bg-main;
    &:hover {
      background: @primary-color-bl400;
      color: #FFFFFF;
    }
  }
}
.filt_clear + .form_group_down {
  margin: 0 0 0 auto;
  min-width: 200px;
}
.search_transaction {
  display: flex;
  align-items: center;
  & > .btn {
    margin-left: 20px;
    width: 168px;
    text-align: center;
  }
  & .filt_clear {
    background: @bg-main;
    &:hover {
      background: @primary-color-bl400;
      color: #FFFFFF;
    }
  }
  & .transact_down {
    margin: 0 0 0 auto;
    color: @font-gray-color;
    padding: 12px;
    &:hover {
      background: @bg-main;
    }
    & span {
      display: none;
    }
  }
}
.transaction_sel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -10px;
  width: 100%;
  max-width: 1000px;
  & .transaction_inp {
    width: 25%;
    padding: 0 10px;
    & .fake_select {
      color: #5A5A5A;
      padding: 14px 12px;
      justify-content: space-between;
      border-radius: 14px;
      border: 1px solid fade(@font-color, 30%);
      background: #FFFFFF;
      margin: 0;
      & > span {
        .ellipsis;
      }
    }
  }
  & .btn {
    margin-left: 10px;
  }
  &.transaction_sel_period {
    max-width: 500px;
    & .transaction_inp {
      width: 50%;
    }
  }
}
.transaction_wrap {
  overflow-x: auto;
  margin-top: 20px;
}
.transaction {
  font-size: 13px;
  border-bottom: 1px solid @bg-main;
  & .transaction_item {
    display: flex;
    min-width: 1276px;
    & > div {
      padding: 15px 11px;
      border: 1px solid @bg-main;
      display: flex;
      flex-direction: column;
      justify-content: center;
      & > div {
        padding: 7px 11px;
        border: 1px solid @bg-main;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &.transact_duo {
        padding: 0;
        border: 0 !important;
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: 1fr;
      }
    }
    &:not(.transaction_head) {
      & > div {
        & > div {
          min-height: 73px;
          /*height: 50%;*/
        }
      }
      & .transact_num {
        border-left-width: 2px;
      }
      & .transact_action {
        border-right-width: 2px;
      }
    }
    &.transaction_head {
      border-radius: 20px 20px 0 0;
      background: @bg-main;
      border-bottom: 2px solid @bg-main;
      & > div {
        border: 1px solid #fff;
        border-bottom-color: transparent;
        & > div {
          border: 1px solid #fff;
          & + div {
            border-bottom-color: transparent;
          }
        }
      }
      & .transact_num {
        border-left-color: transparent;
      }
      & .transact_action {
        border-right-color: transparent;
      }
    }
    &.active {
      background: @bg-main;
    }
  }
}
.transact_num {
  width: 7.8%;
  min-width: 99px;
}
.transact_prod {
  width: 9.1%;
  min-width: 116px;
}
.transact_tip {
  width: 9.1%;
  min-width: 116px;
}
.transact_data {
  width: 10.4%;
  min-width: 133px;
}
.transact_pnr {
  width: 10.6%;
  min-width: 136px;
}
.transact_fio {
  width: 10.6%;
  min-width: 136px;
}
.transact_sum {
  width: 10%;
  min-width: 128px;
  align-items: center;
}
.transact_creat {
  width: 15.8%;
  min-width: 201px;
}
.transact_izmen {
  width: 7.8%;
  min-width: 99px;
}
.transact_action {
  width: 8.9%;
  min-width: 113px;
  align-items: center;
  & a {
    position: relative;
    &.active {
      background: @primary-color-main;
      &:before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 6px;
        background: #EE5C5C;
        position: absolute;
        top: 0;
        right: 0;
        border: 2px solid #E3E5F6;
      }
      &:hover {
        background: @primary-color-bl400;
      }
    }
  }
}

.user_group .dogovora_head {
  margin: -30px -20px 28px;
  padding: 8px 12px;
  font-size: 13px;
  border-radius: 18px 18px 0 0;
  background: @bg-main;
}
.transaction_but {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  & .btn {
    padding: 14px 16px;
    width: 196px;
    text-align: center;
    margin-left: 20px;
  }
}

.select_comments_wrap.shadow {
  padding: 20px 22px;
  max-width: 450px;
  border-radius: 20px;
  & .select_comments_head {
    color: @font-color;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
  }
}
.select_comments {
  margin: 20px 0;
  position: relative;
  & .fake_select {
    color: fade(@font-color-dark, 60%);
    padding: 13px 14px;
    justify-content: space-between;
    border-radius: 14px;
    border: 1px solid fade(@font-color, 30%);
  }
  & .form_group {
    & + .form_group {
      margin-top: 20px;
    }
    &.form_group_down {
      margin-top: 12px;
    }
  }
  & .form_label {
    line-height: 20px;
    font-weight: 800;
  }
  & + .select_comments {
    padding-top: 20px;
    border-top: 1px solid @bg-main;
  }
}
.form_group {
  & textarea {
    border-radius: 14px;
    border: 1px solid fade(@font-color, 30%);
    height: 120px;
    padding: 12px;
    width: 100%;
    resize: none;
    color: @font-color-dark;
    outline: none !important;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    &:focus, &:active, &.focused {
      border-color: fade(@primary-color-main, 40%);
    }
  }
}
.select_comments_body {
  padding: 20px 0;
  margin: 20px 0;
  border-bottom: 1px solid @bg-main;
  border-top: 1px solid @bg-main;
  & .select_comments_info {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  & .select_comments_sbor {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
  }
}

.sels.fake_select {
  color: @font-gray-color;
  border: 1px solid @primary-color-main;
}
.flex_group_but {
  & .status {
    margin: 0 16px;
  }
}
.select_comments_but {
  display: flex;
  & .btn {
    width: calc(50% - 5px);
    text-align: center;
  }
}
.dogovor_favorite {
  color: transparent;
  &.active, &:hover {
    color: @wings_red_400;
    & use {
      stroke: @wings_red_400;
    }
  }
  & use {
    stroke: @font-gray-color;
  }
}
.dogovor_panel {
  display: flex;
  align-items: center;
  padding-top: 6px;
  & .btn:not(.dogovor_block) {
    margin-right: 20px;
    color: @primary-color-main;
    border-color: fade(@primary-color-main, 40%);
    &:hover {
      background: @primary-color-bl400;
      color: #FFFFFF;
      border-color: @primary-color-bl400;
    }
  }
  & .dogovor_block {
    margin: 0 0 0 auto;
  }
  & .form_group_down {
    & .fake_select {
      color: fade(@font-color-dark, 60%);
      background: transparent;
      border-color: fade(@font-color, 30%);
      border-radius: 14px;
      &:hover {
        border-color: @purple_400;
      }
    }
  }
  & .btn-red_fill:hover, .btn-red_fill.active, .btn-red_fill:focus {
    color: #FFF;
  }
}
.dogovor_balanser {
  display: flex;
  margin: 20px -10px 0;
  & .col6 {
    padding: 10px;
  }
}
.dogovor_balans_qvart {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  & .col6 {
    padding: 10px;
  }
  & .dogovor_qvart {
    background: @bg-main;
    padding: 20px 24px;
    border-radius: 20px;
    height: 100%;
    & .dogovor_qvart_mame {
      color: fade(@font-color, 60%);
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.12px;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      & .btn {
        text-transform: none;
        border-radius: 12px;
      }
    }
    & .dogovor_qvart_price {
      display: flex;
      font-size: 26px;
      font-weight: 600;
      line-height: 36px;
      margin-top: 24px;
      color: fade(@font-color, 40%);
      & span {
        display: block;
        margin-right: 6px;
        color: @font-gray-color;
        .ellipsis;
      }
    }
  }
}
.dogovor_balans_block {
  height: 100%;
  background: @bg-main;
  padding: 20px;
  border-radius: 20px;
  & .dogovor_balans_line {
    display: flex;
    justify-content: space-between;
    & .dogovor_balans_name {
      color: @font-onyx-color;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.12px;
      text-transform: uppercase;
    }
    & .dogovor_balans_value {
      color: @font-gray-color;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      padding-left: 15px;
    }
    & + .dogovor_balans_line {
      margin-top: 6px;
    }
  }
}
div.midfont {
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
  color: @font-color;
}
.flex_group_balans {
  margin: 30px 0 15px;
}
.user_group_balans.user_group {
  padding: 26px 30px;
  & .flex_group {
    margin-bottom: 20px;
  }
  & .dogovor_balans_qvart .dogovor_qvart .dogovor_qvart_price {
    font-size: 22px;
  }
}
.balans_dat {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: @font-onyx-color;
  & span {
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    color: @font-color;
    display: block;
    margin-top: 5px;
  }
  & + .balans_dat {
    margin-left: 25px;
  }
}
.detail_tbs {
  & .btn {
    color: @font-gray-color;
    border-radius: 12px;
    margin-left: 10px;
    &.active {
      color: #FFFFFF;
      background: @primary-color-main;
    }
    &.detail_tbs_pay {
      display: flex;
      align-items: center;
      &:after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: @red_500;
        margin-left: 10px;
      }
    }
  }
}

.user_group_nobord {
  &.user_group {
    padding: 0;
    border: none;
    & .form_group {
      margin-bottom: 20px;
      & .line_pay {
        color: @font-color;
        font-size: 22px;
        font-weight: 800;
        line-height: 28px;
      }
    }
  }
}
.user_group_noline {
  &.user_group {
    padding: 0;
    border: none;
  }
}
.modal_side_wrap {
  & .form_group_but {
    display: flex;
    justify-content: space-between;
    & .btn {
      text-align: center;
      width: calc(50% - 10px);
    }
  }
}


.balans_accord {
  background: @bg-main;
  border-radius: 14px;
  overflow: hidden;
  border: 1px solid @primary-color;
  & .accord_head.side_title {
    background: @bg-main;
    height: 60px;
    padding: 8px 24px;
    margin: 0;
    & .balans_accord_head {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      & .balans_period {
        margin-left: 30px;
      }
      & .status {
        margin: 0 60px 0 30px;
      }
    }
  }
  & .accord_body {
    & .user_group_balans.user_group {
      width: 100%;
      margin: 2px 0 0;
      & .flex_group {
        margin-bottom: 30px;
      }
      & .dogovor_balans_qvart {
        & .dogovor_qvart {
          background: #FFFFFF;
        }
      }
    }
  }
  &.accord_item {
    margin-top: 20px;
  }
}
.pagination_wrap.pagination_nomarg {
  margin-left: 0;
  margin-right: 0;
}

.user_group_provaid.user_group {
  margin-top: 50px;
  & .fake_wrap {
    max-width: 304px;
    & li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & span {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-transform: uppercase;
        color: fade(@font-gray-color, 40%);
      }
    }
  }
}
.user_group.user_group2 {
  margin-top: 44px;
  & .user_group_head {
    margin-bottom: 20px;
  }
  & .form_group {
    margin-bottom: 0;
  }
}
.kredit_wrap {
  margin: 15px 0 45px;
}
.kredit_item {
  padding: 28px 24px;
  border-radius: 20px;
  border: 1px solid @bg-main;
  background: #FFF;
  height: 100%;
  & .kredit_item_mame {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.12px;
    text-transform: uppercase;
    color: @font-onyx-color;
    & span {
      /*.ellipsis;*/
    }
  }
  & .kredit_item_value {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 44px;
    .ellipsis;
  }
}
.relat {
  position: relative;
}
.abs_group_but {
  display: flex;
  position: absolute;
  top: -3px;
  right: 0;
  & .btn {
    & + .btn {
      margin-left: 12px;
    }
  }
}
@media (max-width: 1439px) {
  .contract_line {
    flex-wrap: wrap;
    & .contract_line_info {
      width: 100%;
      border: 0;
      margin-bottom: 20px;
    }
    & .fake_wrap {
      margin-left: 0;
    }
  }
  .search_transaction {
    & .btn {
      margin-left: 12px;
    }
    & .filt_clear {
      background: @bg-main;
    }
    & .transact_down {
      margin: 0;
    }
  }
  .transaction_sel {
    margin: 0 -6px;
    & .transaction_inp {
      padding: 0 6px;
    }
  }

}
@media (max-width: 1199px) {
  .search_contract_form {
    & .col3 {
      width: 50%;
    }
  }
  .abs_group_but {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 40px;
  }
}
@media (max-width: 991px) {
  h1.midfont {
    font-size: 26px;
  }
  .search_widget.search_contract {
    padding: 20px 20px 10px 20px;
    margin-top: 30px;
  }
  .contract_line {
    & .contract_line_info {
      justify-content: space-between;
      & > div:last-child {
        margin-right: 0;
      }
    }
    & .add_contract {
      padding: 4px;
      border-radius: 8px;
      margin-left: 16px;
      & span {
        display: none;
      }
    }
  }
  .contract_line_search {
    & .fields_result {
      width: 48px;
      & .to_result {
        & span {
          display: none;
        }
        & svg {
          display: block;
        }
      }
    }
  }
  .work_contr {
    padding: 4px;
    border-radius: 8px;
    background: #FFFFFF;
    & > svg {
      display: block;
    }
    & span {
      display: none;
    }
  }
  .user_group.user_group_dogovor {
    padding: 22px 16px;
    margin-top: 10px;
  }
  .dogovora {
    margin-top: 10px;
    & .dogovora_item {
      padding: 0 12px;
      flex-wrap: wrap;
      & > div {
        min-height: 64px;
        padding: 8px 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        min-width: 130px;
        &.dogovor_but {
          min-width: 32px;
        }
      }
    }
  }
  .flex_group_but {
    & .form_group {
      position: absolute;
      right: 0;
      top: 141px;
    }
  }
  .search_transaction {
    flex-wrap: wrap;
    & > .btn {
      margin: 20px 0 0 0;
      width: 217px;
      &.filt_clear {
        margin-left: 12px;
      }
    }
    & .filt_clear {
      background: @bg-main;
    }
    & .transact_down {
      margin: 0 0 0 12px;
      padding: 11px;
      width: 139px;
      order: -1;
      display: flex;
      justify-content: center;
      color: @primary-color-main;
      border: 1px solid @primary-color-main;
      border-radius: 14px;
      & span {
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-right: 12px;
      }
    }
  }
  .transaction_sel {
    width: calc(100% + 20px);
    max-width: none;
    order: -2;
    &.transaction_sel_period {
      max-width: none;
      & ~ .btn {
        width: calc(33.3333% - 8px);
      }
    }
  }
  .flex_group {
     margin-bottom: 25px;
   }
  .transaction_wrap {
    margin-top: 35px;
    margin-right: -10px;
  }
  .filt_clear + .form_group_down {
    margin: 20px 12px 0 0;
    width: calc(33.3333% - 8px);
    order: -1;
    .btn {
      margin-left: 0;
    }
  }
  .dogovor_balans_wrap {
    overflow: hidden;
    margin: 0 -14px;
  }
  .dogovor_balanser {
    flex-wrap: wrap;
    margin: 10px 4px 0;
    & > .col6 {
      width: 100%;
    }
  }
  .dogovor_panel {
    padding-top: 4px;
    & .dogovor_block {
      margin: 0;
    }
    & .btn:not(.dogovor_block) {
      margin-right: 16px;
    }
    & .form_group_down {
      order: 1;
      & .btn {
        margin: 0 0 0 16px;
        padding: 15px 10px;
        & svg {
          display: none;
        }
      }
    }
  }
  .flex_group_dogovorhead {
    margin: -10px 0 15px;
    padding: 0 5px;
  }
  .flex_group_balans {
    margin: 10px 0 15px;
  }
  .user_group_balans.user_group {
    padding: 26px 12px;
    margin: 20px 0 0;
    & .dogovor_balans_qvart {
      & .col6:last-child {
        width: 100%;
      }
    }
  }
  .accord_body .user_group_balans.user_group .dogovor_balans_qvart .col6:last-child {
    width: 50%;
  }
  .abs_group_but {
    position: relative;
    top: 0;
    & .btn {
      width: calc(50% - 6px);
      text-align: center;
    }
  }
  .user_group_provaid.user_group {
    margin-top: 10px;
    & .col4 {
      width: 30%;
      &:nth-child(2) {
        width: 40%;
      }
    }
    & .checkbox-container {
      line-height: 20px;
    }
  }
  .user_group_nobord.user_group {
    & .form_group {
      margin-bottom: 15px;
    }
    & .col4, .col6 {
      & .form_group {
        margin-bottom: 2px;
      }
    }
  }
  .kredit_wrap {
    margin: 10px 0 0;
    & .col4 {
      width: 50%;
      &:nth-child(1) {
        width: 100%;
      }
    }
    & + .user_group {
      margin-top: 15px;
    }
  }
  .kredit_item {
    padding: 27px 24px;
  }
}
@media (max-width: 767px) {
  .contract_line {
    & .contract_line_info {
      overflow-y: hidden;
      overflow-x: auto;
      padding-bottom: 7px;
      margin-bottom: 13px;
    }
  }
  .contract_line {
    padding: 0 0 10px;
  }

  .dogovora {
    & .dogovora_item {
      padding: 4px 6px 10px;
      & > div {
        min-height: 52px;
        min-width: 127px;
        &.dogovor_status {
          display: none;
        }
        &.dogovor_data_start, &.dogovor_data_end {
          display: flex;
        }
      }
    }
  }
  h1.midfont {
    font-size: 22px;
  }
  .transaction_wrap {
    margin-right: -15px;
  }
  .search_transaction {
    position: relative;
    & > .btn {
      margin: 8px 0 0 0;
      width: calc(50% - 6px);
    }
    & .transact_down {
      margin: 0;
      width: calc(33.3333% - 6px);
      position: absolute;
      right: 0;
      top: 60px;
    }
  }
  .flex_group {
    flex-wrap: wrap;
    justify-content: center;
  }
  .flex_group_but {
    width: 100%;
    margin-top: 46px;
    flex-wrap: wrap;
    & .form_group {
      position: relative;
      right: auto;
      top: 0;
      width: calc(50% - 6px);
      order: 1;
    }
    &:not(.detail_tbs) > .btn {
      margin: 0 12px 0 0;
      width: calc(50% - 6px);
      text-align: center;
    }
  }
  .transaction_sel {
    width: calc(100% + 12px);
    max-width: calc(100% + 12px);
    flex-wrap: wrap;
    justify-content: flex-start;
    & .transaction_inp {
      width: 50%;
      margin-bottom: 12px;
    }
    &.transaction_sel_period {
      padding-top: 70px;
      &~ .btn {
        width: calc(50% - 6px);
      }
    }
  }
  .filt_clear + .form_group_down {
    margin: 0;
    width: 100%;
    position: absolute;
    top: 0;
  }

  .flex_group_dogovorhead {
    margin: 0;
    padding: 0;
    justify-content: flex-start;
    & h1.midfont {
      font-size: 26px;
    }
    & .flex_group_but {
      margin: 0 0 25px;
      justify-content: flex-start;
    }
    & > .btn {
      width: calc(50% - 7px);
    }
  }
  .dogovor_panel {
    padding-top: 18px;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
    & .btn:not(.dogovor_block) {
      margin-right: 0;
      width: calc(50% - 5px);
      text-align: center;
    }
    & .dogovor_block {
      margin: 0;
      position: absolute;
      top: -50px;
      right: 0;
      width: calc(50% - 5px);
      text-align: center;
    }
    & .form_group_down {
      width: 100%;
      margin-top: 20px;
      & .btn {
        margin: 0;
        width: 100%;
      }
    }
  }
  .dogovor_balans_qvart {
    & .col6 {
      width: 50%;
    }
    & .dogovor_qvart {
      & .dogovor_qvart_mame {
        & .btn {
          font-size: 12px;
          padding: 6px 8px;
        }
      }
    }
  }
  .flex_group_balans {
    margin: 10px 0 15px;
    justify-content: flex-start;
  }
  .flex_group_but.detail_tbs {
    justify-content: flex-start;
    margin-top: 20px;
    & .btn {
      margin: 0 10px 0 0;
    }
  }
  .user_group_balans.user_group {
    padding: 26px 28px;
    margin: 20px 0 0;
    & .flex_group {
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: flex-start;
    }
    & .flex_group_but {
      margin-top: 0;
      display: block;
      width: auto;
    }
  }
  .balans_dat + .balans_dat {
    margin: 22px 0 0;
  }

  .balans_accord .accord_head.side_title {
    height: 132px;
    flex-wrap: wrap;
    padding: 0 20px;
    align-items: stretch;
    & .balans_accord_head {
      width: 100%;
      height: 60px;
      & .extr_toggle {
        position: absolute;
        right: 20px;
        top: 50px;
      }
    }
  }
  .flex_group_editdogovor {
    justify-content: flex-start;
    margin-top: -7px;
    margin-bottom: 18px;
    & h1.midfont {
      font-size: 26px;
    }
  }
  .user_group_provaid.user_group {
    & .fake_wrap {
      max-width: 100%;
    }
    & .col4 {
      width: 43%;
      &:nth-child(1) {
        width: 100%;
        margin-bottom: 4px;
      }
      &:nth-child(2) {
        width: 57%;
      }
    }
  }
  .user_group.user_group2 {
    margin-top: 42px;
    & .col4 {
      width: 33.3333%;
    }
    & .col6 {
      width: 50%;
    }
    & .form_group {
      & .form_label {
        font-size: 14px;
        margin-bottom: 7px;
      }
    }
  }
  .kredit_wrap {
    & .col4 {
      width: 100%;
    }
    & + .user_group {
      margin-top: 15px;
    }
  }
}
@media (max-width: 575px) {
  .search_widget.search_contract {
    padding: 23px 20px 10px 20px;
    margin-top: 20px;
  }
  .search_contract_form {
    & .col3 {
      width: 100%;
    }
    & .view_filtr_wrap {
      display: flex;
      position: relative;
      margin: 10px -20px 0;
      width: calc(100% + 40px);
    }
    & .user_group {
      height: 0;
      opacity: 0;
      padding: 0;
      transition: 0.3s;
    }
    &.active {
      & .user_group {
        height: 272px;
        padding: 10px 0;
        opacity: 1;
      }
      & .view_filtr {
        & .filtr_text {
          & span {
            opacity: 0;
            max-height: 0;
            & + span {
              opacity: 1;
              max-height: 20px;
            }
          }
        }
        & svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  .user_group_dogovor {
    padding: 16px 14px;
    & .user_group_head h5 {
      font-size: 14px;
    }
  }

  .dogovora {
    & .dogovora_item {
      padding: 4px 6px 10px;
      & > div {
        min-height: 52px;
        min-width: 0;
        width: 50%;
        &.dogovor_but {
          align-items: flex-end;
        }
      }
    }
  }
  .flex_group_but {
    margin-top: 24px;
    & .form_group {
      width: 100%;
    }
    & > .btn {
      margin: 0 0 12px;
      width: 100%;
    }
  }
  .flex_group {
    margin-bottom: 12px;
    flex-wrap: wrap;
    & > .btn {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      margin-left: 0;
    }
  }
  .search_transaction {
    position: relative;
    & > .btn {
      margin: 0;
      width: 100%;
      &.filt_clear {
        margin: 12px 0 0;
      }
    }
    & .transact_down {
      margin: 0 0 24px;
      order: -3;
      width: 100%;
      position: relative;
      top: 0;
    }
  }
  .transaction_sel {
    & .transaction_inp {
      width: 100%;
      &:nth-child(1), &:nth-child(2) {
        width: 100%;
      }
    }
    &.transaction_sel_period {
      max-width: calc(100% + 12px);
      &~ .btn {
        width: 100%;
      }
    }
  }
  .transaction_but {
    margin-top: 18px;
    flex-wrap: wrap;
    & .btn {
      width: 100%;
      margin: 12px 0 0;
    }
  }
  .flex_group_dogovorhead {
    position: relative;
    & .flex_group_but {
      margin: 30px 0 23px;
      justify-content: space-between;
      & .status {
        margin: 0;
        position: absolute;
        top: -35px;
        left: 0;
        font-size: 10px;
        letter-spacing: 1px;
      }
    }
    & > .btn {
      margin-top: 0;
    }
  }
  .dogovor_panel {
    padding-top: 65px;
    & .btn:not(.dogovor_block) {
      width: 100%;
      padding: 14px 9px;
      margin-bottom: 20px;
    }
    & .dogovor_block {
      top: -3px;
      width: 100%;
    }
    & .form_group_down {
      margin-top: 0;
      & .fake_select {
        padding: 12px 9px;
        margin: 0;
      }
    }
  }
  .dogovor_balans_qvart .col6 {
    width: 100%;
  }
  .flex_group_but.detail_tbs {
    justify-content: center;
    margin: 20px -5px 0;
    flex-wrap: nowrap;
    & .btn {
      margin: 0 5px;
      text-align: center;
      &.detail_tbs_pay:after {
        margin-left: 8px;
      }
    }
  }
  .user_group_balans.user_group {
    padding: 28px 28px;
    margin: 20px 0 0;
    & .flex_group {
      flex-wrap: wrap;
    }
  }
  .balans_accord .accord_head.side_title {
    height: 152px;
    padding: 43px 20px 20px 20px;
    & .balans_accord_head {
      flex-wrap: wrap;
      height: auto;
      & .extr_toggle {
        top: 60px;
      }
      & .balans_period {
        margin-left: 0;
        width: 100%;
      }
      & .status {
        margin: 0;
        position: absolute;
        top: 20px;
      }
      & span {
        margin-top: 8px;
      }
    }
  }
  .balans_accord.accord_item.accord_active {
    margin: 10px -10px 0;
  }
  .balans_accord .accord_body .user_group_balans.user_group {
    width: 100%;
    margin: 0;
    padding: 25px 28px;
  }
  .accord_body .user_group_balans.user_group .dogovor_balans_qvart .col6:last-child {
    width: 100%;
  }
  .flex_group_editdogovor {
    margin-top: 1px;
    margin-bottom: 20px;
  }
  .user_group_provaid.user_group {
    & .fake_wrap {
      max-width: 100%;
    }
    & .col4 {
      width: 100%;
      &:nth-child(2) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .user_group.user_group2 {
    & .col4 {
      width: 100%;
    }
    & .col6 {
      width: 100%;
    }
  }
  .kredit_wrap {
    margin: 33px 0 0;
  }
  .kredit_item .kredit_item_value {
    font-size: 18px;
    line-height: 24px;
    margin-top: 47px;
  }
  .user_group2 {
    & .user_group_head {
      & h5 {
        font-size: 18px;
      }
    }
  }
  .user_group_nobord.user_group {
    & .col4, .col6 {
      & .form_group {
        margin-bottom: 0;
      }
    }
  }
  .abs_group_but {
    margin-top: 8px;
    flex-wrap: wrap;
    & .btn {
      width: 100%;
      margin-top: 12px;
      & + .btn {
        margin-left: 0;
      }
    }
  }
  .table_new .balans_accord {
    margin: 10px -10px 0;
    & .accord_head.side_title {
      height: 60px;
      & .balans_accord_head_start {
        height: auto;
        padding: 0 40px 0 55px;
        position: relative;
      }
      & .accord_ico {
        position: absolute;
        top: 0;
        left: 0;
      }
      & span {
        margin-top: 0;
      }
      & .extr_toggle {
        top: 15px;
        right: 15px;
      }
    }
  }
}