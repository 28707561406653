@import "partials/params";
@import "partials/mixins";

.flex_row_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form_group_center {
  display: flex;
  justify-content: center;
  align-items: center;
  & > .btn + .btn {
    margin-left: 20px;
  }
}
.to-top_up {
  box-shadow: 0 6px 18px 0 rgba(101, 83, 216, 0.13);
  padding: 10px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  transform: rotate(-180deg);
}
.orders {
  padding: 0 20px;
  margin-top: 33px;
  color: @font-color;
  & .blocks_head {
    align-items: flex-start;
  }
  & .btn {
    width: 119px;
    text-align: center;
  }
}
.pagination_nomarg + .orders {
  margin-top: 20px;
}
.blocks_head {
  .flex-center(row, space-between);
}
.orders_table {
  margin: 15px -20px 0;
  border: 2px solid @font-white-color;
  border-radius: @radius-20;
  overflow: hidden;
}
.orders_head {
  .flex-center(row, space-between);
  padding: 12px 14px;
  background: @bg-main;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  & > div {
    padding: 8px 6px;
    display: flex;
    align-items: center;
  }
}
.sort {
  .flex-center;
  margin-left: 4px;
  cursor: pointer;
  & a {
    display: block;
    width: 12px;
    height: 12px;
    cursor: pointer;
    background: url(./img/icon/chevron-up.svg) no-repeat 0 0;
    &.down {
      transform: rotate(180deg);
      margin-top: -3px;
    }
    &:hover {
      opacity: 0.5;
    }
  }
}
.usesort {
  display: flex;
  align-items: center;
  justify-content: center;
  & .sort {
    margin-left: 6px;
    cursor: pointer;
    padding: 0;
    width: 16px;
  }
  & use {
    fill: @font-color-dark;
    color: @font-color-dark;
  }
  &.active {
    color: @primary-color-main;
  }
  &.active_down {
    & svg {
      margin-left: -7px;
    }
    & use {
      fill: @primary-color-main;
      color: transparent;
    }
  }
  &.active_up {
    & svg {
      margin-right: -7px;
    }
    & use {
      fill: transparent;
      color: @primary-color-main;
    }
  }
}


.orders_item {
  .flex-center(row, space-between);
  padding: 0 14px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid @primary-color;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
  & > div {
    padding: 8px 6px;
    &.ord_date_travel {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  & .ord_order {
    display: flex;
    & img {
      display: block;
      margin-right: 8px;
      width: 20px;
    }
  }
  & .ord_attention {
    .flex-center;
    min-height: 59px;
  }
}
.ord_order {
  width: 110px;
}
.ord_date_order {
  width: 92px;
}
.ord_status {
  width: calc(33% - 238px);
  & .status {
    white-space: normal;
  }
}
.ord_attention {
  width: 80px;
  justify-content: center;
}
.ord_date_travel {
  width: 104px;
}
.ord_route {
  width: calc(33% - 228px);
}
.ord_pass {
  width: 110px;
  word-break: break-all;
}
.ord_clients {
  width: 100px;
}
.ord_amount {
  width: 124px;
}
.ord_company {
  width: calc(33% - 242px);
}
.status {
  display: inline-block;
  border-radius: @radius-30;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  white-space: nowrap;
  &.cancelled {
    background: rgba(231, 61, 61, 0.10);
    color: #BB3030;
  }
  &.at_work {
    background: fade(@primary-color-bl600, 40%);
    color: @purple_700;
  }
  &.completed {
    background: rgba(89, 217, 178, 0.40);
    color: #3D9076;
  }
  &.agreement {
    background: #FFD8B4;
    color: #CF6D34;
  }
  &.void {
    background: rgba(90, 90, 90, 0.30);
    color: fade(@font-color, 60%);
  }
  &.st_job {
    background: @font-mint-color;
    color: #FFFFFF;
  }
  &.status_big {
    padding: 6px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
  }
}
.essent {
  &-red {
    color: #E73D3D;
  }
  &-gray {
    color: @font-onyx-color;
  }
}
.att_span {
  display: inline-block;
  border-radius: 30px;
  padding: 2px 10px;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  &.att-red {
    background: rgba(231, 61, 61, 0.1);
    color: #BB3030;
  }
  &.att-green {
    background: rgba(89, 217, 178, 0.4);
    color: #3D9076;
  }
}
.widspan {
  & > span, > a {
    width: 100%;
    display: flex;
    color: @font-color;
  }
}

.block_widget {
  background: @bg-main;
  padding: 20px 20px;
  border-radius: 20px;
  color: @font-color-dark;
  margin-top: 38px;
  & .block_widget_head {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  & .widget_head_info {
    display: flex;
    align-items: center;
    & .block_widget_switch {
      display: flex;
      border-radius: 12px;
      border: 1px solid fade(@primary-color-main, 40%);
      & label {
        padding: 6px 16px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        z-index: 1;
        & .checkmark {
          border-radius: 12px;
          position: absolute;
          width: 100%;
          left: 0;
          height: 100%;
          top: 0;
          border: 0;
          background: transparent;
          color: @font-gray-color;
          .anim-fade;
          z-index: -1;
        }
        & input:checked ~ .checkmark {
          background: @primary-color-main;
          & + span {
            color: #FFFFFF;
          }
        }
        &:hover {
          & span {
            color: #fff;
          }
          & .checkmark {
            background: @primary-color-main;
          }
        }
      }
      & a {
        padding: 6px 16px;
        border-radius: 12px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: @font-gray-color;
        .anim-fade;
        &.active, &:hover {
          background-color: @primary-color-main;
          color: #fff;
        }
      }
      & + .block_widget_checkbox {
        margin-left: 20px;
      }
    }
    & .block_widget_checkbox {
      display: flex;
      & .checkbox-container {
        padding: 14px 14px;
      }
    }
  }
  & .widget_head_but {
    display: flex;
    align-items: center;
    & > * {
      margin-left: 20px;
    }
    & .extr_toggle {
      color: @primary-color-main;
      border-color: fade(@primary-color-main, 40%);
      & svg {
        transform: rotate(180deg);
        margin-left: 10px;
      }
      & .filtr_text {
        .flex-center;
        & span {
          opacity: 1;
          max-height: 20px;
          .anim-fade;
          & b {
            display: none;
            font-weight: 400;
          }
          & + span {
            opacity: 0;
            max-height: 0;
          }
        }
      }
    }
  }
  & .widget_inputs {
    opacity: 1;
    /*max-height: 400px;*/
    margin: 10px 0;
    .anim-fade;
    & .block_widget_head {
      padding: 0 0 20px;
    }
    &.widget_inputs_icon {
      margin-bottom: -10px;
    }
  }
  & .user_group {
    padding: 0;
    margin: 0;
    border: 0;
  }
  &.active {
    & .extr_toggle {
      color: #fff;
      background-color: @primary-color-main;
      & svg {
        transform: rotate(0deg);
      }
      & .filtr_text {
        & span {
          opacity: 0;
          max-height: 0;
          & + span {
            opacity: 1;
            max-height: 20px;
          }
        }
      }
    }
    .widget_inputs {
      opacity: 0;
      max-height: 0;
      margin: 0;
    }
  }
}

.block_widget_menu {
  background: @bg-main;
  padding: 8px 10px;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 40px;
  &.block_widget_menu_doc {
    margin-bottom: 13px;
    & + h1 + .block_widget {
      margin-top: 20px;
    }
  }
  & .block_widget_menu_scroll {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: -8px;
    padding-bottom: 8px;
    white-space: nowrap;
    /*max-width: 750px;*/
    & a {
      display: block;
      color: @font-onyx-color;
      text-decoration: none;
      margin: 0 10px;
      line-height: 24px;
      position: relative;
      .anim-fade;
      &:after {
        content: "";
        position: absolute;
        bottom: -7px;
        left: 0;
        right: 0;
        height: 1px;
        .anim-fade;
        background-color: @bg-main;
      }
      &:hover, &.active {
        color: @primary-color-main;
        &:after {
          background-color: @primary-color-main;
        }
      }
    }
  }
}
.block_widget_menu_gray {
  text-align: center;
  & .block_widget_menu {
    display: inline-block;
    width: auto;
    border-radius: 12px;
    background: @primary-color-gray;
    margin: -10px 0 27px;
    padding: 6px 10px;
    & .block_widget_menu_scroll {
      & a {
        color: #FFFFFF;
        margin: 0 2px;
        border-radius: 12px;
        padding: 6px 16px;
        line-height: 20px;
        &:after {
          content: none;
        }
        &.active, &:hover {
          background-color: @primary-color-main;
        }
      }
    }
  }
}
.block_widget_menu_fix {
  position: fixed;
  top: 98px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 762px;
  z-index: 1;
  margin-left: 112px;
  .anim-fade;
  & .block_widget_menu {
    margin: 0;
  }
  & + .head_ico_date {
    margin-top: 61px;
  }
}
.sidebar.active + .wrapper {
  & .block_widget_menu_fix {
    margin-left: 51px;
  }
}

.menu_order_comm {
  & span {
    color: rgba(255, 255, 255, 0.40);
    font-weight: 800;
  }
}
.menu_order_bron {
  & span {
    color: #fff;
    font-weight: 800;
    &.active {
      color: fade(@font-color, 30%);
    }
  }
  &:before {
    position: absolute;
    top: 12px;
    right: 16px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    vertical-align: middle;
    margin-left: 10px;
  }
  &-attention {
    padding-right: 34px !important;
    &:before {
      content: "";
      background: @red;
    }
  }
  &-ready {
    padding-right: 34px !important;
    &:before {
      content: "";
      background: #FFFFFF;
    }
  }
}
[class*="menu_order-"] {
  padding-right: 34px !important;
  &:before {
    content: "";
    position: absolute;
    top: 13px;
    right: 16px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    vertical-align: middle;
    margin-left: 10px;
  }
}
.menu_order {
  &-attention {
    &:before {
      background: @red;
    }
  }
  &-ready {
    &:before {
      background: #FFFFFF;
    }
  }
}

.block_widget_type {
  display: flex;
  margin: -6px -3px;
  & div {
    display: flex;
    align-items: center;
    margin: 0 3px;
    padding: 6px 11px 6px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 12px;
    background-color: fade(@primary-color-main, 50%);
    color: #fff;
    & a {
      display: block;
      margin-left: 6px;
      color: #fff;
    }
  }
}
.block_widget_znach {
  display: flex;
  margin: 16px -4px -4px;
  flex-wrap: wrap;
  & div {
    padding: 4px;
    & a {
      display: block;
      padding: 6px 16px;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      border-radius: 12px;
      color: @font-gray-color;
      &.active {
        background-color: @primary-color-main;
        color: #fff;
      }
    }
  }
}

.input_type {
  background: #FFF;
  position: relative;
  display: flex;
  align-items: center;
  overflow: visible;
  & .plais {
    display: block;
    color: fade(@font-color, 60%);
  }
  & .inp_open_type {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 14px;
    background: #fff;
    color: @font-gray-color;
    &  + .fake_dropdown {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 1;
      margin-top: 10px;
      min-width: 280px;
    }
  }
  &.selected {
    & .plais {
      display: none;
    }
  }
}
.inp_search {
  display: flex;
  align-items: center;
  & .inp_search_ico {
    width: calc(100% - 100px);
    position: relative;
    & svg {
      position: absolute;
      top: 12px;
      left: 12px;
    }
    & .input_field {
      padding-left: 48px;
      background: #FFFFFF;
    }
  }
  & .inp_search_btn {
    width: 80px;
    margin-left: 20px;
  }
}
.pagination_wrap + .table_scrol {
  margin-top: 20px;
}
.table_scrol {
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 8px;
  margin-bottom: -8px;
  margin-right: -30px;
}
.table_tip {
  width: 100%;
  border: 2px solid @bg-main;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 20px;
  overflow: hidden;
  white-space: nowrap;
  & tr:hover {
    & a {
      color: @primary-color-main;
    }
  }
  & th {
    background: @bg-main;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    padding: 16px 24px;
    border-left: 1px solid #FFFFFF;
    border-right: 1px solid #FFFFFF;
    height: 70px;
    &:first-child {
      border-left-color: @wings_blue_100;
    }
    &:last-child {
      border-right-color: @wings_blue_100;
    }
  }
  & td {
    padding: 24px 22px;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    border: 1px solid @bg-main;
    & a {
      color: @font-gray-color;
      display: flex;
      align-items: center;
      & svg {
        margin-right: 6px;
      }
    }
    & > span {
      display: flex;
      align-items: center;
    }
    & span.btn {
      padding: 10px;
      border-radius: 8px;
      margin-right: 10px;
    }
  }
}
.table_tip_doc {
  & th:nth-child(1) {
    width: 9.9%;
  }
  & th:nth-child(2) {
    width: 15%;
  }
  & th:nth-child(3) {
    width: 21.1%;
  }
  & th:nth-child(4) {
    width: 16.4%;
  }
  & th:nth-child(5) {
    width: 18.8%;
  }
  & th:nth-child(6) {
    width: 18.8%;
  }
}
.table_tip_bron {
  & th {
    width: 26.3%;
    &:nth-child(1) {
      width: 21.1%;
    }
  }
}
.table_tip_lonn {
  text-align: center;
  & tr.active {
    background: @bg-main;
  }
  & th {
    font-size: 14px;
    line-height: 20px;
    padding: 13px 12px;
    border-left: 0;
    border-right: 0;
    height: auto;
    min-width: 75px;
    &:first-child {
      padding: 13px 23px;
      vertical-align: middle;
      min-width: 64px;
      & .checkbox-container .checkmark {
        margin: 0;
      }
    }
  }
  & td {
    padding: 13px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border: 0;
    max-width: 300px;
    white-space: normal;
    &.wide_td {
      min-width: 300px;
    }
    &:first-child {
      padding: 13px 23px;
      vertical-align: middle;
      & .checkbox-container .checkmark {
        margin: 0;
      }
    }
  }
}
.modal_side_otchet {
  & .table_tip_lonn {
    & tr:nth-child(2n+2) {
      background: #FAFAFA;
    }
    & td {
      padding: 23px 12px;
    }
  }
  & .table_scrol {
    max-height: calc(100vh - 200px);
    overflow-y: visible;
  }
}

.list_check_col {
  columns: 4;
  & .checkbox-container {
    padding: 5px 0;
  }
}
.head_ico_date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .head_ico_date_head {
    display: flex;
    align-items: center;
    & span.btn {
      padding: 16px;
      color: #fff;
      margin-right: 21px;
      background: @font-onyx-color;
    }
    & .head_ico_date_title {
      & h1 {
        margin: 0 0 8px;
        display: flex;
        align-items: center;
        & .btn {
          margin-left: 32px;
        }
      }
      & .head_ico_date_date {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.12px;
        text-transform: uppercase;
        color: fade(@font-color, 60%);
      }
    }
  }
  & .head_ico_date_right {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.print_version {
  border-bottom: 1px dashed @primary-color-main;
  color: @font-gray-color;
  font-size: 12px;
  &.print_svg {
    position: relative;
    margin-bottom: 10px;
    & svg {
      position: absolute;
      top: 1px;
      left: -20px;
      color: @primary-color-main;
    }
  }
}
.polya_line {
  border-radius: 20px;
  border: 2px solid @wings_blue_100;
  padding: 18px 16px;
  display: flex;
  & > div {
    padding: 0 4px;
    &:nth-child(1) {
      width: 15%;
    }
    &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(6) {
      width: 18.1%;
    }
    &:nth-child(5) {
      width: 12.5%;
    }
  }
  & .status {
    line-height: 18px;
    white-space: normal;
  }
  & .btn-red_fill {
    margin-left: 10px;
    background: @red_500;
    color: #FFFFFF;
    border-radius: 12px;
  }
}
.polya_line_item_name {
  color: fade(@font-color, 60%);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  margin-bottom: 6px;
  .ellipsis;
}
.polya_line_item_value {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  & a {
    color: @primary-color-main;
  }
  &.polya_line_item_value_grey {
    color: @font-color;
  }
  &.polya_line_item_value_b {
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: @font-color-dark;
  }
  & b {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: @font-color-dark;
  }
  &.polya_line_item_price {
    font-weight: 800;
  }
  &.polya_line_item_dark {
    color: @font-color-dark;
  }
  &.polya_line_item_flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    & svg {
      padding: 3px;
    }
  }
}

.chat_body {
  border-radius: 14px;
  border: 2px solid @wings_blue_100;
  padding: 18px;
  background: @wings_blue_100;
}
.chat_gotov {
  border-radius: 14px;
  border: 2px solid @wings_blue_100;
  padding: 18px;
  & .gotov_wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    margin: 20px -8px -5px -5px;
    padding-right: 3px;
    height: 160px;
    overflow-x: hidden;
    overflow-y: auto;
    & .gotov_item {
      margin: 5px;
      padding: 6px 8px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      border-radius: 14px 14px 14px 0;
      background: @wings_blue_100;
      color: @primary-color-main;
    }
  }
  & + .chat_send {
    margin-top: 20px;
  }
}
.chat_send {
  border-radius: 14px;
  border: 2px solid @wings_blue_100;
  padding: 18px;
  & textarea {
    resize: none;
    border: 0;
    height: 246px;
    padding: 0;
    width: 100%;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    outline: none;
    margin: 0;
  }
  & .checkbox-container {
    margin: 20px 0 20px;
  }
  & .btn {
    padding: 6px 10px;
    font-size: 14px;
  }
}
.comment_item {
  & .comment_item_head {
    position: relative;
    font-weight: 800;
    line-height: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 8px 0 6px;
    &:before {
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      background: @wings_blue_200;
      border-radius: 30px;
    }
  }
  & .comment_manager {
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: @mint_600;
    margin: 0 12px;
  }
  & .comment_item_body {
    width: 66%;
    background: @onix_200;
    padding: 6px 16px;
    line-height: 20px;
    font-weight: 500;
    color: @font-color-dark;
  }
  & .comment_item_date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    margin-top: 6px;
    color: fade(@font-color, 40%);
    & .comment_date_time {
      margin: 0 6px;
    }
    & svg {
      color: @primary-color-main;
      padding: 2px;
    }
  }
  &.comment_item_read {
    & .comment_item_date {
      & svg {
        padding: 0;
      }
    }
  }
  &.comment_item_left {
    & .comment_item_head {
      &:before {
        margin-right: 10px;
      }
    }
    .status {
      margin-left: 10px;
    }
    & .comment_item_body {
      border-radius: 0 20px 20px 20px;
    }
  }
  &.comment_item_right {
    & .comment_item_head {
      justify-content: flex-end;
      &:before {
        order: 1;
      }
    }
    & .comment_item_body {
      border-radius: 20px 0 20px 20px;
      margin: 0 0 0 auto;
    }
  }
  & .status {
    cursor: pointer;
  }
}

.pass_grid {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  & .pass_grid_side {
    width: 24.8%;
    padding: 0 10px;
    order: 1;
    & .btn-white {
      color: @primary-color-main;
    }
  }
  & .pass_grid_wrap {
    width: 75.2%;
    padding: 0 10px;
  }
  & .font_bold16 {
    color: @font-color-dark;
  }
}
.grid_grey_title {
  color: fade(@font-color, 60%);
}

.pass_fill {
  background: @wings_blue_100;
  padding: 18px;
}
.pass_ramm {
  border-radius: 20px;
  border: 2px solid @wings_blue_100;
  overflow: hidden;
  &.pass_ramm_cancel {
    border: 2px solid @wings_red_200;
    & .pass_fill {
      background: fade(@red, 10%);
      padding-bottom: 16px;
    }
    & .pass_route {
      border-top: 1px solid #fff;
    }
  }
  &.pass_ramm_void {
    border: 2px solid fade(@font-color, 30%);
    & .pass_fill {
      background: fade(@font-color, 10%);
      padding-bottom: 16px;
    }
    & .pass_route {
      border-top: 1px solid #fff;
    }
  }
}
.pass_flex {
  display: flex;
  margin: 0 -4px;
  & > div {
    padding: 0 4px;
  }
}
.pass_ord_inf_line {
  & > div {
    &.pass_ord_inf_w32 {
      width: 31.9%;
    }
    &.pass_ord_inf_w20 {
      width: 20.1%;
    }
    &.pass_ord_inf_w12 {
      width: 11.8%;
    }
    &.pass_ord_inf_w13 {
      width: 16.3%;
    }
    &.pass_ord_inf_w18 {
      width: 17.9%;
    }
  }
}
.pass_stat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px -5px;
  & .btn {
    margin: 5px;
    display: flex;
    align-items: center;
    & svg {
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
  &.pass_stat_select {
    & .btn {
      opacity: 0.5;
      cursor: default;
      &.active {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
.pass_stat_dop {
  margin: 0 -18px 0;
  padding: 16px 18px 2px;
  border-top: 1px solid #fff;
  display: flex;
}
.pass_flex_col {
  & .pass_flex_item {
    margin-top: 16px;
  }
}
.pass_flex_duo {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  & .pass_flex_item {
    width: 50%;
    padding: 0 4px;
  }
}
.pass_infflex {
  margin: 16px -2px 1px;
}
.pass_flex_infpass {
  display: flex;
  justify-content: space-between;
  margin: 16px -2px 1px;
  & > div {
    padding: 0 2px;
    &:nth-child(1) {
      width: 19.7%;
      max-width: 184px;
    }
    &:nth-child(2) {
      width: 28.2%;
    }
    &:nth-child(3), &:nth-child(4) {
      width: 15.9%;
    }
    &:nth-child(5) {
      width: 20.3%;
    }
  }
}
.pass_route_wrap {
  position: relative;
  & > .row {
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 6px;
    position: relative;
  }
}
.pass_route_from {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 24px;
    top: 55px;
    width: 2px;
    height: calc(100% - 46px);
    background: @primary-color-gray;
  }
}
.pass_route_from:after, .pass_route_to:after {
  content: "";
  display: block;
  position: absolute;
  left: 23px;
  width: 2px;
  height: 2px;
  top: 52px;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid @primary-color-gray;
}
.pass_route_to:after {
  top: 11px;
}
.pass_route_from.pass_route_to {
  & .col4 {
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 22px;
      width: 2px;
      height: 2px;
      top: 48px;
      background: #ffffff;
      border-radius: 4px;
      border: 2px solid @primary-color-gray;
    }
  }
}
.pass_route_bilet {
  & .pass_route_from {
    margin-bottom: -26px;
    &:before {
      left: calc(33.3333% - 11px);
      height: calc(100% - 18px);
      top: 24px;
    }
    &:after {
      left: calc(33.3333% - 12px);
      top: 22px;
    }
  }
  & .pass_route_to {
    &:after {
      left: calc(33.3333% - 12px);
      top: 22px;
    }
  }
  & .pass_route_from.pass_route_to {
    & .col4 {
      &:after {
        content: none;
      }
    }
  }
}
.pass_reg_cancel {
  margin-left: 6px;
}
.pass_route {
  padding: 15px 18px 16px;
  border-top: 1px solid @wings_blue_100;
  & .pass_route_city {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    & .pass_id, .pass_time {
      opacity: 0.8;
      display: inline-block;
      margin-left: 2px;
    }
    & .pass_id {
      font-weight: 800;
      color: @font-color-dark;
    }
    & .pass_route_city_tick {
      color: fade(@font-color-dark, 80%);
    }
    & .pass_route_city_station {
      color: fade(@font-gray-color, 80%);
    }
  }
  & .pass_ord_inf_line {
    margin-top: 16px;
    &.pass_route_oldprice {
      & .polya_line_item_price {
        text-decoration: line-through;
        color: fade(@font-color-dark, 60%);
      }
    }
    &.pass_route_newprice {
      & .polya_line_item_price {
        color: @font-color-dark;
      }
    }
  }
  &:last-child {
    padding-bottom: 19px;
  }
}
.pass_airport {
  display: flex;
  align-items: center;
  line-height: 20px;
  & img {
    display: block;
    width: 30px;
    min-width: 30px;
    margin-right: 8px;
  }
  & .btn {
    background: @primary-color-gray;
    border-radius: 30px;
    margin-right: 8px;
    color: #FFFFFF;
    padding: 6px;
  }
  & b {
    display: block;
  }
}
.text_ico32 {
  display: flex;
  align-items: center;
  color: @font-color-dark;
  & svg {
    background: fade(@font-color, 10%);
    border-radius: 32px;
    margin-right: 10px;
    color: @font-gray-color;
  }
}
.pass_great_noinf {
  color: fade(@font-color, 40%);
  text-align: center;
  margin-top: 10px;
}
.pass_great_inf {
  line-height: 20px;
  margin-top: 10px;
}
.pass_great {
  position: relative;
  padding: 18px;
  border-radius: 20px;
  & ul {
    padding: 0 0 0 21px;
    margin: 3px 0 0;
    & li {
      padding: 2px 0;
      overflow: auto;
    }
  }
  &.pass_great_empty {
    border: 2px dashed fade(@font-color, 10%);
  }
  &.pass_great_orange {
    background: fade(@Peach_500, 40%);
    & .text_ico32 svg {
      background: #FFFFFF;
    }
  }
  &.pass_great_green {
    background: fade(@font-mint-color, 30%);
    & .text_ico32 svg {
      background: #FFFFFF;
    }
    & .pass_great_inf {
      & .btn {
        margin-top: 10px;
      }
    }
  }
}
.pass_load_wrap {
  padding: 24px 18px 32px;
  background: #FFFFFF;
  text-align: center;
  line-height: 20px;
  & .pass_load_img {
    padding: 10px 0 14px;
  }
  & .font_bold16 {
    padding: 10px 0;
    color: @font-color-dark;
  }
  & .pass_load_red {
    color: @wings_red_400;
  }
  &.pass_load_success, &.pass_load_error {
    & .pass_load_img {
      margin-top: 25px;
    }
    & .pass_load_txt {
      margin-bottom: 15px;
    }
  }
}
.span_marg_r10 {
  & b {
    font-weight: 800;
  }
  & span {
    margin-left: 11px;
  }
}
.pass_ticket_chast {
  display: inline-block;
  color: fade(@font-color, 40%);
}

.pass_ticket_slide {
  position: relative;
  margin-top: 13px;
  & .pass_ticket_wrap {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  & .pass_ticket_outer {
    display: flex;
  }
  & .pass_ticket_item {
    width: 226px;
    min-width: 226px;
    height: 70px;
    padding: 4px 10px;
    border-radius: 20px;
    background: rgba(255,255,255,0.4);
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    color: @font-onyx-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 5px;
    & span {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      opacity: 0.7;
    }
    &.active {
      background: @primary-color-main;
      color: #FFFFFF;
    }
    &.pass_ticket_item_all {
      width: 111px;
      min-width: 111px;
    }
  }
  .slider_nav {
    position: absolute;
    top: -41px;
    right: 0;
    & .slider-nav {
      background: #fff;
      box-shadow: none;
      color: @font-gray-color;
    }
  }
}

.flex_bron_form {
  display: flex;
  margin: -4px;
  & > div {
    width: 33.3333%;
    padding: 4px;
  }
}
.pass_bron_hotel {
  display: flex;
  align-items: center;
  margin-top: 16px;
  & .pass_bron_hotel_img {
    width: 160px;
    min-width: 160px;
    height: 160px;
    margin-right: 16px;
    border-radius: 20px;
    overflow: hidden;
  }
  & .pass_bron_hotel_info {
    flex: 1 1 auto;
  }
  & .hotel_title {
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    color: @font-color-dark;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  & .pass_hotel_info {
    display: flex;
    justify-content: space-between;
    margin: 0 -2px;
    & > div {
      min-width: 15.5%;
      padding: 0 2px;
    }
  }
}
.select_bron {
  font-size: 12px;
  font-weight: 500;
  & .user_group .form_group .form_label {
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.12px;
    text-transform: uppercase;
    color: fade(@font-color, 60%);
  }
}

.modal_side.modal_side_bron {
  height: auto;
  border-radius: 30px 30px 0 0;
  top: auto;
  & .modal_head {
    & .checkbox-container {
      margin-left: 30px !important;
      order: 0;
    }
  }
  & .zabron {
    margin: 0 60px 0 auto;
  }
  & .pinned_flex {
    max-height: 352px;
  }
}
.pinned_wrap.pinned_wrap_usluga {
  & .pinned_line {
    min-width: 986px;
    width: auto;
    flex: 1 1 auto;
  }
  & .extr_buttons {
    width: auto;
    min-width: 290px;
    border-left: 1px solid @font-white-color;
    margin-left: 0;
    padding: 0;
  }
  & .pinned_price {
    margin-right: 4px;
    display: block;
  }
  & .price_flex {
    & i, span {
      width: auto;
    }
  }
}
.bold_15_dark {
  font-size: 15px;
  font-weight: 800;
  line-height: normal;
  color: @font-color;
}
.marg_top_12 {
  margin-top: 12px;
}
.bron_dop_item {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  & > div {
    padding: 16px 4px;
    &:last-child {
      flex: 1 1 auto;
    }
  }
  & .bron_dop_item_title {
    width: 554px;
    display: flex;
    & span {
      display: block;
      width: 120px;
    }
  }
  & .bron_dop_item_90 {
    width: 90px;
  }
  & .bron_dop_item_253 {
    width: 253px;
  }
  & .bron_dop_item_name {
    width: 252px;
  }
}

.air_mesta_wrap {
  background: fade(@font-color, 10%);
  margin: 0 -40px -16px;
  & .air_mesta_body {
    width: 516px;
    height: 454px;
    padding: 6px 64px 20px;
    margin: 0 auto;
    background: #FFFFFF;
    position: relative;
    & .air_place_head {
      padding: 14px 27px 16px;
      background: #FFFFFF;
      border-radius: 120px 120px 0 0;
      border: 4px solid @font-white-color;
      border-bottom: 0;
    }
    & .air_place_line {
      display: flex;
      align-items: center;
      &:not(.air_place_head) {
        position: relative;
        &:before, &:after {
          content: "";
          width: 7px;
          height: 24px;
          background: @font-white-color;
          border-radius: 3.5px;
          position: absolute;
          top: 10px;
        }
        &:before {
          left: -28px;
        }
        &:after {
          right: -28px;
        }
      }
    }
    & .air_place_num {
      width: 40px;
      margin: 0 4px;
      text-align: center;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 1px;
      color: fade(@font-color, 60%);;
    }
    & .air_place_razd {
      width: 36px;
      margin: 0 -4px 0 1px;
    }
    & .air_mesta_scroll {
      max-height: 392px;
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: 14px;
      margin-right: -14px;
      margin-top: -8px;
      & > div {
        border-left: 4px solid @font-white-color;
        border-right: 4px solid @font-white-color;
        padding: 0 27px;
        width: 388px;
        margin-top: -4px;
      }
    }
    & .air_tic {
      width: 40px;
      height: 40px;
      border-radius: 14px;
      position: relative;
      font-size: 10px;
      font-weight: 800;
      line-height: 12px;
      border: 2px solid;
      padding: 16px 0 8px;
      text-align: center;
      color: @font-gray-color;
      margin: 4px;
      cursor: pointer;
      &-green {
        background-color: @mint_300;
        border-color: @mint_300;
      }
      &-blue {
        background-color: @primary-color-bl200;
        border-color: @primary-color-bl200;
      }
      &-purple {
        background-color: @purple_200;
        border-color: @purple_200;
      }
      &-gray {
        background-color: fade(@font-color, 10%);
        border-color: transparent;
        padding: 6px;
        & svg {
          color: fade(@font-color, 30%);
        }
      }
      &-baby {
        background-image: url(./img/icon/baby.svg);
        background-repeat: no-repeat;
        background-position: top center;
      }
      &-pets {
        background-image: url(./img/icon/pets.svg);
        background-repeat: no-repeat;
        background-position: center 4px;
      }
      &-check {
        border: 2px solid @primary-color-main;
        &:after {
          content: "";
          position: absolute;
          left: 11px;
          bottom: -6px;
          width: 14px;
          height: 14px;
          border-radius: 4px;
          background: @primary-color-main url(./img/icon/ico_check2.svg) no-repeat center center / 10px;
        }
      }
    }
  }
}
.modal_dop_sub {
  padding: 24px 0 20px;
  position: relative;
  &:after {
    content: "";
    display: block;
    margin: 0 -40px;
    height: 35px;
    box-shadow: 0 6px 10px 0 rgba(101, 83, 216, 0.13);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}
.vibor_place_inf {
  display: flex;
  justify-content: space-between;
  & .vibor_place_item {
    &:nth-child(1) {
      width: 254px;
    }
    &:nth-child(2) {
      width: 386px;
    }
    &:nth-child(3) {
      width: 186px;
    }
    & .polya_line_item_name {
      margin-bottom: 0;
    }
  }
}
.columns2 {
  columns: 2;
}

.form_group {
  & .check_oform {
    align-items: center;
    min-height: 48px;
  }
}

.otchet_option {
  &.active {
    color: #FFFFFF;
    background: @primary-color-main;
  }
  & + .fake_dropdown {
    position: absolute;
    right: -40px;
    top: 100%;
    z-index: 1;
    margin-top: 30px;
    min-width: 334px;
    border-radius: 0;
    border: 2px solid #D6D7F7;
    max-height: calc(100vh - 464px);
    overflow: auto;
    min-height: 600px;
    & .flex {
      justify-content: space-between;
      padding: 5px 10px;
      & h5 {
        color: @font-color;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        & img {
          margin-right: 8px;
        }
      }
      & .btn {
        background: @bg-main;
        color: @font-gray-color;
      }
    }
    & .checkbox-container {
      line-height: 18px;
      &:after {
        content: "";
        position: absolute;
        top: -3px;
        left: 22px;
        width: 25px;
        height: 24px;
        background: url(./img/icon/ravno.svg) no-repeat center center;
      }
      & .checkmark {
        margin-right: 33px;
      }
    }
    & .suggestion-block + .flex {
      margin-top: 14px;
    }
  }
}

.but_sum {
  & li {
    background: none !important;
  }
  & .btn {
    padding: 6px 16px;
    width: 100%;
    justify-content: space-between;
    & span {
      display: block;
      text-align: left;
      width: 193px;
      line-height: 24px;
      .ellipsis;
      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 25px;
        min-width: 25px;
        height: 24px;
        margin-right: 10px;
        background: url(./img/icon/ravno.svg) no-repeat center center;
      }
    }
  }
}

@media (max-width: 1439px) {
  .ord_order {
    width: 94px;
  }
  .ord_date_order, .ord_date_travel {
    width: 87px;
  }
  .ord_clients {
    width: 90px;
    & span {
      justify-content: center;
    }
  }
  .ord_amount {
    width: 100px;
  }
  .ord_company {
    width: 90px;
  }
  .ord_route {
    width: 90px;
  }
  .ord_attention {
    width: 75px;
  }
  .ord_status {
    width: 125px;
  }
  .pinned_wrap.pinned_wrap_usluga .extr_buttons {
    min-width: 170px;
  }
}
@media (max-width: 1299px) {
  .air_mesta_wrap {
    & .air_mesta_body {
      height: calc(100vh - 132px);
      & .air_mesta_scroll {
        max-height: calc(100% - 24px);
      }
    }
  }
  .pinned_wrap.pinned_wrap_usluga .pinned_line {
    width: calc(100% - 210px);
    min-width: 0;
  }
  .block_widget_menu_fix {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 1199px) {
  .head_ico_date {
    position: relative;
    & .head_ico_date_head {
      width: 100%;
      & .head_ico_date_title {
        flex: 1 1 auto;
        & h1 .btn {
          margin-left: auto;
        }
      }
    }
    & .head_ico_date_right {
      flex-direction: row;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .print_version.print_svg {
    margin: 0 10px 0 0;
  }
  .polya_line {
    flex-wrap: wrap;
    padding: 8px;
    & > div:nth-child(n) {
      width: 33.3333%;
      padding: 10px;
    }
  }
  .pass_grid {
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
    & .pass_grid_side {
      width: calc(100% + 20px);
      padding: 0;
      order: 0;
      display: flex;
      margin-left: -10px;
      margin-right: -10px;
      & > div {
        width: calc(50% - 20px);
        margin: 0 10px;
      }
    }
    & .pass_grid_wrap {
      width: 100%;
      padding: 0;
    }
  }
  .pass_infflex {
    margin: 4px -10px 4px;
  }
  .pass_flex_infpass {
    flex-wrap: wrap;
    margin: 4px -10px -4px;
    & > div {
      width: 33.3333% !important;
      padding: 10px;
    }
  }
  .pass_flex {
    flex-wrap: wrap;
    margin: 4px -10px -10px;
    & > div {
      width: 33.3333% !important;
      padding: 10px;
      &.pass_ord_wide {
        width: 66.6666% !important;
      }
    }
  }
  .pass_route .pass_ord_inf_line {
    margin-top: 7px;
  }
  .pass_bron_hotel .pass_hotel_info {
    flex-wrap: wrap;
    margin: -10px;
    & > div {
      padding: 10px;
      width: 35%;
      &:nth-child(2n+2) {
        width: 65%;
      }
    }
  }
  .modal_side.modal_side_bron {
    height: 100%;
    border-radius: 0;
    top: 0;
    & .modal_head {
      padding-left: 0;
      & .checkbox-container {
        margin-left: 0 !important;
        order: -1;
      }
    }
    & .zabron {
      margin: 0 10px;
    }
    & .pinned_flex {
      max-height: calc(100vh - 83px);
    }
  }
  .bron_dop_item {
    flex-wrap: wrap;
    & .bron_dop_item_title {
      width: 100%;
      border-bottom: 1px solid @font-white-color;
      & span {
        width: 21%;
      }
    }
    & .bron_dop_item_90, .bron_dop_item_name {
      width: 25%;
    }
    & .bron_dop_item_253 {
      width: 37%;
    }
  }
  .pinned_wrap.pinned_wrap_usluga {
    & .extr_buttons {
      width: calc(25% - 9.5px);
      flex: initial;
      border: none;
      position: absolute;
      bottom: 1px;
      right: 0;
      height: 50px;
    }
    & .pinned_price {
      display: block;
    }
  }
  .modal_dop_sub {
    padding: 19px 0 20px;
    & .vibor_place_item:nth-child(1) {
      width: 266px;
    }
    & .vibor_place_item:nth-child(2) {
      width: 264px;
    }
    & .vibor_place_item:nth-child(3) {
      width: 170px;
    }
  }
  .air_mesta_wrap {
    margin: 0 -14px -16px;
    & .air_mesta_body {
      padding: 24px calc(50% - 208px) 20px;
      width: calc(100% - 28px);
      margin: 0 14px;
    }
  }
  .table_scrol {
    margin-right: -14px
  }
  .otchet_option + .fake_dropdown {
    right: -24px;
  }
}
@media (max-width: 991px) {
  .orders_item {
    flex-wrap: wrap;
    padding: 4px 14px 5px;
    & > div {
      display: flex;
      align-items: flex-start;
      height: 58px;
      flex-direction: column;
      justify-content: center;
      &:before {
        display: block;
        width: 100%;
        color: fade(@font-color, 60%);
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 4px;
      }
    }
  }
  .orders {
    padding: 0;
  }
  .orders_table {
    margin: 15px 0 0;
  }
  .pagination_wrap {
    margin: 20px 0 0;
  }
  .orders_head {
    display: none;
  }
  .ord_order, .ord_route {
    width: 19.7%;
  }
  .ord_date_order, .ord_pass {
    width: 19.7%;
  }
  .ord_status, .ord_clients {
    width: 23.2%;
  }
  .ord_attention, .ord_amount {
    width: 16.6%;
    align-items: flex-start !important;
  }
  .ord_date_travel, .ord_company {
    width: 20.8%;
  }
  .ord_route, .ord_date_travel {
    line-height: 1;
  }
  /*.ord_order:before {
    content: "заказ";
  }
  .ord_date_order:before {
    content: "дата заказа";
  }
  .ord_status:before {
    content: "Статус";
  }
  .ord_attention:before {
    content: "важно";
  }
  .ord_date_travel:before {
    content: "даты поездки";
  }
  .ord_route:before {
    content: "Маршрут";
  }
  .ord_pass:before {
    content: "пассажиры";
  }
  .ord_clients:before {
    content: "число клиентов";
  }
  .ord_amount:before {
    content: "сумма";
  }
  .ord_company:before {
    content: "Компания";
  }*/
  .orders_table_attrmob .orders_item > div:before {
    content: attr(data-namecol);
  }

  .block_widget {
    & .widget_head_info {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      & .block_widget_switch + .block_widget_checkbox {
        margin: 10px  0 0;
      }
    }
    & .widget_head_but {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-end;
      & .extr_toggle {
        order: -1;
        margin-bottom: 17px;
      }
    }
    & .col3 {
      width: 50%;
    }
  }
  form {
    & .flex_group {
      margin-bottom: 30px;
      margin-top: 10px;
    }
  }
  .flex_title_but.flex_title_but_left {
    margin: 0 -6px;
    & .btn {
      border-radius: 12px;
      padding: 6px 12px;
    }
    & > * {
      margin: 0 6px;
    }
  }
  .block_widget_type {
    margin: 0 -3px;
    & div {
      padding: 5px 5px 5px 8px;
      font-size: 12px;
      line-height: 18px;
      border-radius: 8px;
      & a {
        margin-left: 1px;
        & svg {
          width: 16px;
          height: 16px
        }
      }
    }
  }
  .just_input {
    &.row {
      flex-wrap: nowrap;
      margin: -10px -7px;
      & > div {
        padding: 10px 7px;
      }
    }
    & .input_type {
      padding: 9px 35px 9px 11px;
      & .inp_open_type {
        top: 12px;
        right: 12px;
        padding: 3px 3px;
      }
    }
  }
  .modal_side_otchet {
    & .just_input.row {
      margin: 0 0 0 auto;
    }
    & .table_tip_lonn td {
      padding: 6px 6px;
    }
  }
  .inp_date {
    & .input_field {
      .ellipsis;
      padding-right: 36px;
    }
  }
  .table_scrol {
    margin-right: -14px;
  }
  .modal_side_scroll {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 100px);
  }
  .list_check_col {
    columns: 3;
  }

  .block_widget_menu_gray {
    & .block_widget_menu {
      margin-top: -5px;
      width: 100%;
    }
  }
  .head_ico_date {
    & .head_ico_date_head {
      & .head_ico_date_title h1 {
        font-size: 26px;
      }
      & span.btn {
        padding: 14px 16px;
      }
    }
  }
  .polya_line {
    flex-wrap: wrap;
    padding: 8px 16px;
  }
  .btn-inl_width {
    width: 100%;
  }
  .table_tip_bron {
    table-layout: fixed;
    & th {
      width: 21%;
      &:nth-child(1) {
        width: 37%;
      }
    }
    & td:nth-child(2) {
      white-space: normal;
      word-break: break-all;
    }
  }
  .table_tip_lonn {
    & th {
      font-size: 12px;
      line-height: 18px;
      padding: 9px 6px;
      height: auto;
      vertical-align: top;
      &:first-child {
        padding: 9px 11px;
        min-width: 40px;
      }
    }
    & td {
      padding: 9.5px 6px;
      font-size: 12px;
      line-height: 18px;
      max-width: 210px;
      &.wide_td {
        min-width: 210px;
      }
      &:first-child {
        padding: 9.5px 11px;
        min-width: 40px;
      }
    }
  }
  .usesort {
    justify-content: flex-start;
    flex-direction: column;
    & .sort {
      order: -1;
    }
  }
  .comment_item {
    & .comment_item_body {
      width: 74%;
    }
    & .status {
      display: none;
    }
  }
  .chat_wrap {
    & .col4, .col8 {
      width: 100%;
    }
  }
  .chat_gotov {
    & .gotov_wrap {
      height: 78px;
    }
  }
}
@media (max-width: 767px) {
  .orders_item {
    padding: 4px 10px 5px;
    justify-content: flex-start;
  }
  .ord_order, .ord_date_order, .ord_date_travel, .ord_route, .ord_amount, .ord_company  {
    width: 25.3%;
  }
  .ord_status, .ord_pass {
    width: 27.7%;
  }
  .ord_attention, .ord_clients {
    width: 21.7%;
    padding-right: 0 !important;
  }
  .pagination_wrap {
    flex-wrap: wrap;
    justify-content: center;
    & .pagination_view {
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
    }
  }

  .block_widget {
    margin-top: 20px;
    & .widget_inputs {
      max-height: none;
    }
  }
  form .flex_group {
    margin-bottom: 20px;
    justify-content: flex-start;
  }
  .flex_title_but.flex_title_but_left {
    margin: 20px -6px 0;
    width: calc(100% + 20px);
    & .btn-fix {
      & .btn {
        width: auto;
      }
    }
    & > * {
      flex: inherit;
    }
  }
  .just_input.row {
    flex-wrap: wrap;
    margin: -7px;
    & > div {
      padding: 7px;
    }
    & .form_group {
      width: 50%;
    }
    & > div:not(.form_group) {
      order: 1;
      margin-top: 8px;
      margin-bottom: 6px;
    }
  }
  .widget_inputs_icon .just_input.row {
    flex-wrap: nowrap;
    & > div:not(.form_group) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .table_scrol {
    margin-right: -20px;
  }
  .modal_side_scroll {
    max-height: calc(100vh - 120px);
  }
  .list_check_col {
    columns: 2;
  }

  .block_widget_menu_gray {
    & .block_widget_menu {
      border-radius: 30px;
      margin: -10px 0 30px;
    }
  }
  .head_ico_date {
    flex-wrap: wrap;
    & .head_ico_date_head {
      align-items: flex-start;
      & span.btn {
        padding: 16px;
        /*& .icon32 {
          width: 24px;
          height: 24px;
        }*/
      }
    }
    & .print_version {
      order: -1;
      margin: 0 0 18px auto;
      display: block;
    }
    & .head_ico_date_right {
      & .print_version {
        margin: 0;
      }
    }
  }
  .polya_line {
    padding: 8px 16px;
    & > div:nth-child(n) {
      width: 50%;
      padding: 10px 4px;
    }
  }
  .btn-inl_width {
    padding: 10px 16px;
  }
  .table_tip_bron {
    white-space: normal;
    & th {
      width: 23%;
      padding: 14px;
      height: 68px;
      &:nth-child(1) {
        width: 31%;
      }
    }
    & td {
      padding: 14px;
      height: 86px;
      & span.btn {
        padding: 5px;
      }
    }
  }
  .comment_item {
    & .comment_item_head {
      margin: 20px 0 6px;
    }
    & .comment_item_body {
      width: 100%;
    }
  }
  .chat_send {
    & textarea {
      height: 152px;
    }
  }
  .pass_stat_dop {
    padding: 16px 18px 0;
  }
  .pass_flex_infpass > div {
    width: 50% !important;
  }
  .pass_flex > div {
    width: 50% !important;
  }
  .pass_route_wrap {
    & .col4 {
      width: 50%;
    }
    & .col8 {
      width: 50%;
      & .col6 + .col6 {
        padding-top: 0;
      }
    }
  }
  .pass_route_double {
    display: flex;
    flex-wrap: wrap;
    & .font_bold16 {
      width: 100%;
    }
    & .pass_flex {
      width: 50%;
      & > div {
        width: 100% !important;
        height: 62px;
      }
    }
  }
  .pass_route_bilet {
    & .pass_route_from {
      margin-bottom: -10px;
      &:before {
        left: calc(50% - 5px);
        height: calc(100% - 5px);
      }
      &:after {
        left: calc(50% - 7px);
      }
    }
    & .pass_route_to {
      &:after {
        left: calc(50% - 7px);
      }
    }
  }
  .pass_bron_hotel {
    align-items: flex-start;
    & .pass_bron_hotel_img {
      width: 100px;
      min-width: 100px;
      height: 100px;
    }
    & .pass_hotel_info > div {
      &:nth-child(n) {
        width: 100%;
      }
    }
  }
  .pass_flex > div.pass_ord_wide {
    width: 100% !important;
  }
  .modal_side.modal_side_bron {
    & .modal_head {
      & .checkbox-container {
        order: 0;
        margin-top: 19px;
      }
      & .modal_close {
        position: absolute;
        top: 5px;
        right: 11px;
      }
    }
    & .zabron {
      margin: 17px 0 0;
      width: auto;
      & .btn {
        padding: 6px 20px;
      }
    }
  }
  .air_mesta_wrap {
    margin: 0 -20px -16px;
    & .air_mesta_body {
      padding: 10px calc(50% - 180px) 20px;
      width: 100%;
      margin: 0;
      height: calc(100vh - 206px);
      & .air_place_head {
        padding: 35px 13px 16px;
        border-radius: 56px 56px 0 0;
      }
      & .air_mesta_scroll {
        padding-right: 0;
        margin-right: 0;
        max-height: calc(100% - 46px);
        & > div {
          padding: 0 13px;
          width: 360px;
        }
      }
      & .air_place_line:not(.air_place_head):before {
        left: -14px;
      }
      & .air_place_line:not(.air_place_head):after {
        right: -14px;
      }
    }
  }
  .modal_dop_sub {
    padding: 11px 0 23px;
  }
  .vibor_place_inf {
    flex-wrap: wrap;
    & .vibor_place_item:nth-child(1) {
      width: 100%;
      margin-bottom: 11px;
    }
    & .vibor_place_item:nth-child(2) {
      width: 55%;
    }
    & .vibor_place_item:nth-child(3) {
      width: 45%;
      padding-left: 10px;
    }
  }
  .columns2 {
    columns: 1;
  }
  .form_group_center > .btn + .btn {
    margin-left: 8px;
    & span {
      display: none;
    }
  }
  .otchet_option + .fake_dropdown {
    right: -30px;
  }
}
@media (max-width: 575px) {
  .head_ico_date {
    & .head_ico_date_head {
      & span.btn {
        padding: 6px;
        & .icon32 {
          width: 24px;
          height: 24px;
        }
      }
    }
    & .head_ico_date_right {
      position: relative;
      margin-left: 76px;
    }
  }
  .news {
    & .blocks_head {
      flex-wrap: wrap;
    }
    & .blocks_button {
      margin-top: 10px;
      justify-content: space-between;
      width: 100%;
      & .fake_select {
        padding: 5px 0;
      }
      & .sort_wrap + .main_but {
        margin-left: 0;
        padding: 6px 17px;
      }
    }
  }
  .newstbl_item {
    flex-wrap: wrap;
    & .newstbl_text {
      width: 100%;
      padding-bottom: 0px;
    }
    & .newstbl_date {
      width: 130px;
    }
  }
  .orders_item {
    & > div {
      width: 50% !important;
    }
  }
  .block_widget {
    margin-top: 20px;
    & .block_widget_head {
      justify-content: flex-start;
      padding: 10px 0;
      flex-direction: column;
      align-items: center;
    }
    & .widget_head_info {
      width: 100%;
      text-align: center;
      & .block_widget_switch {
        flex-direction: column;
        width: 100%;
        & + .block_widget_checkbox {
          width: 100%;
          & .checkbox-container {
            width: 50%;
          }
        }
      }
    }
    .widget_head_but {
      align-items: center;
      order: -1;
      width: 100%;
      margin-bottom: 10px;
      & > * {
        margin: 0;
        width: 100%;
        text-align: center;
      }
      & .extr_toggle {
        margin-bottom: 10px;
        & span {
          & b {
            display: inline;
          }
        }
      }
    }
    & .col3 {
      width: 100%;
    }
  }
  .menu_flex {
    flex-wrap: wrap;
    margin: -6px 0;
    & a {
      margin: 6px 0;
    }
  }
  .just_input.row {
    & .form_group {
      width: 100%;
    }
  }
  .fake_wrap_subduo .fake_dropdown .fake_wrap {
    & a {
      flex-wrap: nowrap;
    }
  }
  .inp_search {
    flex-wrap: wrap;
    & .inp_search_ico {
      width: 100%;
    }
    & .inp_search_btn {
      width: 100%;
      margin: 20px 0 0;
    }
  }
  .table_tip {
    white-space: normal;
    & tr {
      & th {
        padding: 8px 11px;
        font-size: 10px;
      }
      & td {
        padding: 8px 7px;
        font-size: 10px;
        line-height: 14px;
        word-break: break-word;
        & a {
          flex-wrap: wrap;
        }
        & > span {
          flex-wrap: wrap;
        }
        & span.btn {
          padding: 6px;
          margin-right: 0;
          margin-bottom: 8px;
        }
      }
    }
  }
  .table_tip_doc {
    display: block;
    & thead, tbody {
      display: block;
    }
    & tr {
      display: grid;
      grid-template-columns: 17.5% 20% 28.5% 34%;
      grid-auto-rows: 35px;
      & th {
        width: auto !important;
        white-space: nowrap;
      }
      & td {
        width: auto !important;
        & .table_tip_num {
          white-space: nowrap;
          justify-content: center;
        }
      }
    }
    & tbody {
      & tr {
        grid-auto-rows: 73px;
      }
    }
    & th, & td {
      display: flex;
      align-items: center;
      height: auto;
      &:nth-child(1) {
        grid-column-start: 1;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      &:nth-child(2) {
        grid-column-start: 2;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      &:nth-child(3) {
        order: 3;
      }
      &:nth-child(4) {
        order: 1;
      }
      &:nth-child(5) {
        order: 2;
      }
      &:nth-child(6) {
        order: 4;
      }
    }
    & th {
      border: 1px solid #FFFFFF;
      border-top-color: #E8EEF6;
      border-bottom-color: #E8EEF6;
      &:nth-child(2) {
        justify-content: center;
      }
      &:nth-child(3) {
        order: 3;
        border-top-color: #FFFFFF;
      }
      &:nth-child(4) {
        order: 1;
        border-bottom-color: #FFFFFF;
      }
      &:nth-child(5) {
        order: 2;
        border-bottom-color: #FFFFFF;
        border-right-color: #E8EEF6;
      }
      &:nth-child(6) {
        order: 4;
        border-top-color: #FFFFFF;
      }
    }
  }
  .table_tip_lonn {
    & tr {
      & th {
        font-size: 12px;
        line-height: 18px;
        padding: 9px 6px;
      }
      & td {
        padding: 9.5px 6px;
        font-size: 12px;
        line-height: 18px;
        word-break: normal;
      }
    }
  }
  .list_check_col {
    columns: 1;
  }
  .table_scrol_head_mob {
    & tbody {
      max-height: 399px;
      overflow-x: hidden;
      overflow-y: auto;
      display: block;
      width: 100%;
      & tr {
        display: flex;
        & td {
          display: flex;
          align-items: center
        }
      }
    }
    & .table_tip_bron {
      display: block;
      & tr {
        & td {
          width: 24.3%;
          &:nth-child(1) {
            width: 27.1%;
          }
        }
      }
    }
  }
  .block_widget_menu_gray {
    & .block_widget_menu {
      margin: 0 0 30px;
    }
  }
  .head_ico_date {
    & .head_ico_date_head {
      & .head_ico_date_title h1 {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
  .polya_line {
    padding: 10px 16px;
    & > div:nth-child(n) {
      width: 100%;
      padding: 7px 4px;
    }
  }
  .table_tip_bron {
    & th {
      height: 60px;
      line-height: 14px;
      width: 24.3%;
      &:nth-child(1) {
        width: 27.1%;
      }
    }
    & tr td {
      height: auto;
      padding: 5px 6px;
    }
  }
  .comment_item {
    & .comment_item_head {
      line-height: 18px;
      font-size: 12px;
      &:before {
        width: 24px;
        height: 24px;
      }
    }
    & .comment_item_head_manag {
      color: @mint_600;
    }
    & .comment_manager {
      display: none;
    }
    & .comment_item_body {
      line-height: 18px;
      font-size: 12px;
    }
    &.comment_item_right {
      & .comment_item_head:before {
        margin-left: 10px;
      }
    }
  }
  .pass_grid .pass_grid_side {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    flex-wrap: wrap;
    & > div {
      width: 100%;
      margin: 0;
      & + div {
        margin-top: 20px;
      }
    }
  }
  .pass_stat .btn {
    padding: 6px 16px;
  }
  .span_marg_r10 {
    & b {
      display: block;
      margin-bottom: 6px;
    }
    & .inf_ticket_num {
      margin-left: 0;
    }
    & .status {
      margin: 6px 50% 0 0;
    }
  }
  .pass_ramm_inform .pass_fill {
    background: #fff;
  }
  .pass_infflex {
    margin: 8px 0 -11px;
  }
  .pass_flex_infpass {
    margin: 8px 0 -11px;
    & > div {
      width: 100% !important;
      padding: 8px 0;
    }
  }
  .pass_route {
    padding: 9px 18px 10px;
    & .pass_ord_inf_line {
      margin-top: 10px;
    }
  }
  .pass_route_wrap {
    & > .row {
      padding-left: 20px;
      margin-top: 3px;
    }
    & .col4 {
      width: 100%;
    }
    & .col8 {
      width: 100%;
      padding-top: 0;
    }
  }
  .pass_route_from:after {
    left: 11px;
    top: 33px;
  }
  .pass_route_to:after {
    left: 11px;
    top: 30px;
  }
  .pass_route_from:before {
    left: 13px;
    top: 36px;
    height: calc(100% - 8px);
  }
  .pass_flex {
    margin-left: 0;
    margin-right: 0;
    & > div {
      width: 100% !important;
      padding: 7px 0;
    }
  }
  .pass_route_bilet {
    & .pass_route_from {
      margin-bottom: -10px;
      &:before {
        left: 13px;
        height: calc(100% - 5px);
        top: 36px;
      }
      &:after {
        left: 11px;
        top: 33px;
      }
    }
    & .pass_route_to {
      &:after {
        left: 11px;
        top: 33px;
      }
    }
  }
  .pass_flex_duo {
    margin: 0;
    & .pass_flex_item {
      width: 100%;
      padding: 0;
    }
  }
  .pass_bron_hotel {
    flex-wrap: wrap;
    & .pass_bron_hotel_img {
      margin: 0 0 16px;
    }
    & .hotel_title {
      margin-bottom: 10px;
    }
    & .pass_hotel_info {
      margin: -7px 0;
      & > div {
        padding: 7px 0;
      }
    }
  }
  .bron_dop_item {
    & > div {
      padding: 10px 3px;
    }
    & .bron_dop_item_title {
      font-size: 13px;
      border-bottom: 0;
      & span {
        width: 35%;
      }
    }
    & .bron_dop_item_90 {
      width: 30%;
      & + .bron_dop_item_90 {
        width: 23%;
      }
    }
    & .bron_dop_item_name {
      width: 47%;
    }
    & .bron_dop_item_253 {
      width: 50%;
    }
  }
  .pinned_wrap.pinned_wrap_usluga {
    & .extr_buttons {
      width: 100%;
      border-top: 1px solid @font-white-color;
      position: relative;
      bottom: 0;
      height: 46px;
      padding-right: 0 !important;
      margin-bottom: 5px;
    }
  }
  .modal_side_bron {
    & .pinned + .pinned {
      margin-top: 0;
    }
  }
}
