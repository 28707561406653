@import "partials/params";
@import "partials/mixins";

.tabs {
  display: flex;
  &-but {
    & a {
      display: block;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      padding: 6px 18px;
      border-radius: 8px;
      color: @font-color-dark;
      white-space: nowrap;
      .anim-fade;
      &:hover {
        color: #fff;
        background: @primary-color-bl400;
        opacity: 1;
      }
      &.active {
        color: #fff;
        background: @primary-color-main;
        border-radius: 8px;
        opacity: 1;
      }
    }
  }
  &-fill {
    .clean-list;
    display: inline-flex;
    border-radius: 14px 14px 0px 0px;
    background: @primary-color;
    & li {
      padding: 7px 17px 7px 16px;
      display: flex;
      align-items: center;
      color: fade(@font-color, 40%);
      cursor: pointer;
      .anim-fade;
      & span {
        display: block;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        margin: 0 10px;
        white-space: nowrap;
      }
      & a {
        color: @font-gray-color;
        .anim-fade;
      }
      & + li {
        border-left: 1px solid @bg-main;
      }
      &.active, &:hover {
        border-radius: 14px 14px 0 0;
        border-left-color: transparent;
        background: @primary-color-bl400;
        color: #ffffff;
        & + li {
          border-left-color: transparent;
        }
        & a {
          color: #ffffff;
        }
      }
      &.active {
        background: @primary-color-gray;
      }
    }
  }
}

.menu_flex {
  display: flex;
  & a {
    display: block;
    padding: 6px 16px;
    border-radius: 12px;
    color: @font-color;
    &.active {
      color: #fff;
      background: @primary-color-main;
    }
  }
}

@media (max-width: 991px) {
  .tabs-fill li {
    width: 33.3333%;
    & span {
      font-size: 13px;
      .ellipsis;
    }
  }
}
@media (max-width: 767px) {
  .tabs-fill li {
    width: 216px;
    padding: 7px 8px;
    & span {
      margin: 0 9px;
    }
  }
}
@media (max-width: 575px) {

  .tabs-fill li {
    width: 150px;
    & span {
      margin: 0 8px;
      font-size: 12px;
    }
  }
}