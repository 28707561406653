@import "partials/params";
@import "partials/mixins";


.carriers_switch {
  & a + a {
      margin-left: 10px;
    }
}
.modal_head_carr {
  margin-left: -10px;
  width: calc(100% + 10px) !important;
  & > div {
    width: 25%;
    padding: 0 10px;
  }
}

.carriers_wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px 0;
  & .carriers_item {
    width: 25%;
    padding: 0 10px;
  }
  & .group_title {
    opacity: 0.4;
    line-height: 20px;
    padding: 4px 0;
  }
  & .checkbox-container {
    padding: 5px 0;
  }
}

@media (max-width: 1299px) {
  .modal_head_carr {
    flex-wrap: wrap;
    padding-right: 0 !important;
    & > div {
      width: auto;
    }
    & .carriers_switch {
      margin-right: 12px;
      & a {
        padding: 3px 16px;
      }
    }
    & .search_form {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 3px;
    }
  }
  .carriers_wrap {
    & .carriers_item {
      width: 50%;
      &:nth-child(n+3) {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .modal_side.modal_side_carr {
    padding: 11px 0;
  }
  .modal_head_carr {
    & .search_form {
      margin-top: 22px;
      margin-bottom: -7px;
    }
  }
}

@media (max-width: 575px) {
  .modal_side {
    & .modal_head_carr {
      margin-top: 0;
      & .carriers_check {
        width: 100%;
        margin-top: 22px;
      }
      & .carriers_switch {
        margin-right: 0;
        margin-left: -4px;
      }
    }
  }
  .carriers_wrap {
    max-height: calc(100vh - 163px);
    overflow-x: hidden;
    overflow-y: auto;
    & .carriers_item {
      width: 100%;
      &:nth-child(n+2) {
        margin-top: 20px;
      }
    }
  }

}

