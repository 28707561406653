@import "partials/params";
@import "partials/mixins";

.news_cal {
  display: flex;
  margin: 20px -10px 0;
  & .calendar {
    width: 33.333%;
  }
  & .news {
    width: 66.666%;
  }
  & > div {
    padding: 0 10px;
    & > div {
      .bordered;
      padding: 28px 18px 22px 18px;
      height: 100%;
      background: @bg-dr-main;
    }
  }
  & .fake_select {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 9px;
  }
}

.calendar .fake_select {
  font-size: 14px;
  padding: 4px 0;
  & .icon18 {
    margin-left: 10px;
  }
}

.blocks_button {
  .flex-center(row);
}
.sort_wrap {
  position: relative;
  & + .main_but {
    margin-left: 20px;
  }
}

.newstbl {
  margin-top: 20px;
}
.newstbl_item {
  .flex-center(row, space-between);
  border-top: 1px solid fade(@primary-color, 50%);
  padding: 0 8px;
  margin-left: -19px;
  margin-right: -19px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 16px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    opacity: 0;
    background: @bg-read;
    .anim-fade;
  }
  &.no_read {
    background: @bg-noread;
    &:before {
      opacity: 1;
    }
    & .newstbl_text {
      font-weight: 800;
      color: @font-color-dark;
    }
    & .newstbl_but .newstbl_but_mail img {
      opacity: 0;
      & + img {
        opacity: 1;
      }
    }
    & .newstbl_but_logo .newstbl_but_mail img {
      opacity: 1;
    }
  }
  & > div {
    padding: 11px 10px 12px 10px;
  }
  & .newstbl_text {
    color: @font-gray-color;
    line-height: 20px;
    width: calc(100% - 186px);
    & span {
      color: @font-color-mid;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      opacity: 0.6;
    }
  }
  & .newstbl_date {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    width: 100px;
  }
}
.newstbl_but {
  .flex-center(row, space-between);
  width: 94px;
  & a + a {
    margin-left: 10px;
    height: 32px;
    width: 32px;
    padding: 5px;
    background: #fff;
    border-radius: 8px;
  }
  & a, img {
    display: block;
    color: @font-color-dark;
  }
  & .newstbl_but_mail {
    height: 32px;
    width: 32px;
    padding: 5px;
    background: #fff;
    border-radius: 8px;
    position: relative;
    & img, svg {
      position: absolute;
      top: 5px;
      left: 5px;
      opacity: 1;
      .anim-fade;
    }
    & img + img, svg + svg {
      opacity: 0;
    }
  }
  &.newstbl_but_logo {
    & .newstbl_but_mail {
      padding: 0;
      background: none;
    }
    & img {
      top: 0;
      left: 0;
    }
  }
}
.no_read .newstbl_but .newstbl_but_mail{
  & img {
    opacity: 0;
    & + img {
      opacity: 1;
    }
  }
  & svg {
    opacity: 0;
    & + svg {
      opacity: 1;
    }
  }
}

#news_calendar {
  margin: 0 -8px;
  & .ui-widget.ui-widget-content {
    margin-top: 27px;
    background: transparent;
    padding: 0 4px;
  }
  & .ui-datepicker {
    & .ui-datepicker-title {
      order: 0;
      color: @primary-color-main;
      width: 100%;
      text-align: center;
    }
    & .ui-datepicker-prev, .ui-datepicker-next {
      position: absolute;
      top: -4px;
      left: 5px;
    }
    & .ui-datepicker-next {
      left: auto;
      right: 5px;
    }
    & .ui-datepicker-header {
      margin-bottom: 20px;
      padding: 0 6px;
    }
    & th {
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      padding: 3px 5px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    & td {
      width: 48px;
      height: 48px;
      padding: 2px;
      & span, a {
        font-size: 16px;
      }
    }

    & .ui-state-default:not(.ui-state-active) {
      color: @font-color;
    }
  }
}
.bron_one_calendar {
  width: 340px;
  & #news_calendar {
    & .ui-datepicker td {
      width: 44px;
      height: 44px;
    }
    & .ui-widget.ui-widget-content {
      width: 316px;
      margin-bottom: 16px;
      margin-top: 20px;
    }
  }
}
.news_calendar_two {
  max-width: 754px;
  margin: 0 auto;
}
.news_calendar_head {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 15px 0 15px;
  & span {
    display: block;
    color: @font-color;
    font-size: 18px;
    font-weight: 800;
    line-height: 27px;
  }
  & .btn {
    text-align: center;
    display: block;
  }
}
.news_calendar_period {
  display: flex;
  align-items: center;
  padding: 0 15px 22px;
  & span {
    display: flex;
    align-items: center;
    padding: 5px 16px;
    border-radius: 12px;
    color: @font-gray-color;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 10px;
    .anim-fade;
    & svg {
      display: none;
    }
    &:hover {
      background: @primary-color-main;
      color: #FFFFFF;
    }
    &.active {
      background: @primary-color-main;
      color: #FFFFFF;
      & svg {
        display: block;
        color: #FFFFFF;
        margin-left: 5px;
        margin-right: -4px;
      }
    }
  }
}
.cal6 {
  width: 50%;
  padding: 0 15px;
}
@media (max-width: 1439px) {
  #news_calendar {
    & .ui-widget.ui-widget-content {
      width: 100%;
    }
    .ui-datepicker {
      & td {
        width: 36px;
        height: 36px;
        & span, a {
          font-size: 16px;
        }
      }
    }
  }
  .ui-state-mark:before {
    top: 1px
  }
}
@media (max-width: 991px) {
  .news_cal {
    flex-wrap: wrap;
    & .calendar, .news {
      width: 100%;
    }
    & .news {
      margin-top: 20px;
    }
  }
  .newstbl {
    max-width: none;
  }
  #news_calendar {
    & .ui-datepicker {
      & td {
        width: 48px;
        height: 48px;
        padding: 2px;
        & a {
          width: 44px;
          margin: 0 auto;
        }
      }
    }
  }
  .ui-state-mark:before {
    top: 6px
  }
}
@media (max-width: 767px) {
  .newstbl_item {
    align-items: flex-start;
  }
  #news_calendar .ui-datepicker td a {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .news_calendar_head {
    padding: 0 5px;
  }
  .cal6 {
    width: 100%;
    margin-top: 10px;
  }
}