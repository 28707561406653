@import "partials/params";
@import "partials/mixins";

.sidebar {
  width: @width-side;
  color: @font-color-dark;
  .anim-fade;
  &.active {
    width: @width-side-small;
    & + .wrapper {
      width: calc(100% - 103px);
    }
    & .side_logo {
      flex-wrap: wrap;
      & a {
        width: 50px;
        height: 50px;
        margin: 0 auto;
      }
    }

    & .group_block:first-child {
      padding: 20px 16px 15px;
    }
    & .side_plane {
      margin: 0;
      text-align: center;
    }
    & .plane_name {
      font-weight: 500;
      margin: 13px 0 0;
    }
    & .plane_price {
      font-size: 12px;
    }
    & .side_nav a {
      font-size: 0;
      & .icon24 {
        margin: 0 auto;
      }
    }
  }
  & .group_block {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid rgba(64, 64, 64, 0.10);
  }
  & .group_block:first-child {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 37px;
    padding-bottom: 18px;
  }
  & .side_logo {
    display: flex;
    align-items: center;
    &.side_logo_shab {
      & > a {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        background: @primary-color-bl400;
        color: @font-white-color;
        font-size: 32px;
        line-height: 42px;
        font-weight: 800;
        display: flex;
        .flex-center;
      }
      & .plane_price {
        font-size: 14px;
      }
    }
  }
  & .group_block:last-child {
    border-bottom: 0;
  }
  & .group_block:last-child .side_nav li a {
    opacity: 0.6;
  }
  & .group_block:last-child .side_nav li:hover a {
    opacity: 1;
  }
}
.side_plane {
  font-size: 18px;
  font-weight: 800;
  margin-left: 15px;
  line-height: 24px;
  & .plane_name {
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 2px;
  }
}
.side_nav {
  .clean-list;
  & li {
    padding: 12px 30px 12px 26px;
    border-left: 4px solid transparent;
    .anim-fade;
    & a {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.14px;
      display: flex;
      align-items: center;
      color: @font-color-dark;
      .anim-fade;
    }
    & .icon24 {
      margin-right: 20px;
    }
    &.active, &:hover {
      border-left: 4px solid @color-side;
      background: fade(@color-side-bg, 10%);
      & a {
        color: @color-side;
      }
    }
  }
}

@media (max-width: 1199px) {
  .sidebar {
    left: -278px;
    position: fixed;
    top: 67px;
    width: 278px;
    background: @font-white-color;
    height: calc(100% - 67px);
    z-index: 30;
    overflow-x: hidden;
    overflow-y: auto;
    &.open {
      left: 0;
    }
  }
  .sidebar.active + .wrapper {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sidebar {
    left: -220px;
    width: 220px;
    &.active {
      width: 220px;
      & .side_logo {
        flex-wrap: nowrap;
      }
      & .group_block:first-child {
        padding: 37px 30px 18px 30px;
      }
      & .side_logo a {
        margin: 0;
      }
      & .side_plane {
        margin: 0 0 0 15px;
        text-align: left;
      }
      & .plane_name {
        font-weight: 600;
        margin: 0 0 2px;
      }
      & .plane_price {
        font-size: 18px;
      }
      & .side_nav a {
        font-size: 14px;
        & .icon24 {
          margin: 0 20px 0 0;
        }
      }
    }
  }
  .sidebar {
    position: absolute;
    height: auto;
  }
}