@import "partials/params";
@import "partials/mixins";

.suggestion-block {
  .clean-list;
  padding: 8px;
  /*border-radius: @radius-20;
  border: 1px solid fade(@primary-color-main, 30%);*/
  background: #FFF;
  max-width: 360px;
  max-height: 390px;
  overflow: auto;
  & li {
    padding: 12px 16px 12px 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      border-radius: 14px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), @primary-color-main;
    }
    & .suggestion_ico {
      position: absolute;
      top: 13px;
      left: 16px;
      opacity: 0.4;
    }
    & .suggestion_text {
      overflow: hidden;
      color: @font-gray-color;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-right: 15px;
      width: 220px;
      white-space: nowrap;
    }
    & .suggestion_kod {
      color: fade(@font-gray-color, 40%);
      text-align: right;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
    }
    & button {
      padding: 0;
    }
  }
}
.suggestion-hotels {
  & li {
    padding: 13px 16px;
    flex-wrap: wrap;
    &.suggestion_otel {
      padding: 4px 16px;
    }
    &.suggestion_cat {
      padding-bottom: 4px;
      & .suggestion_text {
        color: @font-color-dark;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    & .suggestion_info {
      .ellipsis;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      color: fade(@font-color-dark, 60%);
      opacity: 0.7;
    }
  }
}
.fake_input + .suggestion-block {
  position: absolute;
  top: 100%;
  left: 0;
}

.fake_select {
  .flex-center(row);
  color: @font-gray-color;
  border: 1px solid transparent;
  & .icon18 {
    margin-left: 10px;
  }
  & + .fake_dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    margin-top: 10px;
    &.fake_dropdown_right {
      left: auto;
      right: 0;
    }
  }
  &-disable {
    background: fade(@font-color, 10%);
    color: fade(@font-color, 60%) !important;
    border-color: fade(@font-color, 10%) !important;
  }
  &:focus, &:active, &.focused {
    outline: none;
    color: @font-gray-color;
    background: fade(@primary-color-main, 30%);
    border: 1px solid @primary-color-main;
  }
  &:hover {
    border: 1px solid fade(@primary-color-main, 40%);
  }
}
.fake_dropdown {
  background: #FFFFFF;
  outline: 0;
  overflow: hidden;
  border-radius: 14px;
  padding: 10px;
  min-width: 100%;
  display: none;
  /*&.active {
    opacity: 1;
    width: 100%;
    transition: all 279ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }*/
  & ul {
    .clean-list;
    width: 100%;
    max-height: 176px;
    min-height: 16px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    & li {
      padding: 12px 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      white-space: nowrap;
      cursor: pointer;
      &:hover, &.active {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), @primary-color-main;
        border-radius: 14px;
      }
    }
    &.suggestion-block {
      max-height: 300px;
      margin-right: -10px;
      padding-right: 10px;
      & li {
        padding: 8px 12px;
        white-space: normal;
        & .suggestion_text {
          width: auto;
        }
        & .input_field {
          border: 0;
          padding: 5px 0;
        }
      }
    }
  }
}
.fake_wrap_subduo {
  text-align: left;
  & > a {
    padding: 12px 20px;
  }
  & .fake_dropdown {
    overflow: visible;
    & ul {
      overflow: visible;
    }
    & .fake_wrap {
      margin: -12px -20px;
      & > a {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), @primary-color-main;
        border-radius: 14px;
        padding: 12px 20px;
        border: 0;
        & svg {
          transform: rotate(-90deg);
        }
      }
    }
    & .fake_select:focus, & .fake_select:active, & .fake_select:hover, & .fake_select.focused {
      border: 0;
      background: none;
    }
    & .fake_select + .fake_dropdown {
      left: 100%;
      top: 0;
      margin: 0 0 0 10px;
    }
  }
}


.select_pass_wrap {
  padding: 8px;
  max-width: 352px;
  .checkbox-container {
    padding: 13px 14px;
  }
  &.select_pass_wrap_top {
    bottom: 100%;
  }
}
.pass_numb {
  display: flex;
  justify-content: space-between;
  padding: 13px 14px;
  & .pass_numb_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ellipsis;
    & span {
      display: block;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      opacity: 0.4;
    }
  }
}
.quantity_inner {
  display: flex;
  align-items: center;
  & input {
    border: 0;
    background: none;
    line-height: 22px;
    font-size: 14px;
    text-align: center;
    color: fade(@font-color-dark, 60%);
  }
  & button {
    border: 0;
    background: fade(@primary-color, 30%);
    border-radius: 6px;
    cursor: pointer;
    .anim-fade;
    .clean;
    &:hover {
      background: fade(@primary-color, 100%);
    }
  }
  &.quantity_inner_ramm {
    border-radius: 14px;
    justify-content: space-between;
    padding: 0 11px;
    border: 1px solid fade(@font-color, 30%);
  }
}
.select_class {
  padding: 12px 14px;
  border-top: 1px solid fade(@primary-color-main, 30%);
  & input {
    display: none;
    &:checked + span {
      box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.30);
      border: 5px solid @bg-radiocheck;
    }
  }
  & label {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    & + label {
      margin-top: 14px;
    }
    & span {
      display: block;
      width: 18px;
      height: 18px;
      border-radius: @radius-30;
      border: 1px solid @bg-bord-light;
      margin-right: 10px;
    }
  }
}

.select_room_wrap.shadow {
  border-radius: 20px;
  max-height: 390px;
  overflow: auto;
}
.select_room {
  border-bottom: 1px solid fade(@blue_500, 30%);
}
.room_child_wrap {
  flex-wrap: wrap;
  padding: 0 14px 13px;
  & .room_child_title {
    display: none;
  }
  & .room_child_cont {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    & > span {
      border-radius: 14px;
      border: 1px solid fade(@font-color, 30%);
      padding: 6px 16px;
      display: flex;
      align-items: center;
      margin: 4px 4px 0 0;
      & svg {
        margin-left: 9px;
      }
    }
  }
  &:not(.active) {
    & .add-child {
      width: 100%;
      text-align: center;
      & svg {
        display: none;
      }
    }
  }
  &.active {
    & .room_child_title {
      display: block;
      width: 100%;
      margin-bottom: 6px;
    }
    & .add-child {
      border-radius: 8px;
      padding: 0;
      height: 32px;
      margin-top: 4px;
      & span {
        display: none;
      }
      & svg {
        display: block;
      }
    }
  }
}

.room_numb {
  padding: 10.5px 14px 0;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  color: @font-color;
  & + .pass_numb {
    padding-top: 0;
    padding-bottom: 10px;
  }
}
.room_footer {
  padding: 13px 14px;
  display: flex;
  & .add-room {
    width: 100%;
    margin-right: 20px;
    text-align: center;
  }
}


.range-wrap {
  width: 100%;
  & .range_selected {
    & .range .range-from-text, .range .range-to-text {
      background: @primary-color-main;
    }
  }
}
.range {
  height: 50px;
  position: relative;
  display: block;
  user-select: none;
  & .range-body {
    position: relative;
    display: block;
    user-select: none;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    & .range-line {
      border-radius: @radius-30 0 0 @radius-30;
      top: 36px;
      height: 4px;
      background-color: @primary-color;
      position: relative;
      display: block;
      overflow: hidden;
      outline: none !important;
    }
    & .range-from-text, .range-to-text {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      cursor: default;
      white-space: nowrap;
      background: @primary-color-gray;
      color: #FFF;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 2px 10px;
    }
    & .range-from-text {
      border-radius: 4px 4px 4px 0;
    }
    & .range-to-text {
      border-radius: 4px 4px 0 4px;
    }
  }

  & .range-bar {
    top: 36px;
    height: 4px;
    background: #97A1FB;
    position: absolute;
    display: block;
    left: 0;
    width: 0;
  }
  & .range-handle {
    top: 26px;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background: @primary-color-bl400;
    position: absolute;
    display: block;
    box-sizing: border-box;
    cursor: default;
    z-index: 1;
    &.type_last {
      z-index: 2;
    }
  }
}

.range_flex_no_dots {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 384px;
  height: 350px;
  padding: 20px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  & .range_scroll {
    height: 310px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 10px;
    margin-right: -10px;
  }
  & .range {
    height: 36px;
  }
  & .range_title {
    font-size: 12px;
    font-weight: 800;
    line-height: 18px;
    color: @font-color-dark;
    margin-bottom: -2px;
    display: block;
  }
  & .range-wrap {
    margin-top: 14px;
  }
  & .range-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  & .range-body {
    & .range-line {
      top: 6px;
    }
    & .range-from-text, .range-to-text {
      top: 17px;
      color: @font-color-dark;
      font-size: 12px;
      font-weight: 800;
      line-height: 18px;
      letter-spacing: 0;
      text-transform: none;
      padding: 0;
      background: none;
    }
  }
  & .range-handle {
    display: none;
  }
  & .range-bar {
    top: 6px;
    background-color: @font-mint-color;
  }

  & .range-wrap-err {
    & .range-bar {
      background-color: @wings_red_400;
    }
    & .range-to-text {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      & i {
        font-style: normal;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: @wings_red_300;
      }
    }
  }
  & .range_stat {
    display: block;
    padding: 2px 6px;
    border-radius: 30px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: 0;
  }
  & .range_stat_err {
    color: @red;
    background: @wings_red_100;
  }
  & .rang_bron_info {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: @red;
  }
  & .btn {
    margin-top: 12px;
  }
  & .range_cont + .range_cont {
    margin-top: 23px;
    padding-top: 23px;
    border-top: 1px solid @bg-main;
  }
}

@media (max-width: 1439px) {
  .select_pass_wrap {
    right: 0;
    left: auto;
  }
}
@media (max-width: 1299px) {
  .select_pass_wrap {
    &.select_pass_wrap_top {
      bottom: auto;
    }
  }
}
@media (max-width: 991px) {
  .fake_select + .fake_dropdown.fake_dropdown_right {
    left: 0;
    right: auto;
  }
}
@media (max-width: 575px) {
  .range_flex_no_dots {
    width: 375px;
  }
  .fake_select + .fake_dropdown {
    margin-top: 6px;
    &.fake_dropdown_right {
      max-width: 228px;
      overflow: visible;
      white-space: normal;
      &.fake_dropdown_right_mob {
        left: auto;
        right: 0;
      }
    }
  }
}