@import "partials/params";
@import "partials/mixins";

.services_wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px 0;
  & .services_item {
    width: 25%;
    padding: 10px;
    & > div {
      border-radius: @radius-20;
      background: @bg-main;
      padding: 30px;
    }
  }
}
.services_head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
  & .services_img {
    width: 36px;
    height: 36px;
    border-radius: 7px;
    background: @primary-color-main;
    .flex-center;
    img {
      display: block;
    }
  }
  & .services_burg {
    display: block;
    width: 24px;
    height: 24px;
    background: url(img/icon-service/services_burg.svg) no-repeat 0 0;
  }
}
.services_footer {
  & h3 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }
  & .services_info {
    color: #C4C4C4;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    & span {
      color: @font-color;
    }
  }
}
@media (max-width: 991px) {
  .services_wrap .services_item {
    width: 33.333%;
  }
}
@media (max-width: 767px) {
  .services_wrap {
    margin: 10px -5px 0;
    & .services_item {
      width: 50%;
      padding: 5px;
      & > div {
        padding: 20px;
        & h3 {
          font-size: 16px;
          margin-bottom: 4px;
        }
      }
    }
  }
}