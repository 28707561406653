@import "partials/params";
@import "partials/mixins";

.extradition_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .extradition_date {
    width: 570px;
    position: relative;
    padding: 0 10px;
    overflow: hidden;
    margin-left: -5px;
    & .extr_date_item {
      padding: 0 5px;
      width: 111px;
      min-width: 111px;
    }
    & .slide_arrow {
      padding: 7px;
    }
    & .icon24 {
      width: 18px;
      height: 18px;
    }
    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 90px;
      height: 100%;
    }
    &:before {
      left: 0;
      background: linear-gradient(90deg, #FFF 0.07%, #FFF 0.08%, rgba(255, 255, 255, 0.00) 60.90%);
    }
    &:after {
      right: 0;
      background: linear-gradient(270deg, #FFF 0.07%, #FFF 0.08%, rgba(255, 255, 255, 0.00) 60.90%);
    }
  }
}
.card_date {
  border-radius: 20px;
  border: 1px solid @primary-color;
  display: inline-flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 82px;
  width: 100%;
  cursor: pointer;
  .anim-fade;
  &:hover {
    border-color: fade(@primary-color-bl400, 40%);
    & .card_price {
      color: @primary-color-bl400;
    }
  }
  &.active {
    background: @bg-main;
    border-color: @bg-main;
    & .card_price {
      color: @font-gray-color;
    }
  }
  & .card_info {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    .ellipsis;
  }
  & .card_price {
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    .ellipsis;
  }
}
.extradition_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .fake_wrap {
    margin: 0 30px;
  }
}
.switch {
  .flex-center(row);
  margin-left: 20px;
  & .btn-icon {
    border-radius: 8px 0 0 8px;
  }
  & .btn-icon + .btn-icon {
    border-radius: 0 8px 8px 0;
  }
}
.extradition_tabs {
  display: inline-flex;
  margin-top: 20px;
  width: 100%;
  & .tabs {
    max-width: calc(100% - 148px);
    overflow: auto;
  }
}
.new_search {
  border-radius: 14px 14px 0 0;
  background: @primary-color-main;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 7px 16px;
  white-space: nowrap;
  & span {
    margin-right: 3px;
  }
  &:hover {
    background: @primary-color-bl400;
  }
  & svg {
    margin-left: 10px;
  }
}
.extr_shap {
  margin-top: 4px;
  border: 2px solid @blue_500;
  border-radius: 14px;
  overflow: hidden;
  height: 60px;
  border-bottom: 20px;
  .anim-fade;
  & .extr_tr_main {
    & > div {
      padding: 6px 4px;
    }
  }
  & .extr_tr_content {
    background: #ffffff;
    & + .extr_tr_content {
      border-top: 1px solid @primary-color;
    }
    & > div {
      & > div + div {
        margin-top: 2px;
      }
    }
  }
  & .extr_tr_main {
    & .extr_toggle svg, .extr_path, .extr_komis, .extr_price {
      opacity: 1;
      .anim-fade;
    }
  }

  &.active {
    height: auto;
    & .extr_toggle svg {
      transform: rotate(180deg);
    }
    & .extr_tr_main {
      & .extr_path, .extr_komis, .extr_price {
        opacity: 0;
      }
    }
  }
  .extr_price {
    text-align: center;
  }
  .extr_komis {
    text-align: right;
    color: @font-mint-color;
    & i {
      font-style: normal;
      color: @font-color;
    }
  }
}
.extr_tr {
  background: @primary-color;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: @font-gray-color;
  &.extr_head {
    border-radius: 0 14px 14px 14px;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    background: @primary-color-gray;
    min-width: 1277px;
    padding: 4px 0;
    & + .extr_buttons {
      position: absolute;
      right: 0;
      top: 8px;
      width: 89px;
      padding-left: 0;
      background: @primary-color-gray;
    }
    &.active {
      border-radius: 0 14px 0 0;
    }
  }
  & > div:first-child {
    padding-left: 12px;
  }
  & > div {
    padding: 8px 4px;
  }
}
.reis_hide {
  display: none;
}
.reis_active {
  & .extr_head:not(.select_reis) {
    border-radius: 0 14px 0 0;
  }
  & .select_reis {
    border-radius: 0 0 14px 14px;
    border-top: 1px solid fade(@font-color, 10%);
    background: fade(@primary-color-bl600, 10%);
    align-items: flex-start;
    padding: 0;
    & + .extr_buttons {
      top: 57px;
      width: 162px !important;
      padding-right: 2px !important;
      background: transparent;
    }
    & > div {
      padding: 4px;
    }
  }
}
.reis_val {
  & span {
    color: @font-color;
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    background: fade(@font-color, 10%);
    padding: 0 4px;
  }
  & i {
    cursor: pointer;
    background: url(./img/icon/ico_close.svg) no-repeat 0 0;
    width: 12px;
    height: 12px;
    display: block;
    margin-left: 2px;
  }
  & + .reis_val {
    margin-top: 2px;
  }
}
.extr_shap .extr_tr_content_osn {
  /*background: @primary-color;*/
  background: @bg-main;
}
.extr_reis {
  width: 7.1%;
}
.extr_from_date, .extr_to_date {
  font-size: 15px;
  font-weight: 800;
  color: @font-color;
  line-height: normal;
  position: relative;
  display: inline-block;
  & sup {
    color: #FF3A3A;
    font-size: 10px;
    position: absolute;
    top: -9px;
    right: -9px;
  }
}
.klas_gruz {
  display: flex;
  & svg {
    margin-left: 8px;
    color: fade(@font-gray-color, 40%);
  }
  & span {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(./img/icon/gruz.svg) no-repeat 0 0;
    position: relative;
    color: #FFF;
    text-align: center;
    font-size: 9px;
    font-weight: 800;
    line-height: 9px;
    text-transform: uppercase;
    margin-left: 8px;
    & i {
      font-style: normal;
      display: flex;
      position: absolute;
      top: 4px;
      right: -11px;
      width: 14px;
      height: 11px;
      align-items: center;
      justify-content: center;
      color: @font-mint-color;
    }
  }
}

.extr_from, .extr_to {
  width: 11.4%;
}
.extr_path {
  width: 7%;
}.extr_transfer {
   width: 7.2%;
 }
.extr_klas {
  width: 8.6%;
  & .gray_but {
    margin-bottom: 8px;
  }
}
.extr_provaid {
  width: 6.3%;
}
.extr_komis {
  width: 7.8%;
}
.extr_price {
  width: 14.5%;
}
.extr_buttons {
  width: 18.7%;
}
.extr_logo {
  width: 29.9%;
  display: flex;
  align-items: center;
  & img {
    display: block;
    margin-right: 10px;
  }
}

.extr_buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 12px !important;
  & a + a {
    margin-left: 10px;
  }
  &.extr_buttons_empty {
    display: none !important;
  }
}
.price_flex {
  display: flex;
  color: fade(@font-color, 60%);
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
  &.price_flex_bold {
    color: @font-color;
    font-size: 15px;
    font-weight: 800;
    line-height: 18px;
    margin-top: 4px;
  }
  & span {
    text-align: right;
    display: block;
    width: calc(100% - 55px);
    padding-right: 5px;
  }
  & i {
    font-style: normal;
    display: block;
    width: 55px;
    text-align: left;
  }
}
.compact_price {
  display: none;
}
.compact_block {
  display: none !important;
}
.extradition_table.tbcomp {
  & .extr_fil {
    display: none !important;
  }
  & .reis_hide {
    display: none !important;
  }
}

.reis_extend {
  display: flex;
  align-items: flex-start;
  & .reis_side {
    width: 0px;
    overflow: hidden;
    .anim-fade;
  }
  & .reis_items {
    width: 100%;
    .anim-fade;
  }
  &.compact {
    & .extr_shap {
      border-radius: @radius-20;
      height: auto;
      & + .extr_shap {
        margin-top: 10px;
      }
    }
    & .reis_side {
      display: block;
      width: 304px;
      padding: 18px 15px;
      border-radius: @radius-20;
      border: 2px solid @primary-color;
      background: #FFF;
      margin-top: 4px;
      .anim-fade;
    }
    & .reis_items {
      width: calc(100% - 324px);
      margin-left: 20px;
      & .extr_tr {
        flex-wrap: wrap;
      }
      & .extr_tr_content_osn {
        align-items: normal;
      }
    }
    & .extr_tr_main {
      display: none;
    }
    & .extr_tr_content:not(.extr_tr_content_osn) {
      display: none;
    }
    & .compact_block {
      display: flex !important;
    }
    & .extend_block {
      display: none;
    }
    & .compact_info {
      width: 68.2%;
      padding: 18px 20px;
      flex-wrap: wrap;
      height: auto;
    }
    & .compact_logo {
      width: 100%;
      display: flex;
      align-items: center;
    }
    & .compact_tablo {
      display: flex;
      flex-wrap: wrap;
      padding: 12px 0 20px;
      width: 100%;
      margin: 0;
      & .tablo_dop_info {
        width: 100%;
        height: 18px;
        text-align: center;
        position: absolute;
        top: -20px;
        left: 0;
        color: fade(@font-gray-color, 80%);
      }
      .tablo_center {
        display: flex;
        width: 100%;
        position: relative;
        margin-top: 18px;
      }
      & .date_comp {
        display: flex;
        width: 120px;
        position: relative;
        & .extr_from_date {
          display: flex;
          flex-wrap: wrap;
          font-size: 13px;
          font-weight: 600;
          line-height: 18px;
          color: @font-gray-color;
          & span {
            order: -1;
            width: 80%;
            font-size: 22px;
            font-weight: 800;
            line-height: 28px;
            color: @font-color;
          }
        }
        & .extr_from_port {
          position: absolute;
          top: 3px;
          right: 2px;
          font-size: 16px;
          font-weight: 800;
          line-height: 24px;
        }
        &.date_last {
          order: 1;
          justify-content: flex-end;
          & .extr_from_date {
            text-align: right;
            justify-content: flex-end;
          }
          & .extr_from_port {
            left: 5px;
            right: auto;
          }
        }
      }
      & .date_graf {
        width: calc(100% - 240px);
        & .graf_line {
          height: 2px;
          background: @primary-color-gray;
          margin: 13px 6px;
          position: relative;
          & .graf_circle {
            position: absolute;
            top: -3px;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: #ffffff;
            border: 2px solid @primary-color-gray;
            &_first {
              left: 0;
            }
            &_last {
              right: 0;
            }
            &_dop {
              left: 50%;
              margin-left: -4px;
              & span {
                position: absolute;
                bottom: 5px;
                left: -13px;
                font-size: 16px;
                font-weight: 800;
                line-height: 24px;
              }
            }
          }
        }
        & .tablo_path {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: fade(@font-gray-color, 80%);
          & > div {
            width: 50%;
            display: flex;
            justify-content: center;
            & .info_which_reis + div {
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }
          & .path_val {
            margin-left: 4px;
            color: #23232B;
          }
        }
      }
      &.peresadka {
        padding-top: 15px;
        & .tablo_dop_info {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          & .extr_peresad {
            margin: 0 7px;
          }
        }
      }
    }
    & .tablo_but {
      margin-top: 0;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      & .btn {
        color: @primary-color-main;
      }
    }
    & .extr_buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      padding: 18px 12px 18px 20px !important;
      width: 31.2%;
      position: relative;
      border-left: 1px solid fade(@primary-color-main, 30%);
      & a + a {
        margin-left: 10px;
      }
      & .compact_price {
        display: block;
        padding: 45px 0 38px;
        width: 100%;
        text-align: right;
        position: absolute;
        right: 12px;
        bottom: 50px;
        & .price_flex_bold {
          margin-top: 2px;
        }
        & .gray_but {
          font-size: 13px;
          font-weight: 600;
          line-height: 12px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
        & .price_flex {
          justify-content: flex-end;
          & span {
            width: auto;
            font-size: 26px;
            font-weight: 800;
            line-height: 28px;
          }
          & i {
            width: auto;
            font-size: 22px;
            font-weight: 800;
            line-height: 28px;
          }
        }
      }
      & .extr_fix {
        position: absolute;
        top: 18px;
        right: 12px;
      }
      & .extr_bron, .extr_tarif {
        width: calc(50% - 10px);
        text-align: center;
      }
      & .extr_tarif {
        background: #ffffff;
        color: @primary-color-bl400;
        &:hover {
          background: @primary-color-bl400;
          color: #fff;
        }
      }
    }
    & .logo_images {
      display: flex;
      align-items: center;
      & img {
        width: 30px;
        display: block;
        & + img {
          margin-left: -5px;
        }
      }
    }
    & .logo_company {
      margin: 0 0 0 8px;
      color: fade(@font-gray-color, 80%);
    }
    .reis_icon {
      display: flex;
      align-items: flex-start;
      color: @primary-color-gray;
      margin: 0 0 0 auto;
      & div + div {
        margin-left: 12px;
      }
      .active {
        color: @font-mint-color;
      }
      & .ico_val {
        position: relative;
        & i {
          font-style: normal;
          position: absolute;
          top: 0;
          left: 0;
          width: 22px;
          height: 22px;
          color: #fff;
          font-size: 8px;
          .flex-center;
        }
        &.ico_gruz {
          margin-right: 2px;
          & svg {
            padding-right: 4px;
          }
          & i {
            width: 18px;
          }
          & span {
            position: absolute;
            right: -3px;
            top: 2px;
            font-size: 8px;
          }
        }
      }
    }
  }

}
.logo_detail {
  display: none;
}
.tablo_mob {
  display: none;
}
.date_dop {
  display: none !important;
}
.info_which_reis {
  display: none;
}

.modal_side.modal_side_pinned {
  & .modal_head {
    margin: 11px 0 23px 0;
    & .checkbox-container {
      margin-left: 30px;
    }
    & .applay_but {
      margin: 0 60px 0 auto;
    }
    & .modal_close {
      top: 5px;
    }
  }
}
.pinned_flex {
  margin-left: -40px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 352px;
  padding-right: 10px;
  & .pinned + .pinned {
    margin-top: 4px;
  }
}
.pinned {
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .pinned_wrap {
      border: 1px solid @primary-color-main;
      outline: 1px solid @primary-color;
      & .pinned_check {
        background: @primary-color-main;
        & .checkmark {
          background: url(./img/icon/ico_check3.svg) no-repeat center center;
          border: 0;
        }
      }
    }
  }
}
.pinned_wrap {
  border-radius: @radius-20;
  border: 1px solid @primary-color;
  outline: 1px solid @primary-color;
  display: flex;
  overflow: hidden;

  & .pinned_check {
    width: 38px;
    border-right: 1px solid @primary-color;
    padding-right: 5px;
    .anim-fade;
    .flex-center;
    & .checkmark {
      margin-right: 0;
    }
  }
  & .extr_tr {
    background: #ffffff;
    padding: 0 0 0 8px;
  }
  &:hover {
    & .pinned_check {
      background: @bg-main;
    }
  }
  & .pinned_line {
    width: calc(67.6% - 38px);
  }
  & .extr_buttons {
    width: 32.4%;
    align-items: center;
    border-left: 1px solid @primary-color;
    padding-right: 8px !important;
  }
  & .extr_tr {
    & > div {
      padding: 10px 4px;
    }
    & + .extr_tr {
      border-top: 1px solid @primary-color;
    }
  }
  .extr_reis {
    width: 9.8%;
  }
  .extr_from, .extr_to {
    width: 17.2%;
    & span {
      margin-left: 3px;
    }
  }
  .extr_path {
    width: 10.7%;
  }
  .extr_transfer {
    width: 10.9%;
  }
  .extr_klas {
    width: 13%;
  }
  .extr_provaid {
    width: 9.3%;
  }
  .extr_komis {
    width: 11.9%;
    text-align: right;
    & i {
      font-style: normal;
    }
  }
  & .pinned_price {
    margin-right: 22px;
  }
  & .extr_fix.activ .icon24 {
    padding: 3px;
  }
}

@media (min-width: 1300px) {
  .reis_extend.compact .extr_buttons {
    min-height: 200px;
  }
}

@media (max-width: 1439px) {
  .overflow {
    border-radius: 0 0 14px 14px;
  }
  .extradition_head {
    flex-wrap: wrap;
    & .extradition_date {
      width: 100%;
    }
    & .extradition_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 30px;
      & .fake_wrap {
        order: -1;
        margin-left: 0;
      }
    }
  }
  .extradition_tabs {
    margin-top: 30px;
    & .tabs {
      max-width: calc(100% - 103px);
    }
    & .new_search {
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -29px;
        top: 0;
        width: 29px;
        height: 32px;
        background: linear-gradient(270deg, #FFF 0.07%, #FFF 0.08%, rgba(255, 255, 255, 0.00) 100%);
      }
    }
  }

  .extr_shap {
    height: 64px;
    & .extr_tr_main {
      padding: 2px 0;
    }
    & .extr_tr_content {
      padding: 8px 0;
      & > div {
        padding-top: 8px;
        padding-bottom: 8px;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  .extr_tr_content {
    flex-wrap: wrap;
    & .extr_reis, .extr_klas {
      width: 17.1%;
    }
    & .extr_klas {
      padding-left: 10px;
    }
    & .extr_from, .extr_to, .extr_provaid {
      width: 24.1%;
    }
    & .extr_transfer {
      width: 17.3%;
    }
    & .extr_path {
      width: 16.9%;
    }
    & .extr_transfer {
      padding-right: 10px;
    }
    & .extr_komis {
      width: 33.9%;
      text-align: right;
    }
    & .extr_price {
      width: 24.9%;
      padding: 7px 10px 7px 4px !important;
      & .price_flex {
        justify-content: flex-end;
        & i, span {
          width: auto;
        }
      }
    }
    & .extr_buttons {
      width: 100%;
      justify-content: space-between !important;
      align-items: center;
      padding: 8px 6px !important;
      flex-direction: row !important;
      height: auto !important;
      & a:not(.extr_fix) {
        width: calc(50% - 32px);
        max-width: 322px;
        text-align: center;
      }
      & a.extr_fix {
        margin: 0 0 0 auto;
      }
      & a + a {
        margin-left: 24px;
      }
    }
  }
  .extr_tr {
    font-size: 12px;
    & > div:first-child {
      padding-left: 10px;
    }
  }

  .extr_tr_main {
    & .extr_logo {
      width: 16.6%;
      font-size: 10px;
      letter-spacing: 1px;
      & img {
        width: 30px;
        padding: 8px 0;
        display: block;
        margin-right: 4px;
      }
    }
    & .extr_path {
      width: 22.7%;
    }
    & .extr_transfer, .extr_klas, .extr_provaid {
      padding: 0 !important;
      width: 0;
    }
    & .extr_komis {
      width: 24.3%;
    }
    & .extr_price {
      width: 22.7%;
    }
    & .extr_buttons {
      width: 13.7%;
      padding-right: 10px !important;
    }
  }
  .price_flex.price_flex_bold {
    font-size: 14px;
    margin-bottom: 2px;
    margin-top: 2px;
  }
  .new_search {
    width: 103px;
    text-transform: capitalize;
    & span {
      display: none;
    }
  }

  .pinned_wrap {
    & .pinned_line {
      width: calc(68.6% - 38px);
      padding: 1px 0;
    }
    & .extr_buttons {
      width: 31.4%;
    }
    & .extr_tr {
      font-size: 13px;
      padding: 0 0 0 4px;
    }
    & .extr_reis {
      width: 9.9%;
    }
    & .extr_from, .extr_to {
      width: 16.7%;
    }
    & .extr_path {
      width: 11.7%;
    }
    & .extr_transfer {
      width: 11.5%;
    }
    & .extr_klas {
      width: 14.1%;
    }
    & .extr_provaid {
      width: 12.1%;
    }
    & .extr_komis {
      width: 7.3%;
      text-align: left;
    }
    & .hide_pk {
      display: none;
    }
    & .pinned_price {
      margin-right: 8px;
      & .price_flex_bold {
        font-size: 15px;
      }
    }
  }
}
@media (max-width: 1299px) {
  .logo_detail {
    display: block;
  }
  .reis_extend.compact {
    & .reis_side {
      display: none;
    }
    & .reis_items {
      width: 100%;
      margin-left: 0px;
    }
    & .extr_shap + .extr_shap {
      margin-top: 4px;
    }
    & .compact_info {
      width: 100%;
      padding: 10px 18px;
      flex-direction: row;
      position: relative;
    }
    & .compact_tablo {
      padding: 12px 0 11px;
    }
    & .compact_logo {
      flex-wrap: wrap;
    }
    & .logo_company {
      margin: 0 0 0 10px;
      font-size: 13px;
    }
    & .tablo_but {
      & .btn {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        top: 12px;
        background: transparent;
      }
      & .btn-alert {
        display: none;
      }
    }

    & .extr_buttons {
      width: 100%;
      border-top: 1px solid rgba(100, 94, 252, 0.3);
      border-left: 0;
      padding-left: 0 !important;
      padding-bottom: 8px !important;
      padding-right: 18px !important;
      align-items: flex-end;
      & .compact_price {
        padding: 0 10px 0 0;
        width: 25%;
        position: relative;
        right: auto;
        bottom: auto;
      }
      & .extr_bron, .extr_tarif {
        width: calc(37.5% - 46px);
        text-align: center;
      }
      & .extr_fix {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }

  .modal_side.modal_side_pinned {
    border-radius: 0;
    & .modal_head {
      margin: -1px 0 15px 0;
      justify-content: space-between;
      & .modal_close {
        position: relative;
        top: 0;
        right: 0px;
      }
    }
    & .modal_side_wrap {
      padding: 0 4px 0 14px;
    }
  }
  .modal_side_pinned {
    & .modal_head {
      & .checkbox-container {
        margin-left: 0 !important;
        order: -1;
      }
      & .modal_head_rzn {
        & .checkbox-container {
          margin-left: 20px !important;
        }
      }
    }
  }

  .pinned_flex {
    max-height: calc(100vh - 83px);
    margin-left: 0;
    & .pinned + .pinned {
      margin-top: 10px;
    }
  }
  .pinned_wrap {
    flex-wrap: wrap;
    position: relative;
    & .pinned_check {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
    & .pinned_line {
      width: calc(100% - 40px);
      margin-left: 40px;
    }
    & .extr_tr {
      font-size: 12px;
      padding: 5px 0 6px;
      & + .extr_tr {
        padding-top: 9px;
      }
      & > div > div + div {
        margin-top: 2px;
      }
    }
    & .extr_reis {
      width: 18.2%;
    }
    & .extr_from, .extr_to {
      width: 25.3%;
    }
    & .extr_path {
      width: 15.1%;
    }
    & .extr_transfer {
      width: 16.1%;
    }
    .extr_klas {
      width: 18.2%;
      padding-left: 11px !important;
      & .gray_but {
        margin-bottom: 5px;
      }
    }
    & .extr_provaid {
      width: 48.7%;
    }
    & .extr_komis {
      width: 12.4%;
      text-align: right;
    }
    & .extr_price {
      width: 19.8%;
    }
    & .hide_pk {
      display: block;
    }
    & .modal_link {
      font-size: 12px;
    }
    & .extr_buttons {
      width: calc(100% - 40px);
      align-items: center;
      border-left: 0;
      padding-left: 8px;
      padding-bottom: 13px;
      margin-left: 40px;
    }
    & .pinned_price {
      display: none;
    }
    & a + a {
      margin-left: 24px;
    }
    & .extr_bron, .extr_tarif {
      width: calc(50% - 40px);
      text-align: center;
    }

  }
}
@media (max-width: 767px) {
  .new_search {
    width: 50px;
    font-size: 0;
    & svg {
      margin-left: 0;
    }
  }
  .extradition_tabs .tabs {
    max-width: calc(100% - 50px);
  }

  .extradition_head .extradition_right .fake_wrap {
    margin-right: 15px;
  }
  .extr_shap {
    & .extr_tr_content {
      & .extr_reis {
        width: 18.5%;
      }
      & .extr_from, .extr_to {
        width: 25.2%;
      }
      & .extr_path {
        width: 14.8%;
      }
      & .extr_transfer {
        width: 16.3%;
      }
      & .extr_klas {
        width: 18.3%;
        & .gray_but {
          margin-bottom: 2px;
        }
      }
      & .extr_provaid {
        width: 15%;
      }
      & .extr_komis {
        width: 31.7%;
      }
      & .extr_price {
        width: 35%;
        padding-right: 14px !important;
        & .price_flex.price_flex_bold {
          margin-bottom: -2px;
          margin-top: 6px;
        }
      }
    }
  }
  .extr_tr_main {
    & .extr_logo {
      flex-wrap: wrap;
      width: 19.2%;
      & img {
        padding: 0;
        margin-right: 0;
      }
    }
    & .extr_path {
      width: 16.5%;
    }
    & .extr_komis {
      width: 20.8%;
    }
    & .extr_price {
      width: 25.7%;
      text-align: right;
      & .price_flex {
        justify-content: flex-end;
        & span, i {
          width: auto;
        }
        &.price_flex_bold {
          margin-bottom: 4px;
          margin-top: 1px;
        }
      }
    }
    & .extr_buttons {
      width: 20.8%;
      padding-right: 8px !important;
    }
  }

  .reis_extend.compact {
    & .compact_info {
      width: 100%;
      padding: 10px 12px;
    }
    & .compact_tablo {
      padding: 20px 0 5px;
      & .date_comp {
        & .extr_from_date {
          & span {
            font-size: 18px;
            line-height: 24px;
          }
        }
        & .extr_from_port {
          top: 1px;
        }
      }
      & .tablo_center {
        margin-top: 19px;
      }
      & .date_graf .graf_line {
        margin: 11px 6px;
      }
      &.peresadka {
        padding-top: 25px;
      }
    }

    & .extr_buttons {
      padding-right: 11px !important;
      justify-content: center !important;
      padding-top: 12px !important;
      padding-bottom: 11px !important;
      & .compact_price {
        margin: 0 auto 0 0;
        padding: 0 0 0 13px;
        width: auto;
        text-align: left;
        & .price_flex {
          justify-content: flex-start;
          margin: 0;
        }
      }
      & .extr_bron, .extr_tarif {
        width: 124px;
      }
      & .extr_fix {
        margin: 0 0 0 auto;
      }
    }
  }
  .extr_from_date sup, .extr_to_date sup {
    font-size: 9px;
    position: relative;
    top: 0;
    right: auto;
  }
  .modal_side.modal_side_pinned {
    & .modal_side_wrap {
      padding: 0 10px 0 20px;
    }
    & .modal_head {
      margin: -1px 0 20px 0;
      & .checkbox-container {
        margin-left: 0 !important;
        order: 1;
        margin-top: 15px;
      }
      & .applay_but {
        margin: 15px 0 0 auto;
        width: auto;
        order: 2;
      }
    }
  }
  .pinned_flex {
    max-height: calc(100vh - 125px);
    & .pinned + .pinned {
      margin-top: 20px;
    }
  }
  .pinned_wrap {
    outline: none !important;
    & .pinned_check {
      bottom: auto;
      padding-right: 0;
      width: 100%;
      height: 40px;
      position: relative;
      border-bottom: 1px solid #E6E6F9;
      border-right: 0;
    }
    & .pinned_line {
      width: 100%;
      margin-left: 0;
    }
    & .extr_from_date, .extr_to_date {
      margin-top: 0 !important;
      font-size: 14px;
    }
    & .extr_tr {
      padding: 6px 8px 6px;
      & > div {
        padding: 8px 4px;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    & .extr_reis {
      width: 26.4%;
      padding-left: 4px !important;
    }
    & .extr_from, .extr_to {
      width: 36.5%;
    }
    & .extr_to {
      padding-right: 0 !important;
    }
    & .extr_path {
      width: 26.1%;
    }
    & .extr_transfer {
      width: 37%;
    }
    & .extr_klas {
      width: 36.9%;
      padding-left: 4px !important;
      & .gray_but {
        margin-bottom: 1px;
      }
    }
    & .extr_provaid {
      width: 30%;
    }
    & .extr_komis {
      width: 26.5%;
    }
    & .extr_price {
      width: 43.5%;
      padding-right: 8px !important;
    }
    & .extr_buttons {
      width: 100%;
      padding-bottom: 16px;
      margin-left: 0;
      & a + a {
        margin-left: 8px;
      }
      & .extr_bron, .extr_tarif {
        width: calc(50% - 24px);
      }
    }
  }
}

@media (max-width: 575px) {
  .extradition_head {
    & .extradition_right {
      flex-wrap: wrap;
      & .fake_wrap {
        margin-right: 6px;
      }
      & > div + div {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  .extradition_tabs {
    margin-top: 20px;
  }
  .extradition_table {
    & .overflow {
      overflow: hidden;
      & .extr_head > div {
        width: 120px;
      }
    }
  }
  .extr_shap {
    height: 112px;
    & .extr_tr_main {
      padding: 4px 0;
      flex-wrap: wrap;
      & > div {
        padding: 4px 0;
        height: 52px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      & .extr_logo {
        width: 41.9%;
        flex-direction: row;
        justify-content: flex-start;
        & img {
          margin-right: 4px;
        }
      }
      & .extr_path {
        width: 27.5%;
      }
      & .extr_komis {
        width: 30.6%;
        padding-right: 11px;
      }
      & .extr_price {
        width: 71.9%;
        text-align: left;
        padding-left: 11px;
        & .price_flex {
          justify-content: flex-start;
        }
      }

      & .extr_buttons {
        width: 28.1%;
        justify-content: flex-end !important;
        align-items: center;
        flex-direction: row !important;
        height: auto !important;
      }
    }
    & .extr_tr_content {
      & .extr_reis, .extr_path, .extr_provaid {
        width: 26.8%;
        padding-left: 11px;
      }
      & .extr_from, .extr_transfer {
        width: 35.9%;
      }
      & .extr_to, .extr_klas {
        width: 36.9%;
        padding-left: 4px;
      }
      & .extr_komis {
        width: 27.8%;
      }
      .extr_price {
        width: 45.4%;
      }
      & .extr_buttons {
        & a:not(.extr_fix) {
          width: calc(50% - 24px);
        }
        & a + a {
          margin-left: 8px;
        }
      }
    }
    &.active {
      & .extr_tr_main {
        & .extr_path, .extr_komis, .extr_price {
          opacity: 1;
        }
      }
    }
  }
  .extr_tr {
    & > div:first-child {
      padding-left: 11px;
    }
    &.extr_head {
      padding: 4px 0 6px;
    }
  }

  .reis_extend.compact {
    & .reis_icon {
      margin: 18px 0 0;
      width: 100%;
    }
    & .tablo_but .btn {
      left: auto;
      transform: none;
      top: 12px;
      right: 3px;
      font-size: 13px;
    }
    & .compact_tablo {
      padding: 0 0 5px;
      & .tablo_center {
        margin-top: 16px;
        flex-wrap: wrap;
      }
      & .date_comp {
        width: 22%;
        flex-wrap: wrap;
        & .extr_from_date {
          font-size: 0;
          width: 100%;
          & span {
            font-size: 16px;
            width: 100%;
          }
        }
        &.date_last {
          order: 0;
          justify-content: flex-start;
          margin-right: 40%;
          & .extr_from_date {
            text-align: left;
            justify-content: flex-start;
          }
          & .extr_from_port {
            left: auto;
          }
          &:before {
            content: "–";
            display: block;
            position: absolute;
            top: 0px;
            left: -14px;
            color: @font-color;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 24px;
          }
        }
        & .extr_from_port {
          top: 0;
          position: relative;
          left: 0;
          right: auto;
          font-size: 13px;
          line-height: 18px;
        }
        &.date_dop {
          display: flex !important;
          margin-top: 10px;
        }
      }
      & .date_graf {
        width: 56%;
        padding-left: 9.5%;
        position: absolute;
        right: 0;
        top: 0;
        & .graf_line {
          display: none;
        }
        & .tablo_dop_info {
          width: 100%;
          height: auto;
          text-align: left;
          position: relative;
          top: 0;
          left: 0;
        }
        & .tablo_path {
          justify-content: flex-start;
          flex-wrap: wrap;
          & .info_which_reis {
            display: flex;
            align-items: center;
            & .icon24 {
              width: 18px;
              margin-right: 4px;
            }
            & + div {
              width: 100%;
              display: flex;
              justify-content: flex-start !important;
            }
          }
          & > div {
            width: 100%;
            justify-content: flex-start;
            flex-wrap: wrap;
            height: 40px;
          }
          & > div + div {
            margin-top: 10px;
          }
        }
        & .tablo_dop_info {
          display: none;
        }
      }
      &.peresadka {
        padding-top: 0;
      }
    }
    & .extr_buttons {
      justify-content: space-between !important;
      padding-left: 13px !important;
      & .compact_price {
        margin: 0 0 9px;
        padding-left: 0;
        width: 100%;
      }
      & a:not(.extr_fix) {
        width: calc(50% - 5px);
      }
      & .extr_fix {
        margin: 0;
        position: absolute;
        top: 18px;
        right: 11px;
      }
    }
  }
}
