@import "partials/params";
@import "partials/mixins";

.ui-widget.ui-widget-content {
  font-family: 'Manrope', sans-serif;
  border: none;
  width: 344px;
  margin: 20px auto 0;
  &.ui-datepicker-multi-2 {
    padding: 0 20px 20px;
    max-width: 754px;
    width: auto !important;
    background: transparent;
    margin-top: 15px;
    margin-bottom: 30px;
    & .ui-datepicker-title {
      text-align: center;
      width: 100%;
      color: @primary-color-main;
      margin-bottom: 18px;
    }
    & td span, td a {
      font-size: 16px;
    }
    & th {
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 2px 5px;
    }
    & .ui-datepicker-group table {
      width: 100%;
      margin: 0 0 0;
    }
    & .ui-datepicker-group-first {
      padding: 0 15px 0 10px;
    }
    & .ui-datepicker-group-last {
      padding: 0 10px 0 15px;
    }
    & .ui-datepicker-prev, .ui-datepicker-next {
      position: absolute;
      top: 120px;
    }
    & .ui-datepicker-prev {
      left: -40px;
    }
    & .ui-datepicker-next {
      right: -40px;
      left: auto;
    }
    & td {
      padding: 2px;
    }
    & td span, td a {
      min-width: 44px;
      height: 44px;
    }
  }
}
.ui-datepicker  {
  padding: 0;
  & table {
    font-size: 16px;
    margin: 0;
  }
  & .ui-datepicker-header {
    display: flex;
    align-items: center;
    padding: 0 10px;
    background: none;
    border: none;
  }
  & .ui-datepicker-prev, .ui-datepicker-next {
    display: block;
    position: relative;
    top: 0;
    width: 32px;
    height: 32px;
    left: 0;
    right: 0;
    padding: 8px;
    border-radius: 8px;
    background: @bg-main;
    transform: rotate(-90deg);
  }
  & .ui-datepicker-next {
    transform: rotate(90deg);
    margin-left: 12px;
  }
  & span.ui-icon {
    background: url(./img/icon/chevron-up.svg) no-repeat 0 0 / 100% auto;

  }
  & .ui-datepicker-title {
    margin: 0;
    text-align: left;
    order: -1;
    color: @font-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    width: calc(100% - 76px);
  }
  & th {
    text-align: center;
    color: @font-gray-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    padding: 11px 5px;
  }
  & td {
    width: 50px;
    height: 44px;
    padding: 0;
    & span, a {
      .flex-center(row);
      height: 100%;
      padding: 0;
      text-align: center;
      text-decoration: none;
      color: @font-color;
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      border-radius: 8px;
      &:hover {
        background: @primary-color;
        color: @font-color;
      }
    }
  }
}
.ui-state-default, .ui-widget-content .ui-state-default {
  border: none;
  background: none;
  font-weight: 500;
  color: @font-color;
}
.ui-state-highlight, .ui-widget-content .ui-state-highlight {
  background: @primary-color;
}
.ui-state-active, .ui-widget-content .ui-state-active {
  background: @primary-color-main;
  color: #FFFFFF;
}
.ui-state-sel, .ui-button.ui-state-sel:hover {
  background: fade(@primary-color-main, 30%) !important;
  color: @font-color;
}
.ui-state-mark {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 6px;
    width: 6px;
    height: 6px;
    background: @bg-calen-date;
    border-radius: 3px;
  }
}

@media (max-width: 767px) {
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 {
      padding: 0 10px 20px;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 {
    & td {
      width: 34px;
      height: 34px;
      & span, a {
        min-width: 30px;
        height: 30px;
        font-size: 14px;
      }
    }
    & .ui-datepicker-group-first, .ui-datepicker-group-last {
      padding: 0 10px;
    }
    & .ui-datepicker-prev, .ui-datepicker-next {
      top: 95px;
    }
    & .ui-datepicker-prev {
      left: -30px;
    }
    & .ui-datepicker-next {
      right: -30px;
    }
  }
}
@media (max-width: 575px) {
  .ui-datepicker-multi-2 .ui-datepicker-group {
    width: 100%;
    margin-top: 10px;
  }
  .ui-widget.ui-widget-content.ui-datepicker-multi-2 {
    max-width: 360px;
    & td {
      width: 46px;
      height: 44px;
      padding: 0;
      & span, a {
        min-width: 46px;
        height: 44px;
        font-size: 16px;
      }
    }
    & .ui-datepicker-prev {
      top: 255px;
    }
    & .ui-datepicker-next {
      top: -33px;
    }
  }
}


.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

.dp__menu {
  font-family: 'Manrope', sans-serif !important;
  padding-bottom: 10px;
  border: none !important;
}
.dp__cell_inner {
  border: 2px solid #fff !important;
  border-radius: 8px !important;
  font-size: 14px !important;
}
.dp__range_end, .dp__range_start, .dp__active_date {
  background-color: @primary-color-main !important;
  border-color: transparent;
  color: #fff;
}
.dp__range_between {
  background: fade(@primary-color-main, 30%) !important;
  color: @font-color;
}
.dp__calendar_header_item {
  font-size: 12px;
  font-weight: 500;
  height: 30px;
}