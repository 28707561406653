@import "partials/params";
@import "partials/mixins";


.regdetail_wrap {
  display: flex;
  margin: 0 -10px;
  & > div {
    padding: 0 10px;
    & > div {
      border-radius: @radius-20;
      background: @primary-color;
      padding: 30px 20px;
      height: 100%;
    }
  }
  & .regdetail {
    & .reg_title {
      color: #404040;
      font-size: 22px;
      font-weight: 800;
      line-height: 28px;
    }
    & .detail_wrap {
      display: flex;
      padding: 0;
      max-height: none;
      align-items: flex-start;
      margin-top: 20px;
      & .detail_item {
        & .detail_airport {
          flex-wrap: wrap;
          & .detail_airport_val {
            width: 100%;
            margin-bottom: 5px;
          }
        }
      }
    }
    & .detail_item {
      width: 39.3%;
      max-width: 500px;
      & .detail_line {
        & .detail_path {
          margin-top: 7px;
        }
      }
      &:first-child {
        margin: 0 auto 0 0;
      }
    }
    & .detail_peresad {
      width: 21.4%;
      max-width: 172px;
      padding: 0 20px;
      margin: 0;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      & div {
        width: 100%;
        margin-top: 6px;
        text-align: center;
      }
      & span {
        margin: 0;
      }
      & svg {
        margin-right: 4px;
      }
    }
  }
  & .regbonus {
    .ellipsis;
    & > b {
      display: block;
      margin-bottom: 6px;
    }
  }
}

.user_group {
  margin-top: 20px;
  padding: 28px 20px;
  border-radius: @radius-20;
  border: 2px solid @primary-color;
  & .form_group {
    & .form_label {
      .ellipsis;
      font-weight: 800;
      line-height: 20px;
      margin-bottom: 6px;
      display: block;
    }
  }
  & .sub_group {
    display: flex;
    margin-top: 10px;
    & > * {
      width: 50%;
    }
    & .take_num {
      width: 100%;
    }
  }
  & .fake_select {
    color: @font-gray-color;
    padding: 14px 12px;
    justify-content: space-between;
    border-radius: @radius-btn;
    border: 1px solid fade(@font-color, 30%);
  }
  &.user_group_vidl {
    border-color: @bg-ramm-pass;
  }
  &.user_group_nopad {
    padding: 0;
    border-radius: 0;
    border: none;
  }
  & .form_group {
    & .input_value {
      display: none;
    }
    &.sel_value {
      & .input_value {
        display: block;
        line-height: 20px;
        font-size: 14px;
        font-weight: 600;
      }
      & input {
        display: none;
      }
    }
  }
  &.user_group_alert {
    border: 2px solid @Peach_400;
    background: #FFF2EB;
    & .user_group_head {
      margin-bottom: 0;
      & > .btn {
        margin: 0 0 0 20px;
        width: 304px;
        text-align: center;
        max-width: 100%;
      }
    }
    & h5 {
      width: 66.6666%;
      & div {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        margin-top: 14px;
      }
    }
  }
}
.user_group_scroll {
  max-height: 226px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 8px !important;
  margin-right: -8px;
  & .form_group + .form_group {
    margin-top: 12px;
  }
}
.user_group_head {
  .flex-center(row, flex-start);
  margin-bottom: 14px;
  & h5 {
    color: @onix_600;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    padding: 4px 0;
    width: 33.3333%;
  }
  &.user_group_head_single {
    & h5 {
      padding: 0;
      width: 100%;
    }
  }
  & .alert {
    width: 33.3333%;
  }
  & .form_group_center {
    width: 33.3333%;
    justify-content: flex-end;
    & .btn {
      border-radius: 14px;
    }
  }
  & > .btn {
    margin: 0 0 0 auto;
    border-radius: 20px;
  }
  & .sort_wrap {
    margin: 0 0 0 auto;
    & .fake_select {
      border: 0;
      padding: 5px 0;
    }
  }
}
.user_group_withhead {
  & .user_group_head {
    margin: -28px -20px 28px;
    border-radius: 18px 18px 0 0;
    padding: 18px 20px;
    & h5 {
      color: @font-color;
      font-size: 12px;
      line-height: 24px;
      font-weight: 600;
      text-transform: uppercase;
      width: auto;
    }
  }
  &.user_group_noline {
    & .user_group_head {
      margin: 0;
      padding: 18px 16px;
    }
    & .grid_table_col4 {
      margin: 0;
    }
  }
}
.form_group_center {
  & .btn {
    border-radius: 20px;
  }
}
.group_input {
  display: flex;
  margin: 0 -3px;
  &-duo > div {
    width: 116px;
    padding: 0 3px;
    & + div {
      width: calc(100% - 116px);
    }
  }
  &-trio > div {
    width: 33.3333%;
    padding: 0 3px;
  }
}
.dupl_group {
  display: flex;
  align-items: center;
  & span {
    display: block;
    color: @font-color;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
  }
  & .btn {
    padding: 14px 16px;
    min-width: 136px;
    text-align: center;
    margin-left: 6px;
    border-radius: 20px;
  }
}
.mili_group {
  display: flex;
  align-items: center;
  & span {
    display: block;
    color: fade(@font-color, 60%);
    font-weight: 500;
    line-height: 20px;
  }
  & .btn {
    padding: 14px 16px;
    min-width: 216px;
    text-align: center;
    margin-right: 20px;
  }
}
.card_mili {
  display: flex;
  align-items: center;
  & .btn:not(.fake_select) {
    margin-left: 6px;
    padding: 6px 7px;
    min-width: 99px;
  }
  & .fake_wrap {
    width: 100%;
  }
}
.pay_group {
  display: flex;
  margin: 0 -10px;
  padding: 10px 0;
  & label {
    width: 50%;
    padding: 5px 10px;
    .ellipsis;
  }
}
.user_group_zaezd {
  & .form_group {
    margin-top: 14px;
    &:first-child {
      margin-top: 0;
    }
    & .sub_group {
      align-items: center;
      margin-top: 0;
    }
  }
}

.user_footer {
  margin: 54px -20px -30px;
  border-radius: 0 0 @radius-20 @radius-20;
  background: @primary-color;
  padding: 20px 20px 30px;
  & .row {
    align-items: flex-end;
  }
  & .btn {
    width: 100%;
    display: block;
    text-align: center;
  }
  &.user_footer_hotel {
    margin-top: 20px;
    & .row {
      align-items: flex-start;
    }
    & .fprice_line {
      & + .fprice_line {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        padding: 4px 0;
      }
      &.fprice_line_itog {
        border-bottom: 1px solid fade(@primary-color-main, 40%);
        font-size: 18px;
        line-height: 24px;
        padding: 12px 0 24px;
        & + .fprice_line {
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }
      &.fprice_line_inf {
        font-size: 13px;
        padding-bottom: 0;
      }
      &.fprice_line_allrt {
        font-size: 12px;
        line-height: 18px;
      }
    }
    & .privacy {
      margin-top: 20px;
    }
    & .btn {
      margin-top: 25px;
    }
  }
}

.pass_bron {
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: background-color 150ms, color 150ms ease-in-out;
  white-space: nowrap;
  width: 100%;

  & ul {
    margin: 0;
    padding: 0;
    transition: transform 300ms cubic-bezier(0,.65,.70,.90);
    transform-style: preserve-3d;
  }

  & li {
    --rotateX: 0deg;

    display: flex;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
    transition: opacity 150ms;
    transform-origin: 50% 50%;
    transform: rotateX(var(--rotateX)) translateZ(10px);
    width: 100%;

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      opacity: 0;
      position: absolute;
    }

    &:nth-child(2) {
      --rotateX: -90deg;
    }
    &:nth-child(3) {
      --rotateX: -180deg;
    }
    &:nth-child(4) {
      --rotateX: -270deg;
    }
  }

  & span {
    align-items: center;
    display: flex;
    height: 20px;
    justify-content: center;
    max-width: 0;
    opacity: 0;
    position: relative;
    transform: translateX(-100%);
    transition: max-width 50ms ease, opacity 100ms ease, transform 150ms ease-in-out;
    width: 20px;
  }

  & svg {
    position: absolute;
    // Circle
    &:nth-child(1) {
      --stroke: 40;

      fill: transparent;
      height: 20px;
      left: 0;
      stroke: white;
      stroke-dasharray: var(--stroke);
      stroke-dashoffset: calc(var(--stroke) * 2);
      top: 0;
      width: 20px;
    }

    &:nth-child(2) {
      --stroke: 16;

      fill: transparent;
      height: 16px;
      left: 50%;
      stroke: transparent;
      stroke-dasharray: var(--stroke);
      stroke-dashoffset: var(--stroke);
      top: 9px;
      transform: translate3d(-4px, -3px, 0);
      transition: stroke 300ms;
      width: 16px;
    }
  }
  &.-request,
  &.-success,
  &.-error {
    text-align: left;
    pointer-events: none;

    & span {
      margin-right: 12px;
      max-width: initial;
      opacity: 1;
      transform: translateX(0);
    }

    & li:nth-child(1) {
      opacity: 0;
      position: absolute;
    }
  }

  &.-request {
    background-color: #6553D8;

    & svg {
      &:nth-child(1) {
        animation: stroke 2s linear infinite forwards, rotate 1s linear infinite forwards;
      }
    }

    & ul {
      transform: rotateX(90deg);
    }

    & li:nth-child(2) {
      opacity: 1;
      position: relative;
    }
  }

  &.-success {
    background-color: @font-mint-color;
    & svg {
      &:nth-child(1) {
        fill: #fff;
        top: 0;
        stroke-dasharray: 0;
      }
      &:nth-child(2) {
        animation: stroke 300ms ease-in-out forwards;
        stroke: @font-mint-color;
      }
    }
    & ul {
      transform: rotateX(180deg);
    }
    & li:nth-child(3) {
      opacity: 1;
      position: relative;
    }
  }

  &.-error {
    background-color: fade(@font-color, 10%);
    color: fade(@font-color, 60%);
    & svg {
      &:nth-child(1) {
        fill: #fff;
        top: 0;
        stroke-dasharray: 0;
      }
      &:nth-child(2) {
        animation: stroke 300ms ease-in-out forwards;
        stroke: @font-mint-color;
      }
    }
    & ul {
      transform: rotateX(270deg);
    }
    & li:nth-child(4) {
      opacity: 1;
      position: relative;
    }
  }
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.fprice_line {
  display: flex;
  justify-content: space-between;
  &.fprice_line_itog {
    color: @font-color;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
  }
  & > a {
    font-size: 13px;
  }
  & > div {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    & i {
      font-style: normal;
      padding-left: 5px;
    }
  }
  & + .fprice_line {
    margin-top: 10px;
  }
}
.privacy {
  margin-top: 30px;
  line-height: 20px;
  padding-bottom: 4px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  & > div {
    padding: 10px;
  }
  &.row-center {
    align-items: center;
  }
}
.col3 {
  width: 25%;
}
.col4 {
  width: 33.3333%;
}
.col5 {
  width: 41.6666%;
}
.col6 {
  width: 50%;
}
.col8 {
  width: 66.6666%;
}
.col9 {
  width: 75%;
}
.col12 {
  width: 100%;
}
.col_razdel {
  padding-bottom: 3px !important;
  & + .col_razdel {
    margin-top: 17px;
    margin-bottom: 21px;
  }
}
.razdel {
  width: 100%;
  height: 1px;
  background: @primary-color;
}
.user_stroka {
  display: flex;
  align-items: center;
  & svg {
    margin: 0 16px;
    color: @onix_300;
  }
  & span {
    font-weight: 500;
    color: @onix_300;
  }
}

.access_wrap {
  display: flex;
  margin: 0 -5px;
  padding: 8px 0;
  & a {
    display: block;
    margin: 0 5px;
    padding: 4px;
    border-radius: 8px;
    background: fade(@bg-main, 30%);
  }
}
.access_ico {
  display: block;
  margin: 0 5px;
  padding: 3px;
  border-radius: 4px;
  background: fade(@bg-main, 30%);
  & svg {
    width: 14px;
    height: 14px;
  }
}
.access_1 {
  color: @red_600;
}
.access_2 {
  color: @Peach_600;
}
.access_3 {
  color: @mint_600;
}
.access_4 {
  color: @purple_600;
}
.access_5 {
  color: @wings_blue_400;
}

.check_row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  & .checkbox-container {
    align-items: center;
    min-height: 28px;
    margin-top: 14px;
    &:first-child {
      margin-top: 0;
    }
  }
  & .radio-container {
    align-items: center;
    min-height: 18px;
    margin: 5px 0;
  }
}
.check_row_hor {
  flex-direction: row;
  & .checkbox-container {
    margin-top: 0;
    width: 33.3333%;
    &:nth-child(n+4) {
      margin-top: 14px;
    }
  }
}

.down_avatar {
  display: flex;
  max-width: 390px;
  & .avatar_href {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background: fade(@bg-main, 30%);
    margin-right: 12px;
  }
  & .down_area {
    width: calc(100% - 112px);
    padding: 5px 12px;
    border: 1px dashed @primary-color-bl400;
    border-radius: 8px;
    line-height: 20px;
    & .btn {
      display: block;
      text-align: center;
      width: 100%;
      margin: 6px 0 10px;
    }
    & .area_inf {
      color: fade(@font-gray-color, 40%);
    }
    & .flex_group_but {
      display: none;
      & .btn {
        margin-right: 3px;
        margin-left: 3px;
      }
    }
  }
  &.down_complited {
    & .down_area {
      & > .btn {
        display: none;
      }
    }
    & .flex_group_but {
      display: flex;
      margin: 0 -3px;
    }
  }
}
.but_block_center .btn {
  max-width: 392px;
  margin: 0 auto;
  border-radius: 20px;
  display: block;
  text-align: center;
}
.col12.sep {
  padding: 0 !important;
}

.ofert_title {
  margin-bottom: 20px;
  & .txt_light40 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
  }
}
.text_wrap {
  max-width: 844px;
  line-height: 18px;
  color: @font-color;
  & h2 {
    margin: 0 0 20px;
  }
  & h4 {
    margin: 0 0 20px;
  }
  & a {
    text-decoration: underline;
  }
  & .text_sub {
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  & .text_block {
    margin-bottom: 28px;
    & b {
      color: @font-color-dark;
      font-weight: 800;
      line-height: 20px;
    }
    & p {
      margin-bottom: 12px;
      text-align: justify;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    & ul {
      margin: 0 0 12px;
      padding: 0 32px 0 46px;
      text-align: justify;
      &:last-child {
        margin-bottom: 0;
      }
      & li {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.text_flex_item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  line-height: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  & .text_flex_title {
    width: 245px;
    min-width: 245px;
    margin-right: 20px;
    padding: 4px 0;
  }
  & .text_flex_inf {
    padding: 4px 0;
  }
}
.flex_title_but {
  display: flex;
  margin: 0 -10px;
  & > * {
    flex: 1 1 auto;
    margin: 0 10px;
  }
  & .btn {
    border-radius: 20px;
  }
  & .fake_select {
    height: 32px;
    color: #FFFFFF;
  }
}
.grid_table {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -13px;
}
.grid_table_two_col {
  & .user_line {
    width: 50%;
    &:nth-child(2n+1) {
      padding-right: 10px;
    }
    &:nth-child(2n+2) {
      padding-left: 10px;
    }
    &:nth-child(2) {
      border-top-width: 0;
      padding-top: 0;
    }
    & .access_ico {
      margin: 0 6px 0 0;
    }
  }
}
.grid_table_col4 {
  margin: -17px -8px;
  justify-content: space-between;
  & .user_line {
    width: calc(25% - 15px);
    align-items: center;
    font-size: 13px;
    color: @font-color;
    padding: 10px 0;
    border: none;
    position: relative;
    & a {
      color: @font-color;
    }
    &:nth-child(1) {
      padding-top: 10px;
    }
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      height: 1px;
      width: calc(400% + 60px);
      background: @bg-main;
    }
    &:nth-child(4n+5) {
      &:after {
        content: "";
      }
    }
  }
}
.user_group .grid_table_col4 {
  justify-content: inherit;
}
.grid_table_line {
  & .user_line {
    flex-wrap: wrap;
  }
  & h5 {
    margin-top: -5px;
    & sup {
      font-size: 14px;
      color: fade(@font-color-dark, 40%);
    }
  }
  & .text_titl_inf {
    line-height: 20px;
    & > span {
      display: block;
      width: 100% !important;
      margin-right: 0 !important;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: fade(@font-color-dark, 60%);
      & + div {
        margin-top: 12px;
        width: 100%;
        & * + * {
          margin-top: 6px;
        }
      }
    }
  }
}
.user_line {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  padding: 14px 0;
  border-top: 1px solid @bg-main;
  width: 100%;
  & > span {
    padding: 0;
    width: 100%;
    &:nth-child(1) {
      width: 175px;
      min-width: 175px;
      margin-right: 20px;
    }
  }
  &:nth-child(1) {
    border-top-width: 0;
    padding-top: 0;
  }
}
.grid_manag {
  display: flex;
  margin: 3px -10px;
  .user_manag {
    display: flex;
    align-items: center;
    background: @bg-main;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    padding: 8px 6px;
    width: calc(25% - 20px);
    margin: 3px 10px;
    color: @font-color;
    & .access_ico {
      margin: 0 6px;
    }
    & span {
      flex: 1 1 auto;
    }
    & > svg {
      margin: 0 6px;
    }
  }
}


.text_titl_inf {
  & span {
    line-height: 20px;
    word-break: break-word;
    &:nth-child(1) {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: fade(@font-color-dark, 60%);
      margin-top: 1px;
    }
  }
}
.fill_box {
  & > div {
    & > div {
      border-radius: 20px;
      padding: 30px 20px;
      height: 100%;
    }
  }
}
.user_table_company {
  & .user_group_head {
    margin-bottom: 14px;
    & h5 {
      padding: 0 0 17px;
      width: 100%;
    }
  }
  & .user_group {
    margin-top: 0;
    height: 100%;
  }
}
.fill_box_company {
  & .user_group_head {
    margin-bottom: 30px;
  }
  & .text_titl_inf {
    & span {
      display: block;
      &:nth-child(1) {
        margin-bottom: 6px;
      }
    }
  }
  & .marg_minus > .row {
    margin-top: -20px;
  }
}

.profile_img {
  order: -2;
  & > div {
    width: 304px;
    height: 323px;
    border-radius: @radius-20;
    border: 2px solid @primary-color;
    overflow: hidden;
    & img {
      width: 100%;
    }
  }
}
.profile_second {
  width: calc(100% - 324px);
  order: -1;
}

.flex_with_ico {
  display: flex;
  align-items: center;
  & svg + span {
    margin-left: 6px;
  }
  & span + svg {
    margin-left: 6px;
  }
}

.user_group.alert {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: @font-color;
  & svg {
    margin-right: 26px;
    min-width: 32px;
  }
  & .user_group_head {
    flex-wrap: wrap;
    margin: 0;
    & h5 {
      padding: 0;
      width: 100%;
      margin-bottom: 14px;
    }
  }
}
.user_group.alert-red {
  border: 1px solid @wings_red_700;
  & svg {
    color: @wings_red_700;
  }
}
.user_group.alert-green {
  border: 1px solid @mint_400;
  & svg {
    color: @mint_400;
  }
}
.user_group.alert-orange {
  border: 1px solid @Peach_500;
}

.user_group_news {
  margin-top: 40px;
  & .newstbl_item {
    margin: 4px 0 0;
    padding: 12px 10px;
    border-radius: 12px;
    border: 0;
    background: @wings_blue_100;
    &.no_read {
      background: @bg-noread;
    }
    & > div {
      padding: 0 10px;
    }
    &:before {
      background: @primary-color-main;
    }
    .newstbl_text {
      width: calc(100% - 152px);
    }
    & .newstbl_date {
      color: @font-color;
    }
    & .newstbl_but {
      width: 52px;
    }
  }
  & .notify_none {
    text-align: center;
    line-height: 20px;
  }
}

.login_wrap {
  width: 100%;
  max-width: 1358px;
  padding: 12px 14px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.login_el1, .login_el2 {
  position: absolute;
  z-index: -1;
  opacity: 0.7;
  transform: translate(-50%,0);
}
.login_el1 {
  top: 55px;
  left: 25%;
  width: 240px;
  height: 280px;
  filter: blur(170px);
  color: @bg-login_el1;
}
.login_el2 {
  top: 516px;
  left: 70%;
  width: 262px;
  height: 262px;
  filter: blur(200px);
  color: @bg-login_el2;
}
.box_login {
  background: #FFFFFF;
  padding: 40px 80px;
  border-radius: 40px;
  max-width: 552px;
  width: 100%;
  margin: 78px auto 0;
  color: @font-color;
  & a {
    color: @primary-color-bl400;
  }
  & .box_login_head {
    text-align: center;
    margin-bottom: 28px;
    & .loader {
      margin: 0 auto 24px;
      background-size: contain;
      width: 120px;
      height: 70px;
      & img {
        width: 25px;
        margin-top: -10px;
        left: 63%;
      }
    }
    & h2 {
      margin-top: 30px;
    }
    & .sub_title {
      margin-top: 10px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
  }
  & .form_group_center {
    margin-top: 35px;
    & a {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
  }
  & .error_inf {
    position: relative;
    bottom: 0;
    margin-top: 5px;
  }
  & .user_group.alert {
    margin-top: -4px;
    border-width: 2px;
    padding: 14px 16px 16px 16px;
    margin-bottom: 28px;
    & .user_group_head h5 {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 20px;
    }
    & svg {
      margin-right: 17px;
    }
  }
  &.box_login_send {
    & .form_group {
      display: none;
    }
    & .form_group_center {
      display: none;
      & + .form_group_center {
        display: flex;
      }
    }
  }
  &.box_login_reg {
    max-width: 900px;
    border-radius: 20px;
    margin-top: 88px;
    padding: 10px 40px 40px;
    & .box_login_head {
      text-align: left;
      margin-bottom: 32px;
      & .sub_title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        & span {
          white-space: nowrap;
        }
      }
    }
    .user_group_nobord.user_group .form_group {
      margin-bottom: 33px;
    }
    & .form_group_center {
      margin-top: -13px;
    }
    & .user_group_head h5 {
      width: 100%;
    }
  }
  & .checkbox-container .checkmark {
    border-color: fade(@onix_600, 30%);
  }
  & .btn-disable {
    background: @bg-btn-gray-login;
    &:hover, &.active, &:focus {
      background: @primary-color-bl400;
    }
  }
  & .loader span {
    height: 30px;
    background-size: contain;
  }
}
.show_pass {
  position: relative;
  & .input_field {
    padding-right: 32px;
  }
  & a {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0,-50%);
    color: @font-color;
    & svg + svg {
      display: none;
    }
    &.active {
      & svg {
        display: none;
        & + svg {
          display: block;
        }
      }
    }
  }
}
.user_group .sub_group.sub_group_just {
  justify-content: space-between;
  & > * {
    width: auto;
  }
}
.login_info {
  margin: 90px 0 76px;
  & .login_col {
    height: 100%;
    padding: 47px 33px 40px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    & .login_col_img {
      width: 80px;
      height: 80px;
      background: fade(@primary-color-main, 10%);
      border-radius: 100px;
      padding: 10px;
      color: @color-login;
    }
    & .login_col_title {
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      color: #000044;
      margin: 54px 0 36px;
    }
    & .login_col_text {
      margin: auto 0 0 0;
      font-size: 22px;
      font-weight: 800;
      line-height: 28px;
      color: @color-login;
      & p {
        margin-top: 7px;
      }
      & a {
        color: @color-login;
      }
    }
    &.active {
      background: fade(@primary-color-main, 10%);
      & .login_col_img {
        background: #FFFFFF;
      }
    }
  }
}

.captch_flex {
  display: flex;
  & .captch_img {
    border: 1px solid fade(@font-color, 30%);
    border-radius: @radius-btn;
    width: 136px;
    height: 48px;
    min-width: 136px;
    margin-right: 20px;
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.captch_group {
  & .sub_group {
    & > * {
      width: auto;
      margin-left: 156px;
    }
  }
}

@media (max-width: 1299px) {
  .regdetail_wrap {
    flex-wrap: wrap;
    margin: 0;
    & .col8, .col4 {
      width: 100%;
      padding: 0;
    }
    & .col4 {
      padding-top: 20px;
    }
  }
  .user_stroka {
    & svg {
      margin: 0 8px;
    }
  }
}
@media (max-width: 991px) {
  .colmd8 {
    width: 66.6666%;
  }
  .user_footer {
    & .row {
      & .col4 {
        width: 50%;
        &:first-child {
          width: 100%;
        }
      }
    }
  }
  .user_group_head {
    flex-wrap: wrap;
    & h5, .form_group_center {
      width: 50%;
    }
  }
  .user_group {
    & .alert {
      width: 100%;
      order: 1;
      margin-top: 10px;
    }
    &.user_group_alert {
      & .user_group_head {
        & > .btn {
          margin: 14px 0 0;
        }
      }
      & h5 {
        width: 100%;
        padding: 0;
      }
    }
  }
  .relat {
    & > .flex_group {
      flex-wrap: wrap;
      justify-content: normal;
    }
  }
  .flex_title_but:not(.flex_title_but_left) {
    width: calc(100% + 20px);
    margin-top: 10px;
    & .btn-fix {
      & .btn {
        width: 100%;
      }
    }
  }
  .flex_group_wis_but {
    & .flex_title_but {
      width: auto;
    }
  }
  .fill_box_company {
    & > div {
      width: 100%;
    }
    & > .col4 {
      margin-top: -10px;
      & .col6, & .col12 {
        width: 33.3333%;
      }
    }
  }
  .user_group.user_table_company {
    margin-top: 10px;
  }
  .user_table_company {
    & .col6 {
      width: 100%;
      &:last-child {
        padding-top: 0;
        margin-top: -10px;
      }
    }
    & .user_group_head h5 {
      font-size: 18px;
      width: 100%;
    }
  }
  .grid_table_two_col:not(.grid_table_line) {
    & .user_line {
      width: 100%;
      &:nth-child(2) {
        border-top-width: 1px;
        padding-top: 14px;
      }
      &:nth-child(2n+1) {
        padding-right: 0;
      }
      &:nth-child(2n+2) {
        order: 1;
        padding-left: 0;
      }
    }
  }
  .grid_table_col4 {
    & .user_line {
      width: calc(33.3333% - 13.3334px);
      padding: 8px 0;
      &:nth-child(4n+5) {
        &:after {
          content: none;
        }
      }
      &:nth-child(3n+4) {
        &:after {
          content: "";
          width: calc(300% + 40px);
        }
      }
    }
  }
  .user_table_left {
    & .user_line {
      align-items: flex-start;
      margin-right: 0;
      padding-right: 0;
    }
  }
  .user_table_right {
    & .user_line {
      align-items: flex-start;
      margin-left: 0;
      padding-left: 0;
    }
  }
  .profile_img {
    order: 0;
    & > div {
      width: 213px;
      height: 227px;
    }
    & + div.col6 {
      width: calc(100% - 233px);
      & .user_group_head {
        margin-bottom: 20px;
      }
      & .col6 {
        width: 50%;
      }
    }
  }
  .profile_second {
    width: 100%;
    order: 0;
    margin-bottom: 10px;
    margin-top: -10px;
  }
  .check_row_hor {
    flex-direction: column;
    & .checkbox-container {
      margin-top: 14px;
      width: 100%;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .grid_manag {
    .user_manag {
      width: calc(50% - 20px);
    }
  }
  .login_info {
    margin: 80px 0 66px;
    & .login_col {
      padding: 30px 20px 30px;
      & .login_col_text {
        font-size: 18px;
        line-height: 24px;
      }
      & .login_col_title {
        margin: 45px 0 50px;
      }
    }
  }
  .captch_flex {
    flex-wrap: wrap;
    & .captch_img {
      margin-bottom: 12px;
    }
  }
  .box_login {
    & .box_login_head {
      & .sub_title {
        font-size: 14px;
        line-height: 20px;
      }
    }
    &.box_login_reg {
      & .user_group_nobord.user_group .form_group {
        margin-bottom: 24px;
      }
      & .col4 {
        width: 50%;
      }
    }
  }
  .captch_group {
    & .sub_group {
      & > * {
        margin-left: 0;
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 767px) {
  .col4, .col6, .col8 {
    width: 100%;
  }
  .regdetail_wrap {
    & .col4 {
      padding-top: 10px;
    }
    & .regdetail {
      & .detail_wrap {
        flex-wrap: wrap;
      }
      & .detail_item {
        width: 100%;
        & .detail_title {
          font-size: 16px;
        }
        & .detail_airport {
          padding: 12px 0 12px 0;
          & span {
            font-size: 12px;
          }
          & .modal_rules {
            font-size: 12px;
            margin-top: 0;
          }
        }
        & .detail_line {
          font-size: 13px;
          padding-bottom: 10px;
          & .detail_time {
            font-size: 16px;
          }
        }
        & .detail_dopinfo {
          font-size: 12px;
          margin-top: 0;
          & span {
            margin: 3px 0;
            display: block;
          }
        }
      }
      & .detail_peresad {
        width: 100%;
        max-width: none;
        padding: 20px 0;
        justify-content: flex-start;
        font-size: 13px;
        & div {
          text-align: left;
        }
      }
      & .reg_title {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  .user_group {
    margin-top: 10px;
    padding: 28px 18px;
    & .form_group {
      & .form_label {
        font-size: 13px;
        margin-bottom: 4px;
      }
    }
    & .sub_group {
      font-size: 13px;
    }
    & .alert {
      width: 100%;
      order: 1;
      margin-top: 10px;
    }
  }
  .dupl_group .btn {
    font-size: 16px;
    margin: 0 0 0 auto;
  }
  .col_razdel {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    & + .col_razdel {
      display: none;
    }
  }
  .col_razdel_dvoin {
    padding-bottom: 24px !important;
    padding-top: 14px !important;
  }
  .mili_group {
    margin-top: -25px;
    & .btn {
      min-width: 236px;
      font-size: 16px;
    }
  }
  .form_group .check_oform {
    margin-top: -25px;
    min-height: 0;
  }
  .user_footer {
    margin: 22px -20px -30px;
    font-size: 13px;
    & .row .col4 {
      width: 100%;
    }
  }
  .fprice_line {
    & > div {
      justify-content: flex-end;
      & span, i {
        width: auto;
      }
    }
  }
  .user_stroka span {
    font-size: 14px;
  }
  .form_group_mlad {
    flex-wrap: wrap;
    & .btn {
      width: 100%;
      & + .btn {
        margin: 10px 0 0;
      }
    }
  }
  .text_wrap {
    & .text_block {
      & ul {
        padding: 0 0 0 28px;
      }
    }
  }
  .text_flex_item {
    & .text_flex_title {
      width: 200px;
      min-width: 200px;
    }
  }

  .flex_group_wis_but {
    & .flex_title_but {
      width: calc(100% + 20px);
    }
  }
  .fill_box_company {
    & .row > div {
      width: 50%;
      &.text-right {
        text-align: left;
        width: 100%;
        margin-top: 0;
      }
    }
    & > .col4 .col6 {
      width: 50%;
    }
    & > .col4 .col12 {
      width: 100%;
    }
    & .user_group_head {
      margin-bottom: 10px;
    }
  }
  .user_line span:nth-child(1) {
    width: 145px;
    min-width: 145px;
  }
  .grid_table_line {
    & .user_line {
      width: 100%;
      &:nth-child(2) {
        border-top-width: 1px;
        padding-top: 14px;
      }
      &:nth-child(2n+1) {
        padding-right: 0;
      }
      &:nth-child(2n+2) {
        order: 1;
        padding-left: 0;
      }
    }
  }
  .grid_table_col4 {
    & .user_line {
      width: calc(50% - 10px);
      padding: 8px 0;
      &:nth-child(3n+4) {
        &:after {
          content: none;
        }
      }
      &:nth-child(2n+3) {
        &:after {
          content: "";
          width: calc(200% + 20px);
        }
      }
    }
  }
  .profile_img {
    padding: 0 0 0 30px !important;
    position: relative;
    margin-bottom: -64px;
    margin-top: 20px;
    & > div {
      width: 61px;
      height: 64px;
    }
    & + div.col6 {
      width: 100%;
      order: -1;
    }
  }
  .profile_second {
    margin-top: -30px;
    & .user_group_head {
      margin-left: 85px;
      padding-top: 10px;
    }
  }
  .login_wrap {
    padding-right: 20px;
    padding-left: 20px;
  }
  .box_login {
    padding: 30px 40px;
    border-radius: 30px;
    max-width: 340px;
    & .box_login_head {
      margin-bottom: 16px;
      & .loader {
        margin: 0 auto 10px;
        height: 63px;
      }
      & img {
        width: 130px;
      }
      h2 {
        margin-top: 22px;
        font-size: 26px;
        line-height: 36px;
      }
      & .sub_title {
        font-size: 12px;
        line-height: 18px;
      }
    }
    & .user_group {
      & .form_group {
        margin-bottom: 20px !important;
        & .form_label {
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
      & .sub_group {
        font-size: 12px;
      }
      &.alert {
        margin-top: 0;
        padding: 16px 16px 16px 16px;
        margin-bottom: 18px;
        font-size: 12px;
        line-height: 18px;
        & svg {
          min-width: 24px;
          width: 24px;
        }
      }
    }
    & .form_group_center {
      margin-top: 25px;
      & .btn {
        font-size: 14px;
        padding: 6px 16px;
      }
      & a {
        font-size: 12px;
        line-height: 20px;
      }
    }
    &.box_login_reg {
      margin-top: 80px;
      padding: 23px 25px;
      h2 {
        margin-top: 0;
        font-size: 30px;
        line-height: 40px;
      }
      .box_login_head {
        margin-bottom: 22px;
        & .sub_title {
          font-size: 14px;
          line-height: 20px;
        }
      }
      & .user_group_nobord.user_group .form_group {
        margin-bottom: 20px;
      }
      & .user_group .form_group {
        margin-bottom: 2px !important;
      }
      & .row + .user_group_head {
        margin-top: 18px;
      }
      & .col4 {
        width: 100%;
      }
      & .form_group_center {
        margin-top: 20px;
        & .btn {
          font-size: 16px;
          padding: 16px 16px;
        }
      }
    }
  }
  .login_info {
    margin: 60px 0 46px;
    & .login_col {
      padding: 20px;
      & .login_col_img {
        width: 40px;
        height: 40px;
        padding: 5px;
        & svg {
          width: 30px;
          height: 30px;
        }
      }
      & .login_col_title {
        margin: 18px 0 14px;
        font-size: 16px;
        line-height: 24px;
      }
      & .login_col_text {
        font-size: 14px;
        line-height: 20px;
        &.login_col_text_p {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -10px;
          & p {
            margin: 0;
            padding: 0 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .regdetail_wrap {
    & .regdetail {
      & .detail_wrap {
        margin-top: 15px;
      }
      & .detail_item {
        & .detail_title {
          font-size: 14px;
        }
      }
      & .reg_title {
        font-size: 16px;
      }
    }
  }
  .user_group_head {
    & h5 {
      font-size: 16px;
    }
    & .btn-small {
      padding: 6px 16px;
    }
    & h5.user_stroka {
      width: 100%;
    }
    & .form_group_center {
      width: 100%;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
  .user_group {
    & .sub_group {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
  .user_group_news {
    & .user_group_head {
      & h5 {
        width: 100%;
      }
      & .sort_wrap {
        margin: 7px 0 2px;
      }
    }
    & .newstbl_item {
      & .newstbl_text {
        width: calc(100% - 73px);
        & span {
          white-space: normal;
        }
      }
      & .newstbl_date {
        width: 73px;
        padding: 44px 0 0 10px;
        text-align: center;
      }
      & .newstbl_but {
        width: 63px;
        padding: 0;
        position: absolute;
        right: 10px;
        & .newstbl_but_mail {
          margin: 0 auto;
        }
      }
    }
  }
  .dupl_group {
    flex-wrap: wrap;
    & span {
      margin: 5px 0 7px;
    }
    & .btn {
      margin-left: 0;
    }
  }
  .mili_group {
    flex-wrap: wrap;
    justify-content: center;
    & .btn {
      margin: 0 0 8px;
      width: 100%;
    }
  }
  .card_mili {
    .btn {
      margin-left: 6px;
      padding: 4px;
      min-width: 32px;
      width: 32px;
      height: 32px;
      font-size: 0;
      & svg + span {
        margin-left: 0;
      }
    }
  }
  .fprice_line {
    & > div {
      width: auto;
    }
    & + .fprice_line {
      margin-top: 8px;
    }
  }
  .user_footer .row .col4:first-child {
    margin-bottom: 5px;
  }
  .privacy {
    font-size: 14px;
  }
  .down_avatar {
    flex-wrap: wrap;
    & .down_area {
      width: 100%;
      line-height: 20px;
      margin-top: 6px;
    }
  }
  .text_flex_item {
    align-items: flex-start;
    & .text_flex_title {
      width: 140px;
      min-width: 140px;
    }
  }
  .btn-wide-tret {
    min-width: 100%;
  }
  .btn-fix {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    width: 100%;
    height: 109px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-top: 1px solid @bg-main;
    padding: 0 20px;
    z-index: 10;
    & .btn-wide-tret {
      width: 100%;
    }
  }
  .flex_group_wis_but {
    margin-bottom: 30px;
  }
  .fill_box_company {
    & .marg_minus > .row {
      margin-top: -10px;
    }
    & .row > div {
      width: 100%;
      margin-top: 0;
      &.text-right {
        & .btn {
          width: 100%;
        }
      }
    }
    & > .col4 .col6 {
      width: 100%;
    }
    & .user_group_head h5 {
      font-size: 18px;
    }
  }
  .user_line span:nth-child(1) {
    width: 130px;
    min-width: 130px;
    margin-right: 6px;
  }
  .text_titl_inf span:nth-child(1) {
    margin-top: 0;
  }
  .profile_second .user_group_head {
    padding-top: 0;
  }
  .grid_table_col4 {
    & .user_line {
      width: 100%;
      padding: 8px 0;
      &:nth-child(2n+3) {
        &:after {
          content: none;
        }
      }
      &:nth-child(n+2) {
        &:after {
          content: "";
          width: 100%;
        }
      }
    }
  }
  .flex_title_but {
    flex-wrap: wrap;
    & .btn + .btn {
      margin-top: 10px;
    }
  }
  .grid_manag {
    .user_manag {
      width: calc(100% - 20px);
    }
  }
  .box_login {
    padding: 24px;
    max-width: 306px;
    margin: 40px auto 0;
    &.box_login_reg {
      margin-top: 40px;
      & .box_login_head {
        margin-bottom: 26px;
      }
      & .user_group_head h5 {
        font-size: 18px;
      }
      & .user_group .form_group {
        margin-bottom: -2px !important;
      }
      & .row + .user_group_head {
        margin-top: 25px;
      }
    }
  }
  .login_info {
    margin: 40px 0 46px;
    & .login_col {
      & .login_col_title {
        margin: 12px 0 14px;
        font-size: 14px;
        line-height: 20px;
      }
      & .login_col_text {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}