@import "partials/params";
@import "partials/mixins";

.header {
  position: relative;
  .flex-center(row, space-between);
  padding: 14px 0;
  height: @nav-height;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: @font-color-dark;
  & a {
    color: @font-color-dark;
    &.header_burger {
      color: @primary-color-main;
    }
  }
  & .header_left {
    .flex-center(row, space-between);
    & .header_logo {
      width: @width-side;
      padding: 0 0 0 30px;
      position: relative;
      .anim-fade;
      .flex-center(row, space-between);
      & .logo {
        .flex-center(row);
        max-width: 194px;
        height: 50px;
        position: relative;
        overflow: hidden;
        width: 100%;
        .anim-fade;
        & span {
          .anim-fade;
          width: auto;
          display: block;
          overflow: hidden;
          margin-left: 5px;
        }
        & img {
          display: block;
          max-width: 100%;
          position: absolute;
          left: 0;
        }
        &.logo_withtxt {
          height: 40px;
          text-align: center;
          font-weight: 800;
          line-height: normal;
          font-size: 18px;
          color: @font-white-color;
          border-radius: 10px;
          background: @primary-color-main;
          padding: 0 6px;
          .ellipsis;
          & img {
            position: relative;
          }
        }
      }
      &.active {
        /*padding-left: 16px;
        padding-right: 16px;*/
        width: @width-side-small;
        & .logo {
          max-width: 49px;
          border-radius: 100px;
          & img {
            max-width: 194px;
            margin-left: @margin-logo;
          }
          & span {
            width: 0;
            margin: 0;
          }
        }
        & .side_toggle a {
          margin: 4px -15px 0 0;
        }
      }
      & .side_toggle {
        position: absolute;
        right: -40px;
        & a {
          display: inline-block;
          color: #70717C;
          margin: 4px 6px 0 0;
          .anim-fade;
          /*&.active {
            transform: scale(-1, 1);
          }*/
        }
      }
    }
    & .header_cent {
      margin: 0 40px 0 90px;
      & span {
        display: none;
      }
    }
    & .header_tech {
      & span {
        opacity: 0.6;
        display: block;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 10px;
        margin-bottom: 2px;
      }
      & a + a {
        margin-left: 37px;
      }
    }
  }
  & .header_right {
    .flex-center(row, space-between);
    & .header_ico {
      .flex-center(row, space-between);
      margin-right: 20px;
      & img {
        width: 24px;
        height: 24px;
        display: block;
      }
    }
    & .header_user_mess a {
      color: #5A5A5A;
      position: relative;
      display: block;
      &.active:before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background: #EE5C5C;
        position: absolute;
        top: 0;
        right: 0;
        border: 2px solid #E3E5F6;
      }
    }
    & .header_user {
      & a {
        .flex-center(row, flex-end);
        justify-content: flex-end;
        margin-right: 30px;
        width: 176px;
        & span {
          display: block;
          margin-right: 14px;
        }
      }

    }
  }
  &.header_login {
    padding: 9px 12px;
    border-radius: 30px;
    height: auto;
    background: #FFFFFF;
    margin-bottom: 12px;
    & .header_logo img {
      display: block;
      height: 34px;
      width: auto;
    }
    & a.btn {
      color: #FFFFFF;
    }
  }
}
.currency_wrap {
  position: relative;
  & .fake_select:focus, .fake_select:active, .fake_select.focused {
    outline: none;
    color: #5A5A5A;
    background: transparent;
    border: 1px solid transparent;
  }
}
.header_currency {
  margin-right: 100px;
  .flex-center(row, space-between);
  & a {
    display: flex;
    align-items: center;
    color: @font-color-dark;
    & + a {
      margin-left: 20px;
    }
    & svg, img {
      display: block;
      margin-right: 6px;
    }
    & .icon_fill {
      background: @primary-color-bl200;
      border-radius: 4px;
      &:hover {
        color: #FFFFFF;
        background: @primary-color-bl400;
      }
    }
    & .icon_bord {
      border: 1px solid @primary-color-bl200;
      border-radius: 5.5px;
      box-sizing: content-box;
      &:hover {
        border: 1px solid @primary-color-bl400;
      }
    }
    & .icon_rus {
      width: 22px;
      height: 22px;
    }
  }
}
.header_burger {
  display: none;
}

@media (max-width: 1199px) {
  .header {
    & .header_left {
      & .header_logo {
        width: 89px !important;
        padding: 0 20px !important;
        & .logo {
          width: 49px !important;
          border-radius: 100px;
          & img {
            max-width: 194px;
          }
          & span {
            width: 0;
          }
        }
      }
      & .header_cent {
        margin: 0 20px 0 0;
        font-size: 0;
        & a {
          display: flex;
        }
        & span {
          display: flex;
          align-items: center;
          font-size: 14px;
          & svg {
            margin-right: 6px;
          }
        }
      }
    }
    & .header_right {
      & .header_user a {
        width: 170px;
        margin-right: 15px;
      }
      & .header_currency {
        margin-right: 20px;
      }
    }
    &.open_sidebar {
      z-index: 30;
      background: @font-white-color;
    }
  }
  .header_burger {
    .flex-center;
    border-radius: 0 14px 14px 0;
    background: fade(@primary-color-main, 30%);
    width: 71px;
    height: 68px;
    & .burg-close {
      display: none;
    }
    &.active {
      color: #ffffff;
      & .burg-open {
        display: none;
      }
      & .burg-close {
        display: block;
      }
    }
  }

  .side_toggle, .header_tech {
    display: none;
  }
}
@media (max-width: 991px) {

}
@media (max-width: 767px) {
  .header {
    & .header_right .header_user a {
      width: 40px;
      & span {
        display: none;
      }
    }
    &.header_login {
      padding: 12px 20px;
      & img {
        width: 132px;
      }
      & .header_logo {
        margin-bottom: 28px;
      }
      & .header_cent {
        position: absolute;
        left: 20px;
        top: 42px;
      }
      &.header_login_reg {
        & .header_logo {
          margin-bottom: 0;
        }
        & .header_cent {
          position: relative;
          left: 0;
          top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .header {
    & .header_left {
      flex: 1 1 auto;
      & .header_logo {
        display: none;
      }
      & .header_cent {
        margin: 0 0 0 auto;
        & span {
          font-size: 0;
        }
      }
    }
    & .header_currency {
      margin-right: 14px;
      & a {
        font-size: 0;
      }
      & a + a {
        margin-left: 8px;
      }
    }
    & .header_right {
      & .header_ico {
        margin-right: 34px;
      }
      & .header_user a {
        margin-right: 20px;
      }
    }
    &.header_login {
      display: block;
      & .header_logo {
        margin-bottom: 0;
      }
      & .header_cent {
        position: relative;
        left: 0;
        top: 0;
        margin: 7px 0 14px;
        font-size: 12px;
      }
    }
  }
}