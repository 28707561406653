@import "partials/params";
@import "partials/mixins";

.card_tarif {
  padding: 18px 14px;
  border-radius: @radius-20;
  background: @primary-color;
  position: relative;
  border: 2px solid @primary-color;
  & .card_flex {
    display: flex;
    margin: 0 -10px -10px;
    padding-bottom: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    & > div {
      padding: 0 10px;
      width: 100%;
    }
    & .card_body_half {
      width: 50%;
      min-width: 50%;
      padding-bottom: 52px;
      & .tarif_foot {
        position: absolute;
        right: 0;
        width: 50%;
        bottom: 20px;
        padding: 0 10px;
      }
    }
  }
  & .sel_tarif {
    position: absolute;
    top: 18px;
    right: 14px;
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    border: 2px solid fade(@font-color, 30%);
    z-index: 1;
  }
  &.active {
    border: 2px solid @primary-color-bl400;
    & .sel_tarif {
      background: #fff;
      border: 5px solid @primary-color-main;
    }
  }
  & .tarif_logo {
    display: flex;
    align-items: center;
    font-size: 13px;
    & img {
      display: block;
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }
    .tarif_company {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
  & .tarif_title {
    color: @font-color;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin: 20px 0 13px;
  }
  & .tarif_foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    height: 32px;
    & .tarif_price {
      color: @font-color;
      font-size: 18px;
      font-weight: 800;
      line-height: normal;
      display: flex;
      & span {
        display: block;
        margin-left: 4px;
      }
    }
    & .tarif_more {
      display: block;
      &.active svg {
        transform: rotate(180deg);
      }
    }
  }
  & .tarif_dopinfo {
    margin-top: 20px;
    display: none;
    & .dopinfo_item {
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
      &:before {
        content: "";
        display: block;
        width: 18px;
        min-width: 18px;
        height: 18px;
        margin-right: 10px;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 9px;
      }
      &.tarif_yes:before {
        background-color: @font-mint-color;
        background-image: url(./img/icon/list_yes.svg);
      }
      &.tarif_orange:before {
        background-color: #FDA97A;
        background-image: url(./img/icon/list_orange.svg);
      }
      &.tarif_none:before {
        background-color: #9090A4;
        background-image: url(./img/icon/list_none.svg);
      }
      & + .dopinfo_item {
        margin-top: 6px;
      }
    }
  }
  &.card_tarif_one {
    overflow: auto;
    & .card_flex {
      & .card_body_half {
        width: 100%;
      }
    }
  }
}

.tarif_icon {
  display: flex;
  align-items: flex-start;
  color: #A2A2BA;
  margin: 0 0 0 auto;
  & div + div {
    margin-left: 12px;
  }
  & .ico_val {
    position: relative;
    & i {
      font-style: normal;
      position: absolute;
      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      color: #fff;
      font-size: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &.ico_gruz {
      margin-right: 2px;
      & i {
        width: 18px;
      }
      & span {
        position: absolute;
        right: -3px;
        top: 2px;
        font-size: 8px;
      }
    }
  }
  & .active {
    color: #47B795;
  }
}

.card_tarif_wide {
  & .card_body {
    display: flex;
    align-items: flex-end;
    margin: 0 -10px;
    width: calc(100% + 20px);
    padding-bottom: 3px;
    & .card_main {
      width: 50%;
      padding: 0 10px;
      & .tarif_foot {
        margin-top: 56px;
        height: auto;
      }
    }
    & .tarif_dopinfo {
      display: block !important;
      margin: 0;
      width: 50%;
      padding: 0 10px;
    }
  }
}

@media (max-width: 575px) {
  .card_tarif {
    & .card_flex {
      flex-wrap: wrap;
      & .card_body {
        position: relative;
        padding-bottom: 45px;
      }
      & .card_body_half {
        width: 100%;
        padding-bottom: 40px;
        & + .card_body_half {
          padding-bottom: 49px;
          margin-bottom: 2px;
        }
        & .tarif_foot {
          position: absolute;
          right: 0;
          width: 100%;
          bottom: 4px;
          padding: 0 10px;
          left: 0;
        }
      }
    }
    & .tarif_foot {
      position: absolute;
      bottom: -4px;
      left: 10px;
      right: 10px;
      margin: 0;
    }
    &.card_tarif_duo {
      &.active {
        & .sel_tarif {
          top: 50%;
          right: 27px;
        }
      }
    }
    &.card_tarif_wide {
      .card_body {
        flex-wrap: wrap;
        padding-bottom: 50px;
        & .card_main {
          width: 100%;
          & .tarif_foot {
            margin-top: 0;
            left: 20px;
            bottom: 0;
          }
        }
        & .tarif_dopinfo {
          width: 100%;
          margin-top: 25px;
        }
      }
    }
  }
}