@import "partials/params";
@import "partials/mixins";

input {
  font-family: 'Manrope', sans-serif;
}
.fake_wrap {
  position: relative;
  /*z-index: 20;*/
}
.form_input {
  border: 0;
  padding: 3px 14px;
  background: #fff;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  color: @font-color;
  &:focus {
    outline: none;
  }
}
.form_area {
  border-radius: 14px;
  border: 1px solid fade(@font-color, 30%);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 10px;
  background: transparent;
  font-family: 'Manrope', sans-serif;
  width: 100%;
  color: @font-color;
  height: 96px;
  resize: none;
  &:focus {
    outline: none;
  }
}
.simple-typeahead-input {
  font-family: 'Manrope', sans-serif;
  color: @font-color;
  &:focus {
    outline: none;
  }
}
.fake_text {
  display: flex;
  padding: 0 14px;
  font-weight: 500;
  line-height: 20px;
  & .fake_text_title {
    opacity: 0.6;
    width: 80%;
    .ellipsis;
  }
  & .fake_text_kod {
    opacity: 0.4;
    font-weight: 600;
    width: 20%;
    text-align: right;
  }
  & .search-input {
    width: 100%;
    height: 100%;
  }
}
.fake_input_placeholder {
  display: none;
}
.checkbox-container {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background: @primary-color-main url(./img/icon/ico_check2.svg) no-repeat center center;
      border: 0;
    }
  }
  &.checkbox-disable {
    & input {
      &:checked ~ .checkmark {
        background-color: fade(@font-color-dark, 30%);
      }
    }
    & .checkmark {
      background-color: fade(@font-color-dark, 30%);
    }
  }
  & .checkmark {
    position: relative;
    display: block;
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 10px;
    border: 1px solid @bg-bord-light;
    border-radius: 4px;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 2px;
      width: 4px;
      height: 8px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg)
    }
  }
}

.radio-container {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .radiomark {
      background: #fff;
      border: 5px solid @primary-color-main;
    }
  }
  & .radiomark {
    position: relative;
    display: block;
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 10px;
    border: 1px solid rgba(75, 70, 92, 0.2);
    border-radius: 50px;
  }
}

.search_form {
  position: relative;
  & .search_sub {
    position: absolute;
    right: 22px;
    top: 12px;
  }
}
.input_field {
  border-radius: @radius-btn;
  border: 1px solid fade(@font-color, 30%);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 13px 12px;
  background: transparent;
  font-family: 'Manrope', sans-serif;
  width: 100%;
  color: @font-color;
  .anim-fade;
  .ellipsis;
  &:focus, &:active, &.focused {
    outline: none;
    color: @font-gray-color;
    background: fade(@primary-color-main, 30%);
    border: 1px solid @primary-color-main;
  }
  &:hover {
    border: 1px solid fade(@primary-color-main, 40%);
  }
}

input[type="submit"] {
  border: 0;
  width: 100%;
  cursor: pointer;
}
button[type="submit"] {
  border: 0;
  cursor: pointer;
  font-family: "Manrope", sans-serif;
  line-height: 30px;
}

.input_error {
  display: none;
}
.sel_error {
  & .input_field {
    border: 2px solid @wings_red_400;
    padding: 12px 12px;
  }
  & .input_error {
    display: block;
    border-radius: 20px;
    background: @wings_red_400;
    padding: 0 8px;
    color: #FFFFFF;
    margin-top: 6px;
  }
}

.just_input {
  align-items: center;
  & .form_group {
    flex: 1 1 auto;
    & > div {
      background: #FFFFFF;
      border-radius: 14px;
    }
  }
  & .btn-icon {
    background: #FFFFFF;
  }
  & .checkbox-container {
    display: inline-flex;
  }
}
.modal_side_otchet {
  & .relat {
    position: static;
  }
  & .just_input {
    margin: 0 0 0 auto;
    & .btn-icon {
      background: @bg-main;
    }
    & .otchet_option {
      &.active {
        background: @primary-color-main;
      }
      & + .fake_dropdown {
        right: 0;
        margin-top: 100px;
        top: 0;
        height: calc(100% - 102px);
        min-height: 0;
        max-height: none;
      }
    }
    & .modal_close {
      background: fade(@primary-color-main, 50%);
      position: relative;
      top: 0;
      right: 0;
      display: block;
      &:hover {
        background: fade(@primary-color-main, 100%);
      }
    }
  }
}
.fields {
  .chosen-container.chosen-container-single {
    & .search-input {
      border: 0 !important;
    }
    & .chosen-single {
      border: 0 !important;
    }
  }
}
.chosen-container.chosen-container-single {
  & .search-input {
    width: 100%;
    border: 0 !important;
  }
  & .chosen-single {
    border-radius: @radius-btn !important;
    border: 1px solid fade(@font-color, 30%) !important;
    & > div {
      background: url(./img/icon/chevron-select.svg) no-repeat center center;
      width: 18px;
      height: 18px;
      right: 9px;
      top: 14px;
      & b {
        display: none;
      }
    }
  }
  & .chosen-drop {
    box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
    border-radius: 10px !important;
    border: 1px solid @bg-select !important;
    padding: 10px;
    margin-top: 10px;
  }
  & .chosen-results li.highlighted {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), @primary-color-main;
    border-radius: 14px;
    color: @font-gray-color;
  }
  &.chosen-container-active {
    & .chosen-single {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), @primary-color-main !important;
      color: @font-gray-color !important;
    }
    & .search-input {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), @primary-color-main !important;
      color: @font-gray-color !important;
      border: 1px solid @primary-color-main !important;
    }
  }
  & .search-input:focus {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), @primary-color-main !important;
    color: @font-gray-color !important;
    border: 1px solid @primary-color-main !important;
  }
}
.form_group {
  & .select2-container {
    display: block;
    width: 100% !important;
    & > div {
      display: block;
    }
  }
  & .select2-container--default .select2-selection--single {
    color: @font-gray-color;
    padding: 14px 12px;
    justify-content: space-between;
    border-radius: @radius-btn !important;
    border: 1px solid fade(@font-color, 30%);
    display: flex;
    align-items: center;
    height: auto;
    & .select2-selection__rendered {
      line-height: 18px;
      color: @font-gray-color;
      padding: 0;
    }
    & .select2-selection__arrow {
      background: url(./img/icon/chevron-select.svg) no-repeat center center;
      width: 18px;
      height: 18px;
      right: 9px;
      top: 14px;
      & b {
        display: none;
      }
    }
    &:focus, &:active, &.select2-container--open {
      outline: none;
      color: @font-gray-color;
      background: fade(@primary-color-main, 30%);
      border: 1px solid @primary-color-main;
    }
    &:hover {
      border: 1px solid fade(@primary-color-main, 40%);
    }
  }
}
.select2-dropdown {
  margin-top: 10px;
  box-shadow: -2px 6px 22px 0 rgba(162, 162, 186, 0.36);
  border-radius: 10px !important;
  border: 1px solid @bg-select !important;
  padding: 10px;
  & ul li.select2-results__option {
    padding: 12px 20px;
    &:hover, &.select2-results__option--highlighted.select2-results__option--selectable {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), @primary-color-main;
      border-radius: 14px;
      color: @font-gray-color;
    }
  }
}
