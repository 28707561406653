@import "partials/params";
@import "partials/mixins";


.card_tarif_wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 432px;
  padding-right: 10px;
}
.card_tarif_wrap_one {
  & .card_tarif_item {
    width: 25%;
    padding: 10px;
  }
  &.card_tarif_wrap_wide {
    & .card_tarif_item {
      width: 100%;
      & .tarif_more {
        display: none;
      }
    }
    & .card_tarif {
      & .card_body {
        display: flex;
        align-items: flex-end;
        margin: 0 -10px;
        width: calc(100% + 20px);
        padding-bottom: 3px;
        & .card_main {
          width: 50%;
          padding: 0 10px;
          & .tarif_foot {
            margin-top: 56px;
            height: auto;
          }
        }
        & .tarif_dopinfo {
          display: block !important;
          margin: 0;
          width: 50%;
          padding: 0 10px;
        }
      }
    }
  }
}
.card_tarif_wrap_duo {
  & .card_tarif_item {
    width: 50%;
    padding: 10px;
  }
  &.card_tarif_wrap_wide {
    & .card_tarif_item {
      width: 100%;
    }
  }
}

.tarif_switch.tabs-but {
  width: 100%;
  padding: 10px 0;
  margin-top: 25px;
  & a {
    padding: 5px 16px;
    font-size: 16px;
    white-space: normal;
    & .icon18 {
      display: inline-block;
      vertical-align: top;
      margin: 1px 3px;
    }
  }
}
.razdel_switch.tabs-but {
  width: 100%;
  padding: 10px 0;
  margin-top: 10px;
  border-bottom: 1px solid @primary-color-main;
  & a {
    border-radius: @radius-30;
    padding: 1px 6px;
    background: @primary-color-gray;
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    text-transform: uppercase;
    & + a {
      margin-left: 10px;
    }
  }
}
.rules_wrap {
  margin-top: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 249px);
  font-weight: 500;
  line-height: 20px;
  margin-right: -7px;
  padding-right: 17px;
}
.rules_item {
  & .rules_title {
    display: flex;
    justify-content: space-between;
    margin: 0 0 12px;
    font-size: 18px;
    font-weight: 800;
    line-height: normal;
  }
  & + .rules_item {
    margin-top: 24px;
  }
}


.modal_side.modal_side_detail {
  border: 0;
  & h5 {
    font-size: 22px;
  }
}
.detail_wrap {
  margin-top: 18px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 10px;
  max-height: calc(100vh - 119px);
}
.detail_item {
  & .detail_title {
    color: @font-color;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
  }
  & .detail_airport {
    .flex-center(row,space-between);
    padding: 12px 0;
    & .detail_airport_val {
      display: flex;
      align-items: center;
      & img {
        display: block;
        width: 30px;
      }
      & span {
        display: block;
        font-size: 13px;
        opacity: 0.8;
        margin-left: 8px;
      }
    }
    & .modal_rules {
      font-size: 13px;
    }
  }
  & .detail_reis {
    position: relative;
    padding-left: 20px;
  }
  & .detail_line {
    display: flex;
    padding-bottom: 11px;
    & .detail_time {
      color: @font-color;
      font-size: 18px;
      font-weight: 800;
      line-height: 24px;
      width: 75px;
    }
    & .detail_city {
      display: flex;
      line-height: 24px;
      & div {
        color: @font-color;
      }
      & span {
        opacity: 0.8;
        display: block;
        margin-left: 6px;
      }
    }
    & .detail_path {
      display: flex;
      line-height: 20px;
      margin-top: 13px;
      & div {
        color: @font-color;
      }
      & span {
        opacity: 0.8;
        display: block;
        margin-right: 6px;
      }
    }
    & .detail_more {
      margin-top: 12px;
      & .tarif_icon div + div {
        margin-left: 8px;
      }
    }
  }
  & .detail_from, .detail_to {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: -20px;
      /*width: 4px;
      height: 4px;*/
      width: 8px;
      height: 8px;
      top: 8px;
      background: #ffffff;
      border-radius: 4px;
      border: 2px solid @primary-color-main;
    }
  }
  & .detail_from {
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -17px;
      top: 17px;
      width: 2px;
      height: 60px;
      background: @primary-color-main;
    }
    &:after {
      top: 12px;
    }
  }
  & .detail_dopinfo {
    display: flex;
    padding: 0 0 4px 0;
    border-top: 1px solid @bg-main;
    font-size: 13px;
    margin-top: 9px;
    & .dopinfo_bagaz {
      margin-left: 20px;
    }
    & span {
      font-size: 10px;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
      opacity: 0.6;
    }
  }
}
.detail_peresad {
  margin: 30px 0 40px 0;
  display: flex;
  align-items: center;
  & span {
    display: block;
    opacity: 0.8;
    margin: 0 6px 0 20px;
  }
  & div {
    color: #404040;
  }
}
.detail_extr_buttons {
  width: 100%;
  padding: 30px 20px !important;
  align-items: flex-end;
  border-radius: @radius-20;
  background: @primary-color;
  margin-top: 30px;
  & .compact_price {
    display: block;
    margin: 0 auto 0 0;
  }
  & .extr_tarif {
    background: #ffffff;
    color: @primary-color-bl400;
    &:hover {
      background: @primary-color-bl400;
      color: #fff;
    }
  }
  & .extr_bron, .extr_tarif {
    width: calc(50% - 115px);
    text-align: center;
  }
  & .price_flex.price_flex_bold {
    color: #404040;
    font-size: 22px;
    font-weight: 800;
    line-height: 28px;
    margin-bottom: 0;
    margin-top: 10px;
  }
  & .gray_but {
    font-size: 13px;
  }
}

.modal_side_detail {
  & .search_card_wrap {
    display: none;
    max-height: none;
    & > div {
      margin: 22px -2.5px -8px;
    }
    & .search_card {
      width: 137px;
      min-width: 137px;
      padding: 0 2.5px;
      & > div {
        padding: 10px 6px;
      }
    }
    & .tarif_icon {
      margin: 0;
      justify-content: center;
      & div + div {
        margin-left: 4px;
      }
    }
    & .price_flex {
      text-transform: uppercase;
      color: @font-color;
      font-size: 13px;
      font-weight: 800;
      line-height: 18px;
      justify-content: center;
      margin-top: 9px;
      & span, i {
        width: auto;
      }
    }
  }
  .detail_extr_buttons {

    & .compact_price {
      margin: 0;
      width: 100%;
      & span, i {
        width: auto;
      }
    }
    & .extr_bron, .extr_tarif {
      width: calc(50% - 5px);
      margin-top: 9px;
    }
  }
}

.modal_side_detail {
  & .switch {
    margin-left: 0;
    left: auto;
    right: 60px;
    .btn-icon {
      & span {
        display: block;
        width: 16px;
        height: 24px;
        border-width: 2px;
        border-style: solid;
        border-radius: 3px;
        margin: 4px 8px;
        &.switch_niz {
          transform: rotate(90deg);
        }
      }
    }
  }
}


@media (max-width: 1439px) {
  .card_tarif_wrap_one {
    & .card_tarif_item {
      width: 33.3333%;
    }
  }
}
@media (max-width: 1299px) {
  .card_tarif_wrap {
    max-height: calc(100vh - 163px);
  }
}
@media (min-width: 1200px) {
  .modal_side_detail_six {
    & .modal_head {
      & h5 {
        font-size: 18px;
      }
    }
    & .modal_side_wrap {
      padding: 6px 30px 0 16px;
    }
    & .detail_wrap {
      margin-top: 20px;
      margin-bottom: 58px;
      position: relative;
      & .detail_wrap_items {
        padding: 0 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        border-radius: @radius-20;
        background: @primary-color;
      }
    }
    & .detail_item {
      width: calc(33.3333% - 132px);
      padding: 20px 10px;
      & .detail_airport {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        & .modal_rules {
          margin-top: 6px;
        }
      }
      & .detail_line {
        padding-bottom: 10px;
        & .detail_path {
          margin-top: 8px;
        }
        & .detail_more {
          display: none;
        }
      }
    }
    & .detail_peresad {
      width: 132px;
      padding: 20px 10px 20px 34px;
      flex-wrap: wrap;
      margin: 0;
      position: relative;
      background: url(./img/arrow_dots.svg) no-repeat bottom center;
      & span {
        margin: 0 0 0 8px;
      }
      & div {
        margin: 8px 0 0 8px;
      }
      & svg {
        position: absolute;
        left: 10px;
        top: 34px;
      }
    }
    & .detail_extr_buttons {
      margin: 20px 0 0;
      justify-content: flex-start;
      & .compact_price {
        margin: 0 30px 0 0;
        width: 190px;
        min-width: 190px;
      }
      & .extr_bron, .extr_tarif {
        width: 164px;
      }
    }
  }
}
@media (max-width: 1199px) {
  .modal_side_detail {
    & .switch {
      display: none;
    }
  }
  .modal_side.modal_side_detail_six {
    right: 0;
    left: auto;
  }
  .detail_wrap {
    max-height: calc(100vh - 110px);
  }
}
@media (max-width: 991px) {
  .card_tarif_wrap_one {
    & .card_tarif_item {
      width: 50%;
    }
  }
  .detail_extr_buttons {
    & .extr_bron, .extr_tarif {
      width: 109px;
    }
    .price_flex.price_flex_bold {
      & span, i {
        width: auto;
      }
    }
  }
}
@media (max-width: 767px) {
  .modal_side.modal_right {
    width: 100%;
    border-radius: 0;
    & .modal_side_wrap {
      padding: 4px 10px 4px 20px;
    }
    & .modal_head_tarif {
      margin-top: 0;
    }
  }
  .modal_side.modal_side_detail_six {
    width: 100%;
    border-radius: 0;
    & .modal_side_wrap {
      padding: 4px 10px 4px 20px;
    }
    & .modal_head_tarif {
      margin-top: 0;
    }
  }
  .razdel_switch.tabs-but {
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 8px;
    margin-bottom: -8px;
  }
  .tarif_switch.tabs-but {
    margin-top: 20px;
    & a {
      padding: 5px 14px;
      & + a {
        margin-left: 20px;
      }
    }
  }
  .rules_wrap {
    margin-top: 33px;
  }

  .modal_side.modal_side_detail {
    padding-top: 10px;
  }
  .detail_wrap {
    margin-top: 28px;
  }
  .detail_item {
    & .detail_airport {
      flex-wrap: wrap;
      padding: 18px 0 14px 0;
      & .modal_rules {
        font-size: 13px;
        margin-top: 17px;
      }
    }
    & .detail_more {
      display: none;
    }
  }
  .detail_peresad {
    margin: 18px 0 30px 0;
  }
  .modal_side_detail {
    & .search_card_wrap {
      display: flex;
    }
  }
}
@media (max-width: 575px) {
  .card_tarif_wrap_one {
    & .card_tarif_item {
      width: 100%;
    }
  }
  .detail_extr_buttons {
    flex-wrap: wrap;
  }
}









