@import "partials/params";
@import "partials/mixins";

.search_widget {
  padding: 30px 20px 0;
  border-radius: @radius-20;
  color: @font-gray-color;
  background: url(@img-fon-search) no-repeat center center / cover;
  & .tabs-but {
    & a + a {
      margin-left: 7px;
    }
  }
}
h1 + .search_widget {
  margin-top: 38px;
}
.search_service {
  display: flex;
  & .search_all {
    color: @primary-color-main;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 1px;
      height: 32px;
      background: @font-color;
      opacity: 0.3;
      position: absolute;
      top: 1px;
      left: 0;
    }
  }
}
.search_route {
  line-height: 20px;
  display: flex;
  align-items: center;
  color: @font-color-dark;
  margin: 0 0 20px;
  & svg {
    margin-right: 10px;
  }
}
.search_widget_dark {
  & .search_route {
    color: #FFFFFF;
  }
  & .search_service {
    & a {
      color: #FFFFFF;
    }
    & .search_all {
      color: @blue_200;
      &:before {
        background: fade(#fff, 30%);
      }
    }
  }
  & .view_filtr {
    color: @blue_200;
  }
  & .search_checkbox {
    & .checkmark {
      border-width: 2px;
      border-color: fade(#ffffff, 60%);
    }
    & input {
      &:checked ~ .checkmark {
        background: @primary-color-main url(./img/icon/ico_check2.svg) no-repeat center center;
        color: #fff;
        & svg {
          opacity: 0;
        }
      }
    }
    & .checkbox-container {
      color: #FFFFFF;
    }
  }
}
.fields {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  & .fields_line {
    width: 79.5%;
    .flex-center(row, space-between);
    border-radius: @radius-20 0 0 @radius-20;
    border-color: fade(@font-color, 30%);
    border-style: solid;
    border-width: 1px 0 1px 1px;
    background: #FFF;
    position: relative;
  }
  & .fields_direction {
    width: 62.5%;
  }
  .dates {
    width: 37.5%;
    & .form_input {
      border: 1px solid transparent;
      padding: 12px 12px;
      line-height: 36px;
      border-radius: 20px;

      &:focus, &:active, &.focused {
        border: 1px solid @primary-color-main;
      }

      &:hover {
        border: 1px solid fade(@primary-color-main, 40%);
      }
    }
  }
  & .fields_subm {
    width: 20.5%;
    position: relative;
    padding-right: 64px;
  }
  & .fields_hotels {
    width: 44.5%;
    & + .dates {
      width: 55.5%;
    }
  }

  & .pass-clas {
    background: #fff;
    border-color: fade(@font-color, 30%);
    border-style: solid;
    border-width: 1px 0 1px 0;
  }
  & .fields_add_line {
    display: none;
    & a {
      .flex-center;
      color: @primary-color-main;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      width: 100%;
      height: 64px;
      border-radius: @radius-20;
      border: 1px solid fade(@primary-color-main, 20%);
      background: #FFF;
    }
  }
  & .to_result {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 @radius-20 @radius-20 0;
    background: @color-side;
    width: 64px;
    height: 100%;
    color: #fff;
    cursor: pointer;
    .flex-center(row);
    & span {
      width: 0;
      overflow: hidden;
      display: block;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      color: #fff;
      .anim-fade;
    }
  }
  &.active {
    & .fields_line {
      width: 100%;
      border: 1px solid fade(@font-color, 30%);
      border-radius: @radius-20;
      height: 64px;
      & + .fields_line {
        margin-top: 10px;
      }
    }
    & .fields_direction {
      width: 66.5%;
    }
    & .dates {
      width: 33.5%;
    }
    & .fields_subm {
      margin: 10px -5px 0;
      width: calc(100% + 10px);
      display: flex;
      align-items: center;
      & > div {
        width: 33.3333%;
        padding: 0 5px;
      }
    }
    & .fields_pass {
      & .pass-clas {
        border-radius: @radius-20;
        border: 1px solid fade(@font-color, 30%);
      }
    }
    & .fields_add_line {
      display: block;
    }
    .fields_result {
      & a {
        position: relative;
        width: 100%;
        height: 64px;
        border-radius: @radius-20;
        & span {
          width: 132px;
        }
      }
    }
  }
}
.search_hotels {
  & .search_widget_form {
    padding-bottom: 20px;
  }
  & .fields {
    & .fields_line {
      width: 74%;
    }
    & .fields_subm {
      width: 26%;
    }
    & .to_result {
      background: @primary-color-main;
      &:hover {
        background: @primary-color-bl400;
      }
    }
  }
  & .hotel_room {
    color: fade(@font-color-dark, 60%);
    font-size: 12px;
    font-weight: 500;
    opacity: 0.7;
  }
}
.direction {
  position: relative;
}
.fields_direction {
  .flex-center(row, space-between);
  position: relative;
  & > div {
    width: calc(50% - 16px);
  }
  & .swap-directions {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 32px;
    height: 32px;
    padding: 4px;
    transform: translate(-50%, -50%);
    border-radius: 8px;
  }
}
.swap-directions {
  color: @font-icosh;
  background: @bg-icosh;
}
.fields_dates {
  .flex-center(row, space-between);
  position: relative;
  & > div {
    width: 50%;
    &.fake_date_all {
      width: 100%;
    }
  }
}
.remove_line {
  .flex-center;
  position: absolute;
  width: 42px;
  height: 64px;
  right: -1px;
  top: -1px;
  border-radius: 0 @radius-20 @radius-20 0;
  background: #FF9090;
  color: #ffffff;
  border-color: fade(@font-color, 30%);
  border-style: solid;
  border-width: 1px 1px 1px 0;
}
.pass-clas {
  & .form_input {
    padding-top: 0;
    padding-bottom: 0;
  }
  & #pass_comf {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
}
.search_widget_content {
  display: none;
  margin-top: 30px;
}
.search_widget_form {
  padding-bottom: 60px;
  position: relative;
  &.active {
    & .search_checkbox {
      & label:nth-child(n + 5) {
        opacity: 1;
        width: 25%;
        padding: 6px 10px;
        margin-top: 10px;
      }
    }
    & .view_filtr {
      & .filtr_text {
        & span {
          opacity: 0;
          max-height: 0;
          & + span {
            opacity: 1;
            max-height: 20px;
          }
        }
      }
      & svg {
        transform: rotate(180deg);
      }
    }
    & .search_card_wrap {
      max-height: 160px;
    }
  }
  & .input_field {
    margin: -1px 0;
    line-height: 36px;
    border-color: transparent;
    &:focus, &:active, &.focused {
      border: 1px solid @primary-color-main;
    }
    &:hover {
      border: 1px solid fade(@primary-color-main, 40%);
    }
  }
  & .fake_text {
    border: 1px solid transparent;
    padding: 12px 12px;
    line-height: 36px;
    border-radius: 14px;
    &:focus, &:active, &.focused {
      border: 1px solid @primary-color-main;
    }
    &:hover {
      border: 1px solid fade(@primary-color-main, 40%);
    }
    & .simple-typeahead {
      width: 80%;
      & input {
        border: 0;
        width: 100%;
        &:focus {
          outline: none;
        }
      }
    }
  }
  & .pass-clas {
    .fake_text {
      flex-wrap: wrap;
      & > div {
        color: #23232B;
        font-weight: 600;
        line-height: 18px;
        width: 100%;
        .ellipsis;
        &.pass_comf {
          opacity: 0.7;
          color: fade(@font-color, 60%);
        }
      }
    }
  }
}
.search_widget_form {
  & .form_error {
    border-radius: 0 0 20px 20px !important;
    border: 2px solid @wings_red_400 !important;
    background: #FFF;
    position: relative;
    margin-bottom: -2px;
    margin-top: -2px;
    &:before {
      content: attr(data-error);
      .ellipsis;
      position: absolute;
      top: -20px;
      left: -2px;
      border-radius: 8px 8px 0 0;
      background: @wings_red_400;
      right: -2px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #fff;
      padding: 0 8px;
    }
    & .form_input {
      border: 0 !important;
    }
  }
  &.has_error {
    & .fields {
      padding-top: 20px;
      &.active {
        padding-top: 0;
        & .fields_line {
          margin-top: 20px;
          & + .fields_line {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

.search_wrap {
  margin-top: 10px;
}
.search_checkbox {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 15px;
  overflow: hidden;
  .anim-fade;
  & .checkbox-container {
    width: 25%;
    padding: 6px 10px;
    margin-top: 10px;
    .anim-fade;
    &:nth-child(n + 5) {
      opacity: 0;
      width: 0;
      padding: 0;
      margin-top: 0;
    }
  }
  & .checkmark {
    & svg {
      opacity: 0;
    }
  }
  & input {
    &:checked ~ .checkmark {
      background: none;
      color: @primary-color-main;
      & svg {
        opacity: 1;
      }
    }
  }
}



.view_filtr_wrap {
  .flex-center;
  margin: 26px -20px 0;
  width: calc(100% + 40px);
  border-top: 2px solid fade(@blue_500, 10%);
  padding: 14px 20px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.view_filtr {
  color: @primary-color-main;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  & .filtr_text {
    .flex-center;
    & span {
      opacity: 1;
      max-height: 20px;
      .anim-fade;
      & + span {
        opacity: 0;
        max-height: 0;
      }
    }
  }
  & svg {
    margin-left: 10px;
    display: block;
  }
}

.filtr_edit_check {
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 4px;
  color: @primary-color-main;
}
.search_card_wrap {
  max-height: 0;
  overflow: hidden;
  .anim-fade;
  & > div {
    display: flex;
    margin: 20px -10px 5px;
    overflow-y: hidden;
    overflow-x: auto;
  }
  & .search_card {
    width: 25%;
    min-width: 25%;
    padding: 10px;
    & > div {
      border-radius: @radius-20;
      border: 1px solid @font-white-color;
      background: rgba(255, 255, 255, 0.80);
      padding: 16px;
      line-height: 20px;
      height: 100%;
      &:hover {
        border: 1px solid fade(@primary-color-main, 40%);
      }
    }
  }
  & .search_card_foot {
    display: flex;
    color: fade(@font-color, 40%);
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
    & .search_card_pass {
      margin-left: 20px;
    }
  }
}
.search_zd {
  & .fields {
    & .fields_line {
      width: calc(100% - 64px);
    }
    & .fields_subm {
      width: 64px;
    }
    & .to_result {
      background: @primary-color-main;
      &:hover {
        background: @primary-color-bl400;
      }
    }
    & .fields_direction {
      width: 50%;
    }
    & .dates {
      width: 50%;
    }
  }
  & .search_widget_form {
    padding-bottom: 5px;
  }
  & .search_card_wrap {
    max-height: none;
    margin-top: 22px;
  }
}
.direct_variants {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 5px;
  width: 100%;
  padding: 0 14px;
  color: @color-bl500-60;
  .ellipsis;
  & * {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: @color-bl500-60;
    cursor: pointer;
  }
}

.search__modal {
  & .search_widget_form {
    padding-bottom: 0;
  }
  & .fields {
    & .fields_line {
      width: 78.6%;
    }
    & .fields_subm {
      width: 21.4%;
      position: relative;
    }
    & .to_result {
      background: @primary-color-main;
      width: 100%;
      & span {
        width: auto;
      }
      & svg {
        display: none;
      }
    }
    & .fields_dir {
      width: 72.7%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      position: relative;
      & > div {
        width: calc(50% - 16px);
      }
      & .swap-directions {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        color: @font-icosh;
        width: 32px;
        height: 32px;
        padding: 4px;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        background: @bg-icosh;
      }
    }
    & .dates {
      width: 27.3%;
    }
  }
  & .fields_dates {
    & > div {
      width: 100%;
    }
    & .date-to {
      display: none;
    }
  }
  &.search__modal_two {
    & .fields {
      & .fields_line {
        width: 86.1%;
        background: #FFF;
        border-width: 1px 0 1px 1px;
        border-color: rgba(64, 64, 64, 0.3);
        border-style: solid;
      }
      & .fields_subm {
        width: 13.9%;
      }
      & .fields_dir {
        width: 66.1%;
      }
      & .dates {
        width: 33.9%;
      }
    }
    & .fields_dates {
      & > div {
        width: 50%;
      }
      & .date-to {
        display: block;
      }
    }
  }
}
.search__phone {
  display: none;
}
.table_new {
  margin-top: 30px;
  & .balans_accord {
    margin-top: 0;
    background: transparent;
    & .accord_head.side_title {
      background: @bg-accord;
      padding: 8px 12px;
      & .balans_accord_head {
        font-size: 15px;
        font-weight: 800;
        color: @font-color-dark;
      }
    }
    & + .balans_accord {
      margin-top: 20px;
    }
  }
}
.table_new_one {
  & .accord_head.side_title {
    display: none;
  }
}
.table_head {
  display: grid;
  grid-template-columns: 90fr 110fr 78fr 110fr 90fr 80fr 38fr 43fr;
  background: @primary-color-gray;
  border-radius: 20px;
  padding: 0 8px;
  & > div {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 8px 4px;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  & .sort {
    & a {
      background: url(./img/icon/chevron-up_white.svg) no-repeat 0 0;
    }
  }
}
.table_wrap {
  width: 100%;
  & .table_item {
    display: grid;
    grid-template-columns: 90fr 110fr 78fr 110fr 90fr 80fr 38fr 43fr;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: @font-gray-color;
    padding: 12px 8px;
    position: relative;
    & + .table_item {
      border-top: 1px solid @font-white-color;
    }
    & > div {
      padding: 4px;
    }
    & > div:nth-child(1) {
      & b {
        & span {
          display: block;
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
        }
      }
    }
  }
  & b {
    color: @font-gray-color;
    font-size: 15px;
    font-weight: 800;
    line-height: 18px;
    display: block;
    margin-bottom: 2px;
    & span {
      font-weight: 500;
      margin-left: 2px;
    }
  }
  & .table_price {
    text-align: right;
    & b {
      border-bottom: 1px dashed @primary-color-main;
      display: inline-block;
      margin: 0;
    }
  }
  & .name_station {
    font-size: 13px;
    font-weight: 800;
    border-bottom: 1px dashed @primary-color-main;
    & .name_station_sub {
      font-size: 12px;
      font-weight: 600;
    }
  }
  & .table_date {
    text-align: center;
    & span {
      display: block;
    }
    & .table_date_val {
      font-size: 13px;
    }
    & .table_linepoint {
      position: relative;
      width: calc(100% - 16px);
      margin: 8px 8px;
      height: 2px;
      background: @primary-color-main;
      &:after, &:before {
        content: "";
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        top: -1px;
        background: #ffffff;
        border-radius: 4px;
        border: 2px solid @primary-color-main;
      }
      &:after {
        left: -4px;
      }
      &:before {
        right: -4px;
      }
    }
  }
  & sup {
    color: #FF3A3A;
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
.table_new {
  & .table_wrap .table_item > div {
    & .logo_trans {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 800;
      line-height: 20px;
      margin-left: 0;
      & img {
        margin-right: 8px;
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
    }
  }
  & .table_price {
    & b {
      color: @primary-color-main;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
.table_time_txt {
  font-size: 22px;
  line-height: 28px;
  font-weight: 800;
  & span {
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
  }
}


.table_tarif {
  grid-column: 1 / span 8;
  padding: 10px 135px 10px 4px !important;
  & .table_tarif_btn {
    display: flex;
    align-items: center;
    & b {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }
    & span, a {
      color: @font-gray-color;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      text-transform: uppercase;
      padding: 0 10px;
      border-radius: 8px;
      background: fade(@font-gray-color, 10%);
      margin: 0 2px;
      white-space: nowrap;
    }
    & a {
      background: @font-white-color;
      color: @primary-color-main;
    }
  }
  & .table_scrol {
    /*margin-left: -8px;*/
    margin-left: 0;
  }
}
.table_icon {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  color: @primary-color-gray;
  margin: -5px;
  & svg {
    margin: 5px;
  }
  & + div, & + div + div {
    line-height: 25px;
  }
}

.table_item {
  & .table_vib_btn {
    position: absolute;
    right: 0;
    bottom: 12px;
    & svg {
      display: none;
    }
    & span {
      margin-left: 5px;
      & + span {
        display: none;
        margin-left: 5px;
      }
    }
  }
  &.selected, &:hover {
    background: @bg-select;
  }
  &.active {
    & .table_vib_btn {
      & .btn {
        background: @primary-color-main;
        color: #FFF;
        &:hover, &:focus {
          background: @primary-color-bl400;
        }
      }
      & svg {
        display: block;
      }
      & span {
        display: none;
        & + span {
          display: block;
        }
      }
    }
  }
}

.table_head_date {
  margin-left: 5px;
}
.grey_txt {
  color: fade(@font-color, 40%);
}
.grey_txt2 {
  color: fade(@font-color-dark, 30%);
}
.table_item {
  & .grey_txt {
    color: fade(@font-gray-color, 40%);
  }
}
.accord_ico {
  background: @primary-color-bl400;
  border-radius: 100px;
  padding: 10px;
  width: 44px;
  height: 44px;
  margin-right: 20px;
  color: #fff;
}
.accord_arrow_reis {
  margin: 0 8px;
}
.col6 > .accord_item > .modal_body > .modal_col_body {
  padding: 0 10px;
}
.side_filtr {
  & .side_title {
    position: relative;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    border-bottom: 1px solid @font-white-color;
  }
  & .reset_filtr {
    margin-right: 55px;
    font-size: 14px;
  }
  & .modal_head h5 {
    font-size: 14px;
    line-height: 20px;
  }
}

.modal_tabl_scroll {
  max-height: 260px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: -7px;
  padding-right: 7px;
}
.modal_tabl {
  border: 2px solid @font-white-color;
  border-radius: 20px;
  overflow: hidden;
  & .modal_tabl_item {
    padding: 0 16px;
    display: flex;
    & div {
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      padding: 12px 6px 11px 6px;
      &:nth-child(1) {
        width: 274px;
      }
      &:nth-child(2) {
        width: 80px;
      }
      &:nth-child(3) {
        width: 80px;
      }
      &:nth-child(4) {
        width: 94px;
      }
    }
    & + .modal_tabl_item {
      border-top: 1px solid @font-white-color;
    }
  }
  & .modal_tabl_head {
    background: @font-white-color;
    align-items: center;
    & div {
      font-size: 10px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 9px 6px;
    }
  }
}

.vg_zag {
  svg {
    color: @primary-color-main;
    padding: 0 12px;
    margin: 0;
    width: 42px;
    height: 29px;
  }
  & span {
    margin-left: 12px;
  }
  &.vg_zag_duo {
    & span {
      color: @onix_300;
    }
  }
}
.kviborymest {
  & .btn {
    max-width: 392px;
    margin: 0 auto;
    border-radius: 20px;
  }
}
.flex_title_sel1 {
  & > *:nth-child(-n+3) {
    border-bottom: 1px solid @font-color;
  }
}
.flex_title_sel2 {
  & > *:nth-child(n+3) {
    border-bottom: 1px solid @font-color;
  }
  & > span {
    border-bottom: 0 !important;
  }
}
.vagon_block {
  background: @blue_200;
  border-radius: 14px;
  & .vagon_block_item {
    display: grid;
    grid-template-columns: 317fr 230fr 211fr 230fr 168fr;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding: 16px 10px;
    & > div {
      padding: 8px 10px;
    }
    & + .vagon_block_item {
      border-top: 1px solid #FFFFFF;
    }
  }
  & .vagons_item .vagons_head {
    background: @bg-vagonfon;
  }
}
.but_sel_vagon {
  & a {
    background: @blue_300;
    border-radius: 14px;
    padding: 12px;
    height: 100%;
    color: @font-gray-color;
    & b {
      margin-left: 6px;
      font-size: 12px;
    }
  }
}
.vg_zaglav {
  display: flex;
  align-items: center;
  width: 90px;
  margin-right: 8px;
  & .vg_zaglav_ico {
    background: @primary-color-bl400;
    border-radius: 100px;
    padding: 7px;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    color: #fff;
  }
  & .vg_zaglav_num {
    font-size: 15px;
    font-weight: 800;
    line-height: normal;
    color: @font-color;
  }
}
.vg_zaglav_reis {
  & span + span {
    margin-left: 6px;
  }
}
.vagon_class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 18px;
  border-radius: 14px;
  border: 2px solid @bg-vagonfon;
  & .flex_group_but {
    & > .btn {
      display: none;
    }
  }
}
.vagon_span {
  display: flex;
  & span {
    background: @bg-main;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 5px 16px;
    border-radius: 12px;
    display: block;
    cursor: pointer;
    & + span {
      margin-left: 6px;
    }
    & b {
      font-weight: 800;
      color: @primary-color-main;
    }
    &:hover, &.active {
      color: #FFFFFF;
      background: @primary-color-main;
      & b {
        color: #FFFFFF;
      }
    }
  }
}
.line_zag_text_name {
  color: fade(@font-gray-color, 60%);
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 4px;
  .ellipsis;
}
.line_zag_text_value {
  font-size: 15px;
  font-weight: 800;
  line-height: normal;
  color: @font-color;
}
.vagons_item {
  border-radius: 14px;
  border: 2px solid @bg-vagonfon;
  display: flex;
  overflow: hidden;
  & .vagons_head {
    background: fade(@bg-vagonfon, 60%);
    padding: 6px 18px;
    width: 26.4%;
    & .table_icon {
      color: @font-onyx-color;
    }
  }
  & .vagons_body {
    width: 73.6%;
    overflow: hidden;
  }
  & .vagons_body_item {
    width: 100%;
    display: flex;
    padding: 6px 8px 11px;
    border-bottom: 1px solid @bg-vagonfon;
    & > div {
      padding: 4px 10px;
    }
    &:last-child {
      margin-bottom: -1px;
    }
    & .vagons_body_num {
      width: 14.1%;
    }
    & .vagons_body_place {
      width: 54.8%;
    }
    & .vagons_body_serv {
      width: 31.1%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & .vagons_body_serv_btn {
        margin-top: 8px;
        & .btn {
          margin: 0 0 0 auto;
        }
        & span {
          & + span {
            display: none;
          }
        }
        & svg {
          display: none;
          & + span {
            margin-left: 0;
          }
        }
      }
    }
    &.selected, &:hover {
      background: @bg-main;
    }
    &.active {
      & .vagons_body_serv {
        & .vagons_body_serv_btn {
          & .btn {
            background: @primary-color-main;
            color: #FFF;
            &:hover, &:focus {
              background: @primary-color-bl400;
            }
          }
          & span {
            display: none;
            & + span {
              display: block;
              margin-left: 4px;
            }
          }
          & svg {
            display: block;
            & + span {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  & .table_tarif .table_tarif_btn {
    flex-wrap: wrap;
    & span, a {
      margin: 2px;
    }
  }
  & .row {
    margin: 0 -4px;
    & > div {
      padding: 4px;
    }
    & .col12 {
      margin-top: 8px;
    }
  }
  & + .vagons_item {
    margin-top: 8px;
  }
  &.vagons_item_sel {
    flex-wrap: wrap;
    & .vagons_head {
      width: 100%;
      & .row {
        justify-content: space-between;
        & > div {
          width: auto;
          margin-top: 0;
        }
      }
    }
    & .vagons_body {
      width: 100%;
    }
    & .vagons_body_place {
      width: 46%;
    }
    & .vagons_body_serv {
      width: 39.9%;
    }
  }
}
.vagons_item_mesta {
  flex-wrap: wrap;
  & .vagon_block {
    border-radius: 12px 12px 0 0;
  }
  & .vagons_head {
    border-radius: 0 0 14px 14px;
    width: 100%;
  }
  & .vagons_body {
    width: 100%;
  }
}
.vagons_check {
  display: flex;
  align-items: center;
  & .pay_group {
    margin: 0 0 0 24px;
    & label {
      width: auto;
    }
  }
}
.but_vagon_wrap {
  display: flex;
  margin: 0 -4px;
  & button {
    background: @bg-main;
    cursor: pointer;
    width: 92px;
    min-width: 92px;
    height: 54px;
    padding: 10px 14px;
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    border-radius: 14px;
    margin: 0 4px;
    text-align: left;
    & span {
      color: fade(@font-color, 40%);
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      text-transform: uppercase;
      display: block;
      margin-top: 2px;
    }
    &.active {
      background: @primary-color-main;
      color: #FFF;
      & span {
        color: rgba(255, 255, 255, 0.80);
      }
    }
    &:hover {
      background: @primary-color-bl400;
      color: #FFF;
      & span {
        color: rgba(255, 255, 255, 0.80);
      }
    }
  }
}

.vagons_place_price {
  display: flex;
  justify-content: space-between;
  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    & span {
      display: block;
      margin-left: 4px;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      white-space: nowrap;
      & b {
        font-weight: 800;
      }
    }
  }
}
.flex_title_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search__wrap + .flex_title_head {
  padding-top: 20px;
}
.line_dash {
  border-bottom: 1px dashed @primary-color-main;
}
.check_group {
  display: flex;
  margin: 0 -10px;
  padding: 10px 0;
  & label {
    padding: 5px 10px;
  }
  &.check_group_trio {
    & label {
      padding: 5px 8px;
    }
  }
}
.vagons_row_place {
  & .vagons_body_item {
    border-bottom: 0;
    align-items: center;
    &.salon_filter {
      flex-wrap: wrap;
      padding: 11px 8px 6px 8px;
      & .salon_filter_dp {
        display: flex;
        align-items: center;
        max-width: 100%;
        flex: 1 1 auto;
      }
    }
  }
  & .check_group {
    max-width: 384px;
    width: 100%;
    &.check_group_trio {
      max-width: none;
      width: auto;
    }
  }
}
.razm_group {
  & > div  {
    width: 206px;
    padding: 0 3px;
  }
  & .fake_select  {
    color: @font-gray-color;
    padding: 14px 12px;
    justify-content: space-between;
    border-radius: 14px;
    border: 1px solid fade(@font-color, 30%);
  }
  &.razm_group_trio {
    & > div  {
      width: 169px;
    }
  }
}
.vagons_diapazon {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
  & > * {
    padding-left: 8px;
  }
  & .form_group {
    width: 68px;
    & .input_field {
      text-align: center;
    }
  }
  & b {
    width: 87px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
  }
  & span {
    color: @primary-color-main;
  }
}

.salon {
  display: flex;
  width: 1236px;
  height: 165px;
  padding: 0.4px 10px 7px 69px;
  background: url(./img/storey.png) no-repeat 0 0 / contain;
  & .salon_group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 160px;
    width: 263px;
    & .seat {
      position: relative;
      cursor: pointer;
      width: 27px;
      height: 27px;
      margin-right: 26px;
      margin-top: 6.26px;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      padding: 4px 5px 0 0;
      z-index: 1;
      font-family: Arial;
      text-align: right;
      &:nth-child(4n+3) {
        margin-top: 32.24px;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(./img/seat/seat_norm.svg) no-repeat 0 0;
        z-index: -1;
      }
      &.seat_free:before {
        background: url(./img/seat/seat_free.svg) no-repeat 0 0;
      }
      &.seat_clos {
        font-size: 0;
        &:before {
          background: url(./img/seat/seat_clos.svg) no-repeat 0 0;
          font-size: 0;
        }
      }
      &.seat_nofilt:before {
        background: url(./img/seat/seat_nofilt.svg) no-repeat 0 0;
      }
      &.seat_sel {
        color: #fff;
        &:before {
          background: url(./img/seat/seat_sel.svg) no-repeat 0 0;
        }
      }
      &.seat_empty:before {
        content: none;
      }
    }
    &.salon_group2 {
      margin-left: 57px;
      width: 451px;
      & .seat {
        margin-right: 32px;
        padding: 2px 5px 0 0;
        &:before {
          transform: scaleX(-1);
        }
      }
    }
    &.salon_group3 {
      margin-left: -3px;
      width: 162px;
      & .seat {
        margin-right: 28px;
      }
    }
    &.salon_group4 {
      margin-left: 62px;
      width: 76px;
      & .seat {
        margin-right: 10px;
        &:before {
          transform: scaleX(-1);
        }
      }
    }
  }
}
.salon_legend {
  display: flex;
  margin: 14px -6px 0;
  width: 560px;
  & span {
    display: flex;
    align-items: center;
    padding: 4px 20px 4px 6px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    &:before {
      content: "";
      width: 18px;
      height: 18px;
      margin-right: 10px;
      border-radius: 4px;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
.salon_legend_free:before {
  background: @mint_400;
}
.salon_legend_close:before {
  background: fade(@font-color,40%);
}
.salon_legend_sel:before {
  background: @primary-color-main;
}
.salon_legend_nofilt:before {
  background: @Peach_300;
}
.select_vagon {
  display: none;
}

.salon_alert {
  width: 100%;
  & .salon_alert_inf {
    border-radius: 14px;
    border: 1px dashed @font-gray-color;
    background: fade(@font-color, 10%);
    display: flex;
    height: 165px;
    padding: 0 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }
}

.search_area {
  border: 1px solid fade(@font-gray-color, 30%);
  padding: 11px 11px;
  display: flex;
  border-radius: @radius-btn;
  & input {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border: 0;
    outline: none;
    padding: 0;
    height: 24px;
  }
  & button {
    width: 24px;
    min-width: 24px;
    margin-left: 6px;
    background: none;
    border: 0;
    outline: none;
    padding: 0;
  }
}

.alpha_wrap {
  & .alpha_head {
    overflow: hidden;
    border-radius: @radius-btn @radius-btn 0 0;
    background: @primary-color-gray;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    & a {
      padding: 19px 12px;
      color: #FFFFFF;
      display: block;
      &.active, &:hover {
        background: fade(@font-gray-color, 30%);
        border-radius: @radius-btn;
      }
    }
  }
  & .alpha_body {
    padding: 6px 12px;
    border: 1px solid fade(@font-gray-color, 30%);
    border-radius: 0 0 20px 20px;
    display: flex;
    & .alpha_zag {
      width: 157px;
      min-width: 157px;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 0 4px;
      height: 32px;
      display: flex;
      align-items: center;
    }
    & .alpha_alfavit {
      display: flex;
      overflow: hidden;
      margin-right: 6px;
      & > * {
        cursor: pointer;
        margin-left: 6px;
        padding: 6px 8px;
        border-radius: 12px;
        background: @bg-dr-main;
        font-size: 14px;
        font-weight: 800;
        line-height: 20px;
        text-transform: uppercase;
        color: @primary-color-main;
        &.active, &:hover {
          background: @primary-color-main;
          color: #FFFFFF;
        }
      }
    }
    & .alpha_reset {
      cursor: pointer;
      width: 142px;
      min-width: 142px;
      margin: 0 0 0 auto;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      padding: 0 8px;
      height: 32px;
      display: flex;
      align-items: center;
    }
    & .alpha_more {
      cursor: pointer;
      width: 30px;
      min-width: 30px;
      border-radius: 12px;
      margin: 0 0 0 auto;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      padding: 0;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: @bg-dr-main;
      color: @primary-color-main;
      &.active, &:hover {
        background: @primary-color-main;
        color: #FFFFFF;
      }
    }
    & .alpha_hide {
      display: none;
      margin-left: 6px;
      background: none;
      color: fade(@font-color-dark, 30%);
      width: auto;
      min-width: 0;
      &:hover {
        background: none;
        color: fade(@font-color-dark, 30%);
      }
    }
    &.active {
      & .alpha_alfavit {
        flex-wrap: wrap;
        margin-top: -6px;
        & > * {
          margin-top: 6px;
        }
      }
      & .alpha_more {
        display: none;
      }
      & .alpha_hide {
        display: flex;
      }
    }
  }
}

@media (max-width: 1199px) {
  .search_widget {
    margin-top: 0;
  }
  .fields:not(.active) {
    & .fields_line {
      width: 100%;
      flex-wrap: wrap;
      border: 0;
      background: none;
      & .fields_direction {
        width: 100%;
        height: 64px;
        border-radius: @radius-20;
        border-color: fade(@font-color, 30%);
        border-style: solid;
        border-width: 1px;
        background: #FFF;
        margin-bottom: 2px;
        & + .dates {
          width: 60%;
        }
      }
      & .fields_dates {
        height: 64px;
        padding-left: 0;
        border-radius: @radius-20 0 0 @radius-20;
        border-color: fade(@font-color, 30%);
        border-style: solid;
        border-width: 1px 0 1px 1px;
        background: #FFF;
      }
    }
    & .fields_subm {
      width: 40%;
      position: absolute;
      left: 60%;
      top: 66px;
      & .pass-clas {
        /*border-radius: 0 @radius-20 @radius-20 0;*/
        & .form_input {
          padding: 0;
        }
      }
    }
  }
  .fields.active {
    & .fields_line + .fields_line {
      margin-top: 2px;
    }
    & .fields_subm {
      margin-top: 2px;
    }
  }
  .search_hotels {
    & .fields:not(.active) {
      & .fields_line {
        width: 66%;
        background: #FFF;
        border-width: 1px 0 1px 1px;
        border-color: rgba(64, 64, 64, 0.3);
        border-style: solid;
      }
      & .fields_dates {
        border: 0;
        height: 62px;
      }
      .fields_subm {
        width: 34%;
        position: static;
        & .pass-clas {
          border-radius: 0;
        }
      }
    }
  }
  .search_zd {
    & .fields:not(.active) {
      & .fields_line {
        width: calc(100% - 64px);
        border-width: 1px;
        background: #fff;
        & .fields_direction {
          width: 50%;
          border-radius: 20px 0 0 20px;
          margin-bottom: 0 !important;
          border-width: 0;
        }
        & .dates {
          width: 50%;
        }
      }
      & .fields_subm {
        width: 64px;
        position: relative;
        top: 0 !important;
        left: auto;
      }
    }
  }
  .search_checkbox {
    & .checkbox-container {
      width: 50%;
      margin-top: 2px;
    }
  }
  .search_widget_form {
    padding-bottom: 58px;
  }
  .search_widget_form.active .search_checkbox label:nth-child(n+5) {
    width: 50%;
  }
  .search_wrap {
    margin-top: 19px;

  }
  .search_card_wrap {
    & > div {
      overflow: auto;
      padding-bottom: 8px;
      margin-bottom: -8px;
    }
    & .search_card {
      width: 245px;
      min-width: 245px;
    }
  }
  .has_error {
    & .fields:not(.active) {
      & .fields_direction {
        margin-bottom: 22px;
      }
      & .fields_subm {
        top: 106px;
      }
    }
  }
  .search__modal .fields:not(.active) {
    & .fields_dates {
      border: 0;
      height: 62px;
    }
    & .fields_subm {
      width: 13.9%;
      position: relative;
      left: 0;
      top: 0;
    }
  }
  .table_new .table_price b {
    font-size: 15px;
    line-height: 20px;
  }
  .table_time_txt {
    font-size: 18px;
    line-height: 24px;
  }

  .table_wrap .table_item .table_icon {
    & + div, & + div + div {
      line-height: 21px;
    }
  }
}
@media (min-width: 576px) and (max-width: 1439px) {
  .fields.active .fields_dates .date {
    display: none;
  }
  .fake_date_all {
    display: block !important;
    width: 100% !important;
  }
}
@media (max-width: 991px) {
  .search_hotels {
    & .fields:not(.active) {
      .fields_line {
        width: 100%;
        background: none;
        border: none;
      }
      & .fields_hotels {
        width: 100%;
        height: 64px;
        border-radius: 20px;
        border-color: rgba(64, 64, 64, 0.3);
        border-style: solid;
        border-width: 1px;
        background: #FFF;
        margin-bottom: 2px;
        & + .dates {
          width: calc(66.6666% - 42px);
          & .fields_dates {
            border-radius: 20px 0 0 20px;
            border-color: rgba(64, 64, 64, 0.3);
            border-style: solid;
            border-width: 1px 0 1px 1px;
          }
          & .form_input {
            padding: 11px 12px;
          }
        }
      }

      & .fields_subm {
        width: calc(33.3333% + 40px);
        position: absolute;
        top: 66px;
        left: calc(66.6666% - 42px);
        & .pass-clas {
          border-width: 1px 0 1px 0;
          & .fake_text {
            padding: 11px 12px;
          }
        }
      }
    }
    & .has_error {
      & .fields:not(.active) {
        & .fields_hotels {
          margin-bottom: 22px;
        }
        & .fields_subm {
          top: 106px;
        }
      }
    }
  }
  .search_zd {
    & .fields:not(.active) {
      & .fields_line {
        width: 100%;
        border: 0;
        background: transparent;
        & .fields_direction {
          width: 100%;
          border-radius: 20px;
          border-width: 1px;
          margin-bottom: 40px !important;
        }
        & .dates {
          width: 100%;
          padding-right: 64px;
        }
      }
      & .fields_subm {
        position: absolute;
        left: calc(100% - 64px);
        top: 104px !important;
        width: 64px;
        height: 64px;
      }
    }
    & .has_error {
      & .fields:not(.active) {
        & .fields_direction {
          margin-bottom: 50px !important;
        }
        & .fields_subm {
          top: 134px !important;
        }
      }
    }
  }
  .search__modal {
    display: none;
  }
  .search__phone {
    display: flex;
    align-items: center;
    background: @primary-color;
    padding: 12px 20px;
    margin: 0 -14px 4px;
    & .search_phone_data {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 52px);
      background: #FFFFFF;
      border-radius: 8px;
      padding: 11px 20px;
      & .search_phone_data_title {
        color: @primary-color-main;
        display: flex;
        & svg {
          margin: 0 3px;
        }
      }
      & .search_phone_data_date {
        font-weight: 500;
      }
    }
    & .search_phone_btn {
      display: flex;
      margin-left: 11px;
      & a {
        display: block;
        margin-left: 9px;
        width: 32px;
        height: 32px;
        color: @font-gray-color;
      }
    }
  }
  .table_head {
    display: none;
  }
  .table_wrap {
    & .table_item {
      grid-template-columns: repeat(3, 1fr);
      padding: 0;
      & .table_tarif {
        grid-column: 1 / span 3;
      }
      & > div {
        background: @primary-color;
        &:nth-child(n+5) {
          background: #FFFFFF;
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
      & > div:nth-child(1) {
        grid-column: 1 / span 3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 12px 10px 12px;
        & b {
          display: flex;
          align-items: center;
          & span {
            margin-left: 25px;
          }
        }
      }
      & > div:nth-child(5) {
        grid-column: 1 / span 3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 12px;
      }
      & > div:nth-child(2) {
        padding-left: 12px;
        padding-bottom: 16px;
      }
      & > div:nth-child(4) {
        padding-right: 12px;
        padding-bottom: 16px;
      }
      & > div:nth-child(2), > div:nth-child(6) {
        padding-left: 12px;
      }
      & > div:nth-child(4), > div:nth-child(8) {
        padding-right: 12px;
      }
      & > .table_tarif {
        padding: 8px 135px 16px 12px !important;
        border-top: 1px solid @font-white-color;
      }
    }
    & .table_icon {
      margin: 0 -5px;
    }
    & .table_price {
      text-align: right;
    }
    & .table_tarif_btn {
      & b {
        font-size: 10px !important;
        line-height: 12px !important;
        letter-spacing: 1px;
        text-transform: uppercase;
        width: 75px;
        min-width: 75px;
      }
      & span, a {
        padding: 8px 10px !important;
      }
    }
  }
  .table_item .table_vib_btn {
    bottom: 10px;
    padding: 4px !important;
  }
  .font_22 {
    font-size: 18px;
  }
  .vg_zaglav_reis {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .vagon_block {
    & .vagon_block_item {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 16px 2px;
      & > div:nth-child(1), & > div:nth-child(5) {
        grid-column: 1 / span 3;
      }
    }
  }
  .vagons_item {
    flex-wrap: wrap;
    & .vagons_head {
      width: 100%;
      padding: 6px 10px;
    }
    & .vagons_body {
      width: 100%;
    }
    & .vagons_body_item {
      padding: 6px 0 11px;
      & .vagons_body_num {
        display: none;
      }
      & .vagons_body_place {
        width: 60.8%;
      }
      & .vagons_body_serv {
        width: 39.2%;
      }
    }
    & .row {
      & .col12 {
        margin-top: 0;
      }
      & > div {
        &:nth-child(1) {
          width: 11.5%;
        }
        &:nth-child(2) {
          width: 11.8%;
        }
        &:nth-child(3) {
          width: 27.1%;
        }
        &:nth-child(4) {
          width: 100%;
          order: 1;
        }
        &:nth-child(5) {
          width: 49.2%;
        }
      }
    }
    & .table_tarif .table_tarif_btn {
      flex-wrap: nowrap;
    }
    &.vagons_item_sel {
      & .vagons_head .row > div {
        &:nth-child(1) {
          width: 11.5%;
        }
        &:nth-child(2) {
          width: 11.8%;
        }
        &:nth-child(3) {
          width: 27.1%;
        }
        &:nth-child(4) {
          width: 100%;
        }
        &:nth-child(5) {
          width: 49.2%;
        }
      }
    }
    & .table_tarif .table_tarif_btn {
      display: flex;
      white-space: nowrap;
      & span {
        background: fade(@font-color, 10%);
        border-radius: 8px;
        font-size: 10px;
        line-height: 12px;
        padding: 8px 10px;
      }
    }
  }
  .vagon_class {
    & .flex_group_but {
      width: auto;
      margin-top: 0;
      & > .btn {
        display: block;
        width: auto;
        margin: 0;
      }
      & > div {
        display: none;
      }
    }
  }
  .vagons_row_place {
    & .vagons_body_item {
      &.salon_filter  {
        padding: 11px 0 6px;
        border-top: 1px solid @onix_200;
        & .salon_filter_dp {
          margin-top: 10px;
          margin-bottom: -5px;
        }
      }
    }
  }
  .vagons_diapazon b {
    width: 68px;
    font-size: 10px;
    font-weight: 800;
    line-height: 12px;
  }

}
@media (max-width: 767px) {
  .fields:not(.active) {
    & .fields_line {
      padding-bottom: 2px;
      padding-top: 0;
      & .fields_direction + .dates {
        width: 100%;
      }
      & .fields_dates {
        border-radius: @radius-20;
        border-width: 1px;
      }
    }
    & .fields_subm {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      & .pass-clas {
        border-radius: 20px 0 0 20px;
        border-width: 1px 0 1px 1px;
      }
    }
  }
  .fields.active {
    & .fields_line {
      padding: 0;
    }
  }
  .search_widget_form .fake_text {
    border-radius: 20px;
  }
  .fields .dates .form_input {
    border-radius: 20px;
  }
  .search_hotels {
    & .fields:not(.active) {
      & .fields_line {
        width: 100%;
        background: none;
        border: none;
      }
      & .fields_hotels {
        width: 100%;
        height: 64px;
        border-radius: 20px;
        border-color: rgba(64, 64, 64, 0.3);
        border-style: solid;
        border-width: 1px;
        background: #FFF;
        margin-bottom: 2px;
        & + .dates {
          width: calc(66.6666% - 42px);
          & .fields_dates {
            border-radius: 20px 0 0 20px;
            border-color: rgba(64, 64, 64, 0.3);
            border-style: solid;
            border-width: 1px 0 1px 1px;
          }
          & .form_input {
            padding: 11px 12px;
          }
        }
      }

      & .fields_subm {
        width: calc(33.3333% + 43px);
        position: absolute;
        top: 66px;
        left: calc(66.6666% - 42px);
        & .pass-clas {
          border-width: 1px 0 1px 0;
          & .fake_text {
            padding: 11px 12px;
          }
        }
      }
    }
  }
  .search_zd {
    & .fields:not(.active) {
      & .fields_line {
        & .fields_dates {
          border-radius: 20px 0 0 20px;
        }
      }
    }
  }
  .search_service {
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 8px;
    margin-bottom: -8px;
  }
  h1 + .search_widget {
    margin-top: 20px;
  }
  .has_error {
    & .fields:not(.active) {
      & .fields_subm {
        top: 0;
      }
    }
  }
  .search__phone {
    margin: 0 -20px 4px;
  }
  .side_filtr .col6 {
    width: 50%;
  }

  .modal_tabl_scroll {
    max-height: 348px;
  }
  .modal_side.modal_mob_bottom {
    height: auto;
    top: auto;
    bottom: 0;
    transform: translate(-50%, 0%);
    border-radius: 30px 30px 0 0;
    & .modal_head {
      margin-top: 28px;
      margin-bottom: -15px;
    }
    & .modal_close {
      width: 67px;
      height: 5px;
      border-radius: 100px;
      background: @font-white-color;
      left: 50%;
      right: auto;
      top: -25px;
      transform: translate(-50%,0%);
      & svg {
        display: none;
      }
    }
  }
  .modal_tabl {
    & .modal_tabl_head {
      display: none;
    }
    & .modal_tabl_item {
      flex-wrap: wrap;
      padding: 0 14px;
      & div {
        padding: 3px 4px 4px;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:before {
          margin-bottom: 2px;
          font-size: 10px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: fade(@font-color, 60%);
          white-space: nowrap;
        }
        &:nth-child(1) {
          height: 24px;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: calc(100% + 28px);
          background: @font-white-color;
          margin: 0 -14px;
          padding: 2px 14px 3px;
          &:before {
            content: "станция";
            margin-right: 8px;
            display: inline;
            margin-bottom: 0;
          }
        }
        &:nth-child(2) {
          width: 33.3333%;
          &:before {
            content: "прибытие";
          }
        }
        &:nth-child(3) {
          width: 33.3333%;
          &:before {
            content: "стоянка, мин.";
          }
        }
        &:nth-child(4) {
          width: 33.3333%;
          &:before {
            content: "отправление";
          }
        }
      }
    }
  }
  .font_22 {
    font-size: 15px;
  }
  .flex_title_head {
    & .btn-icon-text {
      padding: 7px 12px;
      & span {
        display: none;
        & + svg {
          margin-left: 0;
        }
      }
    }
  }
  .vagons_item {
    & .vagons_body_item {
      flex-wrap: wrap;
      & .vagons_body_place {
        width: 100%;
        & .flex_group_but {
          margin-top: 0;
        }
      }
      & .vagons_body_serv {
        width: 100%;
        flex-direction: row;
        & .vagons_body_serv_btn {
          margin-top: 14px;
        }
      }
    }
    & .row {
      & > div {
        &:nth-child(1) {
          width: 18%;
        }
        &:nth-child(2) {
          width: 18.4%;
        }
        &:nth-child(3) {
          width: 26.5%;
        }
        &:nth-child(5) {
          width: 37.1%;
        }
      }
    }
    & .table_tarif .table_tarif_btn span {
      padding: 8px 10px;
    }
  }
  .vagon_span {
    max-width: calc(100% - 52px);
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 8px;
    margin-bottom: -8px;
    & span {
      white-space: nowrap;
    }
  }
  .vg_zag svg {
    padding: 0 4px;
    width: 26px;
  }
  .font_22 b + span {
    margin-left: 4px;
  }
  .vagons_row_place {
    & .vagons_body_item {
      &.salon_filter  {
        padding: 6px 0 6px;
        & .salon_filter_dp {
          margin-top: 10px;
          margin-bottom: -5px;
        }
      }
    }
  }
  .razm_group {
    width: calc(100% - 240px);
    & > div {
      width: 50%;
    }
    &.razm_group_trio {
      & > div  {
        width: 33.3333%;
      }
    }
  }
  .salon_filter_dp {
    flex-wrap: wrap;
    & .razm_group_trio {
      width: 100%;
      & + .vagons_diapazon {
        width: 100%;
        margin-top: 8px;
        & b {
          width: 95px;
          padding-left: 0;
        }
        & .form_group {
          width: calc(50% - 60px);
        }
      }
    }
  }
  .salon_alert {
    & .salon_alert_inf {
      height: auto;
      padding: 30px;
    }
  }
  .alpha_wrap .alpha_body .alpha_zag {
    width: 92px;
    min-width: 92px;
  }
}
@media (max-width: 575px) {
  .search_widget {
    padding: 22px 10px 0;
  }
  .search_widget_form {
    padding-bottom: 48px;
  }
  .search_route {
    margin: 20px 0 8px;
  }
  .search_wrap {
    margin-top: 8px;
  }
  .view_filtr_wrap {
    margin: 26px -10px 0;
    width: calc(100% + 20px);
  }
  .fields:not(.active) {
    & .fields_line {
      & .fields_direction {
        border: none;
        background: none;
        flex-wrap: wrap;
      }
    }
  }
  .search_checkbox {
    & .checkbox-container {
      width: 100%;
    }
  }
  .search_widget_form.active .search_checkbox label:nth-child(n+5) {
    width: 100%;
  }
  .fields.active {
    & .fields_line {
      border: none;
      height: auto;
      background: none;
      flex-wrap: wrap;
    }
    & .fields_direction {
      width: 100%;
      flex-wrap: wrap;
    }
    & .dates {
      width: 100%;
    }
    & .fields_dates {
      width: 100%;
      flex-wrap: wrap;
      padding: 0;
      & > div {
        width: 100%;
        height: 64px;
        border-radius: @radius-20;
        border: 1px solid fade(@font-color, 30%);
        background: #ffffff;
        margin-top: 2px;
        display: flex;
        align-items: center
      }
    }
    & .fields_subm {
      margin: 2px 0 0;
      flex-wrap: wrap;
      width: 100%;
      position: relative;
      & > div {
        width: 100%;
        padding: 0;
      }
      & .fields_result a {
        position: absolute;
        width: 64px;
        border-radius: @radius-20;
        top: auto;
        bottom: 0;
        & span {
          display: none;
        }
      }
    }
    & .fields_add_line {
      margin-top: 2px;
    }
  }

  .fields_direction {
    height: auto !important;
    & > div {
      width: 100%;
      height: 64px;
      border-radius: @radius-20;
      border: 1px solid fade(@font-color, 30%);
      background: #ffffff;
      display: flex;
      align-items: center;
      margin-top: 2px;
      & .fake_input {
        width: 100%;
      }
    }
    & .swap-directions {
      display: none;
    }
  }
  .search_hotels {
    & .fields:not(.active) {
      & .fields_hotels + .dates {
        width: 100%;
        & .fields_dates {
          border-radius: 20px;
        }
      }
      & .fields_subm {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        & .pass-clas {
          border-width: 1px 0 1px 1px;
          border-radius: 20px 0 0 20px;
        }
      }
    }
    & .has_error {
      & .fields:not(.active) {
        & .fields_subm {
          top: 0;
        }
      }
    }
  }
  .search_zd {
    & .fields:not(.active) {
      & .fields_subm {
        top: 210px !important;
      }
      & .direction + .direction {
        margin-top: 40px;
      }
    }
    & .has_error {
      & .fields:not(.active) {
        & .fields_subm {
          top: 250px !important;
        }
        & .direction + .direction {
          margin-top: 50px;
        }
      }
    }
  }
  .direct_variants {
    margin-top: 1px;
  }
  .remove_line {
    top: auto;
    bottom: 0;
  }
  .has_error {
    & .fields:not(.active) {
      & .direction + .direction {
        margin-top: 22px;
      }
      & .fields_subm {
        top: 0;
      }
    }
    & .fields.active {
      & .direction {
        margin-bottom: 20px;
      }
      & .fields_line + .fields_line {
        margin-top: 22px !important;
      }
    }
  }

  .table_wrap {
    & .table_item {
      display: flex;
      flex-wrap: wrap;
      background: @bg-select;
      & .table_tarif {
        width: 100%;
      }
      & .table_icon {
        width: calc(100% + 10px);
      }
      & > div:nth-child(1) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 12px 10px 12px;
        & b {
          & span {
            margin-left: 15px;
          }
        }
      }
      & > div:nth-child(2), > div:nth-child(4) {
        width: 75%;
        padding-left: 12px;
        min-height: 80.5px;
      }
      & > div:nth-child(3) {
        width: 25%;
        position: absolute;
        top: 45px;
        right: 0;
        height: 159px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & .table_linepoint {
          width: 2px;
          margin: 8px auto;
          flex: 1;
          &:before {
            right: -1px;
          }
          &:after {
            left: -1px;
            top: auto;
            bottom: -3px;
          }
        }
      }
      & > div:nth-child(5) {
        justify-content: flex-start;
      }
      & > div:nth-child(6) {
        width: 50%;
      }
      & > div:nth-child(7) {
        width: 20%;
      }
      & > div:nth-child(8) {
        width: 30%;
      }
    }
  }
  .side_filtr .col6 {
    width: 100%;
  }
  .font_22 {
    font-size: 14px;
    white-space: nowrap;
    flex-wrap: wrap;
    line-height: 20px;
  }
  .vg_zag svg {
    height: 22px;
  }
  .vagon_block {
    & .vagon_block_item {
      padding: 4px 0;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      & > div:nth-child(2) {
        width: 75%;
      }
      & > div:nth-child(3) {
        width: 25%;
        position: absolute;
        top: 41px;
        right: 0;
        height: 159px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & .table_linepoint {
          width: 2px;
          margin: 8px auto;
          flex: 1;
          &:before {
            right: -3px;
          }
          &:after {
            left: -3px;
            top: auto;
            bottom: -3px;
          }
        }
      }
      & > div:nth-child(4) {
        width: 75%;
      }
      & > div:nth-child(5) {
        width: 100%;
      }
    }
  }
  .vg_zaglav {
    width: 50px;
    margin-right: 4px;
    & .vg_zaglav_ico {
      display: none;
    }
  }
  .vagon_class {
    padding: 10px 10px;
  }
  .vagons_item {
    & .row {
      & > div {
        &:nth-child(1) {
          width: 36%;
        }
        &:nth-child(2) {
          width: 36%;
        }
        &:nth-child(3) {
          width: 50%;
        }
        &:nth-child(5) {
          width: 50%;
        }
      }
    }
    & .vagons_body_item {
      & .vagons_body_serv {
        flex-direction: column;
        & .vagons_body_serv_btn {
          margin-top: 8px;
          & .btn {
            margin: 0;
            width: 100%;
          }
        }
      }
    }
    &.vagons_item_sel .vagons_head .row {
      justify-content: inherit;
      & > div {
        &:nth-child(1) {
          width: 36%;
        }
        &:nth-child(2) {
          width: 36%;
        }
        &:nth-child(3) {
          width: 50%;
        }
        &:nth-child(5) {
          width: 50%;
        }
      }
    }
  }

  .vagon_slide {
    display: none;
  }
  .select_vagon {
    display: block;
    width: 100%;
    z-index: 6;
    margin: -2px 0 -6px 0;
    padding: 4px 6px !important;
    & .fake_select {
      border: 1px solid fade(@font-color, 30%);
      padding: 14px 12px;
      justify-content: space-between;
      font-weight: 700;
      border-radius: 14px;
      & > span {
        margin: 0 0 0 auto;
        font-weight: 600;
        text-transform: uppercase;
        opacity: 0.4;
      }
    }
    & .fake_dropdown ul li {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      & > span {
        font-weight: 600;
        text-transform: uppercase;
        opacity: 0.4;
      }
    }
  }
  .vagons_check {
    & .line_zag_text_value {
      width: 52px;
    }
    & .pay_group {
      margin: -6px 0 0 6px;
      flex-wrap: wrap;
      width: calc(100% - 58px);
      padding: 0;
    }
  }
  .check_group {
    flex-wrap: wrap;
    margin-top: -10px;
    & label {
      width: 100%;
      padding: 10px;
    }
    &.check_group.check_group_trio label {
      padding: 10px 10px;
    }
  }
  .vagons_row_place .vagons_body_item.salon_filter .salon_filter_dp {
    margin-top: -4px;
    flex-wrap: wrap;
  }
  .razm_group {
    width: calc(100% + 6px);
    flex-wrap: wrap;
    & > div {
      width: 100%;
      margin-top: 4px;
    }
  }
  .vagons_diapazon {
    margin: 10px 0 0;
    width: 100%;
    & b {
      width: 95px;
      padding-left: 0;
    }
    & .form_group {
      width: 94px;
    }
  }
  .razm_group.razm_group_trio > div {
    width: 100%;
  }
  .salon_alert {
    & .salon_alert_inf {
      padding: 20px;
    }
  }
}

.shem2 {
  & .search_widget_form .fake_text .simple-typeahead input {
    color: @primary-color-main;
  }
  & .fields .dates .form_input {
    color: @primary-color-main;
  }
}